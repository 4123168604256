import React, { useState } from "react";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
  useTheme,
} from "@material-ui/core/styles";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Box,
  Card,
  CardHeader,
  Collapse,
  Divider,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import LocalShippingIcon from "@material-ui/icons/LocalShippingOutlined";
import PlaceOutlined from "@material-ui/icons/PlaceOutlined";
import ScheduleIcon from "@material-ui/icons/Schedule";
import Schedule from "./Schedule";
import CardContent from "@material-ui/core/CardContent";
import LaunchIcon from "@material-ui/icons/Launch";
import CategoryIcon from "@material-ui/icons/CategoryOutlined";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import _ from "lodash";
import { Link as RouterLink } from "react-router-dom";
import { getProductCountText } from "../utils";
import { withResponsiveTarget } from "../withResponsiveTarget";
import AvatarWrapper from "../List/AvatarWrapper";
import constants from "../../Common/constants";
import styled from "styled-components";

const ResponsiveLink = withResponsiveTarget(Link);
const ListItemIconSm = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 30,
}));
const ListItemSm = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
}));

const useStyles = makeStyles((theme) => {
  return {
    card: {},
    cardHeader: {
      paddingBottom: theme.spacing(1),
    },
    listItemIcon: {
      minWidth: 26,
    },
    content: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    scheduleContainer: {
      paddingLeft: theme.spacing(4),
    },
  };
});

const themeOpts = {
  typography: {
    // htmlFontSize: 18,
    fontSize: 11,
  },
};

export default function Popup({ marker, user }) {
  const theme = useTheme();
  const classes = useStyles();

  const daysCount = _.filter(marker.schedule, (v) => v.length).length;

  const themeSm = React.memo(() => createMuiTheme({ ...theme, ...themeOpts }), [
    theme,
  ]);

  return (
    <Paper elevation={0} className="Marker__popup">
      <ThemeProvider theme={themeSm}>
        <Card elevation={0}>
          <CardHeader
            avatar={
              <AvatarWrapper
                alt={user.displayName}
                height={40}
                size="40px"
                profilePicture={user.profilePicture}
              />
            }
            className={classes.cardHeader}
            title={
              <Typography variant={"p"} color={"primary"} component={Box}>
                <ResponsiveLink
                  component={RouterLink}
                  to={`/boutique${
                    user.isGroup
                      ? `/${constants.STORE_TYPE.GROUP}`
                      : `/${constants.STORE_TYPE.PRODUCER}`
                  }/${user.storeSlug}`}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {marker.storeName}
                  <LaunchIcon fontSize="small" style={{ marginLeft: 3 }} />
                </ResponsiveLink>
              </Typography>
            }
            subheader={
              <Typography
                variant={"body2"}
                color={"textSecondary"}
                style={{ textAlign: "start" }}
              >
                {marker.name}
              </Typography>
            }
          />

          <Divider element="span" />
          <CardContent className={classes.content} style={{ paddingBottom: 8 }}>
            <List disablePadding>
              <ListItemSm>
                <ListItemIconSm>
                  <PlaceOutlined color={"primary"} />
                </ListItemIconSm>
                <ListItemText primary={marker.address} />
              </ListItemSm>
              <ExpandableItem
                icon={<ScheduleIcon color={"primary"} />}
                getFirstRow={(expanded) => {
                  const secondPart = !expanded
                    ? `: ${daysCount} jour${
                        daysCount > 1 ? "s" : ""
                      } disponible${daysCount > 1 ? "s" : ""}`
                    : "";
                  return `Retrait en ce lieu${secondPart}`;
                }}
              >
                <Schedule schedule={marker.schedule} />
              </ExpandableItem>
              <ListItemSm>
                <ListItemIconSm>
                  <MenuBookIcon color="primary" />
                </ListItemIconSm>
                <ListItemText
                  primary={getProductCountText(user.productCount)}
                />
              </ListItemSm>
              {user.categories.length ? (
                <ExpandableItem
                  icon={<CategoryIcon color={"primary"} />}
                  getFirstRow={(expanded) => {
                    if (expanded) return "Categories";
                    return (
                      <Typography noWrap={true}>
                        {user.categories.join(", ")}
                      </Typography>
                    );
                  }}
                >
                  <ul style={{ paddingLeft: 20 }}>
                    {user.categories.map((category) => (
                      <li key={category} style={{ textAlign: "start" }}>
                        {category}
                      </li>
                    ))}
                  </ul>
                </ExpandableItem>
              ) : null}
              {marker.doesDelivery && (
                <ListItemSm>
                  <ListItemIconSm>
                    <LocalShippingIcon color="primary" />
                  </ListItemIconSm>
                  <ListItemText primary="Ce producteur propose également la livraison" />
                </ListItemSm>
              )}
            </List>
          </CardContent>
        </Card>
      </ThemeProvider>
    </Paper>
  );
}

function ExpandableItem({ icon, getFirstRow, children }) {
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <React.Fragment>
      <ListItemSm button onClick={handleExpandClick}>
        <ListItemIconSm>{icon}</ListItemIconSm>
        <ListItemText>{getFirstRow(expanded)}</ListItemText>
        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemSm>
      <List component="div" disablePadding>
        <Collapse in={expanded}>
          <ListItemText style={{ paddingLeft: theme.spacing(4) }}>
            {children}
          </ListItemText>
        </Collapse>
      </List>
    </React.Fragment>
  );
}
