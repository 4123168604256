import React, { useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Material Components
import NativeSelect from "@material-ui/core/NativeSelect";

// Material icons
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";

// Styleds
const StyledSelect = styled(NativeSelect)`
  padding: 8px;
  font-size: 16px;
  width: 100%;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.palette.grey["100"]};
  font-weight: 400;
  height: 38px;
  text-align-last: center;
`;

const DropDownIcon = styled(KeyboardArrowDown)`
  right: 10px;
  color: ${({ theme }) => theme.palette.grey["700"]};
`;

const SelectInput = ({ id, className, value, onChange, options }) => {
  const handleChange = useCallback((event) => onChange(event.target.value), [
    onChange,
  ]);
  return (
    <StyledSelect
      disableUnderline
      className={className}
      IconComponent={DropDownIcon}
      id={id}
      value={value}
      onChange={handleChange}
    >
      {options.map((it, idx) => (
        <option key={`${id}-${idx}-option`} value={it}>
          {it}
        </option>
      ))}
    </StyledSelect>
  );
};

SelectInput.protoTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  options: PropTypes.array,
};

SelectInput.defaultProps = {
  id: "",
  className: "",
  value: "",
  onChange: () => {},
  options: [],
};

export default SelectInput;
