import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Material Components
import { Typography } from "@material-ui/core";

// Styleds
const StyledTytle = styled(Typography)`
  font-weight: bold;
`;

const Title = ({ children, color, noWrap, className, component }) => {
  return (
    <StyledTytle
      component={component}
      variant="body1"
      className={className}
      color={color}
      noWrap={noWrap}
    >
      {children}
    </StyledTytle>
  );
};

Title.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  component: PropTypes.string,
  noWrap: PropTypes.bool,
};

Title.defaultProps = {
  color: "textSecondary",
  component: undefined,
  noWrap: false,
};

export default Title;
