import React from "react";
import { Theme } from "@material-ui/core";
import { createAppTheme } from "../Common/appTheme";
import GlobalLoader from "../Common/GlobalLoader";

export type EmailType = "INVALID" | "NO_REPLY" | "CONTACT_EMAIL";
export type ExternalSiteType =
  | "INVALID_EXTERNAL_SITE_TYPE"
  | "SUPPORT_CENTER"
  | "KNOWLEDGE_DATABASE";
export type FaviconType =
  | "FAVICON_16"
  | "FAVICON_32"
  | "FAVICON_96"
  | "FAVICON";
export type LogoType =
  | "INVALID_LOGO_TYPE"
  | "LARGE"
  | "LARGE_WHITE"
  | "LARGE_VERTICAL"
  | FaviconType;

export type Multimedia = {
  id: string;
  filename: string;
  url: string;
};
export type BrandLogo = {
  multimedia: Multimedia;
  logoType: LogoType;
};
export type BrandLink = {
  url: string;
  externalSiteType: ExternalSiteType;
};
export type BrandEmail = {
  emailAddress: string;
  emailType: EmailType;
};
export type BrandCompany = {
  name: string;
  phone: string;
};

export interface MarketplaceFeatureFlags {
  manageCustomerBasicInfo: boolean;
}

export type BrandConfig = {
  id: string;
  theme: {
    palette: any;
    typography: Partial<Theme["typography"]> & { htmlFontSize: number };
    logoConfigurations: BrandLogo[];
  };
  externalSiteConfigurations: BrandLink[];
  emailConfigurations: BrandEmail[];
  featureFlags: MarketplaceFeatureFlags;
  company: BrandCompany;
  updatedAt: string;
};

interface ILocalStorageItem {
  rawValue: string | null;
  jsonParsedValue: Object | null;
  setValue: <S extends boolean>(
    value: S extends true ? Object : string,
    stringify?: S
  ) => void;
}

class LocalStorageItem implements ILocalStorageItem {
  constructor(private key: string) {}
  get rawValue() {
    return localStorage.getItem(this.key);
  }
  get jsonParsedValue() {
    return this.rawValue ? JSON.parse(this.rawValue) : null;
  }
  setValue<S extends boolean>(
    value: S extends true ? Object : string,
    stringify?: S
  ) {
    const itemValue = stringify ? JSON.stringify(value) : (value as string);
    localStorage.setItem(this.key, itemValue);
  }
}

class MockLocalStorageItem implements ILocalStorageItem {
  static inMemoryStorage: Record<string, string> = {};
  constructor(private key: string) {}
  get rawValue() {
    return MockLocalStorageItem.inMemoryStorage[this.key];
  }
  get jsonParsedValue() {
    return this.rawValue ? JSON.parse(this.rawValue) : null;
  }
  setValue<S extends boolean>(
    value: S extends true ? Object : string,
    stringify?: S
  ) {
    MockLocalStorageItem.inMemoryStorage[this.key] = stringify
      ? JSON.stringify(value)
      : (value as string);
  }
}

function createLocalStorageItem(key: string) {
  function isLocalStorageSupported() {
    const mod = "modernizr";
    try {
      localStorage.setItem(mod, mod);
      localStorage.removeItem(mod);
      return true;
    } catch (e) {
      return false;
    }
  }

  return isLocalStorageSupported()
    ? new LocalStorageItem(key)
    : new MockLocalStorageItem(key);
}

const brandConfigRequestErrorMessage = "Error when try to get brand config.";
const emptyBrandConfig = {} as BrandConfig;
const BrandConfigContext = React.createContext<BrandConfig>(emptyBrandConfig);
export const storageBranConfigItem = createLocalStorageItem("brandConfig");
const storageBrandIdItem = createLocalStorageItem("brandId");

export const BrandConfigProvider: React.FC = ({ children }) => {
  const [brandConfig, setBrandConfig] =
    React.useState<BrandConfig>(emptyBrandConfig);

  React.useEffect(() => {
    const localBranConfig = storageBranConfigItem.jsonParsedValue;
    if (localBranConfig) {
      setBrandConfig(localBranConfig);
    }
    (async () => {
      const brandConfig = await requestBrandConfig();
      setBrandConfig(brandConfig || localBranConfig);
      if (brandConfig) {
        storageBranConfigItem.setValue(brandConfig, true);
      }
    })();
  }, []);

  return brandConfig === emptyBrandConfig ? (
    <GlobalLoader />
  ) : (
    <BrandConfigContext.Provider value={brandConfig}>
      {children}
    </BrandConfigContext.Provider>
  );
};

async function requestBrandConfig() {
  try {
    const response = await fetch(getBrandConfigUrl());
    return await response.json();
  } catch (error) {
    console.error(brandConfigRequestErrorMessage, error);
    return null;
  }
}

function getBrandConfigUrl() {
  const partialUrl = getBrandId() || `domain/${getHostName()}`;
  return `${process.env.REACT_APP_BRAND_CONFIG_URL}/feconfig/${partialUrl}/marketplace`;
}

function getBrandId() {
  let [, brandId] = /brandId=(.*?)(?:&.*)?$/.exec(window.location.search) || [
    null,
    null,
  ];
  if (brandId === null) {
    brandId = storageBrandIdItem.rawValue;
  } else {
    storageBrandIdItem.setValue(brandId as string);
  }
  return brandId;
}

function getHostName() {
  const hostName = window.location.hostname || "";
  return hostName === "localhost" ||
    hostName.includes("amplifyapp.com") ||
    hostName.includes("netlify.app")
    ? "admin.kuupanda.com"
    : hostName;
}

export function withBrandConfig(WrappedComponent: typeof React.Component) {
  return (props: Record<string, unknown>) => {
    const brandConfig = useBrandConfig();

    if (!brandConfig) return brandConfigRequestErrorMessage;

    const logoConfigurations = brandConfig.theme?.logoConfigurations;

    React.useEffect(() => {
      if (logoConfigurations.length) {
        assignFavicons(brandConfig.theme.logoConfigurations);
      }
    }, [logoConfigurations, brandConfig.theme]);

    const configWithMuiTheme = React.useMemo(() => {
      const muiTheme = createAppTheme(brandConfig.theme);
      return { ...brandConfig, muiTheme };
    }, [brandConfig]);

    return <WrappedComponent {...props} brandConfig={configWithMuiTheme} />;
  };
}

export function useBrandConfig() {
  return React.useContext(BrandConfigContext);
}

function assignFavicons(logos: BrandLogo[]) {
  const faviconTypes: FaviconType[] = [
    "FAVICON",
    "FAVICON_16",
    "FAVICON_32",
    "FAVICON_96",
  ];
  for (const faviconType of faviconTypes) {
    const faviconUrl = searchFaviconUrl(logos, faviconType);
    if (faviconUrl) {
      setFaviconHref(faviconUrl, faviconType);
    }
  }
}

function searchFaviconUrl(logos: BrandLogo[], faviconType: FaviconType) {
  const favicon = logos.find(({ logoType }) => logoType === faviconType);
  return favicon ? favicon.multimedia.url : "";
}

function setFaviconHref(faviconHref: string, faviconType: FaviconType) {
  const size = faviconType === "FAVICON" ? "" : faviconType.slice(-2);
  const faviconLink = getFaviconLink(size);
  if (faviconLink.href === faviconHref) return;
  faviconLink.href = faviconHref;
}

function getFaviconLink(size: string): HTMLLinkElement {
  const sizesQuery = size ? `[sizes='${size}x${size}']` : ":not([sizes])";
  const link = document.head.querySelector(
    "link[rel='icon']" + sizesQuery
  ) as HTMLLinkElement;
  return link || createFaviconLink(size);
}

function createFaviconLink(size: string): HTMLLinkElement {
  const link = document.createElement("link");
  link.rel = "icon";
  if (size) {
    link.setAttribute("sizes", `${size}x${size}`);
  }
  return link;
}
