import { latLng } from "../ProducerList/utils";

// thiw one centers on ceret
// export const DEFAULT_CENTER = latLng(42.484256, 2.7473919)
// arbitrary set to mid-way between Perpignan and Ceret
export const DEFAULT_CENTER = latLng(42.566816, 2.815458);
export const DEFAULT_ZOOM = 11;

export const DEFAULT_TAGS = [
  { value: "AB", label: "Agriculture biologique" },
  { value: "AOC", label: "AOC" },
  { value: "AOP", label: "AOP" },
  { value: "BIO_COHERENCE", label: "Bio cohérence" },
  { value: "BIO_PARTENAIRE", label: "Bio partenaire" },
  { value: "DEMETER", label: "Demeter" },
  { value: "ECOCERT", label: "Ecocert" },
  { value: "IGP", label: "IGP" },
  { value: "LABEL_ROUGE", label: "Label rouge" },
  { value: "NATURE_ET_PROGRES", label: "Nature et progrès" },
  { value: "STG", label: "STG" },
  { value: "jeunes_agriculteurs", label: "Jeunes agriculteurs" },
  { value: "SUD_FRANCE", label: "Sud de France" },
  { value: "HVE", label: "Haute valeur environnementale" },
];

export const DEFAULT_CATEGORIES = [
  { value: "_AROMATIC_PLANTS", label: "Plantes aromatiques" },
  { value: "_ALGAE", label: "Algues" },
  { value: "_BAKERY", label: "Boulangerie/Patisserie" },
  { value: "_BEVERAGES", label: "Boissons" },
  { value: "_MEAT-AND-DELICATESSEN", label: "Boucherie/Charcuterie" },
  { value: "_BAKERY", label: "Boulangerie/Patisserie" },
  { value: "_COSMETICS", label: "Cosmétique et bien-être" },
  { value: "_SEEDLINGS", label: "Plants maraîchers" },
  { value: "_CEREALS-LEGUMES", label: "Céréales et légumineuses" },
  { value: "_SPICE", label: "Epices" },
  { value: "_EDIBLE-FLOWERS", label: "Fleurs comestibles" },
  { value: "_FRUITS-VEGETABLES", label: "Fruits/Légumes" },
  { value: "_DAIRY-PRODUCTS", label: "Produits laitiers" },
  { value: "_EGGS", label: "Oeufs" },
  { value: "_AROMATIC-PLANTS", label: "Plantes aromatiques" },
  { value: "_SEA-PRODUCTS", label: "Produits de la mer" },
  { value: "_BEE-PRODUCTS", label: "Produits de la ruche" },
  { value: "_TRANSFORMED-PRODUCTS", label: "Produits transformés" },
  { value: "_COMBO", label: "Lots" },
];

export const ALL_CATEGORIES = [
  ...DEFAULT_CATEGORIES,
  { value: "_FOOD", label: "Alimentaire" },
  { value: "_READY-TO-WEAR", label: "Prêt-à-porter" },
  { value: "_RESTAURATION", label: "Restauration" },
  { value: "_OTHER", label: "Autre" },
  { value: "_MULTIMEDIA", label: "Multimédia / Électroménager" },
  { value: "_ART-DECO", label: "Art et décoration" },
  { value: "_BOOK-GAME", label: "Librairie, jeux" },
  { value: "_JEWELLERY", label: "Bijouterie" },
  { value: "_CARE-COSMETICS", label: "Soin et bien être" },
  { value: "_FLORIST", label: "Fleuriste / Jardinerie" },
];
export const DEFAULT_PRODUCTS = [
  { value: "_BREAD", label: "Pain" },
  { value: "_BREAD-PASTRY", label: "Viennoiserie" },
  { value: "_BRIOCHE", label: "Brioche Et Gâches" },
  { value: "_HONEY", label: "Miel" },
  { value: "_ROYAL-JELLY", label: "Gelée Royale" },
  { value: "_POLLEN", label: "Pollens" },
  { value: "_PROPOLIS", label: "Propolis" },
  { value: "_ALCOHOL", label: "Spiritueux" },
  { value: "_BEER-CIDER", label: "Bière Et Cidre" },
  { value: "_JUICE", label: "Jus" },
  { value: "_WINE-CHAMPAGNE", label: "Vin Et Champagne" },
  { value: "_CHEESE", label: "Fromage" },
  { value: "_EGGS", label: "Oeufs" },
  { value: "_FRESHCREAM", label: "Crème Et Crème Fraiche" },
  { value: "_MILK", label: "Lait" },
  { value: "_MILK-DRINKS", label: "Boissons Dérivées" },
  { value: "_YOGURT", label: "Yahourt" },
  { value: "_ACEROLA-CARIBBEAN-CHERRY", label: "Acérola, Cerise Des Antilles" },
  { value: "_ALEXANDERS", label: "Maceron" },
  { value: "_ALMOND", label: "Amande" },
  { value: "_APPLE", label: "Pomme" },
  { value: "_APRICOT", label: "Abricot" },
  { value: "_ARBUTUS", label: "Arbouse" },
  { value: "_BASIL", label: "Basilic" },
  { value: "_AROMATIC-PLANTS", label: "Autres Plantes Aromatiques" },
  { value: "_ARTICHOKE", label: "Artichaut" },
  { value: "_ASPARAGUS", label: "Asperge" },
  { value: "_ANNONA_SQUAMOSA", label: "Attier, Pomme Cannelle, Cachiman" },
  { value: "_AVOCADO", label: "Avocat" },
  { value: "_AZUKI-JAPAN-RED-BEAN", label: "Azuki, Haricot Rouge Du Japon" },
  { value: "_BALSAM-PEAR", label: "Momordique" },
  { value: "_BAMBOO-SHOOTS", label: "Pousse De Bambou" },
  { value: "_BANANA", label: "Banane, Banane Plantin" },
  { value: "_BARBERRY", label: "Épine-Vinette" },
  { value: "_BEAN", label: "Haricot, Fève" },
  { value: "_BEET", label: "Betterave" },
  { value: "_BERGAMOT-COMBAVA", label: "Bergamote, Combava" },
  { value: "_BITTER-MELON", label: "Margose" },
  { value: "_BLACKBERRY", label: "Mûre" },
  { value: "_BLUEBERRY", label: "Myrtille Et Myrte" },
  { value: "_BREDES", label: "Brèdes" },
  { value: "_BROCCOLI", label: "Brocoli" },
  { value: "_BURDOCK", label: "Bardane, Chardon" },
  { value: "_CABBAGE", label: "Chou" },
  { value: "_CAIMITE-STAR-APPLE", label: "Caïmite, Pomme De Lait" },
  { value: "_CAROM", label: "Carambole" },
  { value: "_CARROT", label: "Carotte" },
  { value: "_CASHEW-APPLE", label: "Pomme De Cajou" },
  { value: "_CASHEW-NUT", label: "Noix De Cajou" },
  { value: "_CASSAVA", label: "Manioc" },
  { value: "_CASSIS", label: "Cassis" },
  { value: "_CAULIFLOWER", label: "Chou-Fleur" },
  { value: "_CELERY", label: "Céleri, Célerie-Rave" },
  { value: "_CHARD", label: "Poirée" },
  { value: "_CHAYOTE-CHRISTOPHINE", label: "Chayote, Christophine" },
  { value: "_CHERIMOLE-CHERIMOYA", label: "Cherimole, Cherimoya" },
  { value: "_CHERRY", label: "Cerise" },
  { value: "_CHERRY-CAYENNE", label: "Cerise De Cayenne" },
  { value: "_CHERVIS", label: "Chervis" },
  { value: "_CHESTNUT", label: "Châtaigne Et Marron" },
  { value: "_CHIA", label: "Chia" },
  { value: "_CHICKPEA", label: "Pois Chiche" },
  { value: "_CHILLI-PEPPER", label: "Piment" },
  { value: "_CHIVE", label: "Ciboulette" },
  { value: "_CLAYTONE-OF-CUBA", label: "Claytone De Cuba" },
  { value: "_COCOA-BEANS", label: "Cacao, Fèves" },
  { value: "_COCONUT", label: "Noix De Coco" },
  { value: "_CORIANDER", label: "Coriandre" },
  { value: "_CORN", label: "Maïs" },
  { value: "_COWPEAS", label: "Niébé" },
  { value: "_CRANBERRY", label: "Groseille, Canneberge" },
  { value: "_CRESS", label: "Cresson" },
  { value: "_CROSNE-FROM-JAPAN", label: "Crosne Du Japon" },
  { value: "_CUCUMBER", label: "Concombre" },
  { value: "_CYTHER-PLUM", label: "Prune De Cythère" },
  { value: "_DAIKON", label: "Daikon" },
  { value: "_DAMSON", label: "Quetsche" },
  { value: "_DANDELION", label: "Pissenlit" },
  { value: "_DASHEEN-TARO", label: "Dachine-Taro" },
  { value: "_DATE", label: "Datte" },
  { value: "_DURIAN", label: "Durian" },
  { value: "_EARTH-PEAR", label: "Poire De Terre" },
  { value: "_EGGPLANT", label: "Aubergine" },
  { value: "_ENDIVE", label: "Endives, Chicon" },
  { value: "_FEI", label: "Fe'I" },
  { value: "_FEIJOA", label: "Feijoa" },
  { value: "_FENNEL", label: "Fenouil" },
  { value: "_FIG", label: "Figue" },
  { value: "_FROSTY-FICOIDE", label: "Ficoïde Glaciale" },
  { value: "_GARLIC", label: "Ail, Aillet" },
  { value: "_GINGER", label: "Gingembre" },
  { value: "_GIREMBELLE", label: "Girembelle" },
  { value: "_GOMBO", label: "Gombo" },
  { value: "_GOURD", label: "Gourde" },
  { value: "_GRAPEFRUIT", label: "Pamplemousse, Pomélos" },
  { value: "_GRAPES", label: "Raisin" },
  { value: "_GRELOS", label: "Grelos" },
  { value: "_GRENADE", label: "Grenade" },
  {
    value: "_GRENADILLA-PASSION-FRUIT",
    label: "Grenadille, Fruits De La Passion Et Assimilés",
  },
  { value: "_GUAVA", label: "Goyave" },
  { value: "_HAZELNUT", label: "Noisette" },
  { value: "_HELIANTHI", label: "Hélianthi" },
  { value: "_HORSERADISH", label: "Raifort" },
  { value: "_HUCKLEBERRY", label: "Airelle" },
  { value: "_ICAQUE", label: "Icaque" },
  { value: "_JACKFRUIT-BREADFRUIT", label: "Jacquier, Fruit-À-Pain" },
  { value: "_JERUSALEM-ARTICHOKE", label: "Topinambour" },
  { value: "_JUJUBE", label: "Jujube" },
  { value: "_KHAKI", label: "Kaki" },
  { value: "_KIWANO", label: "Kiwano" },
  { value: "_KIWI", label: "Kiwi" },
  { value: "_KOHLRABI", label: "Chou-Rave" },
  { value: "_KUMQUAT", label: "Kumquat" },
  { value: "_LEEK", label: "Poireau" },
  { value: "_LEMON", label: "Citron" },
  { value: "_LENS", label: "Lentille" },
  { value: "_LIME", label: "Citron Vert" },
  { value: "_LITCHI-RAMBUTAN", label: "Litchi, Rambutan" },
  { value: "_LONGAN", label: "Longane" },
  { value: "_LOOFAH", label: "Luffa" },
  { value: "_MANDARIN", label: "Mandarine, Clémentine" },
  { value: "_MANGO", label: "Mangue" },
  { value: "_MANGOSTEEN", label: "Mangoustan" },
  { value: "_MEDLAR", label: "Nèfle" },
  { value: "_MELON", label: "Melon" },
  { value: "_MINT", label: "Menthe" },
  { value: "_MIRABELLE", label: "Mirabelle" },
  { value: "_MORELLO", label: "Griotte" },
  { value: "_MUSTARD", label: "Moutarde" },
  { value: "_NAVEL-OF-VENUS", label: "Nombril De Vénus" },
  { value: "_NECTARINE-PEACH", label: "Brugnon, Pêches" },
  { value: "_NETTLE", label: "Ortie" },
  { value: "_NUTMEG", label: "Noix De Muscade" },
  { value: "_NUTS", label: "Noix" },
  { value: "_OKRA", label: "Okra" },
  { value: "_OLIVE", label: "Olive" },
  { value: "_ONION", label: "Oignon" },
  { value: "_ORACH", label: "Arroche" },
  { value: "_ORANGE", label: "Orange" },
  { value: "_PAK-CHOI-PE-TSAI", label: "Pak Choï, Pe-Tsaï" },
  { value: "_PAPAYA", label: "Papaye" },
  { value: "_PARSLEY", label: "Persil" },
  { value: "_PARSNIP", label: "Panais" },
  { value: "_PATTYPAN", label: "Pâtisson" },
  { value: "_PAWPAW", label: "Asimine" },
  { value: "_PEAS", label: "Petit Pois Et Assimilés" },
  { value: "_PEANUT", label: "Arachide, Cacahuète" },
  { value: "_PEAR", label: "Poire" },
  { value: "_PEPPER", label: "Poivron" },
  { value: "_PHYSALIS", label: "Physalis" },
  { value: "_PICKLE", label: "Cornichon" },
  { value: "_PINEAPPLE", label: "Ananas" },
  { value: "_PISTACHIO", label: "Pistache" },
  { value: "_PITAYA", label: "Pitaya" },
  { value: "_PLATE", label: "Plaquebière" },
  { value: "_PLUM-AND-PRUNE", label: "Prune, Pruneau Et Assimilés" },
  { value: "_POTATO", label: "Pomme De Terre" },
  { value: "_PRICKLY-PEAR", label: "Figue De Barbarie" },
  { value: "_PUMPKIN", label: "Citrouille, Potimarron, Potiron" },
  { value: "_PURSLANE", label: "Pourpier" },
  { value: "_QUENETTE", label: "Quenette" },
  { value: "_QUINCE", label: "Coing" },
  { value: "_RADISH", label: "Radis Et Radis Noir" },
  { value: "_RADISH-FROM-JAPAN", label: "Radis Du Japon" },
  { value: "_RASPBERRY", label: "Framboise" },
  { value: "_RHUBARB", label: "Rhubarbe" },
  { value: "_ROSEHIP", label: "Cynorrhodon" },
  { value: "_RUTABAGA", label: "Rutabaga" },
  { value: "_SALAD", label: "Salade" },
  { value: "_SALAK", label: "Salak" },
  { value: "_SALSIFY", label: "Salsifis" },
  { value: "_SAMPHIRE", label: "Salicorne" },
  { value: "_SAPODILLA", label: "Sapotille" },
  { value: "_SAPOTE", label: "Sapote" },
  { value: "_SASKATOON", label: "Amélanche" },
  { value: "_SHALLOT", label: "Échalote" },
  { value: "_SORREL", label: "Oseille" },
  { value: "_SOY", label: "Soja" },
  { value: "_SPINACH", label: "Épinard, Blette Et Assimilés" },
  { value: "_SPRING-ONION", label: "Ciboule, Cebette, Ciboule De Chine" },
  { value: "_SQUASH", label: "Courge, Calebasse Et Assimilés" },
  { value: "_STRAWBERRY", label: "Fraise" },
  { value: "_TAMARILLO", label: "Tamarillo" },
  { value: "_TAMARIND", label: "Tamarin" },
  { value: "_TAXO", label: "Taxo" },
  { value: "_THE-PASSIVE-LORES", label: "Passiflores" },
  { value: "_TOMATO", label: "Tomate" },
  { value: "_TUBEROUS-GESS", label: "Châtaigne De Terre" },
  { value: "_TUBEROUS-GLYCINE", label: "Glycine Tubéreuse" },
  { value: "_TUBEROUS-NASTURTIUM", label: "Capucine Tubéreuse" },
  { value: "_TURMERIC", label: "Curcuma" },
  { value: "_TURNIP", label: "Navet" },
  { value: "_VEGETABLE-SNAKE", label: "Serpent Végétal" },
  { value: "_WAKAME", label: "Wakame" },
  { value: "_WASABI", label: "Wasabi" },
  { value: "_WATERMELON", label: "Pastèque" },
  { value: "_WINGED-YAM", label: "Igname Ailée" },
  { value: "_YAM", label: "Patate Douce" },
  { value: "_YELLOW-NUTSEDGE", label: "Amande De Terre" },
  { value: "_YUZU", label: "Yuzu" },
  { value: "_ZUCCHINI", label: "Courgette" },
  { value: "_COW-MEAT", label: "Viande De Bœuf Et Veau" },
  { value: "_HAM", label: "Jambon Et Saucisson" },
  { value: "_HORSE-MEAT", label: "Viande De Cheval" },
  { value: "_LAMB-MEAT", label: "Viande De Mouton Et Agneau" },
  { value: "_PATE", label: "Pâté Et Rillettes" },
  { value: "_PORK-MEAT", label: "Viande De Porc" },
  { value: "_POULTRY-MEAT", label: "Viande De Volailles" },
  { value: "_RABBIT-MEAT", label: "Viande De Lapin" },
  { value: "_BISCUITS", label: "Biscuits" },
  { value: "_CONFECTIONERY", label: "Confiserie" },
  { value: "_ICECREAM", label: "Glace" },
  { value: "_JAM", label: "Confiture" },
  { value: "_PASTRY", label: "Patisserie" },
  { value: "_SPREAD", label: "Pâte À Tartiner" },
  { value: "_COMBO", label: "Combo" },
  { value: "_COMPOTE", label: "Compote" },
  { value: "_FRUIT-PUREE", label: "Purée De Fruits" },
  { value: "_FRUITS-VEGET-CART", label: "Panier Fruits et Légumes" },
  { value: "_SALT", label: "Sel" },
  { value: "_PEEPER", label: "Poivre" },
  { value: "_SPICEMIX", label: "Mélange" },
  { value: "_HERBAL-TEAS", label: "Tisane" },
  { value: "_SYRUP", label: "Sirop" },
  { value: "_YAHOURT-VEGETAUX", label: "Yaourt végétaux" },
  { value: "_FRUIT_LEGUMES-DESHYDRATES", label: "Fruits Légumes déshydratés" },
  { value: "_OIL", label: "Huile" },
  { value: "_VINEGAR", label: "Vinaigre" },
  { value: "_SUGAR", label: "Sucre" },
  { value: "_LACTO-FERMENTATION", label: "Lacto-fermentation" },
  { value: "_EDIBLE-FLOWERS", label: "Fleurs comestibles" },
  { value: "_RICE", label: "Riz" },
  { value: "_SPIRULINA", label: "Spiruline" },
  { value: "_JELLY-CANDY", label: "Pâte de fruit" },
  { value: "_WILDPLANTS", label: "Plantes sauvages" },
  { value: "_SOUP", label: "Soupes" },
  { value: "_SEA-FISH", label: "Poissons de mer" },
  { value: "_FRESH-WATER-FISH", label: "Poissons d'eau douce" },
  { value: "_SEA-EGGS", label: "Oeufs de poisson" },
  { value: "_SEA-FOOD", label: "Coquillages et crustacés" },
  { value: "_FISH-CAN", label: "Conserves" },
  {
    value: "_SEA-PRODUCTS-OTHER",
    label: "Poissons et Produits de la mer - Autre",
  },
  { value: "_FRUITS-VEGETABLES-OTHER", label: "Fruits Légumes - Autre" },
  { value: "_BEVERAGES-OTHER", label: "Boissons - Autre" },
  { value: "_HYDROSOLS", label: "Hydrolats" },
  {
    value: "_TRANSFORMED-PRODUCTS-OTHER",
    label: "Produits Transformés - Autre",
  },
  { value: "_PASTA", label: "Pâtes" },
  { value: "_VEGETABLE-PATE", label: "Pâtés végétaux et tartinades" },
  { value: "_DAIRY-PRODUCTS-OTHER", label: "Produits Laitiers - Autre" },
  { value: "_BAKERY-OTHER", label: "Boulangerie - Autre" },
  { value: "_EDIBLE-FLOWERS-OTHER", label: "Fleurs Comestibles - Autre" },
  { value: "_BEE-PRODUCTS-OTHER", label: "Produits De La Ruche - Autre" },
  {
    value: "_MEAT-AND-DELICATESSEN-OTHER",
    label: "Boucherie Charcuterie - Autre",
  },
  { value: "_SPICE-OTHER", label: "Épices - Autre" },
  { value: "_ALGAE-OTHER", label: "Algues - Autre" },
  { value: "_SOAP-SHAMPOO", label: "Savon et Shampoing" },
  { value: "_CREAM-BODY-MILK", label: "Crème et Lait pour le corp" },
  { value: "_DEODORANT", label: "Déodorants" },
  { value: "_PERFUME", label: "Parfums" },
  { value: "_ESSENTIAL-OIL", label: "Huiles essentielles" },
  { value: "_SAFFRON", label: "Safran" },
  { value: "_COSMETIC-OTHER", label: "Cosmétique et bien-être - Autre" },
];

export const SIZES = [
  "Taille unique",
  "Prématuré",
  "Nouveau né",
  "1 mois",
  "1-3 mois",
  "3-6 mois",
  "6-9 mois",
  "9-12 mois",
  "12-18 mois",
  "18-24 mois",
  "24-36 mois",
  "4 ans",
  "6 ans",
  "8 ans",
  "10 ans",
  "12 ans",
  "14 ans",
  "16 ans",
  "XXXS / 30",
  "XXS / 32",
  "XS / 34",
  "S / 36",
  "M / 38",
  "L / 40",
  "XL / 42",
  "XXL / 44",
  "XXXL / 46",
  "4XL / 48",
  "XS",
  "S",
  "M",
  "L",
  "XL",
  "XXL",
  "XXXL",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
];

export const DEFAULT_PRODUCER_LIST_COVER_1 =
  "https://s3.eu-west-3.amazonaws.com/kuu.imgs/original/assets/producer-list-default-image-1.jpg";
export const DEFAULT_PRODUCER_LIST_COVER_2 =
  "https://s3.eu-west-3.amazonaws.com/kuu.imgs/original/assets/producer-list-default-image-2.png";

export const PAYMENT_TYPES = {
  ONLINE_PAYMENT: "onlinePayment",
  SPOT_PAYMENT: "spotPayment",
};

const constants = {
  DATE_FORMAT: "dd/MM/yyyy",
  DATE_TIME_FORMAT: "dd/MM/yyyy HH:mm",
  CONTACT_POINT_TYPE: {
    TAKE_AWAY: "TAKE_AWAY",
    DELIVERY: "DELIVERY",
    CHRONOPOST: "CHRONOPOST",
    CHRONOPOST_RELAY: "CHRONOPOST_RELAY",
    CHRONOPOST_VITI: "CHRONOPOST_VITI",
  },
  FEES_TYPE: {
    DELIVERY: "DELIVERY",
  },
  STORE_TYPE: {
    PRODUCER: "producer",
    GROUP: "group",
  },
  ENVIRONMENT: {
    DEV: "development",
    PROD: "production",
  },
  STORAGE_TYPE: {
    STANDARD: "STANDARD",
    COLD: "COLD",
  },
  CHRONOPOST_TYPES: {
    CHRONOPOST: "CHRONOPOST",
    CHRONOPOST_RELAY: "CHRONOPOST_RELAY",
    CHRONOPOST_VITI: "CHRONOPOST_VITI",
  },
  CHRONOPOST_MODES: { FRESH: "FRESH", DRY: "DRY" },
  CHRONOPOST_MAX_WEIGHTS: {
    CHRONOPOST: 30,
    CHRONOPOST_RELAY: 20,
  },
  CHRONOPOST_DELIVERY_FREQUENCY: {
    EVERYDAY: "EVERYDAY",
    EVERY_TWO_DAYS: "EVERY_TWO_DAYS",
    TWICE_A_WEEK: "TWICE_A_WEEK",
    ONCE_A_WEEK: "ONCE_A_WEEK",
  },
  COMPONENT: {
    STORE: "STORE",
    FORM: "FORM",
  },
  PRODUCT_ERRORS: {
    PRICE_CHANGE: "PRICE_CHANGE",
    UNAVAILABLE: "UNAVAILABLE",
    STOCK_CHANGE: "STOCK_CHANGE",
  },
};

export default constants;
