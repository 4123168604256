import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

// Material Components
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Hidden from "@material-ui/core/Hidden";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  StepConnector,
  StepLabel,
  Typography,
} from "@material-ui/core";

// Material Hooks
import { useTheme } from "@material-ui/core/styles";

// Components
import ButtonComponent from "./Button";
import media from "../Helpers/media";

const Container = styled.div`
  width: 100%;
`;
const CheckboxContainer = styled(Grid)`
  margin: 20px;
  max-width: 100%;
  ${media.down.sm`
    width: 300px;
  `}
`;
const StyledStepper = styled(Stepper)`
  background-color: inherit;
  padding: 0;
  .MuiStepLabel-label {
    ${({ theme }) => theme.typography.body1}
    color: ${({ theme }) => theme.palette.grey["800"]}
  }
  .MuiStepIcon-root {
    color: transparent;
    border: 1.5px solid ${({ theme }) => theme.palette.grey["300"]};
    box-sizing: border-box;
    border-radius: 50px;
    .MuiStepIcon-text {
      fill: ${({ theme }) => theme.palette.grey["300"]};
    }
  }
  .MuiStepIcon-active,
  .MuiStepIcon-completed {
    color: ${({ theme }) => theme.palette.primary.main};
    border: none;
    .MuiStepIcon-text {
      fill: #fff;
    }
  }
  .MuiSvgIcon-root {
    width: 1.7em;
    height: 1.7em;
  }
  .MuiStepConnector-alternativeLabel {
    top: 1.3em;
    left: calc(-50% + 1.7em);
    right: calc(50% + 1.7em);
  }
`;
const marginButton = css`
  margin: 10px 5px;
`;

const StepContainer = styled.div``;
const ButtonContainer = styled.div`
  margin: 10px 0;
  padding: 0 4em;
`;
const BackIcon = styled(ArrowBackIcon)`
  background-color: ${(props) =>
    !props.disabled ? props.backgroundcolor : "rgba(0, 0, 0, 0.26)"};
  color: ${(props) => props.iconcolor};
  border-radius: 12px;
  margin-right: 5px;
`;
const BackButton = styled(Button)`
  ${marginButton};
  float: left;
`;
const NextButton = styled(Button)`
  ${marginButton};
  float: right;
`;
const CompletedButton = styled(Button)`
  ${marginButton};
  float: right;
`;
const MobileStepContainer = styled.div`
  background-color: white;
  padding-top: 17px;
  height: 100%;
`;
const MobileStepComponent = styled(Grid)`
  background-color: white;
`;
const StyledStepperMobile = styled(Stepper)`
  background-color: inherit;
  padding: 24px 0;
  .MuiStep-horizontal {
    padding: 0;
    .MuiStepLabel-iconContainer {
      padding: 0;
    }
  }
`;
const DotMobile = styled.span`
  background-color: white;
  border: 10px solid
    ${({ theme, active }) =>
      active ? theme.palette.primary.main : theme.palette.grey["100"]};
  border-radius: 20px;
  width: 6px;
  height: 6px;
`;
const StyledStepConnector = styled(StepConnector)`
  height: 3px;
  border: 0;
  background-color: ${(props) =>
    props.active || props.completed
      ? props.theme.palette.primary.main
      : props.theme.palette.grey["100"]};
  border-radius: 1px;
  .MuiStepConnector-lineHorizontal {
    border-top-style: hidden;
  }
`;
const MobileButton = styled(ButtonComponent)`
  left: 5%;
  width: 90%;
  margin-bottom: 20px;
`;
const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey["800"]};
  text-transform: uppercase;
  font-weight: bold;
`;

// Custom Hooks
export function useSteps(initSteps = [], deps = []) {
  const [contemplatedList, setContemplatedList] = useState(
    initSteps.map((i) => i.completed)
  );

  const switchCompleted = useCallback(
    (key, { rollback } = {}) => {
      const index = initSteps.findIndex((s) => s.key === key);
      if (index !== -1) {
        if (rollback && index === 0) {
          setContemplatedList(initSteps.map(() => false));
        } else {
          setContemplatedList((previous) => [
            ...previous.slice(0, index),
            !rollback,
            ...previous.slice(index + 1),
          ]);
        }
      }
    },
    [initSteps]
  );

  const steps = useMemo(
    () =>
      initSteps.map((step, index) => {
        return {
          ...step,
          completed: contemplatedList[index],
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...deps, contemplatedList]
  );

  return [steps, switchCompleted];
}

export function useActiveStep(steps = [], { match }) {
  const [activeStep, setActiveStep] = useState(steps[0]);

  useMemo(() => {
    if (match) {
      const {
        params: { step = steps[0] },
      } = match;
      setActiveStep(steps.find((elem) => elem.key === step) || steps[0]);
    }
  }, [match, steps]);

  return [activeStep, setActiveStep];
}

const CheckboxStepper = ({ checked, onChange, label }) => {
  return (
    <CheckboxContainer
      container
      item
      sm={12}
      direction="column"
      alignContent="flex-end"
    >
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={onChange}
              color="primary"
              name="acceptTerms"
            />
          }
          label={
            <Typography component="h2" variant="caption" color="textSecondary">
              {label}
            </Typography>
          }
        />
      </Grid>
    </CheckboxContainer>
  );
};

// Mobile Component
function MobileStepper({ steps, activeStep, buttonController }) {
  return (
    <MobileStepComponent container justify="space-between">
      <Button
        size="small"
        onClick={buttonController.back.onClick}
        disabled={activeStep === 0 || buttonController.back.hide}
      >
        {buttonController.back && !buttonController.back.hide && (
          <ArrowBackIcon />
        )}
      </Button>
      <Grid item xs={6} sm={8}>
        <StyledStepperMobile
          activeStep={activeStep}
          connector={<StyledStepConnector />}
        >
          {steps.map(({ disabled, key, completed }) => (
            <Step key={key} disabled={disabled}>
              <StepLabel
                disabled={!completed || disabled}
                completed={completed}
                StepIconComponent={({ active, completed }) => (
                  <DotMobile active={active || completed} />
                )}
              />
            </Step>
          ))}
        </StyledStepperMobile>
      </Grid>
      <Button size="small" disabled></Button>
    </MobileStepComponent>
  );
}

const Component = ({
  children,
  steps,
  activeStep,
  buttonController,
  goToStep,
}) => {
  const theme = useTheme();
  const activeStepIdx = useMemo(
    () =>
      activeStep ? steps.findIndex((elem) => elem.key === activeStep.key) : 0,
    [activeStep, steps]
  );

  const isLastStep = useMemo(
    () => activeStepIdx === steps.length - 1,
    [activeStepIdx, steps]
  );
  return (
    <Container>
      <Hidden smDown>
        <StyledStepper
          alternativeLabel
          activeStep={activeStepIdx}
          connector={<StyledStepConnector />}
        >
          {steps.map(({ label, disabled, key, completed }, index) => (
            <Step key={key} disabled={disabled}>
              <StepButton
                onClick={() => goToStep({ step: index, key })}
                disabled={!completed || disabled}
                completed={completed}
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </StyledStepper>
        <StepContainer>{children}</StepContainer>

        <ButtonContainer>
          {buttonController.back && !buttonController.back.hide && (
            <BackButton
              component="button"
              disabled={activeStepIdx === 0}
              onClick={buttonController.back.onClick}
              color="primary"
            >
              <BackIcon
                disabled={activeStepIdx === 0}
                backgroundcolor={theme.palette.primary.main}
                iconcolor={theme.palette.primary.contrastText}
              />
              {buttonController.back.label}
            </BackButton>
          )}
          {buttonController.completed &&
            !buttonController.completed.hide &&
            ((buttonController.completed.onlyLastStep && isLastStep) ||
              !buttonController.completed.onlyLastStep) && (
              <CompletedButton
                variant="contained"
                onClick={buttonController.completed.onClick}
                color="primary"
                disabled={buttonController.completed.disabled}
              >
                {buttonController.completed.label}
              </CompletedButton>
            )}
          {buttonController.next && !buttonController.next.hide && (
            <NextButton
              disabled={isLastStep || buttonController.next.disabled}
              variant="contained"
              onClick={buttonController.next.onClick}
              color="primary"
            >
              {buttonController.next.label}
            </NextButton>
          )}
        </ButtonContainer>
      </Hidden>
      <Hidden mdUp>
        <MobileStepper
          steps={steps}
          activeStep={activeStepIdx}
          buttonController={buttonController}
        />
        <MobileStepContainer>
          <Title component="h1" variant="body1" align="center">
            {activeStep.label}
          </Title>
          {children}
          {buttonController.next && !buttonController.next.hide ? (
            <MobileButton
              size="large"
              onClick={buttonController.next.onClick}
              disabled={isLastStep || buttonController.next.disabled}
            >
              {buttonController.next.label}
            </MobileButton>
          ) : (
            buttonController.completed &&
            !buttonController.completed.hide &&
            ((buttonController.completed.onlyLastStep && isLastStep) ||
              !buttonController.completed.onlyLastStep) && (
              <MobileButton
                size="large"
                onClick={buttonController.completed.onClick}
                disabled={buttonController.completed.disabled}
              >
                {buttonController.completed.label}
              </MobileButton>
            )
          )}
        </MobileStepContainer>
      </Hidden>
    </Container>
  );
};

export const controllerButtonPropTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  hide: PropTypes.bool,
  disabled: PropTypes.bool,
};

export const stepPropTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  key: PropTypes.string,
  component: PropTypes.element.isRequired,
  completed: PropTypes.bool.isRequired,
};

Component.propTypes = {
  children: PropTypes.element.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape(stepPropTypes)).isRequired,
  activeStep: PropTypes.shape(stepPropTypes).isRequired,
  buttonController: PropTypes.shape({
    back: PropTypes.shape(controllerButtonPropTypes),
    next: PropTypes.shape(controllerButtonPropTypes),
    completed: PropTypes.shape({
      ...controllerButtonPropTypes,
      onlyLastStep: PropTypes.bool,
    }),
  }),
  goToStep: PropTypes.func,
};
Component.defaultProps = {
  buttonController: {
    back: {},
    next: {},
    completed: {},
  },
  goToStep: () => {},
};

export default Component;
