import React from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";

export const QuantityBtn = React.forwardRef(function QuantityBtn(
  { square, ...props },
  ref
) {
  const padding = square ? { paddingLeft: 5, paddingRight: 5 } : null;
  return (
    <Button
      ref={ref}
      color={"primary"}
      variant={"contained"}
      disableElevation
      style={{
        minWidth: 35,
        ...padding,
      }}
      {...props}
    />
  );
});

QuantityBtn.propTypes = {
  square: PropTypes.bool,
};
