import React from "react";
import styled from "styled-components";
import ResponsivePicture from "../Common//ResponsivePicture";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import { Link, Avatar, Grid, useMediaQuery } from "@material-ui/core";
import { baseMargin } from "./styles";
import media from "../Helpers/media";

const Container = styled.div`
  ${baseMargin}
`;

const StyledResponsivePicture = styled(ResponsivePicture)``;
const StaticCard = styled(Card)`
  box-shadow: none;
  border-radius: 15px;
`;

const GridParentStyled = styled(Grid)`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.palette.grey.A700};
`;

const ProducerStyled = styled.span`
  margin-left: 10px;
  font-weight: bold;
`;

const StyledCardContent = styled(CardContent)`
  padding: 20px 50px;
  @media (max-width: 600px) {
    padding: 20px 10px;
  }
`;

const StyledResponsiveProfilePicture = styled(ResponsivePicture)`
  border-radius: 100%;
  @media (min-width: 600px) {
    width: 60px;
  }
  width: 60px;
`;

const DescriptionBox = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette.grey["300"]};
  color: ${({ theme }) => theme.palette.grey.A700};
  padding: 5px 10px 10px;
  position: relative;
  float: left;
  border-radius: 5px;
  @media (min-width: 600px) {
    &:after {
      content: " ";
      left: 40px;
      position: absolute;
      top: -5px;
      border-top: none;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      border-bottom: 5px solid ${({ theme }) => theme.palette.grey["300"]};
    }
  }
`;

const TitleStyled = styled.div`
  font-weight: 600;
  font-size: 2rem;
  ${media.down.md`
    font-size: 1.5rem;
  `}
  color: ${({ theme }) => theme.palette.grey.A400};
  margin-bottom: 5px;
`;

const Categories = styled.div`
  margin-bottom: 15px;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 0.9rem;
  font-weight: 500;
`;

function StyledIcon({ icon }) {
  const Comp = styled(icon)`
    color: ${({ theme }) => theme.palette.grey.A200};
    margin-right: 5px;
    width: 0.7em;
    height: 0.7em;
  `;
  return <Comp />;
}

function SimplifiedHeader({
  categories,
  name,
  producer,
  description,
  phone,
  email,
  bannerUrl,
  profilePicture,
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Container>
      <StaticCard>
        <CardMedia
          component={() => (
            <StyledResponsivePicture
              height={isMobile ? 100 : 250}
              sizeQuery="(min-width: 750px) 60vw, (max-width: 749px) 95vw"
              baseImgUrl={bannerUrl}
              fitType="cover"
            />
          )}
        />
        <StyledCardContent>
          <TitleStyled>{name}</TitleStyled>
          <Categories>{categories.join(", ")}</Categories>
          <Typography variant="body2" color="textSecondary" component="div">
            <GridParentStyled
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid container item alignItems="center" xs={12} md={4}>
                {profilePicture && (
                  <Avatar
                    alt={name}
                    component={() => (
                      <StyledResponsiveProfilePicture
                        containerStyle={{ width: "auto" }}
                        height={60}
                        sizeQuery="60px"
                        baseImgUrl={profilePicture}
                      />
                    )}
                  />
                )}
                {producer !== "" && <ProducerStyled>{producer}</ProducerStyled>}
              </Grid>
              {phone && (
                <Grid container item alignItems="center" xs={12} md={4}>
                  <StyledIcon icon={PhoneIcon} />{" "}
                  <Link color="inherit" href={`tel:${phone}`}>
                    {phone}
                  </Link>
                </Grid>
              )}
              {email && (
                <Grid container item alignItems="center" xs={12} md={4}>
                  <StyledIcon icon={EmailIcon} />
                  <Link
                    color="inherit"
                    href={`mailto:${email}?subject=[Kuupanda]%20`}
                  >
                    {email}
                  </Link>
                </Grid>
              )}
            </GridParentStyled>
          </Typography>
          <DescriptionBox dangerouslySetInnerHTML={{ __html: description }} />
        </StyledCardContent>
      </StaticCard>
    </Container>
  );
}

SimplifiedHeader.propTypes = {
  categories: PropTypes.array,
  bannerUrl: PropTypes.string,
  name: PropTypes.string,
  producer: PropTypes.string,
  description: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
};

SimplifiedHeader.defaultPropTypes = {
  categories: [],
};

export default SimplifiedHeader;
