import PropTypes from "prop-types";
import React from "react";
import { Button, Card, CardContent, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import ProductorInfo from "../ProductorInfo";
import { CategoryList } from "./CategoryList";

const useStyles = makeStyles({
  root: {
    position: "sticky",
    top: 0,
  },
  image: {
    height: "100%",
    flexGrow: 1,
  },
});

export default function StoreNavigation({
  store,
  loading,
  products,
  categoryNameMap,
}) {
  const classes = useStyles();
  let history = useHistory();

  return (
    <Box className={`${classes.root} kuu-store-navigation`}>
      <Hidden smDown>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          style={{
            width: "100%",
            margin: "0 15px 15px 0",
          }}
          startIcon={<ArrowBackIosIcon />}
          onClick={() => history.goBack()}
        >
          Retour à la recherche
        </Button>
      </Hidden>
      <Card>
        <CardContent>
          <ProductorInfo store={store} loading={loading} />
        </CardContent>
        <CategoryList
          products={products}
          categoryNameMap={categoryNameMap}
          loading={loading}
        />
      </Card>
    </Box>
  );
}

StoreNavigation.propTypes = {
  categoryNameMap: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  products: PropTypes.array.isRequired,
  store: PropTypes.object.isRequired,
};
