import PropTypes from "prop-types";
import React, { useCallback } from "react";
import AddIcon from "@material-ui/icons/Add";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { Box, Tooltip, Typography } from "@material-ui/core";
import { QuantityBtn } from "./QuantityBtn";
import RemoveIcon from "@material-ui/icons/Remove";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";

const QuantitySelector = styled(TextField)`
  border: 1px solid #0000002e;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: 36px;
  font-weight: bold;

  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

export function QuantityControls({
  product,
  handleRemove,
  handleAdd,
  quantity,
  shoppingCartText,
  allowsRemove,
  modal,
  onChange,
  step,
}) {
  const isEmpty = quantity <= 0;
  const stock =
    product.stock !== null && product.stock > -1000 ? product.stock : 100;
  const maxQuantity =
    product.maxSaleQuantity !== null && product.maxSaleQuantity > 0
      ? product.maxSaleQuantity
      : 100;
  const maxSaleQuantity = Math.min(stock, maxQuantity);
  const isMaxReach = maxSaleQuantity && maxSaleQuantity <= quantity;
  const isSmall = !shoppingCartText;
  const size = isSmall ? "small" : undefined;
  const addIcon = isEmpty ? (
    <React.Fragment>
      <AddIcon fontSize={size} />
      <ShoppingCartIcon />
      {shoppingCartText && (
        <span style={{ marginLeft: 10 }}>{shoppingCartText}</span>
      )}
    </React.Fragment>
  ) : (
    <AddIcon />
  );

  const handleChange = useCallback(
    (value) => {
      if (value < 0) {
        value = 0;
      }

      if (step === 1) {
        value = ~~value;
      }

      // don't allow more than available amount
      if (parseInt(value) > parseInt(product.availableQuantity)) {
        return;
      }

      onChange(value);
    },
    [step, onChange]
  );

  return stock > 0 ? (
    <Box
      flexGrow={1}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"flex-end"}
    >
      {quantity > 0 && (
        <React.Fragment>
          {allowsRemove && (
            <QuantityBtn
              id={`remove${modal ? "-modal" : ""}-${product.clientId}`}
              className="added_to_cart"
              size={size}
              square
              onClick={handleRemove}
            >
              <RemoveIcon />
            </QuantityBtn>
          )}
          <QuantitySelector
            type="number"
            fullWidth
            onChange={(event) => handleChange(parseFloat(event.target.value))}
            InputProps={{
              disableUnderline: true,
            }}
            inputProps={{
              step,
              style: { textAlign: "center" },
            }}
            value={quantity}
          />
        </React.Fragment>
      )}
      {isMaxReach ? (
        <Tooltip
          title={"Quantité maximum souhaitée par le producteur"}
          placement={"top"}
        >
          <span>
            <QuantityBtn
              id={`add${modal ? "-modal" : ""}-${product.clientId}`}
              className="added_to_cart"
              size={size}
              square
              disabled
            >
              {addIcon}
            </QuantityBtn>
          </span>
        </Tooltip>
      ) : (
        <QuantityBtn
          id={`add${modal ? "-modal" : ""}-${product.clientId}`}
          className="added_to_cart"
          size={size}
          square={!isEmpty}
          onClick={handleAdd}
        >
          {addIcon}
        </QuantityBtn>
      )}
    </Box>
  ) : (
    <Typography>Non disponible</Typography>
  );
}

QuantityControls.propTypes = {
  handleAdd: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  allowsRemove: PropTypes.bool,
  modal: PropTypes.bool,
  product: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
  shoppingCartText: PropTypes.string,
};

QuantityControls.defaultProps = {
  handleAdd: () => {},
  handleRemove: () => {},
  allowsRemove: false,
  modal: false,
  product: {},
  quantity: 1,
  shoppingCartText: "",
};
