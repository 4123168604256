import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import "./VendorStore.css";
import { Home } from "./views/Home";
import { CssBaseline } from "@material-ui/core";
import constants from "./utils/constants";
import useStoreInfo from "../Hooks/useStoreInfo";
import { useCart } from "../Context/CartContext";
import { useApiConfig } from "../Context/ApiContext";
import { calculateProductAvailableQuantity } from "../Helpers/cart";

const reduceCart = (currentCart) =>
  Object.entries(currentCart).reduce(
    (acc, [id, product]) => ({ ...acc, [id]: product.quantity }),
    {}
  );

function VendorStore(props) {
  const { cart, addProduct, removeFromCart } = useCart();

  // calculate stock for every product
  // const productList = useMemo(
  //   () =>
  //     (props.products || []).map((category) => {
  //       const list = (category.products || []).map((product) => {
  //         const availableQuantity = calculateProductAvailableQuantity({
  //           stock: product.stock,
  //           maxSaleQuantity: product.maxSaleQuantity,
  //         });
  //         return { ...product, availableQuantity };
  //       });
  //
  //       return { ...category, products: list };
  //     }),
  //   [props.products]
  // );
  const productList = useMemo(() => props.products || [], [props.products]);

  const productMap = useMemo(
    () =>
      (productList || [])
        .flatMap((it) => it.products)
        .reduce((acc, it) => ({ ...acc, [it.id]: it }), {}),
    [productList]
  );

  const cartProducts = useMemo(() => reduceCart(cart), [cart]);
  const handleAddToCart = useCallback(
    (data) => {
      const { productID, quantity } = data;
      addProduct({
        id: productID,
        producer: props.producer,
        productMap,
        quantity,
      });
    },
    [addProduct, productMap, props.producer]
  );
  const handleRemoveFromCart = useCallback(
    ({ productID }) =>
      removeFromCart({
        id: productID,
      }),
    [removeFromCart]
  );

  return (
    <div className="VendorStore">
      <CssBaseline />
      <Home
        {...props}
        products={productList}
        onAddToCart={handleAddToCart}
        onRemoveFromCart={handleRemoveFromCart}
        cart={cartProducts}
        categories={props.categories}
      />
    </div>
  );
}

function ConnectedVendorStore(props) {
  const { apiRoot } = useApiConfig();
  const {
    producerId,
    bannerUrl,
    onAddToCart,
    onRemoveFromCart,
    saleChannel,
    isGroup,
  } = props;

  const {
    deliveryPoints,
    contactPoints,
    store,
    isLoading,
    products,
    producer,
    categories,
  } = useStoreInfo(
    producerId,
    apiRoot,
    saleChannel,
    isGroup,
    bannerUrl,
    constants.COMPONENT.STORE,
    [],
    0,
    false
  );

  return (
    <VendorStore
      isLoading={isLoading}
      products={products}
      deliveryPoints={deliveryPoints}
      store={store}
      contactPoints={contactPoints}
      onAddToCart={onAddToCart}
      onRemoveFromCart={onRemoveFromCart}
      producer={producer}
      categories={categories}
    />
  );
}

ConnectedVendorStore.propTypes = {
  producerId: PropTypes.string,
  apiRoot: PropTypes.string,
  bannerUrl: PropTypes.string,
  onAddToCart: PropTypes.func,
  onRemoveFromCart: PropTypes.func,
  isGroup: PropTypes.bool,
  saleChannel: PropTypes.oneOf([
    constants.SALE_CHANNEL.STANDARD,
    constants.SALE_CHANNEL.PROFESSIONAL,
  ]),
  simplified: PropTypes.bool,
};
ConnectedVendorStore.defaultProps = {
  producerId: "ohdelicespaysansboutiqueenligne",
  apiRoot: "https://api.dev.kuupanda.com",
  //apiRoot: "http://localhost:3030",
  bannerUrl:
    "https://s3.eu-west-3.amazonaws.com/kuu.imgs/original/user/farm/48db9a161bdd1e9b1e03063bb1a6fd68cd2cd7fd92fa433d05017064ef32ac2f.png",
  onAddToCart: ({ productID, quantity, modal }) =>
    console.log(`addedToCart ${productID} ${quantity} ${modal}`),
  onRemoveFromCart: ({ productID, quantity, modal }) =>
    console.log(`removedFrom ${productID} ${quantity} ${modal}`),
  saleChannel: constants.SALE_CHANNEL.STANDARD,
  isGroup: false,
  simplified: true,
};

export default ConnectedVendorStore;
