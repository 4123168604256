import React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { withWidth } from "@material-ui/core";

import ProducerList from "../ProducerList/ProducerList";
import VendorStore from "../VendorStore/VendorStore";

import MainLayout from "../Layouts/Main";
import Cart from "../Cart/Cart";
import Home from "../Home";
import Checkout from "../Checkout";
import ProfilePage from "../Profile";
import LoginPage from "../Account/page";
import OrderSummary from "../Checkout/Steps/OrderSummary";
import ResetPasswordPage from "../PasswordManagement/ResetPassword";
import UpdatePassword from "../PasswordManagement/UpdatePassword";

import { AuthProvider } from "../Context/AuthContext";
import { CartProvider } from "../Context/CartContext";

import constants from "../Common/constants";
import AboutUs from "../AboutUs/index";
import { MentionsLegalesView, ProtectionDesDonneesView } from "../views";
import HowTo from "../HowTo";
import TermsView from "../views/TermsView";
import PrivateRoute from "../Common/PrivateRoute";

const oldGroups = [
  "regroupementceret",
  "producteursvallespir",
  "lesproducteurscatalans",
  "les-fermieres-du-capcir",
  "leregroupementdestests",
  "jenny",
  "entrelesarbreslapetitefabriquesansgluten",
  "banquepopulaireoccitaneagen",
  "lesjeunesagriculteurs66",
  "entrelesarbreslabelleverte",
  "opartageprod",
  "grouptest",
  "brun",
  "regroupement",
  "nicolassauvagetlespaniersdumaraicher66",
  "paniersdargences",
];

const oldGroupsRoute = `/(store|boutique)/:id(${oldGroups.join("|")})`;
const MarketPlaceRoutes = ({ width }) => {
  const history = useHistory();
  const location = useLocation();
  const loginRedirectPath =
    (location.state && location.state.from && location.state.from.pathname) ||
    "/";

  return (
    <AuthProvider>
      <CartProvider>
        <Route path="/">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/reset-password">
              <ResetPasswordPage />
            </Route>
            <Route exact path="/update-password/:token">
              <UpdatePassword />
            </Route>
            <Route exact path="/qui-sommes-nous">
              <MainLayout title="Qui sommes nous" hideOrderButton={false}>
                <AboutUs />
              </MainLayout>
            </Route>
            <Route exact path="/comment-ca-marche">
              <MainLayout title="Comment ca marche" hideOrderButton={false}>
                <HowTo />
              </MainLayout>
            </Route>
            <Route exact path="/cgv">
              <MainLayout title="Condition Générale de Vente (CGV)">
                <TermsView />
              </MainLayout>
            </Route>
            <Route exact path="/gdpr">
              <MainLayout title="Politique de protection des données">
                <ProtectionDesDonneesView />
              </MainLayout>
            </Route>
            <Route exact path="/legal-mentions">
              <MainLayout title="Mentions légales">
                <MentionsLegalesView />
              </MainLayout>
            </Route>
            <PrivateRoute exact path="/checkout/summary/:joinedId">
              <MainLayout
                title="Checkout"
                hideFooter
                hideHeader={width === "xs"}
              >
                <OrderSummary width={width} />
              </MainLayout>
            </PrivateRoute>
            <Route path={["/checkout/:step", "/checkout"]}>
              <MainLayout
                title="Checkout"
                hideFooter
                hideHeader={width === "xs"}
              >
                <Checkout baseURL="/checkout" width={width} />
              </MainLayout>
            </Route>
            <Route path="/search">
              <MainLayout fixedHeader hideFooter>
                <ProducerList />
              </MainLayout>
            </Route>
            <Route
              path="/(store|boutique)/:storeType(producer|producteur|group)/:id"
              render={({ match }) => (
                <MainLayout hideFooter hideOrderButton>
                  <VendorStore
                    isGroup={
                      match.params.storeType === constants.STORE_TYPE.GROUP
                    }
                    producerId={match.params.id}
                  />
                </MainLayout>
              )}
            />
            <Route
              path={oldGroupsRoute}
              render={({ match }) => (
                <MainLayout hideFooter hideOrderButton>
                  <VendorStore isGroup={true} producerId={match.params.id} />
                </MainLayout>
              )}
            />
            <Route
              path="/(store|boutique)/:id"
              render={({ match }) => (
                <MainLayout hideFooter hideOrderButton>
                  <VendorStore isGroup={false} producerId={match.params.id} />
                </MainLayout>
              )}
            />
            <MainLayout hideOrderButton={false}>
              <Route path="/login">
                <LoginPage onSuccess={() => history.push(loginRedirectPath)} />
              </Route>
              <PrivateRoute
                exact
                path="/profile"
                component={() => <Redirect to="/profile/commandes" />}
              />
              <PrivateRoute
                exact
                path="/profile/:section"
                render={({
                  match: {
                    params: { section },
                    url,
                  },
                }) => <ProfilePage baseURL={url} section={section} />}
              />
            </MainLayout>
          </Switch>
        </Route>
        <Cart />
      </CartProvider>
    </AuthProvider>
  );
};

export default withWidth()(MarketPlaceRoutes);
