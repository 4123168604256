import React from "react";
import { StylesProvider } from "@material-ui/core/styles";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./VendorStore/VendorStore.css";
import "./App.css";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "@material-ui/core";
import "typeface-noto-sans-tc";

// Components
import SimplifiedStore from "./SimplifiedStore/SimplifiedStore";
import MarketplaceRoutes from "./Marketplace/MarketplaceRoutes";

// Contexts
import { ApiProvider } from "./Context/ApiContext";
import { ConfigProvider } from "./Context/ConfigContext";
import MangoPayTemplate from "./VendorStore/components/Payment/MangoPay/MangoPayTemplate";
import MangoPayPaymentComplete from "./VendorStore/components/Payment/MangoPay/MangoPayPaymentComplete";
import { withBrandConfig } from "./Context/BrandConfigContext";

import GlobalStyle from "./GlobalStyles";
import { CartProvider } from "./Context/CartContext";

function App({ brandConfig: { muiTheme } }) {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <StyledThemeProvider theme={muiTheme}>
          <GlobalStyle />
          <IntlProvider locale="fr" messages={{}}>
            <ApiProvider>
              <Router>
                <Switch>
                  <Route
                    exact
                    path="/payment/mangopay/template/:token"
                    render={({
                      match: {
                        params: { token },
                      },
                    }) => (
                      <ConfigProvider>
                        <MangoPayTemplate token={token} />
                      </ConfigProvider>
                    )}
                  />

                  <Route path="/payment/mangopay/payment-complete" exact>
                    <MangoPayPaymentComplete />
                  </Route>
                  <Route
                    exact
                    path={[
                      "/:storeType(producer|producteur|group)/:producerId/:saleChannelRaw",
                      "/:storeType(producer|producteur|group)/:producerId/:saleChannelRaw/success",
                      "/:storeType(producer|producteur|group)/:producerId/:saleChannelRaw/boutique/:storeFrontSlug",
                      "/:storeType(producer|producteur|group)/:producerId/:saleChannelRaw/boutique/:storeFrontSlug/success",
                    ]}
                  >
                    <CartProvider>
                      <SimplifiedStore />
                    </CartProvider>
                  </Route>
                  <ConfigProvider>
                    <MarketplaceRoutes />
                  </ConfigProvider>
                </Switch>
              </Router>
            </ApiProvider>
          </IntlProvider>
        </StyledThemeProvider>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default withBrandConfig(App);
