import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  useTheme,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { QuantityControls } from "./QuantityControls";
import {
  getProductName,
  getProductUnit,
  calculateVariableWeight,
} from "../../services/utils";
import constants from "../../utils/constants";
import RestoreIcon from "@material-ui/icons/Restore";
import styled from "styled-components";
import ResponsivePicture from "../../../Common/ResponsivePicture";
import { NumberFormatCustom } from "@kuupanda/commons";

const MEDIA_HEIGHT = 180;
const CONTENT_HEIGHT = 58;

const AvailableDateContainer = styled.div`
  font-style: italic;
  font-weight: 400;
  ${({ theme }) => `
    color: ${theme.palette.primary.dark};
  `};
  font-size: 12px;
  display: flex;
  align-items: center;
  span {
    margin: 0 3px;
  }
`;

const ProducerNameText = styled(Typography)`
  position: absolute;
  top: 5px;
  left: 5px;
  font-weight: 600 !important;
  line-height: 1 !important;
  font-size: 0.7rem !important;
  color: white;
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: 70%;
  border-radius: 5px;
  padding: 5px;
  text-transform: uppercase;
  max-width: max-content;
`;

function ProductItem({
  product,
  quantity,
  onAdd,
  onRemove,
  onClick,
  onChange,
}) {
  const [showAvailableDate, setShowAvailableDate] = useState(false);
  const [availableDate, setAvailableDate] = useState("");

  useEffect(() => {
    if (product.preparationType === constants.PREPARATION_TYPE.SPECIFIC_DATE) {
      const availableDateTime = new Date(product.availableDate).getTime();
      if (Date.now() <= availableDateTime) {
        setShowAvailableDate(true);
        try {
          const date = new Date(product.availableDate);
          const day = ("0" + date.getDate()).slice(-2);
          const month = ("0" + (date.getMonth() + 1)).slice(-2);
          const availableDate = `${day}/${month}`;
          setAvailableDate(availableDate);
        } catch (error) {
          setShowAvailableDate(false);
        }
      }
    }
  }, [product]);

  const theme = useTheme();
  const handleAdd = (e) => {
    e.stopPropagation();
    onAdd({ product });
  };

  const handleChange = (quantity) => {
    onChange({ product, quantity });
  };
  const handleRemove = (e) => {
    e.stopPropagation();
    onRemove({ product });
  };
  const handleClick = () => onClick(product);
  const isEmpty = quantity <= 0;
  const stock =
    product.stock !== null && product.stock > -1000 ? product.stock : 100;
  const mediaStyle = {
    height: MEDIA_HEIGHT,
  };
  if (product.picture.default) {
    mediaStyle.marginLeft = 50;
    mediaStyle.marginRight = 50;
    mediaStyle.backgroundSize = "contain";
  }

  const cardStyle = isEmpty
    ? {}
    : { backgroundColor: theme.palette.primary.xxLight };

  const noStock = stock > 0 ? {} : { opacity: 0.5 };

  return (
    <Card style={{ ...cardStyle, ...noStock }} className="product">
      <CardActionArea onClick={handleClick}>
        {product.isProductGroup && (
          <ProducerNameText>
            {product.producer && product.producer.storeName}
          </ProducerNameText>
        )}
        <CardMedia
          className="attachment-woocommerce_thumbnail"
          style={mediaStyle}
          component={() => (
            <ResponsivePicture
              height={180}
              sizeQuery="(min-width: 1280px) 20vw,(min-width: 960px) and (max-width: 1279px) 20vw,(min-width: 600px) and (max-width: 959px) 30vw, (max-width: 599px) 80vw"
              baseImgUrl={product.picture.url}
            />
          )}
        />
        <CardContent style={{ paddingBottom: 0 }}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            style={{ height: CONTENT_HEIGHT }}
          >
            <Typography
              variant={"body1"}
              style={{
                flexGrow: 1,
                fontWeight: "bold",
                lineHeight: 1.1,
                fontSize: 15.3,
              }}
            >
              {getProductName(product)}
            </Typography>
            <Typography></Typography>
            {showAvailableDate && availableDate && (
              <AvailableDateContainer>
                <RestoreIcon />
                <span>disponible à partir du</span>
                {availableDate}
              </AvailableDateContainer>
            )}
          </Box>
        </CardContent>
      </CardActionArea>
      <CardActions style={{ padding: theme.spacing(2), marginTop: 2 }}>
        {/*<Box display={"flex"}>*/}
        <Box mr={1}>
          <Typography
            color={"primary"}
            noWrap
            component="div"
            style={{ color: theme.palette.primary.dark, lineHeight: 1 }}
          >
            {product.price !== "0.00" ? (
              <NumberFormatCustom value={product.price} displayType={"text"} />
            ) : (
              <div>
                <div>Paiement</div>
                <div>sur place</div>
              </div>
            )}
          </Typography>

          <Typography color={"textSecondary"}>
            {product.baseUnit === "unit" &&
              product.averageWeight &&
              product.variableUnit &&
              product.variableWeightPrice && (
                <>
                  <NumberFormatCustom
                    value={calculateVariableWeight(product)}
                    displayType="text"
                    suffix={product.isProfessional ? " € HT" : " €"}
                  />
                  {" / "}
                </>
              )}

            {getProductUnit(product)}
          </Typography>
        </Box>
        <Box
          flexGrow={1}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <QuantityControls
            product={product}
            handleAdd={handleAdd}
            handleRemove={handleRemove}
            quantity={quantity}
            onChange={handleChange}
            step={product.baseUnit === "unit" ? 1 : 0.001}
          />
        </Box>
        {/*</Box>*/}
      </CardActions>
    </Card>
  );
}

ProductItem.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  quantity: PropTypes.number,
};

ProductItem.defaultProps = {
  quantity: 0,
};

export default React.memo(ProductItem);

export function LoadingProductItem() {
  return (
    <Card>
      <Skeleton
        animation="wave"
        variant="rect"
        style={{ height: MEDIA_HEIGHT }}
      />
      <CardContent style={{ paddingBottom: 0 }}>
        <Box style={{ height: CONTENT_HEIGHT }}>
          <Skeleton
            animation="wave"
            height={20}
            width="80%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={10}
            width="80%"
            style={{ marginBottom: 6 }}
          />
        </Box>
      </CardContent>
      <CardActions>
        <Box
          flexGrow={1}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Skeleton
            animation="wave"
            height={32}
            width={50}
            style={{ transform: "none" }}
          />
        </Box>
      </CardActions>
    </Card>
  );
}
