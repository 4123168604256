import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Material Components
import { withWidth, Hidden } from "@material-ui/core";

// Components
import Header from "./Header";
import Footer from "./Footer";
import FooterMobile from "./FooterMobile";

// context
import { useGetAuth } from "../Context/AuthContext";

const Container = styled.div``;
const Content = styled.div`
  padding-top: ${({ width }) => (width !== "xs" ? "32px" : "20px")};
  margin: 2px;
`;

const FooterContainer = styled.div`
  margin-top: 50px;
`;

const MainLayout = ({
  children,
  width,
  title,
  hideHeader,
  fixedHeader,
  hideOrderButton,
  hideFooter,
}) => {
  const { auth, logout } = useGetAuth();

  return (
    <Container>
      {!hideHeader && (
        <Header
          user={auth}
          logout={logout}
          width={width}
          title={title}
          fixed={fixedHeader}
          hideButton={hideOrderButton}
        />
      )}
      <Content width={width}>{children}</Content>

      {!hideFooter && (
        <FooterContainer>
          <Hidden only={["xs", "sm"]}>
            <Footer />
          </Hidden>
          <Hidden only={["md", "lg", "xl"]}>
            <FooterMobile />
          </Hidden>
        </FooterContainer>
      )}
    </Container>
  );
};

MainLayout.propTypes = {
  children: PropTypes.element.isRequired,
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
  title: PropTypes.string,
  hideHeader: PropTypes.bool,
  fixedHeader: PropTypes.bool,
  hideOrderButton: PropTypes.bool,
  hideFooter: PropTypes.bool,
};

MainLayout.defaultProps = {
  title: undefined,
  hideHeader: false,
  fixedHeader: false,
  hideOrderButton: true,
  hideFooter: false,
};

export default withWidth()(MainLayout);
