import React from "react";
import PlaceIcon from "@material-ui/icons/Place";
import InfoIcon from "@material-ui/icons/Info";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import { Box, SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";

const BarnSvg = (
  <svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M12,3L3,8.2V21H9L11.9,18L15,21H21V8.2L12,3M7.9,20V14L10.9,17L7.9,20M8.9,13H14.9L11.9,16L8.9,13M15.9,20L12.9,17L15.9,14V20M15,11H8.8V9H15V11Z"
    />
  </svg>
);

const PresentationIcon = (props) => <SvgIcon {...props}>{BarnSvg}</SvgIcon>;

export const TAB_KEYS = {
  DESCRIPTION: "description",
  CONTACT_POINTS: "contact",
  DELIVERY: "delivery",
  INFORMATION: "information",
};

export const TABS_STATIC = [
  { key: TAB_KEYS.DESCRIPTION, label: "Description", Icon: PresentationIcon },
  {
    key: TAB_KEYS.CONTACT_POINTS,
    label: "Points de retraits",
    Icon: PlaceIcon,
  },
  { key: TAB_KEYS.DELIVERY, label: "Livraison", Icon: LocalShippingIcon },
  { key: TAB_KEYS.INFORMATION, label: "Information", Icon: InfoIcon },
];

export const TabPanel = React.memo(function TabPanel({
  children,
  value,
  index,
  ...other
}) {
  return (
    <Box hidden={value !== index} {...other}>
      {value === index && children}
    </Box>
  );
});

TabPanel.propTypes = {
  children: PropTypes.any,
  index: PropTypes.number.isRequired,
  value: PropTypes.number,
};
