import PropTypes from "prop-types";
import React from "react";
import { Avatar, Box, Typography } from "@material-ui/core";
import PlaceIcon from "@material-ui/icons/Place";
import PhoneIcon from "@material-ui/icons/Phone";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import styled from "styled-components";
import ResponsivePicture from "../../Common/ResponsivePicture";

const StyledBox = styled(Box)`
  padding-top: 0px !important;
`;

const StyledBox2 = styled(Box)`
  font-family: Roboto, Helvetica, Courier New, Arial, sans-serif !important;
  font-size: 14px !important;
`;

const StyledTypography = styled(Typography)`
  font-size: 1.3rem !important;
  @media (max-height: 1000px) {
    font-size: 15px !important;
  }
`;

const StyledResponsivePicture = styled(ResponsivePicture)`
  border-radius: 100%;
  @media (min-width: 600px) {
    width: 112px;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    // position: "relative"
  },
  avatar: {
    width: theme.spacing(14),
    height: theme.spacing(14),
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  },
  storeName: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: 600,
    },
  },
}));
export default function ProductorInfo({ store, loading }) {
  const classes = useStyles();
  if (loading) {
    return (
      <Box
        component={Box}
        className={classes.root}
        display={"flex"}
        flexDirection={"column"}
      >
        <Box
          py={2}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Skeleton
            animation="wave"
            height={30}
            width={200}
            style={{ display: "inline-block", transform: "none" }}
          />
        </Box>
        <Box display={"flex"} justifyContent={"center"} mb={1}>
          <Skeleton
            animation="wave"
            variant="circle"
            className={classes.avatar}
          />
        </Box>

        <Skeleton animation="wave" height={30} />

        <Skeleton animation="wave" height={60} />
      </Box>
    );
  }
  const avatarComp = store.profileUrl ? (
    <Avatar
      alt={store.fullName}
      component={() => (
        <StyledResponsivePicture
          className={classes.avatar}
          containerStyle={{ width: "auto" }}
          height={112}
          sizeQuery="112px"
          baseImgUrl={store.profileUrl}
          fitType="cover"
        />
      )}
    />
  ) : (
    <Avatar className={classes.avatar} alt={store.fullName} />
  );
  return (
    <Box
      component={Box}
      className={classes.root}
      display={"flex"}
      flexDirection={"column"}
    >
      <StyledBox
        py={2}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <StyledTypography
          variant={"h5"}
          align={"center"}
          className={classes.storeName}
        >
          {store.storeName}
        </StyledTypography>
      </StyledBox>
      <Box display={"flex"} justifyContent={"center"} mb={1}>
        {avatarComp}
      </Box>

      <CenterRow Icon={PlaceIcon}>{store.city}</CenterRow>
      {store.phone !== "" && (
        <CenterRow Icon={PhoneIcon}>{store.phone}</CenterRow>
      )}
      <CenterRow>
        <Typography variant={"body2"} align={"center"}>
          {store.productionMethod}
        </Typography>
      </CenterRow>
    </Box>
  );
}

ProductorInfo.propTypes = {
  store: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

function BlockRow({ Icon, children, ...rest }) {
  return (
    <Box
      display="flex"
      alignItems={"center"}
      mb={1}
      overflow={"hidden"}
      {...rest}
    >
      {Icon && <Icon style={{ marginRight: 10 }} color={"action"} />}
      <Box overflow={"hidden"} flexGrow={1}>
        {children}
      </Box>
    </Box>
  );
}

BlockRow.propTypes = {
  Icon: PropTypes.object,
  children: PropTypes.any.isRequired,
};

function CenterRow({ Icon, children, ...rest }) {
  return (
    <Box
      display="flex"
      alignItems={"center"}
      mb={1}
      overflow={"hidden"}
      justifyContent={"center"}
      {...rest}
    >
      {Icon && <Icon style={{ marginRight: 10 }} color={"action"} />}
      <StyledBox2 overflow={"hidden"}>{children}</StyledBox2>
    </Box>
  );
}

CenterRow.propTypes = {
  Icon: PropTypes.object,
  children: PropTypes.any.isRequired,
};
