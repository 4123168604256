import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { addDays, format } from "date-fns";

// Material Components
import {
  CircularProgress,
  Grid,
  Paper,
  Typography,
  withWidth,
  TextareaAutosize,
} from "@material-ui/core";

// Components
import { ContactPointShape } from "../Checkout/proptypes";
import ContactPointDateField from "./ContactPointDateField";
import DeliveryDateFrequency from "./DeliveryDateFrequency";
import ContactPointExtraInfo from "./ContactPointExtraInfo";
import ContactPointButtons from "./ContactPointButtons";
import ContactPointOptions from "./ContactPointOptions";
import SelectRelayPoint from "./SelectRelayPoint";

import constants from "./constants";
import {
  createContactOptions,
  getAvailableDates,
  getContactPointAvailableDate,
  getContactPointInfo,
  isContactPointValid,
  setInputContent,
  checkHasUndeliverableProducts,
  checkIfHasColdProducts,
  checkIfIsSelectable,
} from "../Helpers/contactPoint";
import {
  getAllContactPointFromLocalStorage,
  getClientAddressFromLocalStorage,
  storeChosenDateInLocalStorage,
  storeClientAddressInLocalStorage,
  storeContactPointInLocalStorage,
} from "../Checkout/Components/LocalStorageRepository";
import {
  calculateTotalWeight,
  isCpChronopost,
  isCpDelivery,
  resetHour,
} from "../Checkout/utils";
import LoadScriptOnlyIfNeeded from "./LoadScriptOnlyIfNeeded";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

//hooks
import { useHttpClient } from "../Hooks/useHttpClient";
import { useApiConfig } from "../Context/ApiContext";
import { checkMaxAvailableDate, getProductsForProducer } from "../Helpers/cart";
import { CustomAlert } from "./CustomAlert";

// consts
const librariesToLoad = ["places", "geometry"];
const API_KEY = "AIzaSyBvpOINUfTU3iGWSNwsjBwiBz9KlSQJPVI";

// Styleds
const Container = styled(Grid)``;
const Title = styled(Typography)`
  font-weight: bold;
`;
const VendorName = styled(Typography)`
  margin-bottom: 16px;
`;
const StyledCpTitle = styled.h4`
  color: rgba(0, 0, 0, 0.9);
  font-size: 20px;
`;
const DeliveryOptionsTitle = styled(Title)`
  font-weight: bold;
  margin: 25px 0 5px 0;
`;
const StyledInnerPaperOptions = styled(Paper)`
  background-color: ${({ theme }) => theme.palette.grey["50"]};
  padding: ${(props) => (props.width !== "xs" ? "20px" : 0)};
  width: 100%;
`;
const StyledInnerPaperExtraInfo = styled(Paper)`
  background-color: ${({ theme }) => theme.palette.grey["100"]};
  padding: ${(props) =>
    props.width !== "xs" && props.width !== "sm" ? "20px" : 0};
  width: 100%;
`;
const GridDateFieldStyled = styled(Grid)`
  align-items: flex-end;
  display: flex;
`;
const Body = styled(Grid)`
  ${({ theme, width }) => {
    if (width === "xs") {
      return `
        background-color: ${theme.palette.grey["50"]};
        border: 1px solid ${theme.palette.grey["200"]};
        border-radius: 8px;
        margin: 0;
      `;
    }
  }}
`;
const TextAreaDescription = styled(TextareaAutosize)`
  background: ${(props) => props.theme.palette.grey["100"]};
  color: ${(props) => props.theme.palette.grey["700"]};
  width: ${({ width }) =>
    width !== "xs" && width !== "sm" ? "459px" : "100%"};
  margin-top: 50px;
  border-radius: 8px;
  border: none;
  padding: 20px;
  ${({ theme }) => `
    ${theme.typography.body1}
  `}
`;

const ArrowIcon = styled(ArrowForwardIcon)`
  border: 1px solid;
  border-radius: 50px;
  font-size: 10px;
`;

const SelectRelayContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  margin-bottom: 15px;
`;

const MaxAvailableDateStyle = styled.div`
  margin: 15px 0;
`;

// Hooks
function useCalculateContactPoint({
  contactPoints,
  availableDate,
  preparationTime,
  vendorId,
}) {
  const [selectedContactPoint, setSelectedContactPoint] = useState(undefined);
  const [availableDates, setAvailableDates] = useState(undefined);
  const [date, setDate] = useState(new Date());
  const [isChronoPost, setIsChronoPost] = useState(true);
  const [isDelivery, setIsDelivery] = useState(true);

  const set = useCallback(
    (newSelectedContactPoint) => {
      if (contactPoints && newSelectedContactPoint && vendorId) {
        setSelectedContactPoint(newSelectedContactPoint);
      }
    },
    [contactPoints, vendorId]
  );
  useEffect(() => {
    if (contactPoints && selectedContactPoint) {
      const contactPointInfo = getContactPointInfo(
        contactPoints,
        selectedContactPoint
      );

      const isChronoPost = isCpChronopost(contactPointInfo.type);
      const isDelivery = isCpDelivery(contactPointInfo.type);

      setIsChronoPost(isChronoPost);
      setIsDelivery(isDelivery);

      if (!isChronoPost) {
        const newDate = getContactPointAvailableDate({
          contactPoint: selectedContactPoint,
          currentDate: null,
          contactPoints,
          vendorId,
          availableDate,
          preparationTime,
        });

        setDate(newDate);
        setAvailableDates(
          getAvailableDates(
            contactPointInfo,
            availableDate,
            preparationTime,
            contactPoints,
            selectedContactPoint
          )
        );
      } else {
        //add 1 day for chronopost
        setDate(addDays(new Date(), 1));
      }
    }
  }, [
    availableDate,
    contactPoints,
    preparationTime,
    selectedContactPoint,
    vendorId,
  ]);

  return [
    selectedContactPoint,
    availableDates,
    date,
    set,
    setDate,
    isChronoPost,
    isDelivery,
  ];
}

function MaxAvailableDate(props) {
  const maxAvailableDate = checkMaxAvailableDate(props.cart);
  if (!maxAvailableDate) return null;
  const date = format(maxAvailableDate, "dd/MM/yyyy");
  return (
    <MaxAvailableDateStyle>
      {maxAvailableDate && (
        <CustomAlert
          type="warning"
          text={`La commande contient un produit dont la disponibilité est le ${date}. Cela pourrait impacter le délai de livraison`}
        />
      )}
    </MaxAvailableDateStyle>
  );
}

MaxAvailableDate.propTypes = { cart: PropTypes.any };

function ContactPointMain({
  availableDate,
  title,
  vendorId,
  vendorIdx,
  vendorName,
  showVendorName,
  targetInput,
  totalAmount,
  products,
  preparationTime,
  onStateCheck,
  onRecoveryInfoChange,
  showDateFieldUp,
  clientAddress,
  width,
  showDescription,
  disableNextButton,
  isSimplifiedStore,
  cart,
  ...props
}) {
  const [targetInputModified, setTargetInputModified] = useState(undefined);
  const [invalidState, setInvalidState] = useState(false);
  const [address, setAddress] = useState(
    clientAddress || getClientAddressFromLocalStorage(vendorId)
  );

  const [description, setDescription] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [contactPoints, setContactPoints] = useState(undefined);
  const [relayPoints, setRelayPoints] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentRelay, setCurrentRelay] = useState({});
  const [
    selectedContactPoint,
    availableDates,
    date,
    setSelectedContactPoint,
    setDate,
    isChronoPost,
    isDelivery,
  ] = useCalculateContactPoint({
    contactPoints,
    availableDate,
    preparationTime,
    vendorId,
  });

  const { apiRoot } = useApiConfig();
  const httpClient = useHttpClient(apiRoot);

  const contactPointInfo = useMemo(
    () => getContactPointInfo(contactPoints, selectedContactPoint),
    [selectedContactPoint, contactPoints]
  );

  const exceedTotalWeight = useMemo(() => {
    const cartProducts = getProductsForProducer(
      cart,
      vendorId,
      isSimplifiedStore
    );

    const totalWeight = calculateTotalWeight({ products: cartProducts });

    return totalWeight > 20;
  }, [cart, vendorId, isSimplifiedStore]);

  const hasFreshProducts = useMemo(() => {
    return checkIfHasColdProducts(cart);
  }, [cart]);

  const takeAwayContactPoints = useMemo(
    () =>
      (contactPoints &&
        contactPoints
          .filter((it) => it.type === constants.CONTACT_POINT_TYPE.TAKE_AWAY)
          .map((cp) => ({
            value: cp._id || cp.id,
            label: `${cp.name} ${cp.address && cp.address.text}`,
            comment: cp.comment,
            isSelectable: true, // for now, take away CPs is always selectable
          }))) ||
      [],
    [contactPoints]
  );

  const deliveryContactPoints = useMemo(
    () =>
      (contactPoints &&
        contactPoints
          .filter((it) => it.type !== constants.CONTACT_POINT_TYPE.TAKE_AWAY)
          .map((cp) => ({
            value: cp._id || cp.id,
            label:
              cp.type === constants.CONTACT_POINT_TYPE.CHRONOPOST ||
              cp.type === constants.CONTACT_POINT_TYPE.CHRONOPOST_VITI
                ? `Livraison ${cp.name}`
                : cp.name,
            comment: cp.comment,
            freeFeeOrderAmount: cp.freeFeeOrderAmount,
            minimumDeliveryAmount: cp.minimumDeliveryAmount,
            isSelectable: checkIfIsSelectable(
              cp,
              hasFreshProducts,
              exceedTotalWeight,
              totalAmount,
              cart
            ),
            type: cp.type,
            isRelay: cp.type === constants.CONTACT_POINT_TYPE.CHRONOPOST_RELAY,

            chronopostDisabled:
              checkHasUndeliverableProducts(cart) ||
              (cp.modes &&
                !cp.modes.includes(constants.CHRONOPOST_MODES.FRESH) &&
                hasFreshProducts) ||
              (cp.type === constants.CONTACT_POINT_TYPE.CHRONOPOST_RELAY &&
                exceedTotalWeight),
            exceedTotalWeight,
            hasFreshProducts,
          }))) ||
      [],
    [contactPoints, totalAmount, hasFreshProducts, exceedTotalWeight, cart]
  );

  const hasDeliveryCPs = useMemo(
    () => deliveryContactPoints.length > 0,
    [deliveryContactPoints.length]
  );
  const hasTakeAwayCPs = useMemo(
    () => takeAwayContactPoints.length > 0,
    [takeAwayContactPoints.length]
  );

  useEffect(() => {
    if (targetInput) {
      const input = window.document.getElementById(targetInput);
      if (input) {
        setTargetInputModified(input);
      }
    }
  }, [targetInput]);

  useEffect(() => {
    if (!contactPoints) {
      const validContactPoints = props.contactPoints.filter(
        (contactPointInfo) =>
          isContactPointValid(
            contactPointInfo,
            availableDate,
            preparationTime,
            contactPoints
          )
      );
      if (!validContactPoints || !validContactPoints.length) {
        setInvalidState(true);
        return;
      }

      setContactPoints(validContactPoints);
    }
  }, [
    availableDate,
    contactPoints,
    preparationTime,
    cart,
    props.contactPoints,
    vendorId,
    isSimplifiedStore,
  ]);

  useEffect(() => {
    if (contactPoints && !selectedContactPoint) {
      const storedCPs = getAllContactPointFromLocalStorage();

      const deliveryCP = contactPoints.find(
        (it) =>
          it.type === constants.CONTACT_POINT_TYPE.DELIVERY &&
          !(it.minimumDeliveryAmount > totalAmount)
      );

      const takeAwayCP = contactPoints.find(
        (it) => it.type === constants.CONTACT_POINT_TYPE.TAKE_AWAY
      );

      const storedCP =
        storedCPs &&
        storedCPs[vendorId] &&
        contactPoints.find(
          (it) =>
            it.id === storedCPs[vendorId] &&
            !(it.minimumDeliveryAmount > totalAmount)
        );

      const contactPoint =
        deliveryCP &&
        storedCP &&
        storedCP.type === constants.CONTACT_POINT_TYPE.TAKE_AWAY
          ? createContactOptions(
              [deliveryCP || storedCP || takeAwayCP || contactPoints[0]],
              hasFreshProducts,
              exceedTotalWeight,
              totalAmount,
              cart
            )
          : createContactOptions(
              [storedCP || deliveryCP || takeAwayCP || contactPoints[0]],
              hasFreshProducts,
              exceedTotalWeight,
              totalAmount,
              cart
            );

      setSelectedContactPoint(contactPoint[0]);
    }
  }, [
    contactPoints,
    selectedContactPoint,
    setSelectedContactPoint,
    vendorId,
    totalAmount,
    products,
    hasFreshProducts,
    exceedTotalWeight,
    cart,
  ]);

  useEffect(() => {
    if (contactPoints && selectedContactPoint && !isLoading) {
      setInputContent(
        targetInputModified,
        contactPoints,
        selectedContactPoint,
        vendorId,
        address,
        totalAmount,
        date,
        products,
        onStateCheck,
        onRecoveryInfoChange,
        description,
        isSimplifiedStore,
        cart,
        currentRelay,
        exceedTotalWeight
      );
    }
    if (contactPoints && selectedContactPoint && isLoading) {
      setShowDatePicker(false);
      setIsLoading(false);
    }

    if (contactPoints) {
      const hasAvailableCPs = contactPoints.find(
        (cp) =>
          (!(cp.minimumDeliveryAmount > totalAmount) &&
            cp.type !== constants.CONTACT_POINT_TYPE.TAKE_AWAY) ||
          cp.type === constants.CONTACT_POINT_TYPE.TAKE_AWAY
      );

      if (!hasAvailableCPs) {
        disableNextButton(true);
      }
    }
  }, [
    address,
    contactPoints,
    date,
    description,
    isLoading,
    onRecoveryInfoChange,
    onStateCheck,
    products,
    selectedContactPoint,
    targetInputModified,
    totalAmount,
    vendorId,
    currentRelay,
    cart,
    disableNextButton,
    isSimplifiedStore,
    exceedTotalWeight,
  ]);

  useEffect(() => {
    (async () => {
      if (address) {
        try {
          const response = await httpClient.post(
            `/shipping/chronopost/relay-points`,
            {
              longitude: address.lng,
              latitude: address.lat,
              address: address.address1,
              postalCode: address.postal_code,
              city: address.locality,
              country: "FR",
              provider: "CHRONOPOST",
            }
          );

          setRelayPoints(response.relayPoints);
          setCurrentRelay(response.relayPoints[0]);
        } catch (error) {
          setRelayPoints([]);
        }
      }
    })();
  }, [httpClient, address]);

  const handleChangeContactPoint = useCallback(
    (contactPointValue, contactPoint) => {
      setSelectedContactPoint(contactPoint);
      setShowDatePicker(false);
      storeContactPointInLocalStorage(contactPointValue.value, vendorId);
    },
    [setSelectedContactPoint, vendorId]
  );

  const handleSelectContactPointType = useCallback(
    (type) => {
      const contactPoint =
        type === constants.CONTACT_POINT_TYPE.DELIVERY
          ? deliveryContactPoints.find(
              (cp) =>
                cp.isSelectable ||
                cp.minimumDeliveryAmount > totalAmount ||
                (cp.type === constants.CONTACT_POINT_TYPE.CHRONOPOST_RELAY &&
                  hasFreshProducts) ||
                (cp.type === constants.CONTACT_POINT_TYPE.CHRONOPOST_RELAY &&
                  exceedTotalWeight) // this double check handle with simplified store
            )
            ? deliveryContactPoints.find(
                (cp) =>
                  cp.isSelectable ||
                  cp.minimumDeliveryAmount > totalAmount ||
                  (cp.type === constants.CONTACT_POINT_TYPE.CHRONOPOST_RELAY &&
                    hasFreshProducts) ||
                  (cp.type === constants.CONTACT_POINT_TYPE.CHRONOPOST_RELAY &&
                    exceedTotalWeight)
              )
            : takeAwayContactPoints[0] // this second check is for the case where we dont have any available delivery
          : takeAwayContactPoints[0];
      handleChangeContactPoint(
        {
          value: contactPoint.value,
        },
        contactPoint
      );
    },
    [
      deliveryContactPoints,
      handleChangeContactPoint,
      takeAwayContactPoints,
      totalAmount,
      hasFreshProducts,
      exceedTotalWeight,
    ]
  );

  const handleOnSelect = useCallback(
    (event, value) => {
      handleChangeContactPoint(
        {
          value: event.target.value,
        },
        { value: value }
      );
    },
    [handleChangeContactPoint]
  );

  const handleChangeClientAddress = useCallback(
    (clientAddress) => {
      setAddress(clientAddress);
      storeClientAddressInLocalStorage(clientAddress, vendorId);
    },
    [vendorId]
  );

  const handleChangeDate = useCallback(
    (chosenDate) => {
      const date = resetHour({
        date: chosenDate,
        contactPoint: contactPointInfo,
      });
      storeChosenDateInLocalStorage(
        chosenDate,
        contactPointInfo.type,
        vendorId
      );
      setDate(date);
    },
    [contactPointInfo, setDate, vendorId]
  );

  const handleDescription = useCallback((event) => {
    event.preventDefault();
    const value = event.target.value;
    setDescription(value);
  }, []);

  const handleSelectedRelayPoint = useCallback((relayPoint) => {
    if (relayPoint) {
      setCurrentRelay(relayPoint);
    }
  }, []);

  const handleShowDatePicker = useCallback(() => {
    setShowDatePicker(true);
  }, []);

  if (invalidState) {
    return (
      <div className="producerContactPointInfos">
        <StyledCpTitle>{`${vendorIdx}. ${vendorName}`}</StyledCpTitle>
        <div>
          Impossible de completer cette commande. Veuillez contacter directement
          le producteur
        </div>
      </div>
    );
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <LoadScriptOnlyIfNeeded
      id="script-loader"
      googleMapsApiKey={API_KEY}
      libraries={librariesToLoad}
      loadingElement={<div></div>}
    >
      <Container container spacing={1}>
        {title && (
          <Grid item xs={12}>
            <Title
              align={width !== "xs" ? "left" : "center"}
              variant="subtitle2"
              component="h1"
              color="textSecondary"
            >
              {title}
            </Title>
          </Grid>
        )}
        {showVendorName && (
          <VendorName variant="h5" component="h5">
            {`${vendorIdx}. ${vendorName}`}
          </VendorName>
        )}
        <Body item container spacing={1} width={width}>
          <Grid item container xs={12} alignItems="flex-start">
            <Grid item xs={12} md={7}>
              <ContactPointButtons
                contactPoints={contactPoints}
                handleOnSelect={handleSelectContactPointType}
                hasDeliveryCPs={hasDeliveryCPs}
                hasTakeAwayCPs={hasTakeAwayCPs}
                contactPoint={contactPointInfo}
                unavailableDelivery={
                  !contactPoints.find(
                    (cp) =>
                      !(cp.minimumDeliveryAmount > totalAmount) &&
                      cp.type !== constants.CONTACT_POINT_TYPE.TAKE_AWAY
                  )
                }
              />
            </Grid>

            {showDateFieldUp && (
              <GridDateFieldStyled item xs={12} md={12}>
                {!isChronoPost && availableDates ? (
                  <ContactPointDateField
                    contactPoint={contactPointInfo}
                    selectedDate={date}
                    onShowDatePicker={handleShowDatePicker}
                    showDatePicker={showDatePicker}
                    availableDates={availableDates}
                    onChangeDate={handleChangeDate}
                  />
                ) : (
                  <Container>
                    <DeliveryDateFrequency
                      contactPoint={contactPointInfo}
                      isRelay={
                        contactPointInfo.type ===
                        constants.CONTACT_POINT_TYPE.CHRONOPOST_RELAY
                      }
                    />
                  </Container>
                )}
              </GridDateFieldStyled>
            )}
          </Grid>
          <Grid item xs={12}>
            <DeliveryOptionsTitle
              variant="body1"
              component="h5"
              color="textPrimary"
            >
              <ArrowIcon />
              {contactPointInfo.type !== constants.CONTACT_POINT_TYPE.TAKE_AWAY
                ? " Choisissez parmi les livraisons disponibles"
                : " Choisissez parmi les points de retrait disponibles"}
            </DeliveryOptionsTitle>
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <Grid item container xs={12} md={6}>
              <StyledInnerPaperOptions elevation={0} width={width}>
                {hasDeliveryCPs && isDelivery && (
                  <ContactPointOptions
                    selectedContactPoint={selectedContactPoint}
                    vendorId={vendorId}
                    onSelect={handleOnSelect}
                    contactPointOptions={deliveryContactPoints}
                    totalAmount={totalAmount}
                    hasFreshProducts={hasFreshProducts}
                  />
                )}
                {hasTakeAwayCPs &&
                  contactPointInfo.type ===
                    constants.CONTACT_POINT_TYPE.TAKE_AWAY && (
                    <ContactPointOptions
                      selectedContactPoint={selectedContactPoint}
                      vendorId={vendorId}
                      onSelect={handleOnSelect}
                      contactPointOptions={takeAwayContactPoints}
                      totalAmount={totalAmount}
                      hasFreshProducts={hasFreshProducts}
                    />
                  )}
              </StyledInnerPaperOptions>
            </Grid>
            <Grid item container xs={12} md={6} alignItems="flex-start">
              <StyledInnerPaperExtraInfo elevation={0} width={width}>
                <ContactPointExtraInfo
                  vendorId={vendorId}
                  contactPoint={contactPointInfo}
                  clientAddress={address}
                  onChangeClientAddress={handleChangeClientAddress}
                  totalAmount={totalAmount}
                  isSimplifiedStore={isSimplifiedStore}
                  cart={cart}
                  currentRelay={currentRelay}
                />
              </StyledInnerPaperExtraInfo>
            </Grid>
          </Grid>
          {!showDateFieldUp && (
            <GridDateFieldStyled item xs={12} md={12}>
              {!isChronoPost && availableDates ? (
                <ContactPointDateField
                  contactPoint={contactPointInfo}
                  selectedDate={date}
                  onShowDatePicker={handleShowDatePicker}
                  showDatePicker={showDatePicker}
                  availableDates={availableDates}
                  onChangeDate={handleChangeDate}
                />
              ) : (
                <Container>
                  {contactPointInfo.type ===
                    constants.CONTACT_POINT_TYPE.CHRONOPOST_RELAY &&
                    !hasFreshProducts &&
                    !exceedTotalWeight && (
                      <SelectRelayContainer>
                        <ArrowIcon />{" "}
                        <SelectRelayPoint
                          relayPoints={relayPoints}
                          handleSelectedRelayPoint={handleSelectedRelayPoint}
                          address={address}
                          currentRelay={currentRelay}
                        />
                      </SelectRelayContainer>
                    )}
                  <DeliveryDateFrequency
                    isRelay={
                      contactPointInfo.type ===
                      constants.CONTACT_POINT_TYPE.CHRONOPOST_RELAY
                    }
                    contactPoint={contactPointInfo}
                  />
                </Container>
              )}
            </GridDateFieldStyled>
          )}
          {showDescription && (
            <Grid container item xs={12}>
              <TextAreaDescription
                rows={4}
                placeholder="Laissez un commentaire..."
                type="text"
                onChange={handleDescription}
                value={description}
                width={width}
              />
            </Grid>
          )}
        </Body>
      </Container>
    </LoadScriptOnlyIfNeeded>
  );
}

ContactPointMain.propTypes = {
  availableDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  title: PropTypes.string,
  vendorId: PropTypes.string.isRequired,
  vendorIdx: PropTypes.number.isRequired,
  vendorName: PropTypes.string.isRequired,
  contactPoints: PropTypes.arrayOf(ContactPointShape).isRequired,
  selectedContactPoint: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  showVendorName: PropTypes.bool.isRequired,
  className: PropTypes.string,
  clientAddress: PropTypes.object,
  totalAmount: PropTypes.string.isRequired,
  targetInput: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.string),
  resetDeliveryRate: PropTypes.func.isRequired,
  preparationTime: PropTypes.number,
  showDateFieldUp: PropTypes.bool,
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
  cart: PropTypes.object,
};

ContactPointMain.defaultProps = {
  title: undefined,
  className: undefined,
  clientAddress: undefined,
  cart: {},
  selectedContactPoint: {},
};

export default withWidth()(ContactPointMain);
