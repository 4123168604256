import React, { useCallback } from "react";
import { useKeenSlider } from "keen-slider/react";
import ResponsivePicture from "../Common/ResponsivePicture";
import styled, { css } from "styled-components";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useMediaQuery } from "@material-ui/core";
import "keen-slider/keen-slider.min.css";

const ArrowBase = css`
  ${({ theme, disabled }) => `
    fill: ${disabled ? theme.palette.grey["50"] : theme.palette.grey["800"]};
  `};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;

const ArrowBack = styled(ArrowBackIosIcon)`
  ${ArrowBase};
  padding: 2px 0px 2px 6px;
  left: 18px;
`;

const ArrowForward = styled(ArrowForwardIosIcon)`
  ${ArrowBase};
  left: auto;
  right: 18px;
`;

const Slide = styled.div``;
const Dots = styled.div`
  display: flex;
  padding: 10px 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  ${({ isMobile }) => `
    justify-content: ${isMobile ? "none" : "center"};
    left: ${isMobile ? "10px" : "auto"};
  `};
`;
const Dot = styled.button`
  border: none;
  height: 7px;
  border-radius: 70%;
  margin: 0 5px;
  padding: 3px;
  cursor: pointer;
  :focus {
    outline: none;
  }
  ${({ theme, active }) => `
    border: 1px solid ${theme.palette.warning.light};
    background: ${active ? theme.palette.grey["800"] : "transparent"};
  `};
`;

function SinglePicture({ picture, height }) {
  return (
    <ResponsivePicture
      alt={`product`}
      height={height}
      sizeQuery="(min-width: 1280px) 20vw,(min-width: 960px) and (max-width: 1279px) 20vw,(min-width: 600px) and (max-width: 959px) 30vw, (max-width: 599px) 80vw"
      baseImgUrl={picture.url}
    />
  );
}

function MediaSlider({ pictures, height }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [sliderRef, slider] = useKeenSlider({
    initial: 0,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
  });

  const handlePrev = useCallback((e) => e.stopPropagation() || slider.prev(), [
    slider,
  ]);

  const handleNext = useCallback((e) => e.stopPropagation() || slider.next(), [
    slider,
  ]);

  if (pictures.length === 1) {
    return <SinglePicture picture={pictures[0]} height={height} />;
  }
  return (
    <>
      <div>
        <div ref={sliderRef} className="keen-slider">
          {pictures.map((picture) => {
            return (
              <Slide className="keen-slider__slide">
                <SinglePicture picture={picture} height={height} />
              </Slide>
            );
          })}
        </div>
        {slider && !isMobile && (
          <>
            <ArrowBack onClick={handlePrev} disabled={currentSlide === 0} />
            <ArrowForward
              onClick={handleNext}
              disabled={currentSlide === slider.details().size - 1}
            />
          </>
        )}
      </div>
      {slider && (
        <Dots isMobile={isMobile}>
          {[...Array(slider.details().size).keys()].map((idx) => {
            return (
              <Dot
                key={idx}
                active={currentSlide === idx}
                onClick={() => {
                  slider.moveToSlideRelative(idx);
                }}
              />
            );
          })}
        </Dots>
      )}
    </>
  );
}

export default MediaSlider;
