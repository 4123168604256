import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Material Components
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

// Material Hooks
import { useTheme } from "@material-ui/core/styles";

// Componets
import CircularProgress from "@material-ui/core/CircularProgress";

// Styleds
const Title = styled(Typography)`
  margin-bottom: 20px;
`;
const ButtonSwitch = styled(Button)`
  border-radius: 0;
  width: 100%;
  margin: ${(props) => props.margin || "15px 0"};
`;
const Container = styled.form``;
const Loader = styled(CircularProgress)`
  color: ${(props) => props.customcolor};
`;
const Error = styled(Typography)`
  background-color: ${({ theme }) => theme.palette.error.main};
  border-radius: 4px;
  color: ${({ theme }) => theme.palette.error.contrastText};
  padding: 5px 10px;
  margin-bottom: 12px;
  font-weight: bold;
`;

const Form = ({
  id,
  title,
  showForm,
  children,
  buttonLabel,
  onClick,
  onSubmit,
  linkAfterForm,
  loading,
  disabledSubmit,
  disabledOpenForm,
  error,
}) => {
  const theme = useTheme();
  return (
    <>
      <Title variant="h5" component="h3" align="center">
        {title}
      </Title>
      <Container
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(e, id);
        }}
      >
        {showForm ? (
          <>
            {error && (
              <Error variant="body1" component="h1">
                {error}
              </Error>
            )}
            {children}
            <ButtonSwitch
              variant="contained"
              color="primary"
              type="submit"
              disabled={disabledSubmit}
            >
              {!loading ? (
                buttonLabel
              ) : (
                <Loader
                  customcolor={theme.palette.secondary.contrastText}
                  size={24}
                />
              )}
            </ButtonSwitch>
            {linkAfterForm && linkAfterForm}
          </>
        ) : (
          <ButtonSwitch
            variant="outlined"
            onClick={onClick}
            margin="23px 0"
            disabled={disabledOpenForm}
          >
            {buttonLabel}
          </ButtonSwitch>
        )}
      </Container>
    </>
  );
};

Form.protoTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  error: PropTypes.string,
  showForm: PropTypes.bool,
  onClick: PropTypes.func,
  onSubmit: PropTypes.func,
  linkAfterForm: PropTypes.shape({
    url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

Form.defaultProps = {
  showForm: false,
  onClick: () => {},
  onSubmit: () => {},
  link: null,
  loading: false,
  disabled: false,
  error: undefined,
};

export default Form;
