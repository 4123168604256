import React from "react";
import PropTypes from "prop-types";
import { Divider, List } from "@material-ui/core";
import { scrollTo } from "../../services/utils";
import { CategoryRow, LoadingCategoryRow } from "./CategoryRow";

export class CategoryList extends React.Component {
  static propTypes = {
    categoryNameMap: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    products: PropTypes.array.isRequired,
  };

  handleClick = (category) => {
    scrollTo(`#cat_${category}`, { offset: -25, behavior: "auto" });
  };

  render() {
    const { products, categoryNameMap, loading } = this.props;
    return (
      <List disablePadding>
        {loading ? (
          <div>
            <Divider />
            <LoadingCategoryRow />
            <Divider />
            <LoadingCategoryRow />
            <Divider />
            <LoadingCategoryRow />
            <Divider />
            <LoadingCategoryRow />
          </div>
        ) : (
          products.map((category) => (
            <CategoryRow
              key={category.category}
              category={category}
              categoryNameMap={categoryNameMap}
              onClick={this.handleClick}
            />
          ))
        )}
      </List>
    );
  }
}
