import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
.af-view .w-embed-youtubevideo {
	width: 100%;
	position: relative;
	padding-bottom: 0px;
	padding-left: 0px;
	padding-right: 0px;
	background-image: url("https://d3e54v103j8qbb.cloudfront.net/static/youtube-placeholder.2b05e7d68d.svg");
	background-size: cover;
	background-position: 50% 50%;
      }
      
      .af-view .w-embed-youtubevideo:empty {
	min-height: 75px;
	padding-bottom: 56.25%;
      }
      
      .af-view .w-checkbox {
	display: block;
	margin-bottom: 5px;
	padding-left: 20px;
      }
      
      .af-view .w-checkbox::before {
	content: " ";
	display: table;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-row-span: 1;
	grid-row-end: 2;
	-ms-grid-row: 1;
	grid-row-start: 1;
      }
      
      .af-view .w-checkbox::after {
	content: " ";
	display: table;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-row-span: 1;
	grid-row-end: 2;
	-ms-grid-row: 1;
	grid-row-start: 1;
	clear: both;
      }
      
      .af-view .w-checkbox-input {
	float: left;
	margin-bottom: 0px;
	margin-left: -20px;
	margin-right: 0px;
	margin-top: 4px;
	line-height: normal;
      }
      
      .af-view .w-checkbox-input--inputType-custom {
	border-top-width: 1px;
	border-bottom-width: 1px;
	border-left-width: 1px;
	border-right-width: 1px;
	border-top-color: ${({ theme }) => theme.palette.others.e1};
	border-bottom-color: ${({ theme }) => theme.palette.others.e1};
	border-left-color: ${({ theme }) => theme.palette.others.e1};
	border-right-color: ${({ theme }) => theme.palette.others.e1};
	border-top-style: solid;
	border-bottom-style: solid;
	border-left-style: solid;
	border-right-style: solid;
	width: 12px;
	height: 12px;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
      }
      
      .af-view .w-checkbox-input--inputType-custom.w--redirected-checked {
	background-color: ${({ theme }) => theme.palette.others.blue};
	border-top-color: ${({ theme }) => theme.palette.others.blue};
	border-bottom-color: ${({ theme }) => theme.palette.others.blue};
	border-left-color: ${({ theme }) => theme.palette.others.blue};
	border-right-color: ${({ theme }) => theme.palette.others.blue};
	background-image: url("https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg");
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
      }
      
      .af-view .w-checkbox-input--inputType-custom.w--redirected-focus {
	box-shadow: 0px 0px 3px 1px ${({ theme }) => theme.palette.others.blue};
      }
      
      .af-view .w-form-formradioinput--inputType-custom {
	border-top-width: 1px;
	border-bottom-width: 1px;
	border-left-width: 1px;
	border-right-width: 1px;
	border-top-color: ${({ theme }) => theme.palette.others.e1};
	border-bottom-color: ${({ theme }) => theme.palette.others.e1};
	border-left-color: ${({ theme }) => theme.palette.others.e1};
	border-right-color: ${({ theme }) => theme.palette.others.e1};
	border-top-style: solid;
	border-bottom-style: solid;
	border-left-style: solid;
	border-right-style: solid;
	width: 12px;
	height: 12px;
	border-bottom-left-radius: 50%;
	border-bottom-right-radius: 50%;
	border-top-left-radius: 50%;
	border-top-right-radius: 50%;
      }
      
      .af-view .w-form-formradioinput--inputType-custom.w--redirected-focus {
	box-shadow: 0px 0px 3px 1px ${({ theme }) => theme.palette.others.blue};
      }
      
      .af-view .w-form-formradioinput--inputType-custom.w--redirected-checked {
	border-top-width: 4px;
	border-bottom-width: 4px;
	border-left-width: 4px;
	border-right-width: 4px;
	border-top-color: ${({ theme }) => theme.palette.others.blue};
	border-bottom-color: ${({ theme }) => theme.palette.others.blue};
	border-left-color: ${({ theme }) => theme.palette.others.blue};
	border-right-color: ${({ theme }) => theme.palette.others.blue};
      }
      
      .af-view .w-pagination-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
      }
      
      .af-view .w-pagination-previous {
	display: block;
	color: ${({ theme }) => theme.palette.others.f1};
	font-size: 14px;
	margin-left: 10px;
	margin-right: 10px;
	padding-top: 9px;
	padding-bottom: 9px;
	padding-right: 20px;
	padding-left: 20px;
	background-color: ${({ theme }) => theme.palette.others.e};
	border-top-width: 1px;
	border-right-width: 1px;
	border-bottom-width: 1px;
	border-left-width: 1px;
	border-top-color: ${({ theme }) => theme.palette.others.e1};
	border-right-color: ${({ theme }) => theme.palette.others.e1};
	border-bottom-color: ${({ theme }) => theme.palette.others.e1};
	border-left-color: ${({ theme }) => theme.palette.others.e1};
	border-top-style: solid;
	border-right-style: solid;
	border-bottom-style: solid;
	border-left-style: solid;
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
	border-bottom-left-radius: 2px;
	border-top-left-radius: 2px;
      }
      
      .af-view .w-pagination-next {
	display: block;
	color: ${({ theme }) => theme.palette.others.f1};
	font-size: 14px;
	margin-left: 10px;
	margin-right: 10px;
	padding-top: 9px;
	padding-bottom: 9px;
	padding-right: 20px;
	padding-left: 20px;
	background-color: ${({ theme }) => theme.palette.others.e};
	border-top-width: 1px;
	border-right-width: 1px;
	border-bottom-width: 1px;
	border-left-width: 1px;
	border-top-color: ${({ theme }) => theme.palette.others.e1};
	border-right-color: ${({ theme }) => theme.palette.others.e1};
	border-bottom-color: ${({ theme }) => theme.palette.others.e1};
	border-left-color: ${({ theme }) => theme.palette.others.e1};
	border-top-style: solid;
	border-right-style: solid;
	border-bottom-style: solid;
	border-left-style: solid;
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
	border-bottom-left-radius: 2px;
	border-top-left-radius: 2px;
      }
      
      .af-view {
	padding-left: 0px;
	font-family: Rubik, sans-serif;
	color: ${({ theme }) => theme.palette.others.f};
	font-size: 16px;
	line-height: 24px;
      }
      
      .af-view h1 {
	margin-top: 0px;
	margin-bottom: 32px;
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 54px;
	line-height: 56px;
	font-weight: 700;
	letter-spacing: -0.04em;
      }
      
      .af-view h2 {
	margin-top: 0px;
	margin-bottom: 24px;
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 40px;
	line-height: 48px;
	font-weight: 700;
	letter-spacing: -0.04em;
      }
      
      .af-view h3 {
	margin-top: 0px;
	margin-bottom: 16px;
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 32px;
	line-height: 40px;
	font-weight: 700;
	letter-spacing: -0.04em;
      }
      
      .af-view h4 {
	margin-top: 0px;
	margin-bottom: 12px;
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 28px;
	line-height: 32px;
	font-weight: 700;
	letter-spacing: -0.03em;
      }
      
      .af-view h5 {
	margin-top: 0px;
	margin-bottom: 12px;
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 24px;
	line-height: 30px;
	font-weight: 700;
	letter-spacing: -0.03em;
      }
      
      .af-view h6 {
	margin-top: 0px;
	margin-bottom: 12px;
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 20px;
	line-height: 24px;
	font-weight: 700;
	letter-spacing: -0.02em;
      }
      
      .af-view p {
	margin-bottom: 16px;
      }
      
      .af-view a {
	color: ${({ theme }) => theme.palette.others.h};
	font-weight: 500;
	text-decoration: none;
      }
      
      .af-view ul {
	margin-top: 0px;
	margin-bottom: 10px;
	padding-left: 40px;
      }
      
      .af-view img {
	display: inline-block;
	max-width: 100%;
      }
      
      .af-view blockquote {
	margin-bottom: 24px;
	padding: 0px;
	border-left: 5px none ${({ theme }) => theme.palette.others.i};
	font-size: 24px;
	line-height: 36px;
	letter-spacing: -0.01em;
      }
      
      .af-view figure {
	margin-bottom: 10px;
      }
      
      .af-view figcaption {
	margin-top: 5px;
	text-align: center;
      }
      
      .af-view .af-class-section {
	position: relative;
	display: block;
	margin-bottom: 96px;
	padding: 40px 135px 53px;
	text-align: left;
      }
      
      .af-view .af-class-section.af-class-style-guide-section {
	border-bottom: 1px solid ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-section.af-class-title-section {
	padding-top: 120px;
	padding-bottom: 120px;
      }
      
      .af-view .af-class-section.af-class-no-top-space {
	padding-top: 0px;
      }
      
      .af-view
	.af-class-section.af-class-no-top-space.af-class-pricing-plans-section {
	margin-top: 0px;
      }
      
      .af-view .af-class-section.af-class-no-bottom-space {
	padding-bottom: 0px;
	background-color: ${({ theme }) => theme.palette.others.k};
      }
      
      .af-view .af-class-section.af-class-no-bottom-space.af-class-bg-primary-3 {
	padding-right: 0px;
	padding-left: 0px;
	background-color: ${({ theme }) => theme.palette.success.light};
      }
      
      .af-view .af-class-section.af-class-no-bottom-space.af-class-bg-gray-4 {
	border-radius: 0px;
	background-color: transparent;
      }
      
      .af-view .af-class-section.af-class-overflow-hidden {
	overflow: hidden;
      }
      
      .af-view .af-class-section.af-class-bg-gray-4 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 80px 0px 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-section.af-class-section-small {
	margin-top: 30px;
	padding: 41px 100px 29px;
	background-color: transparent;
	color: ${({ theme }) => theme.palette.others.g};
      }
      
      .af-view .af-class-section.af-class-utility-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-right: 24px;
	padding-left: 24px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	color: ${({ theme }) => theme.palette.others.l};
      }
      
      .af-view .af-class-section.af-class-no-space {
	padding-top: 0px;
	padding-bottom: 0px;
      }
      
      .af-view .af-class-section.af-class-hero-screenshot-above {
	margin-top: 25vh;
	padding-top: 24px;
      }
      
      .af-view .af-class-section.af-class-no-top-space-copy {
	padding-top: 0px;
      }
      
      .af-view
	.af-class-section.af-class-no-top-space-copy.af-class-pricing-plans-section {
	margin-top: 0px;
      }
      
      .af-view .af-class-container-testimonials {
	position: relative;
	z-index: 3;
	width: 100%;
	max-width: 1272px;
	margin-right: auto;
	margin-left: auto;
	padding: 0px 60px;
      }
      
      .af-view .af-class-container-testimonials.af-class-announcement-bar-container {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-container-testimonials.af-class-text-center {
	display: block;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
      }
      
      .af-view
	.af-class-container-testimonials.af-class-text-center.af-class-hero-container {
	position: relative;
	z-index: 3;
	padding-top: 20px;
	padding-bottom: 40px;
      }
      
      .af-view
	.af-class-container-testimonials.af-class-text-center.af-class-hero-container-nav-above {
	margin-top: 96px;
	padding-top: 6vw;
	padding-bottom: 6vw;
      }
      
      .af-view .af-class-container-testimonials.af-class-container-narrow {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 864px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view
	.af-class-container-testimonials.af-class-container-narrow.af-class-align-left {
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
      }
      
      .af-view
	.af-class-container-testimonials.af-class-container-narrow.af-class-text-center {
	max-width: 100%;
      }
      
      .af-view .af-class-container-testimonials.af-class-align-left {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
      }
      
      .af-view .af-class-container-testimonials.af-class-post-breadcrumbs-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
      }
      
      .af-view .af-class-container-testimonials.af-class-hero-container {
	position: relative;
	z-index: 1;
	padding-top: 6vh;
	padding-bottom: 6vh;
      }
      
      .af-view .af-class-container-testimonials.af-class-hero-screenshot-container {
	position: relative;
	z-index: 2;
	margin-top: 72px;
	margin-bottom: -25vh;
	text-align: center;
      }
      
      .af-view .af-class-container-testimonials.af-class-case-study-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
      }
      
      .af-view .af-class-style-guide-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
      }
      
      .af-view .af-class-style-guide-title {
	width: 35%;
	padding-right: 72px;
      }
      
      .af-view .af-class-style-guide-content {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
      }
      
      .af-view .af-class-style-guide-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 48px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
      }
      
      .af-view .af-class-style-guide-row.af-class-bg-primary-3 {
	padding: 24px 24px 0px;
      }
      
      .af-view .af-class-style-guide-row.af-class-narrow {
	max-width: 460px;
      }
      
      .af-view .af-class-style-guide-color-container {
	margin-right: 24px;
      }
      
      .af-view .af-class-text-small {
	font-size: 14px;
      }
      
      .af-view .af-class-text-small.af-class-style-guide-label {
	margin-top: 6px;
	opacity: 0.5;
      }
      
      .af-view .af-class-text-small.af-class-dropdown-subtitle {
	opacity: 0.75;
      }
      
      .af-view .af-class-bg-primary-2 {
	background-color: ${({ theme }) => theme.palette.others.m};
	color: #fff;
      }
      
      .af-view .af-class-bg-primary-2.af-class-style-guide-color {
	border: 0px none #000;
      }
      
      .af-view .af-class-style-guide-color {
	width: 96px;
	height: 96px;
	border-radius: 5px;
      }
      
      .af-view .af-class-style-guide-color.af-class-bg-primary {
	background-color: ${({ theme }) => theme.palette.others.h};
      }
      
      .af-view .af-class-bg-primary-3 {
	background-color: ${({ theme }) => theme.palette.others.n};
	color: #fff;
      }
      
      .af-view .af-class-bg-primary-3.af-class-style-guide-color {
	border: 1px none #000;
      }
      
      .af-view .af-class-bg-success {
	background-color: ${({ theme }) => theme.palette.others.o};
	color: #fff;
      }
      
      .af-view .af-class-bg-warning {
	background-color: ${({ theme }) => theme.palette.others.p};
	color: #fff;
      }
      
      .af-view .af-class-bg-error {
	background-color: ${({ theme }) => theme.palette.others.q};
	color: #fff;
      }
      
      .af-view .af-class-bg-gray-1 {
	background-color: ${({ theme }) => theme.palette.others.g};
	color: #fff;
      }
      
      .af-view .af-class-bg-gray-2 {
	background-color: ${({ theme }) => theme.palette.others.f};
	color: #fff;
      }
      
      .af-view .af-class-bg-gray-3 {
	background-color: ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-bg-gray-4 {
	background-color: ${({ theme }) => theme.palette.others.r};
      }
      
      .af-view .af-class-border {
	border-style: solid;
	border-width: 1px;
	border-color: ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-bg-white {
	background-color: #fff;
      }
      
      .af-view .af-class-bg-primary {
	background-color: ${({ theme }) => theme.palette.others.h};
	color: #fff;
      }
      
      .af-view .af-class-h6-small {
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 16px;
	font-weight: 500;
      }
      
      .af-view .af-class-h6-small.af-class-style-guide-subheading {
	margin-bottom: 24px;
      }
      
      .af-view .af-class-h6-small.af-class-dropdown-heading {
	margin-right: 8px;
	margin-bottom: 0px;
      }
      
      .af-view .af-class-h6-small.af-class-icon-list-heading {
	margin-bottom: 0px;
	font-family: Lato, sans-serif;
	font-weight: 700;
      }
      
      .af-view .af-class-h6-small.af-class-blockquote-author-heading {
	margin-right: 8px;
	margin-bottom: 0px;
      }
      
      .af-view .af-class-h6-small.af-class-career-heading {
	width: 60%;
	margin-bottom: 0px;
      }
      
      .af-view .af-class-h6-small.af-class-customer-heading {
	margin-bottom: 0px;
      }
      
      .af-view .af-class-style-guide-typography {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 16px;
      }
      
      .af-view .af-class-text-large {
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 20px;
	line-height: 30px;
      }
      
      .af-view .af-class-text-large.af-class-footer-description {
	margin-bottom: 24px;
      }
      
      .af-view .af-class-text-spacer {
	margin-right: 12px;
      }
      
      .af-view .af-class-text-tiny {
	font-size: 12px;
      }
      
      .af-view .af-class-text-tiny.af-class-text-right {
	text-align: right;
      }
      
      .af-view .af-class-text-tiny.af-class-checkbox-label {
	margin-bottom: 0px;
      }
      
      .af-view .af-class-text-small-caps {
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0.05em;
	text-transform: uppercase;
      }
      
      .af-view .af-class-text-small-caps.af-class-small-caps-title {
	margin-bottom: 16px;
      }
      
      .af-view .af-class-text-small-caps.af-class-contact-method-heading {
	margin-bottom: 8px;
	opacity: 0.5;
      }
      
      .af-view .af-class-style-guide-icon {
	margin-right: 24px;
	margin-bottom: 24px;
      }
      
      .af-view .af-class-style-guide-icon.af-class-illustration {
	max-height: 100px;
      }
      
      .af-view .af-class-button {
	padding: 10px 24px;
	border-style: solid;
	border-width: 2px;
	border-color: ${({ theme }) => theme.palette.others.h};
	border-radius: 5px;
	background-color: ${({ theme }) => theme.palette.others.h};
	-webkit-transition: opacity 200ms ease, color 200ms ease,
	  border-color 200ms ease, background-color 200ms ease;
	transition: opacity 200ms ease, color 200ms ease, border-color 200ms ease,
	  background-color 200ms ease;
	color: #fff;
	font-weight: 500;
	text-align: center;
      }
      
      .af-view .af-class-button:hover {
	opacity: 0.75;
      }
      
      .af-view .af-class-button.w--current {
	background-color: #fff;
	color: ${({ theme }) => theme.palette.others.h};
      }
      
      .af-view .af-class-button.af-class-button-small {
	padding: 4px 16px;
	font-size: 14px;
      }
      
      .af-view .af-class-button.af-class-button-large {
	padding: 10px 36px;
	border-style: none;
	border-radius: 20px;
	background-color: ${({ theme }) => theme.palette.others.k};
	font-size: 20px;
	line-height: 30px;
      }
      
      .af-view .af-class-button.af-class-button-large.af-class-add-space-right {
	margin-right: 32px;
	padding: 10px 45px;
	border-style: none;
	border-radius: 20px;
	background-color: ${({ theme }) => theme.palette.primary.main};
      }
      
      .af-view
	.af-class-button.af-class-button-large.af-class-add-space-right.af-class-primary-button {
	width: auto;
	margin-right: 20px;
	padding: 9px 50px;
	text-align: center;
      }
      
      .af-view .af-class-button.af-class-button-outline {
	background-color: transparent;
	color: ${({ theme }) => theme.palette.others.h};
      }
      
      .af-view .af-class-button.af-class-button-outline:hover {
	background-color: ${({ theme }) => theme.palette.others.h};
	opacity: 1;
	color: #fff;
      }
      
      .af-view
	.af-class-button.af-class-button-outline.af-class-pricing-comparison-button {
	width: 100%;
	margin-top: 24px;
      }
      
      .af-view .af-class-button.af-class-button-disabled {
	opacity: 0.5;
	cursor: not-allowed;
      }
      
      .af-view .af-class-button.af-class-button-round {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 48px;
	height: 48px;
	padding: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 50%;
      }
      
      .af-view .af-class-button.af-class-button-round.af-class-slider-button-left {
	left: 0px;
	border-style: none;
	background-color: ${({ theme }) => theme.palette.primary.main};
      }
      
      .af-view .af-class-button.af-class-button-round.af-class-slider-button-right {
	right: 0px;
	border-style: none;
	background-color: ${({ theme }) => theme.palette.primary.main};
      }
      
      .af-view .af-class-button.af-class-button-round.af-class-back-to-top-button {
	-webkit-transition: -webkit-transform 200ms ease;
	transition: -webkit-transform 200ms ease;
	transition: transform 200ms ease;
	transition: transform 200ms ease, -webkit-transform 200ms ease;
      }
      
      .af-view
	.af-class-button.af-class-button-round.af-class-back-to-top-button:hover {
	opacity: 1;
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
      }
      
      .af-view .af-class-button.af-class-button-round.af-class-page-navigator-button {
	position: relative;
	margin-right: 12px;
      }
      
      .af-view .af-class-button.af-class-dropdown-button {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	opacity: 1;
      }
      
      .af-view .af-class-button.af-class-dropdown-button.w--open {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
      }
      
      .af-view .af-class-button.af-class-submit-button {
	margin-top: 12px;
	border-style: none;
	border-radius: 20px;
	background-color: ${({ theme }) => theme.palette.primary.main};
      }
      
      .af-view .af-class-button.af-class-button-block {
	width: 100%;
      }
      
      .af-view .af-class-button.af-class-utility-page-button {
	width: 100%;
      }
      
      .af-view .af-class-button.af-class-button-with-icon {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-button.af-class-navbar-button {
	padding-top: 9px;
	padding-bottom: 9px;
	border-style: none;
	border-radius: 20px;
	background-color: ${({ theme }) => theme.palette.primary.main};
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-button.af-class-button-white {
	border-color: #fff;
	background-color: #fff;
	color: ${({ theme }) => theme.palette.others.h};
      }
      
      .af-view .af-class-form-input {
	height: 48px;
	margin-bottom: 12px;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 20px;
	border-style: solid;
	border-width: 1px;
	border-color: ${({ theme }) => theme.palette.others.j};
	border-radius: 5px;
	background-color: #fff;
	-webkit-transition: border-color 200ms ease;
	transition: border-color 200ms ease;
	font-size: 16px;
	line-height: 24px;
      }
      
      .af-view .af-class-form-input:focus {
	border-color: ${({ theme }) => theme.palette.others.h};
      }
      
      .af-view .af-class-form-input.af-class-form-input-small {
	height: 36px;
	padding-left: 16px;
	font-size: 14px;
      }
      
      .af-view .af-class-form-input.af-class-form-input-large {
	height: 72px;
	font-size: 20px;
      }
      
      .af-view
	.af-class-form-input.af-class-form-input-large.af-class-subscribe-form-input {
	margin-right: 12px;
      }
      
      .af-view
	.af-class-form-input.af-class-form-input-large.af-class-search-form-input {
	margin-right: 12px;
	margin-bottom: 0px;
      }
      
      .af-view .af-class-form-input.af-class-textarea-input {
	height: auto;
	min-height: 96px;
	padding-top: 12px;
      }
      
      .af-view
	.af-class-form-input.af-class-textarea-input.af-class-textarea-input-large {
	min-height: 240px;
      }
      
      .af-view .af-class-form-input.af-class-row-form-input {
	margin-bottom: 0px;
      }
      
      .af-view .af-class-field-label {
	margin-top: 4px;
	margin-bottom: 8px;
	opacity: 1;
	font-weight: 400;
      }
      
      .af-view .af-class-navbar-container {
	position: relative;
	z-index: 99;
	background-color: #fff;
      }
      
      .af-view .af-class-navbar-container.af-class-bg-gray-4 {
	background-color: ${({ theme }) => theme.palette.others.r};
      }
      
      .af-view .af-class-navbar-container.af-class-sticky-top {
	position: -webkit-sticky;
	position: sticky;
	top: 0px;
      }
      
      .af-view .af-class-navbar-container.af-class-bg-gray-3 {
	background-color: ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-navbar-container.af-class-bg-primary-3 {
	background-color: ${({ theme }) => theme.palette.others.n};
      }
      
      .af-view .af-class-navbar-container.af-class-bg-transparent {
	margin-bottom: -80px;
	background-color: transparent;
      }
      
      .af-view .af-class-navbar-container.af-class-bg-primary {
	background-color: ${({ theme }) => theme.palette.others.h};
      }
      
      .af-view .af-class-navbar {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 16px 25px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
      }
      
      .af-view .af-class-nav-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
      }
      
      .af-view .af-class-nav-link {
	margin-right: auto;
	margin-left: auto;
	padding: 0px 16px;
	opacity: 0.5;
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
	font-size: 20px;
	line-height: 30px;
	font-weight: 500;
      }
      
      .af-view .af-class-nav-link:hover {
	opacity: 1;
      }
      
      .af-view .af-class-nav-link.w--current {
	opacity: 1;
	color: ${({ theme }) => theme.palette.others.h};
      }
      
      .af-view .af-class-dropdown-list {
	-webkit-transform: translate(0px, 16px);
	-ms-transform: translate(0px, 16px);
	transform: translate(0px, 16px);
      }
      
      .af-view .af-class-dropdown-list.w--open {
	min-width: 200px;
	padding: 12px 0px;
	border-style: solid;
	border-width: 1px;
	border-color: ${({ theme }) => theme.palette.others.j};
	border-radius: 5px;
	background-color: #fff;
	box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05);
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
      }
      
      .af-view .af-class-dropdown-list.af-class-dropdown-list-top {
	padding-bottom: 0px;
      }
      
      .af-view
	.af-class-dropdown-list.af-class-dropdown-list-top.af-class-dropdown-list-large.w--open {
	min-width: 320px;
      }
      
      .af-view .af-class-dropdown-link {
	padding: 4px 24px;
	opacity: 0.5;
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
	color: ${({ theme }) => theme.palette.others.f};
      }
      
      .af-view .af-class-dropdown-link:hover {
	opacity: 1;
      }
      
      .af-view .af-class-dropdown-link.w--current {
	opacity: 1;
	color: ${({ theme }) => theme.palette.others.h};
      }
      
      .af-view .af-class-dropdown-link.af-class-dropdown-link-block {
	width: 100%;
	padding-top: 8px;
	padding-bottom: 8px;
	opacity: 1;
	color: ${({ theme }) => theme.palette.others.f};
	font-weight: 400;
      }
      
      .af-view .af-class-dropdown-link.af-class-dropdown-link-block:hover {
	opacity: 0.5;
      }
      
      .af-view .af-class-footer {
	padding-top: 96px;
	padding-bottom: 48px;
      }
      
      .af-view .af-class-footer-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 120px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
      }
      
      .af-view .af-class-footer-logo-link {
	margin-bottom: 24px;
      }
      
      .af-view .af-class-social-links {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: -12px;
	margin-left: -12px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
      }
      
      .af-view .af-class-social-links.af-class-social-links-circles {
	margin-top: 48px;
	margin-bottom: 0px;
	padding-left: 0px;
      }
      
      .af-view .af-class-social-link {
	margin-right: 12px;
	margin-left: 12px;
	opacity: 0.5;
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
      }
      
      .af-view .af-class-social-link:hover {
	opacity: 1;
      }
      
      .af-view .af-class-social-link.af-class-social-link-circle {
	opacity: 1;
	-webkit-transition: opacity 200ms ease, -webkit-transform 200ms ease;
	transition: opacity 200ms ease, -webkit-transform 200ms ease;
	transition: transform 200ms ease, opacity 200ms ease;
	transition: transform 200ms ease, opacity 200ms ease,
	  -webkit-transform 200ms ease;
      }
      
      .af-view .af-class-social-link.af-class-social-link-circle:hover {
	-webkit-transform: scale(1.075);
	-ms-transform: scale(1.075);
	transform: scale(1.075);
      }
      
      .af-view .af-class-footer-info {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 384px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
      }
      
      .af-view .af-class-footer-menus-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
      }
      
      .af-view .af-class-footer-menu-column {
	margin-left: 72px;
      }
      
      .af-view .af-class-link-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
      }
      
      .af-view .af-class-link-menu-item {
	margin-top: 6px;
	margin-bottom: 6px;
      }
      
      .af-view .af-class-footer-link {
	opacity: 0.5;
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.others.f};
	font-weight: 400;
      }
      
      .af-view .af-class-footer-link:hover {
	opacity: 1;
      }
      
      .af-view .af-class-footer-link.w--current {
	opacity: 1;
	color: ${({ theme }) => theme.palette.others.f};
      }
      
      .af-view .af-class-dropdown-list-footer {
	margin-top: 12px;
	padding-top: 12px;
	padding-bottom: 12px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	background-color: ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-badge {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 30px;
	padding-right: 12px;
	padding-left: 12px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 3px;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
      }
      
      .af-view .af-class-badge.af-class-badge-small {
	height: 24px;
	padding-right: 6px;
	padding-left: 6px;
	font-size: 12px;
	font-weight: 500;
      }
      
      .af-view .af-class-badge.af-class-bg-success.af-class-announcement-badge {
	margin-right: 16px;
      }
      
      .af-view .af-class-badge.af-class-pricing-plan-badge {
	margin-bottom: 24px;
      }
      
      .af-view .af-class-style-guide-item {
	margin-right: 12px;
	margin-bottom: 12px;
      }
      
      .af-view .af-class-style-guide-item.af-class-narrow {
	max-width: 50%;
      }
      
      .af-view .af-class-avatar {
	width: 72px;
	height: 72px;
	border-radius: 50%;
      }
      
      .af-view .af-class-avatar.af-class-avatar-small {
	width: 48px;
	height: 48px;
      }
      
      .af-view .af-class-avatar.af-class-avatar-xlarge {
	width: 168px;
	height: 168px;
      }
      
      .af-view .af-class-avatar.af-class-avatar-xlarge.af-class-avatar-group-item {
	width: 140px;
	height: 140px;
      }
      
      .af-view .af-class-avatar.af-class-avatar-xlarge.af-class-team-member-avatar {
	margin-bottom: 24px;
      }
      
      .af-view .af-class-avatar.af-class-avatar-large {
	width: 96px;
	height: 96px;
      }
      
      .af-view .af-class-avatar.af-class-avatar-xsmall {
	width: 36px;
	height: 36px;
      }
      
      .af-view .af-class-avatar.af-class-post-horizontal-avatar {
	margin-right: 48px;
      }
      
      .af-view .af-class-avatar-container {
	position: relative;
      }
      
      .af-view .af-class-avatar-indicator {
	position: absolute;
	top: 0px;
	right: 5%;
	width: 20%;
	height: 20%;
	min-height: 12px;
	min-width: 12px;
	border: 2px solid #fff;
	border-radius: 50%;
      }
      
      .af-view .af-class-avatar-group {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 16px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
      }
      
      .af-view .af-class-avatar-group-item {
	margin-right: -16px;
	border-style: solid;
	border-width: 5px;
	border-color: ${({ theme }) => theme.palette.others.r};
      }
      
      .af-view .af-class-card {
	position: relative;
	border-style: solid;
	border-width: 1px;
	border-color: ${({ theme }) => theme.palette.others.j};
	border-radius: 10px;
	background-color: #fff;
	text-align: left;
      }
      
      .af-view .af-class-card.af-class-bg-gray-4 {
	background-color: ${({ theme }) => theme.palette.others.r};
      }
      
      .af-view .af-class-card.af-class-bg-white {
	border-color: hsla(0, 0%, 100%, 0);
	background-color: hsla(0, 0%, 100%, 0.05);
	color: #fff;
      }
      
      .af-view .af-class-card.af-class-shadow.af-class-career-description-card {
	margin-top: 24px;
	margin-bottom: 96px;
      }
      
      .af-view .af-class-card.af-class-shadow-large {
	margin-bottom: 82px;
	border-radius: 8px;
      }
      
      .af-view .af-class-card.af-class-logo-card {
	text-align: center;
      }
      
      .af-view .af-class-card.af-class-card-horizontal {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
      }
      
      .af-view .af-class-card.af-class-terms-card {
	margin-bottom: 24px;
      }
      
      .af-view .af-class-card.af-class-pricing-comparison-card.af-class-first {
	border-top-left-radius: 10px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
      }
      
      .af-view .af-class-card.af-class-pricing-comparison-card.af-class-second {
	border-right-style: none;
	border-left-style: none;
	border-radius: 0px;
      }
      
      .af-view .af-class-card.af-class-pricing-comparison-card.af-class-third {
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
      }
      
      .af-view .af-class-card.af-class-form-card {
	width: 100%;
	max-width: 384px;
	margin-bottom: 24px;
      }
      
      .af-view .af-class-card.af-class-form-card.af-class-split-layout-form-card {
	margin-top: 0vh;
      }
      
      .af-view .af-class-card.af-class-featured-post-card {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
      }
      
      .af-view .af-class-card.af-class-help-center-card {
	margin-bottom: 24px;
	-webkit-transition: border-color 200ms ease;
	transition: border-color 200ms ease;
      }
      
      .af-view .af-class-card.af-class-help-center-card:hover {
	border-color: ${({ theme }) => theme.palette.others.h};
      }
      
      .af-view .af-class-card.af-class-related-articles-card {
	margin-bottom: 24px;
      }
      
      .af-view .af-class-card.af-class-blog-card {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
      }
      
      .af-view .af-class-card-body {
	padding: 36px;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	color: ${({ theme }) => theme.palette.others.f};
      }
      
      .af-view .af-class-card-body.af-class-card-horizontal-body {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 72px;
	padding-left: 72px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
      }
      
      .af-view .af-class-card-body.af-class-career-card-body {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 24px;
      }
      
      .af-view .af-class-card-body.af-class-pricing-card-body {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
      }
      
      .af-view .af-class-card-body.af-class-featured-post-card-body {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 72px;
	padding-left: 72px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-card-body.af-class-category-card-body {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 48px;
      }
      
      .af-view .af-class-text-white {
	margin-bottom: -21px;
	padding-top: 0px;
	padding-right: 150px;
	padding-left: 150px;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 35px;
	line-height: 40px;
      }
      
      .af-view .af-class-card-image-top {
	width: 100%;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
      }
      
      .af-view .af-class-menu-button {
	margin-left: 24px;
	padding: 8px;
	background-color: transparent;
      }
      
      .af-view .af-class-menu-button.w--open {
	background-color: transparent;
      }
      
      .af-view .af-class-nav-buttons-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 0px;
	padding-left: 0px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-content: space-between;
	-ms-flex-line-pack: justify;
	align-content: space-between;
	-webkit-box-flex: 0.05;
	-webkit-flex: 0.05 auto;
	-ms-flex: 0.05 auto;
	flex: 0.05 auto;
	-o-object-fit: fill;
	object-fit: fill;
      }
      
      .af-view .af-class-menu-button-cross {
	position: absolute;
	left: 8px;
	top: 8px;
	display: block;
      }
      
      .af-view .af-class-menu-button-cross.af-class-navigator-button-cross {
	left: 10px;
	top: 10px;
	display: none;
      }
      
      .af-view .af-class-menu-button-toggle {
	display: block;
      }
      
      .af-view .af-class-checkbox-field {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
      }
      
      .af-view .af-class-checkbox-label {
	margin-left: 8px;
      }
      
      .af-view .af-class-dropdown-link-text {
	margin-right: 8px;
	opacity: 0.5;
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
	color: ${({ theme }) => theme.palette.others.f};
      }
      
      .af-view .af-class-dropdown-link-text:hover {
	opacity: 1;
      }
      
      .af-view .af-class-dropdown-link-pair {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 4px 24px;
      }
      
      .af-view .af-class-dropdown-link-pair.af-class-dropdown-link-block-pair {
	padding: 0px;
      }
      
      .af-view .af-class-shadow {
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.05);
      }
      
      .af-view .af-class-shadow-small {
	box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05);
      }
      
      .af-view .af-class-shadow-large {
	box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.05);
      }
      
      .af-view .af-class-pill {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 12px 24px 12px 12px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 1000px;
      }
      
      .af-view .af-class-pill.af-class-bg-gray-3 {
	color: ${({ theme }) => theme.palette.others.f};
      }
      
      .af-view .af-class-pill.af-class-pill-notice {
	padding-right: 24px;
	padding-left: 24px;
	text-align: center;
      }
      
      .af-view .af-class-pill.af-class-pill-notice.af-class-bg-gray-3 {
	background-color: ${({ theme }) => theme.palette.success.light};
      }
      
      .af-view .af-class-pill.af-class-pill-notice.af-class-bg-white {
	background-color: hsla(0, 0%, 100%, 0.1);
	color: #fff;
      }
      
      .af-view .af-class-pill.af-class-bg-gray-4 {
	color: ${({ theme }) => theme.palette.others.f};
      }
      
      .af-view .af-class-pill.af-class-mobile-screenshot-pill {
	position: absolute;
	left: -40%;
	top: 10%;
	right: auto;
	bottom: auto;
	background-color: ${({ theme }) => theme.palette.others.r};
	box-shadow: 0 1px 12px 4px rgba(0, 0, 0, 0.1);
      }
      
      .af-view
	.af-class-pill.af-class-mobile-screenshot-pill.af-class-mobile-screenshot-pill-2 {
	left: auto;
	top: auto;
	right: -40%;
	bottom: 40%;
      }
      
      .af-view .af-class-pill.af-class-case-study-card-pill {
	position: absolute;
	top: 24px;
	right: 24px;
	padding-right: 16px;
	padding-left: 16px;
      }
      
      .af-view .af-class-pill-text {
	padding-left: 12px;
      }
      
      .af-view .af-class-pill-heading {
	margin-right: 8px;
	margin-bottom: 0px;
	padding-left: 16px;
      }
      
      .af-view .af-class-pill-image {
	max-height: 36px;
	margin-top: 4px;
	margin-bottom: 4px;
	margin-left: 8px;
      }
      
      .af-view .af-class-expandable {
	position: relative;
	margin-bottom: 12px;
	border-radius: 10px;
      }
      
      .af-view .af-class-expandable-title {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 24px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	cursor: pointer;
      }
      
      .af-view .af-class-expandable-heading {
	margin-bottom: 0px;
      }
      
      .af-view .af-class-expandable-content-wrapper {
	display: none;
	margin-right: 0px;
	margin-left: 0px;
	padding-right: 0px;
	padding-bottom: 12px;
	padding-left: 24px;
	grid-auto-columns: 1fr;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
      }
      
      .af-view .af-class-expandable-content {
	display: block;
      }
      
      .af-view .af-class-expandable-icon {
	display: block;
      }
      
      .af-view .af-class-horizontal-tabs {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
      }
      
      .af-view .af-class-vertical-tabs-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
      }
      
      .af-view .af-class-horizontal-tabs-content {
	padding-left: 48px;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
      }
      
      .af-view .af-class-tab-button {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 12px 20px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 10px;
	background-color: transparent;
	opacity: 0.5;
	-webkit-transition: background-color 200ms ease, opacity 200ms ease;
	transition: background-color 200ms ease, opacity 200ms ease;
      }
      
      .af-view .af-class-tab-button:hover {
	opacity: 1;
      }
      
      .af-view .af-class-tab-button:active {
	background-color: transparent;
      }
      
      .af-view .af-class-tab-button.w--current {
	background-color: ${({ theme }) => theme.palette.others.j};
	opacity: 1;
	color: ${({ theme }) => theme.palette.others.g};
      }
      
      .af-view .af-class-tab-icon {
	margin-right: 16px;
      }
      
      .af-view .af-class-horizontal-tabs-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: -2px;
	margin-bottom: 48px;
	border-bottom: 2px solid ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-horizontal-tabs-menu.af-class-text-center {
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
      }
      
      .af-view .af-class-tab-link {
	top: 2px;
	margin-right: 24px;
	padding: 12px 0px;
	border-bottom: 2px solid transparent;
	background-color: transparent;
	opacity: 0.5;
	-webkit-transition: border-color 200ms ease, color 200ms ease,
	  opacity 200ms ease;
	transition: border-color 200ms ease, color 200ms ease, opacity 200ms ease;
      }
      
      .af-view .af-class-tab-link:hover {
	opacity: 1;
      }
      
      .af-view .af-class-tab-link:active {
	background-color: transparent;
      }
      
      .af-view .af-class-tab-link.w--current {
	border-bottom-color: ${({ theme }) => theme.palette.others.h};
	background-color: transparent;
	opacity: 1;
	color: ${({ theme }) => theme.palette.others.h};
      }
      
      .af-view .af-class-dropdown-icon {
	position: relative;
	margin-right: -8px;
	margin-left: 4px;
      }
      
      .af-view .af-class-dropdown-list-large {
	min-width: 32px;
      }
      
      .af-view .af-class-icon {
	margin-bottom: 24px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
      }
      
      .af-view .af-class-icon-feature-horizontal {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 20px;
	padding-right: 140px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	text-align: left;
      }
      
      .af-view .af-class-icon-feature-content {
	margin-left: 30px;
      }
      
      .af-view .af-class-icon-circle {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 120px;
	height: 120px;
	margin-bottom: 24px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 50%;
      }
      
      .af-view .af-class-icon-circle.af-class-social-icon-circle {
	width: 96px;
	height: 96px;
	margin-bottom: 0px;
      }
      
      .af-view .af-class-icon-list-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 16px;
	margin-bottom: 16px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-icon-list-item.af-class-disabled {
	opacity: 0.25;
      }
      
      .af-view .af-class-icon-list-item.af-class-icon-list-horizontal-item {
	margin-top: 0px;
	margin-right: 24px;
	margin-bottom: 24px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
      }
      
      .af-view .af-class-icon-list-image {
	margin-right: 12px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
      }
      
      .af-view .af-class-icon-list {
	margin-top: 0px;
	margin-bottom: -16px;
	padding-left: 0px;
      }
      
      .af-view .af-class-icon-list.af-class-pricing-plan-list {
	min-height: 400px;
	margin-top: 36px;
	margin-bottom: 36px;
	padding-right: 24px;
	padding-left: 24px;
      }
      
      .af-view .af-class-icon-list.af-class-icon-list-horizontal {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: -24px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
      }
      
      .af-view .af-class-announcement-bar {
	padding-top: 24px;
	padding-bottom: 24px;
      }
      
      .af-view .af-class-announcement-bar.af-class-bg-primary-3 {
	padding-top: 16px;
	padding-bottom: 16px;
      }
      
      .af-view .af-class-text-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-text-row.af-class-text-small.af-class-muted {
	opacity: 0.5;
      }
      
      .af-view .af-class-announcement-cross {
	position: absolute;
	right: 36px;
	opacity: 0.5;
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
	cursor: pointer;
      }
      
      .af-view .af-class-announcement-cross:hover {
	opacity: 1;
      }
      
      .af-view .af-class-section-subtitle {
	max-width: 690px;
      }
      
      .af-view .af-class-section-subtitle.af-class-text-large {
	width: 100%;
	max-width: 10000px;
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-avatar-name-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-avatar-name-container.af-class-article-author {
	margin-top: 24px;
      }
      
      .af-view .af-class-avatar-name {
	margin-left: 12px;
      }
      
      .af-view .af-class-avatar-name.af-class-h6-small {
	margin-bottom: 0px;
      }
      
      .af-view .af-class-breadcrumb-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0px;
	padding-left: 0px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-breadcrumb-link {
	opacity: 0.5;
	-webkit-transition: color 200ms ease, opacity 200ms ease;
	transition: color 200ms ease, opacity 200ms ease;
	color: ${({ theme }) => theme.palette.others.f};
      }
      
      .af-view .af-class-breadcrumb-link:hover {
	opacity: 1;
	color: ${({ theme }) => theme.palette.others.h};
      }
      
      .af-view .af-class-breadcrumb-link.w--current {
	opacity: 1;
	color: ${({ theme }) => theme.palette.others.h};
      }
      
      .af-view .af-class-breadcrumb-link.af-class-text-small {
	display: inline-block;
      }
      
      .af-view .af-class-breadcrumb-list-item {
	margin-right: 6px;
      }
      
      .af-view .af-class-breadcrumb-icon {
	opacity: 0.25;
      }
      
      .af-view .af-class-page-navigation-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: scroll;
	min-height: 100vh;
	margin-bottom: 0px;
	padding: 24px 36px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-left: 1px solid ${({ theme }) => theme.palette.others.j};
	background-color: #fff;
	text-align: center;
      }
      
      .af-view .af-class-page-navigation-list-item {
	margin-bottom: 6px;
      }
      
      .af-view .af-class-divider {
	position: relative;
	z-index: 1;
	width: 100%;
	height: 4vw;
      }
      
      .af-view .af-class-divider.af-class-divider-top {
	bottom: 1px;
	margin-bottom: 96px;
      }
      
      .af-view .af-class-divider.af-class-divider-bottom {
	top: 1px;
	z-index: 1;
	margin-top: 30px;
	border-radius: 0px;
	background-color: transparent;
	color: ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-divider.af-class-divider-bottom-large {
	margin-top: 40px;
      }
      
      .af-view .af-class-divider.af-class-card-divider {
	position: absolute;
	left: 0%;
	top: auto;
	right: auto;
	bottom: 0%;
	height: 48px;
      }
      
      .af-view .af-class-divider.af-class-divider-bottom-small {
	margin-top: 72px;
      }
      
      .af-view .af-class-divider-example {
	margin-bottom: 48px;
	padding: 24px;
	border-radius: 10px;
	background-color: ${({ theme }) => theme.palette.others.n};
      }
      
      .af-view .af-class-text-center {
	text-align: center;
      }
      
      .af-view .af-class-image-tile-gallery {
	display: -ms-grid;
	display: grid;
	-webkit-box-align: start;
	-webkit-align-items: start;
	-ms-flex-align: start;
	align-items: start;
	grid-auto-columns: 1fr;
	grid-column-gap: 24px;
	grid-row-gap: 16px;
	-ms-grid-columns: 0.66fr 0.33fr;
	grid-template-columns: 0.66fr 0.33fr;
	-ms-grid-rows: auto;
	grid-template-rows: auto;
      }
      
      .af-view .af-class-image-gallery-image {
	border-radius: 10px;
	-webkit-transition: -webkit-transform 200ms ease;
	transition: -webkit-transform 200ms ease;
	transition: transform 200ms ease;
	transition: transform 200ms ease, -webkit-transform 200ms ease;
      }
      
      .af-view .af-class-image-gallery-image:hover {
	-webkit-transform: scale(1.04);
	-ms-transform: scale(1.04);
	transform: scale(1.04);
      }
      
      .af-view .af-class-image-gallery-image.af-class-image-gallery-right {
	max-height: 565px;
      }
      
      .af-view .af-class-image-gallery-left {
	display: -ms-grid;
	display: grid;
	margin-top: 0px;
	margin-bottom: 0px;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	grid-auto-columns: 1fr;
	grid-column-gap: 0px;
	grid-row-gap: 24px;
	-ms-grid-columns: 1fr;
	grid-template-columns: 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
      }
      
      .af-view .af-class-image-gallery-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: -12px;
	margin-left: -12px;
      }
      
      .af-view .af-class-image-gallery-link {
	margin-right: 12px;
	margin-left: 12px;
      }
      
      .af-view .af-class-image-gallery-link.af-class-image-gallery-link-top {
	margin-bottom: 24px;
      }
      
      .af-view .af-class-section-title {
	margin-bottom: 72px;
      }
      
      .af-view .af-class-section-title.af-class-add-top-space {
	margin-top: 48px;
      }
      
      .af-view .af-class-team-members-grid {
	display: -ms-grid;
	display: grid;
	grid-auto-columns: 1fr;
	grid-column-gap: 24px;
	grid-row-gap: 48px;
	-ms-grid-columns: 1fr 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
      }
      
      .af-view .af-class-map-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
      }
      
      .af-view .af-class-map-section-left {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
      }
      
      .af-view .af-class-vertical-divider {
	position: absolute;
	top: 0px;
	right: 0px;
	z-index: 2;
	width: 4vw;
	height: 100%;
      }
      
      .af-view .af-class-map-section-right {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 96px 36px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
      }
      
      .af-view .af-class-map {
	height: 100%;
	min-height: 360px;
      }
      
      .af-view .af-class-metrics-horizontal {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0px;
	margin-bottom: 40px;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 35px;
      }
      
      .af-view .af-class-metrics-horizontal-item {
	margin-right: 24px;
	margin-left: 24px;
      }
      
      .af-view .af-class-metric-heading {
	margin-bottom: 8px;
      }
      
      .af-view .af-class-paragraph-large {
	margin-bottom: 24px;
      }
      
      .af-view .af-class-paragraph-large.af-class-text-large {
	margin-right: 140px;
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.grey["800"]};
	font-size: 15px;
	line-height: 25px;
	text-align: left;
      }
      
      .af-view .af-class-map-section-content {
	max-width: 504px;
      }
      
      .af-view .af-class-brand-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: -30px;
	margin-bottom: 0px;
	margin-left: -30px;
	padding-left: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
      }
      
      .af-view .af-class-brand-list-item {
	margin: 6px 30px 12px;
      }
      
      .af-view .af-class-brand-image {
	opacity: 0.7;
      }
      
      .af-view .af-class-brand-image.af-class-blockquote-brand-image {
	max-height: 24px;
	margin-top: 12px;
	margin-bottom: 12px;
      }
      
      .af-view .af-class-brand-image.af-class-case-study-logo {
	margin-bottom: 36px;
      }
      
      .af-view .af-class-medium-heading {
	margin-bottom: 24px;
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 35px;
	line-height: 40px;
	font-weight: 700;
      }
      
      .af-view .af-class-medium-heading.af-class-text-white {
	color: #fff;
      }
      
      .af-view .af-class-medium-heading.af-class-career-description-heading {
	margin-bottom: 8px;
      }
      
      .af-view .af-class-medium-heading.af-class-no-bottom-space {
	margin-bottom: 0px;
      }
      
      .af-view .af-class-small-heading {
	margin-bottom: 32px;
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 32px;
	line-height: 40px;
	font-weight: 700;
	letter-spacing: -0.04em;
      }
      
      .af-view .af-class-small-heading.af-class-metric-heading {
	margin-bottom: 8px;
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.primary.main};
	font-size: 50px;
      }
      
      .af-view .af-class-title-section {
	position: relative;
	padding-top: 120px;
	padding-bottom: 120px;
      }
      
      .af-view .af-class-title-section.af-class-no-bottom-space {
	padding-bottom: 0px;
      }
      
      .af-view .af-class-title-section.af-class-no-bottom-space.af-class-bg-primary {
	padding-top: 99px;
	background-color: transparent;
	background-image: linear-gradient(319deg, ${({ theme }) =>
    theme.palette.secondary.main}, ${({ theme }) =>
  theme.palette.primary.main} 100%, #fff);
	background-clip: border-box;
      }
      
      .af-view .af-class-content-pair-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-left: 0px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-content: flex-start;
	-ms-flex-line-pack: start;
	align-content: flex-start;
      }
      
      .af-view .af-class-content-pair-2.af-class-content-pair-reverse {
	display: -ms-grid;
	display: grid;
	margin-top: 60px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-webkit-flex-direction: row-reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	-webkit-box-pack: end;
	-webkit-justify-content: end;
	-ms-flex-pack: end;
	justify-content: end;
	justify-items: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-content: end;
	-ms-flex-line-pack: end;
	align-content: end;
	grid-auto-columns: 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
      }
      
      .af-view .af-class-content-pair-text {
	display: block;
	width: 100%;
	max-width: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	text-align: justify;
      }
      
      .af-view .af-class-content-pair-image {
	max-width: 26%;
	border-radius: 10px;
      }
      
      .af-view .af-class-large-metrics {
	display: -ms-grid;
	display: grid;
	grid-auto-columns: 1fr;
	grid-column-gap: 24px;
	grid-row-gap: 24px;
	-ms-grid-columns: 1fr 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr;
	-ms-grid-rows: auto;
	grid-template-rows: auto;
      }
      
      .af-view .af-class-large-metrics.af-class-text-center {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	grid-auto-columns: 1fr;
	-ms-grid-columns: 1fr 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr;
	-ms-grid-rows: auto;
	grid-template-rows: auto;
      }
      
      .af-view .af-class-text-huge {
	margin-bottom: 24px;
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 72px;
	line-height: 72px;
	font-weight: 700;
	letter-spacing: -0.04em;
      }
      
      .af-view .af-class-text-huge.af-class-text-white {
	color: ${({ theme }) => theme.palette.others.g};
      }
      
      .af-view .af-class-large-metric-item {
	display: block;
	padding-right: 24px;
	padding-left: 24px;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	grid-auto-columns: 1fr;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
      }
      
      .af-view .af-class-large-metric-item.af-class-text-left {
	text-align: left;
      }
      
      .af-view .af-class-large-metric-item.af-class-large-metric-horizontal {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	text-align: left;
      }
      
      .af-view .af-class-press-grid {
	display: -ms-grid;
	display: grid;
	width: 100%;
	grid-auto-columns: 1fr;
	grid-column-gap: 24px;
	grid-row-gap: 24px;
	-ms-grid-columns: 1fr 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr;
	-ms-grid-rows: auto;
	grid-template-rows: auto;
      }
      
      .af-view .af-class-logo-card {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	min-height: 240px;
	margin-bottom: 24px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 10px;
	background-color: ${({ theme }) => theme.palette.others.r};
      }
      
      .af-view .af-class-avatar-team-wrapper {
	margin-bottom: 48px;
      }
      
      .af-view .af-class-bg-image {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	z-index: 0;
      }
      
      .af-view .af-class-bg-image.af-class-career-hero-bg {
	background-image: url("../images/careers-1.jpg");
	background-position: 50% 50%;
	background-size: cover;
	opacity: 0.5;
      }
      
      .af-view .af-class-bg-image.af-class-contact-hero-bg {
	background-image: url("../images/contact-1.jpg");
	background-position: 50% 100%;
	background-size: cover;
	opacity: 0.5;
      }
      
      .af-view .af-class-bg-image.af-class-account-page-image {
	background-image: url("../images/account.jpg");
	background-position: 50% 50%;
	background-size: cover;
      }
      
      .af-view .af-class-bg-image.af-class-sign-up-page-image {
	background-image: url("../images/account-2.jpg");
	background-position: 0% 50%;
	background-size: cover;
      }
      
      .af-view .af-class-bg-image.af-class-landing-3-hero {
	background-image: url("../images/landing-3.jpg");
	background-position: 50% 50%;
	background-size: cover;
	opacity: 0.25;
      }
      
      .af-view .af-class-bg-image.af-class-hero-blockquote-bg {
	background-image: url("../images/careers-1.jpg");
	background-position: 50% 50%;
	background-size: cover;
	opacity: 0.35;
	-webkit-transform: rotateX(0deg) rotateY(-180deg) rotateZ(0deg);
	transform: rotateX(0deg) rotateY(-180deg) rotateZ(0deg);
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
      }
      
      .af-view .af-class-hero-section-action {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 36px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-content-pair-collage {
	position: relative;
      }
      
      .af-view .af-class-collage-image {
	border: 4px solid #fff;
	border-radius: 10px;
      }
      
      .af-view .af-class-collage-image.af-class-collage-image-2 {
	position: absolute;
	left: auto;
	top: -20%;
	right: -20%;
	bottom: auto;
	max-width: 240px;
      }
      
      .af-view .af-class-collage-image.af-class-collage-image-3 {
	position: absolute;
	left: -12.5%;
	top: auto;
	right: auto;
	bottom: -25%;
      }
      
      .af-view .af-class-card-slider {
	width: 100%;
	height: auto;
	max-width: 1032px;
	margin-top: 50px;
	margin-right: auto;
	margin-left: auto;
	background-color: transparent;
      }
      
      .af-view .af-class-card-horizontal-image {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
      }
      
      .af-view .af-class-card-horizontal-image.af-class-featured-post-image {
	width: 100%;
      }
      
      .af-view .af-class-card-slide {
	height: auto;
	padding-right: 24px;
	padding-left: 24px;
      }
      
      .af-view .af-class-card-slide-mask {
	overflow: visible;
	height: auto;
      }
      
      .af-view .af-class-card-horizontal-image-wrapper {
	position: relative;
      }
      
      .af-view
	.af-class-card-horizontal-image-wrapper.af-class-featured-post-image-wrapper {
	width: 50%;
      }
      
      .af-view
	.af-class-card-horizontal-image-wrapper.af-class-featured-case-study-image-wrapper {
	width: 45%;
      }
      
      .af-view .af-class-card-slide-nav {
	bottom: -36px;
	display: none;
	height: 24px;
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 8px;
	line-height: 0px;
      }
      
      .af-view .af-class-overflow-hidden {
	overflow: hidden;
	width: 100%;
      }
      
      .af-view .af-class-icon-pill-group {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
      }
      
      .af-view .af-class-icon-pill-group-item {
	margin: 6px;
      }
      
      .af-view .af-class-content-pair-group {
	max-width: 576px;
      }
      
      .af-view .af-class-career-list-titles {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-right: 24px;
	padding-left: 24px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-careers-list-item {
	margin-bottom: 12px;
      }
      
      .af-view .af-class-career-list-wrapper {
	width: 100%;
	margin-bottom: 36px;
      }
      
      .af-view .af-class-career-basis {
	width: 20%;
      }
      
      .af-view .af-class-career-location {
	width: 20%;
      }
      
      .af-view .af-class-career-heading {
	width: 60%;
      }
      
      .af-view .af-class-career-link {
	display: block;
	width: 100%;
	color: ${({ theme }) => theme.palette.others.f};
	font-weight: 400;
      }
      
      .af-view .af-class-letterhead {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 48px;
	padding-bottom: 48px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-bottom: 1px solid ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-career-description-meta {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
      }
      
      .af-view .af-class-comma-spacer {
	margin-right: 4px;
      }
      
      .af-view .af-class-article {
	max-width: 672px;
	margin-right: auto;
	margin-left: auto;
      }
      
      .af-view .af-class-article ul {
	margin-top: 24px;
	margin-bottom: 24px;
	padding-left: 24px;
      }
      
      .af-view .af-class-article li {
	padding-top: 6px;
	padding-bottom: 6px;
      }
      
      .af-view .af-class-article blockquote {
	margin-top: 48px;
	margin-bottom: 48px;
	padding-left: 48px;
	border-left: 2px solid ${({ theme }) => theme.palette.others.j};
	color: ${({ theme }) => theme.palette.others.h};
      }
      
      .af-view .af-class-article img {
	border-radius: 10px;
      }
      
      .af-view .af-class-article figure {
	margin-top: 48px;
	margin-bottom: 48px;
      }
      
      .af-view .af-class-article figcaption {
	margin-top: 8px;
	font-size: 14px;
      }
      
      .af-view .af-class-article.af-class-help-center-article {
	margin-right: 0px;
	margin-left: 0px;
      }
      
      .af-view .af-class-form-row {
	display: -ms-grid;
	display: grid;
	grid-auto-columns: 1fr;
	grid-column-gap: 24px;
	grid-row-gap: 16px;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto;
	grid-template-rows: auto;
      }
      
      .af-view .af-class-form-group {
	margin-bottom: 24px;
      }
      
      .af-view .af-class-form-group.af-class-form-group-last {
	margin-top: 12px;
	margin-bottom: 0px;
      }
      
      .af-view .af-class-career-application-heading {
	margin-bottom: 24px;
      }
      
      .af-view .af-class-form-block {
	width: 100%;
	margin-bottom: 0px;
      }
      
      .af-view .af-class-video-lightbox {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-video-lightbox-image {
	border-radius: 10px;
      }
      
      .af-view .af-class-video-play-button {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 96px;
	height: 96px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 50%;
	-webkit-transition: -webkit-transform 200ms ease;
	transition: -webkit-transform 200ms ease;
	transition: transform 200ms ease;
	transition: transform 200ms ease, -webkit-transform 200ms ease;
      }
      
      .af-view .af-class-video-play-button:hover {
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
      }
      
      .af-view .af-class-video-play-button.af-class-video-play-button-center {
	position: absolute;
      }
      
      .af-view .af-class-icon-cards-grid {
	display: -ms-grid;
	display: grid;
	width: 100%;
	max-width: 1000px;
	margin-right: auto;
	margin-left: auto;
	grid-auto-columns: 1fr;
	grid-column-gap: 24px;
	grid-row-gap: 24px;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
      }
      
      .af-view .af-class-icon-cards-grid.af-class-icon-cards-grid-wide {
	max-width: 100%;
	grid-row-gap: 48px;
      }
      
      .af-view .af-class-sticky-top {
	position: -webkit-sticky;
	position: sticky;
	top: 0px;
	z-index: 99;
      }
      
      .af-view .af-class-pill-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
      }
      
      .af-view .af-class-contact-methods-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
      }
      
      .af-view .af-class-horizontal-rule {
	width: 100%;
	height: 1px;
	margin-top: 96px;
	background-color: ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-form-success {
	padding-top: 12px;
	padding-bottom: 12px;
	border-radius: 100px;
	background-color: ${({ theme }) => theme.palette.others.o};
	color: #fff;
      }
      
      .af-view .af-class-form-error {
	margin-top: 12px;
	padding-top: 12px;
	padding-bottom: 12px;
	border-radius: 100px;
	background-color: ${({ theme }) => theme.palette.others.q};
	color: #fff;
	text-align: center;
      }
      
      .af-view .af-class-contact-method-item.af-class-contact-method-group-item {
	margin-right: 48px;
	margin-bottom: 36px;
      }
      
      .af-view .af-class-contact-method-item.af-class-sidebar-contact-method-item {
	margin-bottom: 24px;
      }
      
      .af-view .af-class-address {
	font-style: normal;
      }
      
      .af-view .af-class-contact-method-group {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 48px;
	margin-bottom: -36px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
      }
      
      .af-view .af-class-form-group-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-form-checkbox-agree {
	margin-top: 12px;
      }
      
      .af-view .af-class-pricing-plans-grid {
	display: -ms-grid;
	display: grid;
	margin-bottom: 48px;
	-webkit-box-align: start;
	-webkit-align-items: start;
	-ms-flex-align: start;
	align-items: start;
	grid-auto-columns: 0.25fr;
	grid-column-gap: 24px;
	grid-row-gap: 16px;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto;
	grid-template-rows: auto;
      }
      
      .af-view .af-class-pricing-plans-grid.af-class-pricing-plans-grid-duo {
	max-width: 792px;
	margin-right: auto;
	margin-bottom: 0px;
	margin-left: auto;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
      }
      
      .af-view .af-class-price-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 8px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	text-align: center;
      }
      
      .af-view .af-class-price-number.af-class-text-huge {
	margin-bottom: 0px;
	margin-left: 4px;
      }
      
      .af-view .af-class-price-number.af-class-large-heading {
	margin-bottom: 0px;
	margin-left: 4px;
      }
      
      .af-view .af-class-pricing-plan-heading {
	margin-top: 20px;
	margin-bottom: 24px;
	padding: 7px 20px;
	border-style: none;
	border-width: 2px;
	border-color: ${({ theme }) => theme.palette.others.l};
	border-radius: 20px;
	background-color: ${({ theme }) => theme.palette.secondary.light};
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.others.l};
	font-size: 18px;
	font-weight: 700;
      }
      
      .af-view .af-class-pricing-plan-heading.af-class-pricing-comparison-heading {
	margin-bottom: 12px;
      }
      
      .af-view .af-class-pricing-plan-header {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
      }
      
      .af-view .af-class-three-up-grid {
	display: -ms-grid;
	display: grid;
	width: 100%;
	margin-bottom: -1px;
	padding: 40px 100px;
	grid-auto-columns: 1fr;
	grid-column-gap: 24px;
	grid-row-gap: 48px;
	-ms-grid-columns: 1fr 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr;
	-ms-grid-rows: auto;
	grid-template-rows: auto;
	border-radius: 15px;
	background-color: ${({ theme }) => theme.palette.success.light};
	box-shadow: 0 0 4px 1px rgba(53, 62, 68, 0.09);
      }
      
      .af-view .af-class-three-up-grid-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 36px;
	padding-left: 36px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-faq-group {
	margin-bottom: 36px;
      }
      
      .af-view .af-class-customer-blockquote {
	margin-top: 24px;
      }
      
      .af-view .af-class-pricing-comparison-wrapper {
	margin-bottom: 48px;
      }
      
      .af-view .af-class-pricing-comparison-header {
	position: -webkit-sticky;
	position: sticky;
	top: 24px;
	z-index: 3;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
      }
      
      .af-view .af-class-pricing-comparison-plan {
	width: 25%;
	padding-right: 0px;
	padding-left: 0px;
      }
      
      .af-view .af-class-large-heading {
	margin-bottom: 32px;
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 54px;
	line-height: 56px;
	font-weight: 700;
	letter-spacing: -0.04em;
      }
      
      .af-view .af-class-large-heading.af-class-text-white {
	color: ${({ theme }) => theme.palette.others.g};
      }
      
      .af-view .af-class-large-heading.af-class-text-white-copy-22 {
	color: ${({ theme }) => theme.palette.others.g};
      }
      
      .af-view .af-class-pricing-comparison-section-title {
	width: 100%;
	margin-top: 72px;
	margin-bottom: 24px;
      }
      
      .af-view .af-class-pricing-comparison-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	border-top: 1px solid ${({ theme }) => theme.palette.others.j};
	border-right: 1px solid ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-pricing-comparison-row.af-class-pricing-comparison-row-last {
	border-bottom: 1px solid ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-pricing-comparison-cell {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 24px;
	padding-bottom: 24px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	border-left: 1px solid ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view
	.af-class-pricing-comparison-cell.af-class-pricing-comparison-heading-cell {
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	border-left-style: none;
      }
      
      .af-view .af-class-pricing-comparison-cell-heading {
	margin-bottom: 0px;
      }
      
      .af-view .af-class-pricing-comparison-button {
	width: 100%;
	margin-top: 24px;
      }
      
      .af-view .af-class-check-circle {
	display: block;
      }
      
      .af-view .af-class-utility-page-top-bar {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-top: 48px;
	padding-bottom: 48px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-account-form {
	margin-top: 36px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	text-align: left;
      }
      
      .af-view .af-class-split-layout {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100vh;
      }
      
      .af-view .af-class-split-layout-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
      }
      
      .af-view .af-class-split-layout-image-wrapper {
	position: relative;
	width: 100%;
	max-width: 40%;
	min-height: 320px;
	background-color: ${({ theme }) => theme.palette.others.n};
      }
      
      .af-view .af-class-split-layout-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-right: 12px;
	padding-left: 12px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	text-align: center;
      }
      
      .af-view .af-class-subscribe-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
      }
      
      .af-view .af-class-subscribe-form-wrapper {
	width: 100%;
	max-width: 576px;
	margin-top: 48px;
      }
      
      .af-view .af-class-text-muted {
	opacity: 0.5;
      }
      
      .af-view .af-class-bg-twitter {
	background-color: ${({ theme }) => theme.palette.others.r};
	color: #fff;
      }
      
      .af-view .af-class-bg-instagram {
	background-color: ${({ theme }) => theme.palette.others.t};
	color: #fff;
      }
      
      .af-view .af-class-bg-facebook {
	background-color: ${({ theme }) => theme.palette.others.u};
	color: #fff;
      }
      
      .af-view .af-class-bg-youtube {
	background-color: red;
	color: #fff;
      }
      
      .af-view .af-class-utility-page-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	min-height: 100vh;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
      }
      
      .af-view .af-class-utility-page-wrap.af-class-bg-primary-3 {
	background-color: transparent;
      }
      
      .af-view .af-class-team-members-grid-wrapper {
	width: 100%;
	max-width: 894px;
	margin-bottom: 48px;
      }
      
      .af-view .af-class-team-member-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-team-member-heading {
	margin-bottom: 6px;
      }
      
      .af-view .af-class-team-member-title {
	margin-bottom: 16px;
      }
      
      .af-view .af-class-utility-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 260px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	text-align: center;
      }
      
      .af-view .af-class-card-footer {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 12px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px solid ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-blog-grid {
	display: -ms-grid;
	display: grid;
	grid-auto-columns: 1fr;
	grid-column-gap: 24px;
	grid-row-gap: 24px;
	-ms-grid-columns: 1fr 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr;
	-ms-grid-rows: auto;
	grid-template-rows: auto;
      }
      
      .af-view .af-class-post-title-wrapper {
	position: relative;
	z-index: 2;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 24px;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
      }
      
      .af-view .af-class-post-title-image {
	max-width: 50%;
	border-radius: 10px;
      }
      
      .af-view .af-class-post-meta {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 24px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-post-title-date {
	margin-right: 12px;
      }
      
      .af-view .af-class-post-title-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: 96px;
	padding-top: 24px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
      }
      
      .af-view .af-class-pull-divider-up {
	position: relative;
	z-index: 2;
	margin-bottom: -4vw;
      }
      
      .af-view .af-class-post-share-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 72px;
	margin-left: -6px;
	padding-top: 48px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-top: 1px solid ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-share-button {
	position: relative;
	top: 2px;
	margin-right: 6px;
	margin-left: 6px;
      }
      
      .af-view .af-class-pagination {
	margin-top: 48px;
      }
      
      .af-view .af-class-button-icon {
	margin-right: -12px;
	margin-left: 6px;
      }
      
      .af-view .af-class-button-icon-first {
	margin-right: 6px;
	margin-left: -12px;
      }
      
      .af-view .af-class-wide-post-wrapper {
	margin-bottom: 48px;
      }
      
      .af-view .af-class-featured-post-meta {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 48px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-horizontal-post-date {
	margin-bottom: 8px;
	opacity: 0.5;
      }
      
      .af-view .af-class-horizontal-blog-list {
	display: -ms-grid;
	display: grid;
	grid-auto-columns: 1fr;
	grid-column-gap: 24px;
	grid-row-gap: 48px;
	-ms-grid-columns: 1fr;
	grid-template-columns: 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
      }
      
      .af-view .af-class-post-horizontal-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: -72px;
      }
      
      .af-view .af-class-license-image {
	max-width: 240px;
	border-radius: 5px;
      }
      
      .af-view .af-class-content-pair-graphic {
	display: block;
	width: 35%;
	max-width: 1000px;
	margin-top: 70px;
	margin-right: 41px;
	padding-bottom: 0px;
	padding-left: 0px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	background-color: transparent;
	-webkit-transform: scale(1.6);
	-ms-transform: scale(1.6);
	transform: scale(1.6);
      }
      
      .af-view .af-class-content-pair-graphic.af-class-content-pair-graphic-narrow {
	width: 30%;
      }
      
      .af-view .af-class-content-pair-card {
	max-width: 600px;
      }
      
      .af-view .af-class-card-image-wrapper {
	position: relative;
      }
      
      .af-view .af-class-form-title {
	margin-bottom: 24px;
      }
      
      .af-view .af-class-icon-features-heading {
	margin-bottom: 48px;
	padding-right: 100px;
	font-family: Lato, sans-serif;
	font-size: 35px;
	line-height: 40px;
	text-align: left;
      }
      
      .af-view .af-class-icon-feature-list {
	margin-bottom: 0px;
	padding-left: 0px;
      }
      
      .af-view .af-class-icon-feature-list-item {
	margin-bottom: 24px;
      }
      
      .af-view .af-class-card-image-link-wrapper {
	display: block;
      }
      
      .af-view .af-class-faq-grid {
	display: -ms-grid;
	display: grid;
	max-width: 997px;
	margin: 96px auto 48px;
	grid-auto-columns: 1fr;
	grid-column-gap: 24px;
	grid-row-gap: 48px;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
      }
      
      .af-view .af-class-cta-bg-graphic {
	position: absolute;
	left: auto;
	top: -82px;
	right: 4vw;
	bottom: 0%;
	z-index: 1;
	max-width: 25%;
	margin-top: 0px;
      }
      
      .af-view .af-class-pill-below {
	margin-bottom: 48px;
      }
      
      .af-view .af-class-collection-heading-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-bg-gradient-1 {
	background-image: -webkit-gradient(
	  linear,
	  right top,
	  left top,
	  from(${({ theme }) => theme.palette.others.m}),
	  to(${({ theme }) => theme.palette.others.h})
	);
	background-image: linear-gradient(270deg, ${({ theme }) =>
    theme.palette.others.m}, ${({ theme }) => theme.palette.others.h});
	color: #fff;
      }
      
      .af-view .af-class-bg-gradient-1.af-class-style-guide-color {
	border: 1px none #000;
      }
      
      .af-view .af-class-hero-blockquote-wrapper {
	max-width: 480px;
      }
      
      .af-view .af-class-bg-gradient-2 {
	background-image: -webkit-gradient(
	  linear,
	  left top,
	  right top,
	  from(${({ theme }) => theme.palette.others.m}),
	  to(${({ theme }) => theme.palette.others.n})
	);
	background-image: linear-gradient(90deg, ${({ theme }) =>
    theme.palette.others.m}, ${({ theme }) => theme.palette.others.n});
	color: #fff;
      }
      
      .af-view .af-class-icon-feature-image {
	max-width: 25%;
      }
      
      .af-view .af-class-mobile-screenshot-wrapper {
	position: relative;
	max-width: 20vw;
      }
      
      .af-view .af-class-screenshot-arrow {
	position: relative;
	left: -48px;
	margin-bottom: 24px;
      }
      
      .af-view .af-class-mobile-screenshot-text {
	max-width: 360px;
	margin-top: -48px;
      }
      
      .af-view .af-class-text-block {
	color: ${({ theme }) => theme.palette.others.g};
      }
      
      .af-view .af-class-content-pair-gallery {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 576px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-content-pair-gallery-left {
	width: 75%;
	margin-left: 12px;
      }
      
      .af-view .af-class-video-lightbox-with-text {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 408px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-video-lightbox-text-wrapper {
	margin-left: 24px;
      }
      
      .af-view .af-class-video-lightbox-heading {
	margin-bottom: 0px;
      }
      
      .af-view .af-class-add-space-right {
	margin-right: 12px;
      }
      
      .af-view .af-class-screenshot {
	border-radius: 10px;
      }
      
      .af-view .af-class-screenshot.af-class-content-pair-screenshot {
	position: relative;
	width: 100%;
	max-width: 768px;
	margin-left: 72px;
	border-style: solid;
	border-width: 1px;
	border-color: ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-badge-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 12px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
      }
      
      .af-view .af-class-customer-quotes-wrapper {
	display: -ms-grid;
	display: grid;
	max-width: 576px;
	margin-right: auto;
	margin-left: auto;
	grid-auto-columns: 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 48px;
	-ms-grid-columns: 1fr;
	grid-template-columns: 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
      }
      
      .af-view .af-class-qr-code-image {
	margin-top: 48px;
	margin-bottom: 48px;
      }
      
      .af-view .af-class-back-to-top {
	position: fixed;
	right: 24px;
	bottom: 24px;
	z-index: 98;
	display: none;
      }
      
      .af-view .af-class-case-study-card-logo {
	max-height: 24px;
      }
      
      .af-view .af-class-large-metric-horizontal-text {
	margin-left: 24px;
      }
      
      .af-view .af-class-case-study-meta-list {
	margin-bottom: 24px;
	padding-left: 0px;
      }
      
      .af-view .af-class-case-study-meta-list-item {
	margin-bottom: 16px;
      }
      
      .af-view .af-class-page-demos-wrapper {
	display: -ms-grid;
	display: grid;
	padding: 72px;
	grid-auto-columns: 1fr;
	grid-column-gap: 5vw;
	grid-row-gap: 36px;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto;
	grid-template-rows: auto;
	border-radius: 10px;
	background-color: ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-nav-link-white {
	color: #fff;
      }
      
      .af-view .af-class-decoration-container {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	z-index: 2;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
      }
      
      .af-view .af-class-decoration-container.af-class-bottom-right {
	position: absolute;
	right: 0px;
	bottom: 0px;
	z-index: 2;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
      }
      
      .af-view .af-class-decoration-container.af-class-left-middle {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-decoration-container.af-class-right-middle {
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-decoration-container.af-class-right-top {
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
      }
      
      .af-view .af-class-decoration-container.af-class-left-bottom {
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
      }
      
      .af-view .af-class-decoration {
	position: relative;
	z-index: 1;
	width: 12vw;
      }
      
      .af-view .af-class-decoration.af-class-left {
	left: -12px;
      }
      
      .af-view .af-class-decoration.af-class-right {
	right: -12px;
      }
      
      .af-view .af-class-decoration.af-class-top-right {
	position: absolute;
	top: -24px;
	right: -48px;
	width: 60px;
      }
      
      .af-view .af-class-decoration-circle {
	position: absolute;
	width: 4vw;
	height: 4vw;
	border-radius: 50%;
      }
      
      .af-view .af-class-decoration-circle.af-class-middle {
	left: 50%;
	top: 50%;
      }
      
      .af-view .af-class-decoration-circle.af-class-left {
	left: -1vw;
      }
      
      .af-view .af-class-decoration-circle.af-class-left.af-class-bg-primary {
	z-index: 2;
      }
      
      .af-view .af-class-decoration-circle.af-class-top-right {
	top: 0px;
	right: 0px;
      }
      
      .af-view .af-class-decoration-circle.af-class-right {
	right: -12px;
      }
      
      .af-view .af-class-decoration-circle.af-class-left-top {
	left: -12px;
	top: 0px;
      }
      
      .af-view .af-class-decorations-wrapper {
	position: relative;
      }
      
      .af-view .af-class-decoration-diamond {
	width: 6vw;
	height: 6vw;
	border-radius: 15px;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
      }
      
      .af-view .af-class-decoration-diamond.af-class-bottom-right {
	position: absolute;
	right: -2vw;
	bottom: -2vw;
      }
      
      .af-view .af-class-decoration-diamond.af-class-bottom-left {
	position: absolute;
	left: -2vw;
	bottom: -2vw;
      }
      
      .af-view .af-class-page-navigator {
	position: fixed;
	top: 0px;
	right: 0px;
	z-index: 100;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: scroll;
	height: 100vh;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-page-demo-image {
	display: block;
	margin-bottom: 16px;
	border-radius: 5px;
	box-shadow: 0 4px 12px 0 transparent;
	-webkit-transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
	transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
	transition: box-shadow 200ms ease, transform 200ms ease;
	transition: box-shadow 200ms ease, transform 200ms ease,
	  -webkit-transform 200ms ease;
      }
      
      .af-view .af-class-page-demo-image:hover {
	box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
	-webkit-transform: translate(0px, 0px) scale(1.05);
	-ms-transform: translate(0px, 0px) scale(1.05);
	transform: translate(0px, 0px) scale(1.05);
      }
      
      .af-view .af-class-avatar-image-link {
	-webkit-transition: -webkit-transform 200ms ease;
	transition: -webkit-transform 200ms ease;
	transition: transform 200ms ease;
	transition: transform 200ms ease, -webkit-transform 200ms ease;
      }
      
      .af-view .af-class-avatar-image-link:hover {
	-webkit-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05);
      }
      
      .af-view .af-class-pricing-tabs {
	margin-top: 24px;
      }
      
      .af-view .af-class-search-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 840px;
	margin-top: 48px;
	margin-bottom: 0px;
      }
      
      .af-view .af-class-sidebar-layout {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
      }
      
      .af-view .af-class-sidebar-aside-content {
	position: -webkit-sticky;
	position: sticky;
	top: 24px;
	z-index: 2;
	max-width: 336px;
	padding-bottom: 24px;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
      }
      
      .af-view .af-class-sidebar-main-content {
	width: 100%;
	max-width: 792px;
	padding-right: 24px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
      }
      
      .af-view .af-class-sidebar-contact-methods {
	margin-top: 24px;
      }
      
      .af-view .af-class-help-center-link-block {
	width: 100%;
	font-weight: 400;
      }
      
      .af-view .af-class-category-icon {
	height: 72px;
	margin-right: 48px;
      }
      
      .af-view .af-class-sidebar-main-content-header {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 48px;
	padding-bottom: 48px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-bottom: 1px solid ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-sidebar-main-content-heading {
	margin-top: 24px;
      }
      
      .af-view .af-class-related-article {
	margin-top: 24px;
      }
      
      .af-view .af-class-search-result-paragraph {
	margin-top: 12px;
	margin-bottom: 0px;
      }
      
      .af-view .af-class-utility-page-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
      }
      
      .af-view .af-class-text-block-2 {
	font-size: 16px;
	line-height: 30px;
	font-weight: 500;
      }
      
      .af-view .af-class-text-block-3 {
	font-size: 16px;
      }
      
      .af-view .af-class-text-block-4 {
	font-size: 16px;
      }
      
      .af-view .af-class-text-block-5 {
	font-size: 16px;
      }
      
      .af-view .af-class-text-block-6 {
	font-size: 16px;
      }
      
      .af-view .af-class-link {
	color: ${({ theme }) => theme.palette.primary.main};
      }
      
      .af-view .af-class-heading {
	text-transform: capitalize;
      }
      
      .af-view .af-class-text-block-7 {
	padding-bottom: 20px;
	font-family: "Fa solid 900", sans-serif;
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 40px;
	line-height: 50px;
      }
      
      .af-view .af-class-heading-2 {
	margin-right: 100px;
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 60px;
	font-weight: 500;
	text-align: left;
	letter-spacing: -0.04em;
      }
      
      .af-view .af-class-heading-3 {
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.others.g};
	font-weight: 700;
      }
      
      .af-view .af-class-heading-4 {
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.others.g};
	font-weight: 700;
      }
      
      .af-view .af-class-heading-5 {
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.others.g};
	font-weight: 700;
      }
      
      .af-view .af-class-text-block-8 {
	display: block;
	margin-bottom: 25px;
	color: ${({ theme }) => theme.palette.grey["800"]};
	font-size: 20px;
	font-weight: 400;
	text-align: center;
      }
      
      .af-view .af-class-list {
	display: block;
	font-size: 20px;
	line-height: 45px;
	list-style-type: square;
      }
      
      .af-view .af-class-heading-6 {
	width: 500px;
	margin-right: auto;
	margin-left: auto;
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 40px;
	line-height: 40px;
	text-align: center;
      }
      
      .af-view .af-class-list-item {
	font-size: 20px;
      }
      
      .af-view .af-class-list-item-2 {
	line-height: 34px;
      }
      
      .af-view .af-class-list-item-3 {
	line-height: 20px;
      }
      
      .af-view .af-class-youtube {
	width: 90%;
	height: auto;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-radius: 0px;
	color: ${({ theme }) => theme.palette.others.r};
	text-align: left;
      }
      
      .af-view .af-class-heading-7 {
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 40px;
	font-weight: 700;
      }
      
      .af-view .af-class-text-block-9 {
	color: ${({ theme }) => theme.palette.primary.main};
	font-size: 26px;
      }
      
      .af-view .af-class-text-block-10 {
	color: ${({ theme }) => theme.palette.primary.main};
	font-size: 26px;
      }
      
      .af-view .af-class-text-block-11 {
	color: ${({ theme }) => theme.palette.primary.main};
	font-size: 26px;
      }
      
      .af-view .af-class-heading-8 {
	font-family: Lato, sans-serif;
	font-size: 30px;
	line-height: 35px;
	text-align: left;
      }
      
      .af-view .af-class-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-content: stretch;
	-ms-flex-line-pack: stretch;
	align-content: stretch;
	grid-auto-columns: 1fr;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
      }
      
      .af-view .af-class-image {
	max-width: 80%;
	padding-bottom: 15px;
      }
      
      .af-view .af-class-text-block-12 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-image-2 {
	max-width: 80%;
	padding-bottom: 15px;
      }
      
      .af-view .af-class-columns {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 27px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-content: stretch;
	-ms-flex-line-pack: stretch;
	align-content: stretch;
      }
      
      .af-view .af-class-text-block-13 {
	padding-top: 0px;
	color: ${({ theme }) => theme.palette.grey["800"]};
	font-size: 20px;
	line-height: 26px;
	text-align: center;
      }
      
      .af-view .af-class-column-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-image-3 {
	max-width: 70%;
	padding-bottom: 15px;
      }
      
      .af-view .af-class-heading-9 {
	font-family: Lato, sans-serif;
	font-size: 24px;
	text-align: center;
      }
      
      .af-view .af-class-button-2 {
	padding: 15px 30px;
	border-radius: 20px;
	background-color: ${({ theme }) => theme.palette.primary.main};
	color: #fff;
      }
      
      .af-view .af-class-heading-10 {
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.others.l};
	font-weight: 700;
      }
      
      .af-view .af-class-button-3 {
	padding: 15px 30px;
	border-radius: 10px;
	background-color: ${({ theme }) => theme.palette.others.k};
	color: #fff;
      }
      
      .af-view .af-class-heading-11 {
	margin-top: 32px;
	font-family: Lato, sans-serif;
	font-size: 26px;
	line-height: 40px;
      }
      
      .af-view .af-class-heading-12 {
	margin-top: 12px;
	font-family: Lato, sans-serif;
	font-weight: 500;
      }
      
      .af-view .af-class-button-4 {
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	border: 1px none #000;
	border-radius: 20px;
	background-color: ${({ theme }) => theme.palette.primary.main};
      }
      
      .af-view .af-class-text-block-14 {
	font-family: "Fa solid 900", sans-serif;
	color: ${({ theme }) => theme.palette.others.k};
	font-size: 40px;
      }
      
      .af-view .af-class-icon-features-heading-copy {
	margin-bottom: 48px;
	padding-right: 100px;
	font-family: Lato, sans-serif;
	text-align: right;
      }
      
      .af-view .af-class-content-pair-2-copy {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-left: 0px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-webkit-flex-direction: row-reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-content: flex-start;
	-ms-flex-line-pack: start;
	align-content: flex-start;
      }
      
      .af-view .af-class-content-pair-2-copy.af-class-content-pair-reverse {
	display: -ms-grid;
	display: grid;
	margin-top: 60px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-webkit-flex-direction: row-reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	-webkit-box-pack: end;
	-webkit-justify-content: end;
	-ms-flex-pack: end;
	justify-content: end;
	justify-items: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-content: end;
	-ms-flex-line-pack: end;
	align-content: end;
	grid-auto-columns: 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
      }
      
      .af-view .af-class-content-pair-graphic-copy {
	display: block;
	width: 35%;
	max-width: 1000px;
	margin-top: 70px;
	margin-left: 85px;
	padding-bottom: 0px;
	padding-left: 0px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	background-color: transparent;
	-webkit-transform: scale(1.6);
	-ms-transform: scale(1.6);
	transform: scale(1.6);
      }
      
      .af-view
	.af-class-content-pair-graphic-copy.af-class-content-pair-graphic-narrow {
	width: 30%;
      }
      
      .af-view .af-class-content-pair-text-copy {
	width: 100%;
	max-width: 100%;
	margin-left: 146px;
	padding-right: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	text-align: right;
      }
      
      .af-view .af-class-button-4-copy {
	margin-right: 80px;
	border: 1px none #000;
	border-radius: 20px;
	background-color: ${({ theme }) => theme.palette.primary.main};
	text-align: right;
      }
      
      .af-view .af-class-icon-feature-horizontal-copy {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 20px;
	margin-right: -50px;
	padding-right: 0px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	text-align: right;
      }
      
      .af-view .af-class-icon-feature-horizontal-copy {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 20px;
	padding-right: 140px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	text-align: left;
      }
      
      .af-view .af-class-icon-feature-horizontal-copy {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 20px;
	padding-right: 140px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	text-align: left;
      }
      
      .af-view .af-class-icon-feature-content-copy {
	margin-right: 30px;
	margin-left: 0px;
	text-align: right;
      }
      
      .af-view .af-class-icon-feature-content-copy {
	margin-left: 30px;
      }
      
      .af-view .af-class-icon-feature-content-copy {
	margin-left: 30px;
      }
      
      .af-view .af-class-text-block-14-copy {
	margin-left: 0px;
	font-family: "Fa solid 900", sans-serif;
	color: ${({ theme }) => theme.palette.others.k};
	font-size: 40px;
      }
      
      .af-view .af-class-div-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 20px;
      }
      
      .af-view .af-class-div-block-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 20px;
      }
      
      .af-view .af-class-div-block-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 20px;
      }
      
      .af-view .af-class-button-5 {
	border-radius: 20px;
	background-color: ${({ theme }) => theme.palette.primary.main};
      }
      
      .af-view .af-class-heading-13 {
	margin-bottom: 48px;
	font-family: Lato, sans-serif;
	font-size: 35px;
	line-height: 40px;
      }
      
      .af-view .af-class-image-4 {
	-o-object-fit: cover;
	object-fit: cover;
      }
      
      .af-view .af-class-section-copy {
	position: relative;
	margin-bottom: 96px;
	padding: 96px 135px 53px;
      }
      
      .af-view .af-class-section-copy.af-class-style-guide-section {
	border-bottom: 1px solid ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-section-copy.af-class-title-section {
	padding-top: 120px;
	padding-bottom: 120px;
      }
      
      .af-view .af-class-section-copy.af-class-no-top-space {
	padding-top: 0px;
      }
      
      .af-view
	.af-class-section-copy.af-class-no-top-space.af-class-pricing-plans-section {
	margin-top: -96px;
      }
      
      .af-view .af-class-section-copy.af-class-no-bottom-space {
	padding-bottom: 0px;
      }
      
      .af-view .af-class-section-copy.af-class-no-bottom-space.af-class-bg-primary-3 {
	padding-right: 0px;
	padding-left: 0px;
	background-color: ${({ theme }) => theme.palette.success.light};
      }
      
      .af-view .af-class-section-copy.af-class-no-bottom-space.af-class-bg-gray-4 {
	border-radius: 0px;
	background-color: transparent;
      }
      
      .af-view .af-class-section-copy.af-class-overflow-hidden {
	overflow: hidden;
      }
      
      .af-view .af-class-section-copy.af-class-bg-gray-4 {
	padding: 80px 0px 0px;
      }
      
      .af-view .af-class-section-copy.af-class-section-small {
	margin-top: 30px;
	padding-top: 41px;
	padding-bottom: 29px;
	background-color: transparent;
	color: ${({ theme }) => theme.palette.others.g};
      }
      
      .af-view .af-class-section-copy.af-class-utility-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-right: 24px;
	padding-left: 24px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	color: ${({ theme }) => theme.palette.others.l};
      }
      
      .af-view .af-class-section-copy.af-class-no-space {
	padding-top: 0px;
	padding-bottom: 0px;
      }
      
      .af-view .af-class-section-copy.af-class-hero-screenshot-above {
	margin-top: 25vh;
	padding-top: 24px;
      }
      
      .af-view .af-class-section-copy-copy {
	position: relative;
	margin-bottom: 96px;
	padding: 96px 135px 53px;
      }
      
      .af-view .af-class-section-copy-copy.af-class-style-guide-section {
	border-bottom: 1px solid ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-section-copy-copy.af-class-title-section {
	padding-top: 120px;
	padding-bottom: 120px;
      }
      
      .af-view .af-class-section-copy-copy.af-class-no-top-space {
	padding-top: 0px;
      }
      
      .af-view
	.af-class-section-copy-copy.af-class-no-top-space.af-class-pricing-plans-section {
	margin-top: -96px;
      }
      
      .af-view .af-class-section-copy-copy.af-class-no-bottom-space {
	padding-bottom: 0px;
      }
      
      .af-view
	.af-class-section-copy-copy.af-class-no-bottom-space.af-class-bg-primary-3 {
	padding-right: 0px;
	padding-left: 0px;
	background-color: ${({ theme }) => theme.palette.success.light};
      }
      
      .af-view
	.af-class-section-copy-copy.af-class-no-bottom-space.af-class-bg-gray-4 {
	border-radius: 0px;
	background-color: transparent;
      }
      
      .af-view .af-class-section-copy-copy.af-class-overflow-hidden {
	overflow: hidden;
      }
      
      .af-view .af-class-section-copy-copy.af-class-bg-gray-4 {
	padding: 80px 0px 0px;
      }
      
      .af-view .af-class-section-copy-copy.af-class-section-small {
	margin-top: 30px;
	padding-top: 41px;
	padding-bottom: 29px;
	background-color: transparent;
	color: ${({ theme }) => theme.palette.others.g};
      }
      
      .af-view .af-class-section-copy-copy.af-class-utility-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-right: 24px;
	padding-left: 24px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	color: ${({ theme }) => theme.palette.others.l};
      }
      
      .af-view .af-class-section-copy-copy.af-class-no-space {
	padding-top: 0px;
	padding-bottom: 0px;
      }
      
      .af-view .af-class-section-copy-copy.af-class-hero-screenshot-above {
	margin-top: 25vh;
	padding-top: 24px;
      }
      
      .af-view .af-class-section-copy-copy.af-class-section-small-copy {
	margin-top: 30px;
	padding-top: 41px;
	padding-bottom: 29px;
	background-color: transparent;
	color: ${({ theme }) => theme.palette.others.g};
      }
      
      .af-view .af-class-section-copy-copy.af-class-section-small-copy-copy {
	margin-top: 0px;
	margin-bottom: 0px;
	padding-top: 40px;
	padding-bottom: 40px;
	background-color: ${({ theme }) => theme.palette.others.k};
	color: ${({ theme }) => theme.palette.others.g};
      }
      
      .af-view .af-class-heading-2-copy {
	margin-right: 100px;
	margin-bottom: 10px;
	padding-right: 200px;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 35px;
	line-height: 40px;
	font-weight: 700;
	text-align: left;
	letter-spacing: -0.04em;
      }
      
      .af-view .af-class-paragraph-large-copy {
	margin-bottom: 24px;
      }
      
      .af-view .af-class-paragraph-large-copy.af-class-text-large {
	margin-right: 140px;
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.grey["800"]};
      }
      
      .af-view .af-class-paragraph-large-copy.af-class-text-large-copy {
	margin-right: 140px;
	padding-right: 300px;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 20px;
      }
      
      .af-view .af-class-heading-6-2 {
	width: auto;
	max-width: 600px;
	margin-top: 40px;
	margin-right: auto;
	margin-left: auto;
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 35px;
	line-height: 40px;
	font-weight: 700;
	text-align: center;
      }
      
      .af-view .af-class-columns-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 1100px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 0px;
	padding-left: 0px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-content: space-around;
	-ms-flex-line-pack: distribute;
	align-content: space-around;
      }
      
      .af-view .af-class-image-5 {
	display: inline-block;
      }
      
      .af-view .af-class-column-4 {
	display: block;
	-ms-grid-row-align: auto;
	align-self: auto;
	-webkit-box-ordinal-group: 1;
	-webkit-order: 0;
	-ms-flex-order: 0;
	order: 0;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	grid-auto-columns: 1fr;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
	text-align: center;
      }
      
      .af-view .af-class-text-block-15 {
	font-size: 20px;
      }
      
      .af-view .af-class-text-block-16 {
	font-family: Lato, sans-serif;
	font-size: 20px;
	line-height: 32px;
	text-align: left;
      }
      
      .af-view .af-class-feature-section {
	padding-top: 20px;
	padding-bottom: 100px;
	text-align: left;
      }
      
      .af-view .af-class-flex-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 100px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
      }
      
      .af-view .af-class-feature-image-mask {
	width: 100%;
	margin-right: 40px;
      }
      
      .af-view .af-class-feature-image {
	width: 100%;
	height: 100%;
	padding-left: 0px;
	-o-object-fit: contain;
	object-fit: contain;
	-o-object-position: 50% 50%;
	object-position: 50% 50%;
      }
      
      .af-view .af-class-body {
	padding-top: 0px;
      }
      
      .af-view .af-class-heading-14 {
	font-family: Lato, sans-serif;
	font-size: 35px;
	line-height: 44px;
      }
      
      .af-view .af-class-flex-container-reverse {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 100px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-webkit-flex-direction: row-reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
      }
      
      .af-view .af-class-div-block-4 {
	max-width: 800px;
      }
      
      .af-view .af-class-div-block-5 {
	width: auto;
	max-width: 800px;
      }
      
      .af-view .af-class-feature-image-copy {
	width: 100%;
	height: 100%;
	padding-right: 0px;
	padding-left: 0px;
	-o-object-fit: contain;
	object-fit: contain;
	-o-object-position: 50% 50%;
	object-position: 50% 50%;
      }
      
      .af-view .af-class-feature-image-copy-copy {
	width: 70%;
	height: 100%;
	padding-right: 0px;
	padding-left: 0px;
	-o-object-fit: contain;
	object-fit: contain;
	-o-object-position: 50% 50%;
	object-position: 50% 50%;
      }
      
      .af-view .af-class-feature-image-copy-2 {
	width: 80%;
	height: 100%;
	padding-left: 0px;
	-o-object-fit: contain;
	object-fit: contain;
	-o-object-position: 50% 50%;
	object-position: 50% 50%;
      }
      
      .af-view .af-class-button-6 {
	margin-right: auto;
	margin-left: auto;
	text-align: center;
      }
      
      .af-view .af-class-container {
	text-align: center;
      }
      
      .af-view .af-class-button-7 {
	padding: 12px 25px;
	border: 1px none #000;
	border-radius: 20px;
	background-color: ${({ theme }) => theme.palette.primary.main};
	color: #fff;
      }
      
      .af-view .af-class-div-block-6 {
	position: fixed;
	z-index: 9999;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5);
	-o-object-fit: cover;
	object-fit: cover;
      }
      
      .af-view .af-class-modal-wrapper {
	position: fixed;
	z-index: 9999;
	display: none;
	width: 100%;
	height: 100%;
	margin-top: 0px;
	padding-top: 60px;
	border-radius: 0px;
	background-color: rgba(0, 0, 0, 0.5);
	opacity: 0;
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
      }
      
      .af-view .af-class-form-wrapper {
	display: block;
	overflow: visible;
	width: 650px;
	max-width: 100%;
	margin: auto;
	padding: 20px 20px 40px;
	background-color: #fff;
      }
      
      .af-view .af-class-contact-form {
	padding-top: 100px;
	padding-bottom: 100px;
      }
      
      .af-view .af-class-contact-form-grid {
	display: -ms-grid;
	display: grid;
	margin-top: 40px;
	grid-auto-columns: 1fr;
	grid-column-gap: 20px;
	grid-row-gap: 20px;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto;
	grid-template-rows: auto;
      }
      
      .af-view .af-class-contact-form-copy {
	padding-top: 0px;
	padding-bottom: 0px;
      }
      
      .af-view .af-class-heading-15 {
	font-family: Lato, sans-serif;
	font-size: 30px;
	line-height: 36px;
      }
      
      .af-view .af-class-textarea {
	height: auto;
      }
      
      .af-view .af-class-text-block-17 {
	font-family: "Fa solid 900", sans-serif;
	font-size: 16px;
	text-align: right;
      }
      
      .af-view .af-class-container-2 {
	padding-right: 20px;
	padding-left: 20px;
      }
      
      .af-view .af-class-close-wrapper {
	display: block;
	font-family: "Fa solid 900", sans-serif;
	color: ${({ theme }) => theme.palette.others.g};
	text-align: right;
      }
      
      .af-view .af-class-heading-6-copy {
	width: 500px;
	margin-right: auto;
	margin-left: auto;
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 40px;
	line-height: 40px;
	text-align: center;
      }
      
      .af-view .af-class-heading-16 {
	display: block;
	width: 1100px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-family: Lato, sans-serif;
	font-size: 35px;
	line-height: 40px;
	text-align: center;
      }
      
      .af-view .af-class-text-block-18 {
	display: block;
	width: 1100px;
	padding-bottom: 60px;
	font-family: Lato, sans-serif;
	text-align: left;
      }
      
      .af-view .af-class-section-copy {
	position: relative;
	margin-bottom: 96px;
	padding: 96px 135px 53px;
      }
      
      .af-view .af-class-section-copy.af-class-style-guide-section {
	border-bottom: 1px solid ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-section-copy.af-class-title-section {
	padding-top: 120px;
	padding-bottom: 120px;
      }
      
      .af-view .af-class-section-copy.af-class-no-top-space {
	padding-top: 0px;
      }
      
      .af-view
	.af-class-section-copy.af-class-no-top-space.af-class-pricing-plans-section {
	margin-top: 0px;
      }
      
      .af-view .af-class-section-copy.af-class-no-bottom-space {
	padding-bottom: 0px;
      }
      
      .af-view .af-class-section-copy.af-class-no-bottom-space.af-class-bg-primary-3 {
	padding-right: 0px;
	padding-left: 0px;
	background-color: ${({ theme }) => theme.palette.success.light};
      }
      
      .af-view .af-class-section-copy.af-class-no-bottom-space.af-class-bg-gray-4 {
	border-radius: 0px;
	background-color: transparent;
      }
      
      .af-view .af-class-section-copy.af-class-overflow-hidden {
	overflow: hidden;
      }
      
      .af-view .af-class-section-copy.af-class-bg-gray-4 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 80px 0px 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-section-copy.af-class-section-small {
	margin-top: 30px;
	padding-top: 41px;
	padding-bottom: 29px;
	background-color: transparent;
	color: ${({ theme }) => theme.palette.others.g};
      }
      
      .af-view .af-class-section-copy.af-class-utility-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-right: 24px;
	padding-left: 24px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	color: ${({ theme }) => theme.palette.others.l};
      }
      
      .af-view .af-class-section-copy.af-class-no-space {
	padding-top: 0px;
	padding-bottom: 0px;
      }
      
      .af-view .af-class-section-copy.af-class-hero-screenshot-above {
	margin-top: 25vh;
	padding-top: 24px;
      }
      
      .af-view .af-class-section-copy.af-class-no-top-space-copy {
	padding-top: 0px;
      }
      
      .af-view
	.af-class-section-copy.af-class-no-top-space-copy.af-class-pricing-plans-section {
	margin-top: 0px;
	color: ${({ theme }) => theme.palette.others.v};
      }
      
      .af-view .af-class-section-copy-2 {
	position: relative;
	margin-bottom: 96px;
	padding: 96px 135px 53px;
	background-color: ${({ theme }) => theme.palette.others.k};
      }
      
      .af-view .af-class-section-copy-2.af-class-style-guide-section {
	border-bottom: 1px solid ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-section-copy-2.af-class-title-section {
	padding-top: 120px;
	padding-bottom: 120px;
      }
      
      .af-view .af-class-section-copy-2.af-class-no-top-space {
	padding-top: 0px;
      }
      
      .af-view
	.af-class-section-copy-2.af-class-no-top-space.af-class-pricing-plans-section {
	margin-top: 0px;
      }
      
      .af-view .af-class-section-copy-2.af-class-no-bottom-space {
	padding-bottom: 0px;
      }
      
      .af-view
	.af-class-section-copy-2.af-class-no-bottom-space.af-class-bg-primary-3 {
	padding-right: 0px;
	padding-left: 0px;
	background-color: ${({ theme }) => theme.palette.success.light};
      }
      
      .af-view .af-class-section-copy-2.af-class-no-bottom-space.af-class-bg-gray-4 {
	border-radius: 0px;
	background-color: transparent;
      }
      
      .af-view
	.af-class-section-copy-2.af-class-no-bottom-space.af-class-bg-primary-copy2 {
	margin-bottom: 10px;
	padding-top: 45px;
	padding-right: 0px;
	padding-left: 0px;
	background-color: ${({ theme }) => theme.palette.others.k};
      }
      
      .af-view .af-class-section-copy-2.af-class-overflow-hidden {
	overflow: hidden;
      }
      
      .af-view .af-class-section-copy-2.af-class-bg-gray-4 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 80px 0px 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-section-copy-2.af-class-section-small {
	margin-top: 30px;
	padding-top: 41px;
	padding-bottom: 29px;
	background-color: transparent;
	color: ${({ theme }) => theme.palette.others.g};
      }
      
      .af-view .af-class-section-copy-2.af-class-utility-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-right: 24px;
	padding-left: 24px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	color: ${({ theme }) => theme.palette.others.l};
      }
      
      .af-view .af-class-section-copy-2.af-class-no-space {
	padding-top: 0px;
	padding-bottom: 0px;
      }
      
      .af-view .af-class-section-copy-2.af-class-hero-screenshot-above {
	margin-top: 25vh;
	padding-top: 24px;
      }
      
      .af-view .af-class-section-copy-2.af-class-no-top-space-copy {
	padding-top: 0px;
      }
      
      .af-view
	.af-class-section-copy-2.af-class-no-top-space-copy.af-class-pricing-plans-section {
	margin-top: 0px;
      }
      
      .af-view .af-class-body-2 {
	background-color: #fff;
      }
      
      .af-view .af-class-text-block-19 {
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
      }
      
      .af-view .af-class-image-6 {
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
      }
      
      .af-view .af-class-text-block-20 {
	padding-right: 100px;
	padding-left: 0px;
	-webkit-align-self: auto;
	-ms-flex-item-align: auto;
	-ms-grid-row-align: auto;
	align-self: auto;
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 20px;
	font-weight: 500;
	text-align: left;
      }
      
      .af-view .af-class-text-block-21 {
	margin-bottom: 15px;
	font-size: 20px;
      }
      
      .af-view .af-class-div-block-7 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 10px;
	margin-bottom: 10px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
      }
      
      .af-view .af-class-text-block-22 {
	margin-top: 0px;
	padding-top: 0px;
      }
      
      .af-view .af-class-bold-text {
	padding-right: 10px;
	font-family: Lato, sans-serif;
	font-size: 40px;
      }
      
      .af-view .af-class-pricing-plan-heading-copy {
	margin-top: 20px;
	margin-bottom: 24px;
	padding: 7px 20px;
	border-style: none;
	border-width: 2px;
	border-color: ${({ theme }) => theme.palette.others.l};
	border-radius: 20px;
	background-color: ${({ theme }) => theme.palette.success.light};
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.others.w};
	font-size: 18px;
	font-weight: 700;
      }
      
      .af-view
	.af-class-pricing-plan-heading-copy.af-class-pricing-comparison-heading {
	margin-bottom: 12px;
      }
      
      .af-view .af-class-text-block-21-copy {
	margin-bottom: 15px;
	font-size: 20px;
      }
      
      .af-view .af-class-bold-text-copy {
	padding-right: 10px;
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.others.f};
	font-size: 20px;
      }
      
      .af-view .af-class-div-block-8 {
	-webkit-align-self: auto;
	-ms-flex-item-align: auto;
	-ms-grid-row-align: auto;
	align-self: auto;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
      }
      
      .af-view .af-class-container-testimonials-header {
	position: relative;
	z-index: 3;
	width: 100%;
	max-width: 1272px;
	margin-right: auto;
	margin-left: auto;
	padding: 0px;
      }
      
      .af-view
	.af-class-container-testimonials-header.af-class-announcement-bar-container {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-container-testimonials-header.af-class-text-center {
	display: block;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
      }
      
      .af-view
	.af-class-container-testimonials-header.af-class-text-center.af-class-hero-container {
	position: relative;
	z-index: 3;
	padding-top: 20px;
	padding-bottom: 40px;
      }
      
      .af-view
	.af-class-container-testimonials-header.af-class-text-center.af-class-hero-container-nav-above {
	margin-top: 96px;
	padding-top: 6vw;
	padding-bottom: 6vw;
      }
      
      .af-view .af-class-container-testimonials-header.af-class-container-narrow {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 864px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view
	.af-class-container-testimonials-header.af-class-container-narrow.af-class-align-left {
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
      }
      
      .af-view
	.af-class-container-testimonials-header.af-class-container-narrow.af-class-text-center {
	max-width: 100%;
      }
      
      .af-view .af-class-container-testimonials-header.af-class-align-left {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
      }
      
      .af-view
	.af-class-container-testimonials-header.af-class-post-breadcrumbs-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
      }
      
      .af-view .af-class-container-testimonials-header.af-class-hero-container {
	position: relative;
	z-index: 1;
	padding-top: 6vh;
	padding-bottom: 6vh;
      }
      
      .af-view
	.af-class-container-testimonials-header.af-class-hero-screenshot-container {
	position: relative;
	z-index: 2;
	margin-top: 72px;
	margin-bottom: -25vh;
	text-align: center;
      }
      
      .af-view .af-class-container-testimonials-header.af-class-case-study-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
      }
      
      .af-view .af-class-heading-7-copy {
	margin-bottom: 60px;
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 35px;
	line-height: 40px;
	font-weight: 700;
      }
      
      .af-view .af-class-container-testimonials-pricing {
	position: relative;
	z-index: 3;
	width: 100%;
	max-width: 1272px;
	margin-right: auto;
	margin-left: auto;
	padding: 0px 140px;
      }
      
      .af-view
	.af-class-container-testimonials-pricing.af-class-announcement-bar-container {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-container-testimonials-pricing.af-class-text-center {
	display: block;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
      }
      
      .af-view
	.af-class-container-testimonials-pricing.af-class-text-center.af-class-hero-container {
	position: relative;
	z-index: 3;
	padding-top: 20px;
	padding-bottom: 40px;
      }
      
      .af-view
	.af-class-container-testimonials-pricing.af-class-text-center.af-class-hero-container-nav-above {
	margin-top: 96px;
	padding-top: 6vw;
	padding-bottom: 6vw;
      }
      
      .af-view .af-class-container-testimonials-pricing.af-class-container-narrow {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 864px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view
	.af-class-container-testimonials-pricing.af-class-container-narrow.af-class-align-left {
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
      }
      
      .af-view
	.af-class-container-testimonials-pricing.af-class-container-narrow.af-class-text-center {
	max-width: 100%;
      }
      
      .af-view .af-class-container-testimonials-pricing.af-class-align-left {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
      }
      
      .af-view
	.af-class-container-testimonials-pricing.af-class-post-breadcrumbs-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
      }
      
      .af-view .af-class-container-testimonials-pricing.af-class-hero-container {
	position: relative;
	z-index: 1;
	padding-top: 6vh;
	padding-bottom: 6vh;
      }
      
      .af-view
	.af-class-container-testimonials-pricing.af-class-hero-screenshot-container {
	position: relative;
	z-index: 2;
	margin-top: 72px;
	margin-bottom: -25vh;
	text-align: center;
      }
      
      .af-view
	.af-class-container-testimonials-pricing.af-class-case-study-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
      }
      
      .af-view .af-class-container-testimonials-pricing-title {
	position: relative;
	z-index: 3;
	width: 100%;
	max-width: 1272px;
	margin-right: auto;
	margin-left: auto;
	padding: 0px 60px;
      }
      
      .af-view
	.af-class-container-testimonials-pricing-title.af-class-announcement-bar-container {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-container-testimonials-pricing-title.af-class-text-center {
	display: block;
	padding-top: 0px;
	padding-right: 140px;
	padding-left: 140px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	color: #fff;
      }
      
      .af-view
	.af-class-container-testimonials-pricing-title.af-class-text-center.af-class-hero-container {
	position: relative;
	z-index: 3;
	padding-top: 20px;
	padding-bottom: 40px;
      }
      
      .af-view
	.af-class-container-testimonials-pricing-title.af-class-text-center.af-class-hero-container-nav-above {
	margin-top: 96px;
	padding-top: 6vw;
	padding-bottom: 6vw;
      }
      
      .af-view
	.af-class-container-testimonials-pricing-title.af-class-container-narrow {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 864px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view
	.af-class-container-testimonials-pricing-title.af-class-container-narrow.af-class-align-left {
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
      }
      
      .af-view
	.af-class-container-testimonials-pricing-title.af-class-container-narrow.af-class-text-center {
	max-width: 100%;
      }
      
      .af-view .af-class-container-testimonials-pricing-title.af-class-align-left {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
      }
      
      .af-view
	.af-class-container-testimonials-pricing-title.af-class-post-breadcrumbs-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
      }
      
      .af-view
	.af-class-container-testimonials-pricing-title.af-class-hero-container {
	position: relative;
	z-index: 1;
	padding-top: 6vh;
	padding-bottom: 6vh;
      }
      
      .af-view
	.af-class-container-testimonials-pricing-title.af-class-hero-screenshot-container {
	position: relative;
	z-index: 2;
	margin-top: 72px;
	margin-bottom: -25vh;
	text-align: center;
      }
      
      .af-view
	.af-class-container-testimonials-pricing-title.af-class-case-study-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
      }
      
      .af-view .af-class-heading-17 {
	margin-bottom: 0px;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 35px;
	line-height: 40px;
      }
      
      .af-view .af-class-section-pricing-testimonial {
	position: relative;
	display: block;
	margin-bottom: 96px;
	padding: 40px 135px 53px;
	text-align: left;
      }
      
      .af-view .af-class-section-pricing-testimonial.af-class-style-guide-section {
	border-bottom: 1px solid ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-section-pricing-testimonial.af-class-title-section {
	padding-top: 120px;
	padding-bottom: 120px;
      }
      
      .af-view .af-class-section-pricing-testimonial.af-class-no-top-space {
	padding-top: 0px;
      }
      
      .af-view
	.af-class-section-pricing-testimonial.af-class-no-top-space.af-class-pricing-plans-section {
	margin-top: 0px;
      }
      
      .af-view .af-class-section-pricing-testimonial.af-class-no-bottom-space {
	overflow: hidden;
	margin-top: 100px;
	padding-bottom: 0px;
      }
      
      .af-view
	.af-class-section-pricing-testimonial.af-class-no-bottom-space.af-class-bg-primary-3 {
	padding-right: 0px;
	padding-left: 0px;
	background-color: ${({ theme }) => theme.palette.success.light};
      }
      
      .af-view
	.af-class-section-pricing-testimonial.af-class-no-bottom-space.af-class-bg-gray-4 {
	border-radius: 0px;
	background-color: transparent;
      }
      
      .af-view .af-class-section-pricing-testimonial.af-class-overflow-hidden {
	overflow: hidden;
      }
      
      .af-view .af-class-section-pricing-testimonial.af-class-bg-gray-4 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 80px 0px 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-section-pricing-testimonial.af-class-section-small {
	margin-top: 30px;
	padding-top: 41px;
	padding-bottom: 29px;
	background-color: transparent;
	color: ${({ theme }) => theme.palette.others.g};
      }
      
      .af-view .af-class-section-pricing-testimonial.af-class-utility-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-right: 24px;
	padding-left: 24px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	color: ${({ theme }) => theme.palette.others.l};
      }
      
      .af-view .af-class-section-pricing-testimonial.af-class-no-space {
	padding-top: 0px;
	padding-bottom: 0px;
      }
      
      .af-view .af-class-section-pricing-testimonial.af-class-hero-screenshot-above {
	margin-top: 25vh;
	padding-top: 24px;
      }
      
      .af-view .af-class-section-pricing-testimonial.af-class-no-top-space-copy {
	padding-top: 0px;
      }
      
      .af-view
	.af-class-section-pricing-testimonial.af-class-no-top-space-copy.af-class-pricing-plans-section {
	margin-top: 0px;
      }
      
      .af-view .af-class-image-7 {
	max-width: 70%;
      }
      
      .af-view .af-class-image-8 {
	width: 100%;
	height: 6vh;
	margin-top: 30px;
	margin-bottom: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
      }
      
      .af-view .af-class-section-copy-2-copy {
	position: relative;
	width: 100%;
	margin-bottom: 96px;
	padding: 96px 0px 0px;
	background-color: ${({ theme }) => theme.palette.others.k};
      }
      
      .af-view .af-class-section-copy-2-copy.af-class-style-guide-section {
	border-bottom: 1px solid ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-section-copy-2-copy.af-class-title-section {
	padding-top: 120px;
	padding-bottom: 120px;
      }
      
      .af-view .af-class-section-copy-2-copy.af-class-no-top-space {
	padding-top: 0px;
      }
      
      .af-view
	.af-class-section-copy-2-copy.af-class-no-top-space.af-class-pricing-plans-section {
	margin-top: 0px;
      }
      
      .af-view .af-class-section-copy-2-copy.af-class-no-bottom-space {
	padding-bottom: 0px;
      }
      
      .af-view
	.af-class-section-copy-2-copy.af-class-no-bottom-space.af-class-bg-primary-3 {
	padding-right: 0px;
	padding-left: 0px;
	background-color: ${({ theme }) => theme.palette.success.light};
      }
      
      .af-view
	.af-class-section-copy-2-copy.af-class-no-bottom-space.af-class-bg-gray-4 {
	border-radius: 0px;
	background-color: transparent;
      }
      
      .af-view
	.af-class-section-copy-2-copy.af-class-no-bottom-space.af-class-bg-primary-copy2 {
	margin-bottom: 10px;
	padding-top: 40px;
	padding-right: 0px;
	padding-left: 0px;
	background-color: ${({ theme }) => theme.palette.others.k};
      }
      
      .af-view .af-class-section-copy-2-copy.af-class-overflow-hidden {
	overflow: hidden;
      }
      
      .af-view .af-class-section-copy-2-copy.af-class-bg-gray-4 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 80px 0px 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-section-copy-2-copy.af-class-section-small {
	margin-top: 30px;
	padding-top: 41px;
	padding-bottom: 29px;
	background-color: transparent;
	color: ${({ theme }) => theme.palette.others.g};
      }
      
      .af-view .af-class-section-copy-2-copy.af-class-utility-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-right: 24px;
	padding-left: 24px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	color: ${({ theme }) => theme.palette.others.l};
      }
      
      .af-view .af-class-section-copy-2-copy.af-class-no-space {
	padding-top: 0px;
	padding-bottom: 0px;
      }
      
      .af-view .af-class-section-copy-2-copy.af-class-hero-screenshot-above {
	margin-top: 25vh;
	padding-top: 24px;
      }
      
      .af-view .af-class-section-copy-2-copy.af-class-no-top-space-copy {
	padding-top: 0px;
      }
      
      .af-view
	.af-class-section-copy-2-copy.af-class-no-top-space-copy.af-class-pricing-plans-section {
	margin-top: 0px;
      }
      
      .af-view .af-class-nav-link-menu {
	display: inline;
	margin-right: 40px;
	color: ${({ theme }) => theme.palette.others.f};
      }
      
      .af-view .af-class-nav-link-menu:hover {
	color: ${({ theme }) => theme.palette.primary.main};
      }
      
      .af-view .af-class-link-2 {
	color: ${({ theme }) => theme.palette.primary.main};
	text-decoration: underline;
      }
      
      .af-view .af-class-section-feature-step-3 {
	position: relative;
	display: block;
	margin-bottom: 96px;
	padding: 40px 135px 53px;
	text-align: left;
      }
      
      .af-view .af-class-section-feature-step-3.af-class-style-guide-section {
	border-bottom: 1px solid ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-section-feature-step-3.af-class-title-section {
	padding-top: 120px;
	padding-bottom: 120px;
      }
      
      .af-view .af-class-section-feature-step-3.af-class-no-top-space {
	padding-top: 0px;
      }
      
      .af-view
	.af-class-section-feature-step-3.af-class-no-top-space.af-class-pricing-plans-section {
	margin-top: 0px;
      }
      
      .af-view .af-class-section-feature-step-3.af-class-no-bottom-space {
	padding-bottom: 0px;
	background-color: ${({ theme }) => theme.palette.others.k};
      }
      
      .af-view
	.af-class-section-feature-step-3.af-class-no-bottom-space.af-class-bg-primary-3 {
	padding-right: 0px;
	padding-left: 0px;
	background-color: ${({ theme }) => theme.palette.success.light};
      }
      
      .af-view
	.af-class-section-feature-step-3.af-class-no-bottom-space.af-class-bg-gray-4 {
	border-radius: 0px;
	background-color: transparent;
      }
      
      .af-view .af-class-section-feature-step-3.af-class-overflow-hidden {
	overflow: hidden;
      }
      
      .af-view .af-class-section-feature-step-3.af-class-bg-white-step-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 80px 0px 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-section-feature-step-3.af-class-section-small {
	margin-top: 30px;
	padding-top: 41px;
	padding-bottom: 29px;
	background-color: transparent;
	color: ${({ theme }) => theme.palette.others.g};
      }
      
      .af-view .af-class-section-feature-step-3.af-class-utility-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-right: 24px;
	padding-left: 24px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	color: ${({ theme }) => theme.palette.others.l};
      }
      
      .af-view .af-class-section-feature-step-3.af-class-no-space {
	padding-top: 0px;
	padding-bottom: 0px;
      }
      
      .af-view .af-class-section-feature-step-3.af-class-hero-screenshot-above {
	margin-top: 25vh;
	padding-top: 24px;
      }
      
      .af-view .af-class-section-feature-step-3.af-class-no-top-space-copy {
	padding-top: 0px;
      }
      
      .af-view
	.af-class-section-feature-step-3.af-class-no-top-space-copy.af-class-pricing-plans-section {
	margin-top: 0px;
      }
      
      .af-view .af-class-large-heading-copy {
	margin-bottom: 32px;
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 54px;
	line-height: 56px;
	font-weight: 700;
	letter-spacing: -0.04em;
      }
      
      .af-view .af-class-large-heading-copy.af-class-text-white {
	color: ${({ theme }) => theme.palette.others.g};
      }
      
      .af-view .af-class-large-heading-copy.af-class-text-white-copy-22 {
	margin-top: 60px;
	margin-bottom: 60px;
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 35px;
	line-height: 40px;
      }
      
      .af-view .af-class-heading-6-copy2 {
	width: 600px;
	margin-right: auto;
	margin-left: auto;
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 35px;
	line-height: 40px;
	text-align: center;
      }
      
      .af-view .af-class-image-9 {
	display: none;
      }
      
      .af-view .af-class-section-copy-2-copy-copy {
	position: relative;
	width: 100%;
	margin-bottom: 96px;
	padding: 96px 0px 0px;
	background-color: ${({ theme }) => theme.palette.others.k};
      }
      
      .af-view .af-class-section-copy-2-copy-copy.af-class-style-guide-section {
	border-bottom: 1px solid ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-section-copy-2-copy-copy.af-class-title-section {
	padding-top: 120px;
	padding-bottom: 120px;
      }
      
      .af-view .af-class-section-copy-2-copy-copy.af-class-no-top-space {
	padding-top: 0px;
      }
      
      .af-view
	.af-class-section-copy-2-copy-copy.af-class-no-top-space.af-class-pricing-plans-section {
	margin-top: 0px;
      }
      
      .af-view .af-class-section-copy-2-copy-copy.af-class-no-bottom-space {
	padding-bottom: 0px;
      }
      
      .af-view
	.af-class-section-copy-2-copy-copy.af-class-no-bottom-space.af-class-bg-primary-3 {
	padding-right: 0px;
	padding-left: 0px;
	background-color: ${({ theme }) => theme.palette.success.light};
      }
      
      .af-view
	.af-class-section-copy-2-copy-copy.af-class-no-bottom-space.af-class-bg-gray-4 {
	border-radius: 0px;
	background-color: transparent;
      }
      
      .af-view
	.af-class-section-copy-2-copy-copy.af-class-no-bottom-space.af-class-bg-primary-copy2 {
	margin-bottom: 10px;
	padding-top: 60px;
	padding-right: 0px;
	padding-left: 0px;
	background-color: ${({ theme }) => theme.palette.others.k};
      }
      
      .af-view .af-class-section-copy-2-copy-copy.af-class-overflow-hidden {
	overflow: hidden;
      }
      
      .af-view .af-class-section-copy-2-copy-copy.af-class-bg-gray-4 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 80px 0px 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-section-copy-2-copy-copy.af-class-section-small {
	margin-top: 30px;
	padding-top: 41px;
	padding-bottom: 29px;
	background-color: transparent;
	color: ${({ theme }) => theme.palette.others.g};
      }
      
      .af-view .af-class-section-copy-2-copy-copy.af-class-utility-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-right: 24px;
	padding-left: 24px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	color: ${({ theme }) => theme.palette.others.l};
      }
      
      .af-view .af-class-section-copy-2-copy-copy.af-class-no-space {
	padding-top: 0px;
	padding-bottom: 0px;
      }
      
      .af-view .af-class-section-copy-2-copy-copy.af-class-hero-screenshot-above {
	margin-top: 25vh;
	padding-top: 24px;
      }
      
      .af-view .af-class-section-copy-2-copy-copy.af-class-no-top-space-copy {
	padding-top: 0px;
      }
      
      .af-view
	.af-class-section-copy-2-copy-copy.af-class-no-top-space-copy.af-class-pricing-plans-section {
	margin-top: 0px;
      }
      
      .af-view .af-class-section-copy {
	position: relative;
	display: block;
	margin-bottom: 96px;
	padding: 40px 135px 53px;
	text-align: left;
      }
      
      .af-view .af-class-section-copy.af-class-style-guide-section {
	border-bottom: 1px solid ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-section-copy.af-class-title-section {
	padding-top: 120px;
	padding-bottom: 120px;
      }
      
      .af-view .af-class-section-copy.af-class-no-top-space {
	padding-top: 0px;
      }
      
      .af-view
	.af-class-section-copy.af-class-no-top-space.af-class-pricing-plans-section {
	margin-top: 0px;
      }
      
      .af-view .af-class-section-copy.af-class-no-bottom-space {
	padding-bottom: 0px;
	background-color: ${({ theme }) => theme.palette.others.k};
      }
      
      .af-view .af-class-section-copy.af-class-no-bottom-space.af-class-bg-primary-3 {
	padding-right: 0px;
	padding-left: 0px;
	background-color: ${({ theme }) => theme.palette.success.light};
      }
      
      .af-view .af-class-section-copy.af-class-no-bottom-space.af-class-bg-gray-4 {
	border-radius: 0px;
	background-color: transparent;
      }
      
      .af-view .af-class-section-copy.af-class-overflow-hidden {
	overflow: hidden;
      }
      
      .af-view .af-class-section-copy.af-class-bg-gray-4 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 80px 0px 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-section-copy.af-class-section-small {
	margin-top: 30px;
	padding-top: 41px;
	padding-bottom: 29px;
	background-color: transparent;
	color: ${({ theme }) => theme.palette.others.g};
      }
      
      .af-view .af-class-section-copy.af-class-utility-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-right: 24px;
	padding-left: 24px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	color: ${({ theme }) => theme.palette.others.l};
      }
      
      .af-view .af-class-section-copy.af-class-no-space {
	padding-top: 0px;
	padding-bottom: 0px;
      }
      
      .af-view .af-class-section-copy.af-class-hero-screenshot-above {
	margin-top: 25vh;
	padding-top: 24px;
      }
      
      .af-view .af-class-section-copy.af-class-no-top-space-copy {
	padding-top: 0px;
      }
      
      .af-view
	.af-class-section-copy.af-class-no-top-space-copy.af-class-pricing-plans-section {
	margin-top: 0px;
      }
      
      .af-view .af-class-section-copy-copy {
	position: relative;
	display: block;
	margin-bottom: 96px;
	padding: 40px 135px 53px;
	text-align: left;
      }
      
      .af-view .af-class-section-copy-copy.af-class-style-guide-section {
	border-bottom: 1px solid ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-section-copy-copy.af-class-title-section {
	padding-top: 120px;
	padding-bottom: 120px;
      }
      
      .af-view .af-class-section-copy-copy.af-class-no-top-space {
	padding-top: 0px;
      }
      
      .af-view
	.af-class-section-copy-copy.af-class-no-top-space.af-class-pricing-plans-section {
	margin-top: 0px;
      }
      
      .af-view .af-class-section-copy-copy.af-class-no-bottom-space {
	padding-bottom: 0px;
	background-color: ${({ theme }) => theme.palette.others.k};
      }
      
      .af-view
	.af-class-section-copy-copy.af-class-no-bottom-space.af-class-bg-primary-3 {
	padding-right: 0px;
	padding-left: 0px;
	background-color: ${({ theme }) => theme.palette.success.light};
      }
      
      .af-view
	.af-class-section-copy-copy.af-class-no-bottom-space.af-class-bg-gray-4 {
	border-radius: 0px;
	background-color: transparent;
      }
      
      .af-view .af-class-section-copy-copy.af-class-overflow-hidden {
	overflow: hidden;
      }
      
      .af-view .af-class-section-copy-copy.af-class-bg-gray-4 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 80px 0px 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-section-copy-copy.af-class-section-small {
	margin-top: 30px;
	padding-top: 41px;
	padding-bottom: 29px;
	background-color: transparent;
	color: ${({ theme }) => theme.palette.others.g};
      }
      
      .af-view .af-class-section-copy-copy.af-class-utility-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-right: 24px;
	padding-left: 24px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	color: ${({ theme }) => theme.palette.others.l};
      }
      
      .af-view .af-class-section-copy-copy.af-class-no-space {
	padding-top: 0px;
	padding-bottom: 0px;
      }
      
      .af-view .af-class-section-copy-copy.af-class-hero-screenshot-above {
	margin-top: 25vh;
	padding-top: 24px;
      }
      
      .af-view .af-class-section-copy-copy.af-class-no-top-space-copy {
	padding-top: 0px;
      }
      
      .af-view
	.af-class-section-copy-copy.af-class-no-top-space-copy.af-class-pricing-plans-section {
	margin-top: 0px;
      }
      
      .af-view .af-class-section-copy-copy.af-class-bg-gray-4-copy2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 80px 0px 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: ${({ theme }) => theme.palette.others.r};
      }
      
      .af-view .af-class-paragraph {
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 18px;
      }
      
      .af-view .af-class-paragraph-2 {
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 18px;
      }
      
      .af-view .af-class-paragraph-3 {
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 18px;
	font-weight: 400;
      }
      
      .af-view .af-class-bold-text-2 {
	color: ${({ theme }) => theme.palette.primary.main};
	font-size: 20px;
      }
      
      .af-view .af-class-text-block-24 {
	font-size: 16px;
      }
      
      .af-view .af-class-text-block-25 {
	font-family: Lato, sans-serif;
	line-height: 24px;
      }
      
      .af-view .af-class-pill-wrapper-copy {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
      }
      
      .af-view .af-class-heading-18 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-heading-19 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-heading-20 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-link-3 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-link-4 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-heading-21 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-button-copy {
	padding: 10px 24px;
	border-style: solid;
	border-width: 2px;
	border-color: ${({ theme }) => theme.palette.others.h};
	border-radius: 5px;
	background-color: ${({ theme }) => theme.palette.others.h};
	-webkit-transition: opacity 200ms ease, color 200ms ease,
	  border-color 200ms ease, background-color 200ms ease;
	transition: opacity 200ms ease, color 200ms ease, border-color 200ms ease,
	  background-color 200ms ease;
	color: #fff;
	font-weight: 500;
	text-align: center;
      }
      
      .af-view .af-class-button-copy:hover {
	opacity: 0.75;
      }
      
      .af-view .af-class-button-copy.w--current {
	background-color: #fff;
	color: ${({ theme }) => theme.palette.others.h};
      }
      
      .af-view .af-class-button-copy.af-class-button-small {
	padding: 4px 16px;
	font-size: 14px;
      }
      
      .af-view .af-class-button-copy.af-class-button-large {
	padding: 10px 36px;
	border-style: none;
	border-radius: 20px;
	background-color: ${({ theme }) => theme.palette.others.k};
	font-size: 20px;
	line-height: 30px;
      }
      
      .af-view .af-class-button-copy.af-class-button-large.af-class-add-space-right {
	margin-right: 32px;
	padding: 10px 45px;
	border-style: none;
	border-radius: 20px;
	background-color: ${({ theme }) => theme.palette.primary.main};
      }
      
      .af-view
	.af-class-button-copy.af-class-button-large.af-class-add-space-right.af-class-primary-button {
	padding: 9px 50px;
      }
      
      .af-view
	.af-class-button-copy.af-class-button-large.af-class-add-space-right-copy {
	margin-right: 32px;
	padding: 10px 45px;
	border-style: none;
	border-radius: 20px;
	background-color: ${({ theme }) => theme.palette.primary.main};
      }
      
      .af-view
	.af-class-button-copy.af-class-button-large.af-class-add-space-right-copy.af-class-primary-button {
	padding: 9px 50px;
      }
      
      .af-view .af-class-button-copy.af-class-button-outline {
	background-color: transparent;
	color: ${({ theme }) => theme.palette.others.h};
      }
      
      .af-view .af-class-button-copy.af-class-button-outline:hover {
	background-color: ${({ theme }) => theme.palette.others.h};
	opacity: 1;
	color: #fff;
      }
      
      .af-view
	.af-class-button-copy.af-class-button-outline.af-class-pricing-comparison-button {
	width: 100%;
	margin-top: 24px;
      }
      
      .af-view .af-class-button-copy.af-class-button-disabled {
	opacity: 0.5;
	cursor: not-allowed;
      }
      
      .af-view .af-class-button-copy.af-class-button-round {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 48px;
	height: 48px;
	padding: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 50%;
      }
      
      .af-view
	.af-class-button-copy.af-class-button-round.af-class-slider-button-left {
	left: 0px;
	border-style: none;
	background-color: ${({ theme }) => theme.palette.primary.main};
      }
      
      .af-view
	.af-class-button-copy.af-class-button-round.af-class-slider-button-right {
	right: 0px;
	border-style: none;
	background-color: ${({ theme }) => theme.palette.primary.main};
      }
      
      .af-view
	.af-class-button-copy.af-class-button-round.af-class-back-to-top-button {
	-webkit-transition: -webkit-transform 200ms ease;
	transition: -webkit-transform 200ms ease;
	transition: transform 200ms ease;
	transition: transform 200ms ease, -webkit-transform 200ms ease;
      }
      
      .af-view
	.af-class-button-copy.af-class-button-round.af-class-back-to-top-button:hover {
	opacity: 1;
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
      }
      
      .af-view
	.af-class-button-copy.af-class-button-round.af-class-page-navigator-button {
	position: relative;
	margin-right: 12px;
      }
      
      .af-view .af-class-button-copy.af-class-dropdown-button {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	opacity: 1;
      }
      
      .af-view .af-class-button-copy.af-class-dropdown-button.w--open {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
      }
      
      .af-view .af-class-button-copy.af-class-submit-button {
	margin-top: 12px;
	border-style: none;
	border-radius: 20px;
	background-color: ${({ theme }) => theme.palette.primary.main};
      }
      
      .af-view .af-class-button-copy.af-class-button-block {
	width: 100%;
      }
      
      .af-view .af-class-button-copy.af-class-utility-page-button {
	width: 100%;
      }
      
      .af-view .af-class-button-copy.af-class-button-with-icon {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-button-copy.af-class-navbar-button {
	padding-top: 9px;
	padding-bottom: 9px;
	border-style: none;
	border-radius: 20px;
	background-color: ${({ theme }) => theme.palette.primary.main};
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-button-copy.af-class-button-white {
	border-color: #fff;
	background-color: #fff;
	color: ${({ theme }) => theme.palette.others.h};
      }
      
      .af-view .af-class-button-copy.af-class-button-large-copy {
	padding: 10px 36px;
	border-style: none;
	border-radius: 20px;
	background-color: ${({ theme }) => theme.palette.others.k};
	font-size: 20px;
	line-height: 30px;
      }
      
      .af-view
	.af-class-button-copy.af-class-button-large-copy.af-class-add-space-right {
	margin-right: 32px;
	padding: 10px 45px;
	border-style: none;
	border-radius: 20px;
	background-color: ${({ theme }) => theme.palette.primary.main};
      }
      
      .af-view
	.af-class-button-copy.af-class-button-large-copy.af-class-add-space-right.af-class-primary-button {
	padding: 9px 50px;
      }
      
      .af-view
	.af-class-button-copy.af-class-button-large-copy.af-class-add-space-right-copy {
	margin-right: 32px;
	padding: 10px 45px;
	border-style: none;
	border-radius: 20px;
	background-color: ${({ theme }) => theme.palette.primary.main};
      }
      
      .af-view
	.af-class-button-copy.af-class-button-large-copy.af-class-add-space-right-copy.af-class-primary-button {
	padding: 9px 50px;
      }
      
      .af-view
	.af-class-button-copy.af-class-button-large-copy.af-class-add-space-right-copy.af-class-primary-button-copy {
	padding: 7px 30px 7px 35px;
	border-style: solid;
	border-color: ${({ theme }) => theme.palette.primary.main};
	background-color: #fff;
	color: ${({ theme }) => theme.palette.primary.main};
      }
      
      .af-view .af-class-heading-8-conseils {
	max-width: 800px;
	font-family: Lato, sans-serif;
	font-size: 35px;
      }
      
      .af-view .af-class-body-3 {
	line-height: 20px;
      }
      
      .af-view .af-class-faq-group-conseils {
	margin-top: 30px;
	margin-bottom: 36px;
      }
      
      .af-view .af-class-section-conseils {
	position: relative;
	display: block;
	margin-bottom: 96px;
	padding: 40px 135px 53px;
	background-color: ${({ theme }) => theme.palette.others.r};
	text-align: left;
      }
      
      .af-view .af-class-section-conseils.af-class-style-guide-section {
	border-bottom: 1px solid ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-section-conseils.af-class-title-section {
	padding-top: 120px;
	padding-bottom: 120px;
      }
      
      .af-view .af-class-section-conseils.af-class-no-top-space {
	padding-top: 0px;
      }
      
      .af-view
	.af-class-section-conseils.af-class-no-top-space.af-class-pricing-plans-section {
	margin-top: 0px;
      }
      
      .af-view .af-class-section-conseils.af-class-no-bottom-space {
	padding-bottom: 0px;
	background-color: ${({ theme }) => theme.palette.others.k};
      }
      
      .af-view
	.af-class-section-conseils.af-class-no-bottom-space.af-class-bg-primary-3 {
	padding-right: 0px;
	padding-left: 0px;
	background-color: ${({ theme }) => theme.palette.success.light};
      }
      
      .af-view
	.af-class-section-conseils.af-class-no-bottom-space.af-class-bg-gray-4 {
	border-radius: 0px;
	background-color: transparent;
      }
      
      .af-view .af-class-section-conseils.af-class-overflow-hidden {
	overflow: hidden;
      }
      
      .af-view .af-class-section-conseils.af-class-bg-gray-4 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 80px 0px 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-section-conseils.af-class-section-small {
	margin-top: 30px;
	padding: 41px 100px 29px;
	background-color: transparent;
	color: ${({ theme }) => theme.palette.others.g};
      }
      
      .af-view .af-class-section-conseils.af-class-utility-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-right: 24px;
	padding-left: 24px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	color: ${({ theme }) => theme.palette.others.l};
      }
      
      .af-view .af-class-section-conseils.af-class-no-space {
	padding-top: 0px;
	padding-bottom: 0px;
      }
      
      .af-view .af-class-section-conseils.af-class-hero-screenshot-above {
	margin-top: 25vh;
	padding-top: 24px;
      }
      
      .af-view .af-class-section-conseils.af-class-no-top-space-copy {
	padding-top: 0px;
      }
      
      .af-view
	.af-class-section-conseils.af-class-no-top-space-copy.af-class-pricing-plans-section {
	margin-top: 0px;
      }
      
      .af-view .af-class-icon-list-conseils {
	margin-top: 0px;
	margin-bottom: -16px;
	padding-left: 0px;
      }
      
      .af-view .af-class-icon-list-conseils.af-class-pricing-plan-list {
	min-height: 400px;
	margin-top: 36px;
	margin-bottom: 36px;
	padding-right: 24px;
	padding-left: 24px;
      }
      
      .af-view .af-class-icon-list-conseils.af-class-icon-list-horizontal {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: -24px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
      }
      
      .af-view .af-class-icon-list-conseils.af-class-pricing-plan-list-conseils {
	min-height: auto;
	margin-top: 36px;
	margin-bottom: 36px;
	padding-right: 24px;
	padding-left: 24px;
      }
      
      .af-view .af-class-icon-list-item-conseils {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0px;
	margin-bottom: 10px;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
      }
      
      .af-view .af-class-icon-list-item-conseils.af-class-disabled {
	opacity: 0.25;
      }
      
      .af-view .af-class-icon-list-item-conseils.af-class-icon-list-horizontal-item {
	margin-top: 0px;
	margin-right: 24px;
	margin-bottom: 24px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
      }
      
      .af-view .af-class-columns-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
      }
      
      .af-view .af-class-column-5 {
	display: none;
      }
      
      .af-view .af-class-heading-22 {
	position: static;
	left: 0%;
	top: 2%;
	right: auto;
	bottom: auto;
	margin-top: 60px;
	margin-left: 0px;
	padding-left: 0px;
	font-family: Lato, sans-serif;
	font-size: 28px;
	text-align: left;
      }
      
      .af-view .af-class-column-6 {
	display: none;
      }
      
      .af-view .af-class-heading-8-ml {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-paragraph-large-ml {
	margin-bottom: 24px;
      }
      
      .af-view .af-class-paragraph-large-ml.af-class-text-large {
	margin-right: 140px;
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.grey["800"]};
	text-align: left;
      }
      
      .af-view .af-class-paragraph-large-ml.af-class-text-large-ml {
	margin-right: 140px;
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.grey["800"]};
	text-align: left;
      }
      
      .af-view .af-class-content-pair-image-ml {
	max-width: 26%;
	border-radius: 10px;
      }
      
      .af-view .af-class-heading-8-cc {
	font-family: Lato, sans-serif;
	font-size: 30px;
	line-height: 35px;
      }
      
      .af-view .af-class-paragraph-large-cc {
	margin-bottom: 24px;
      }
      
      .af-view .af-class-paragraph-large-cc.af-class-text-large {
	margin-right: 140px;
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.grey["800"]};
	text-align: left;
      }
      
      .af-view .af-class-paragraph-large-cc.af-class-text-large-cc {
	margin-right: 140px;
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.others.g};
	text-align: left;
      }
      
      .af-view .af-class-content-pair-image-cc {
	max-width: 26%;
	border-radius: 10px;
      }
      
      .af-view .af-class-three-up-grid-pricing {
	display: -ms-grid;
	display: grid;
	width: 100%;
	margin-bottom: -1px;
	padding: 40px 20px;
	grid-auto-columns: 1fr;
	grid-column-gap: 24px;
	grid-row-gap: 48px;
	-ms-grid-columns: 1fr 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr;
	-ms-grid-rows: auto;
	grid-template-rows: auto;
	border-radius: 15px;
	background-color: ${({ theme }) => theme.palette.success.light};
	box-shadow: 0 0 4px 1px rgba(53, 62, 68, 0.09);
      }
      
      .af-view .af-class-hero-section-action-sales {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 10px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-section-sales {
	position: relative;
	display: block;
	margin-bottom: 96px;
	padding: 40px 135px 53px;
	text-align: left;
      }
      
      .af-view .af-class-section-sales.af-class-style-guide-section {
	border-bottom: 1px solid ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-section-sales.af-class-title-section {
	padding-top: 120px;
	padding-bottom: 120px;
      }
      
      .af-view .af-class-section-sales.af-class-no-top-space {
	padding-top: 0px;
      }
      
      .af-view
	.af-class-section-sales.af-class-no-top-space.af-class-pricing-plans-section {
	margin-top: 0px;
      }
      
      .af-view .af-class-section-sales.af-class-no-bottom-space {
	padding-bottom: 0px;
	background-color: ${({ theme }) => theme.palette.others.k};
      }
      
      .af-view
	.af-class-section-sales.af-class-no-bottom-space.af-class-bg-primary-3 {
	padding-right: 0px;
	padding-left: 0px;
	background-color: ${({ theme }) => theme.palette.success.light};
      }
      
      .af-view .af-class-section-sales.af-class-no-bottom-space.af-class-bg-gray-4 {
	border-radius: 0px;
	background-color: transparent;
      }
      
      .af-view .af-class-section-sales.af-class-overflow-hidden {
	overflow: hidden;
      }
      
      .af-view .af-class-section-sales.af-class-bg-gray-4 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 80px 0px 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-section-sales.af-class-section-small {
	margin-top: 30px;
	padding: 41px 100px 29px;
	background-color: transparent;
	color: ${({ theme }) => theme.palette.others.g};
      }
      
      .af-view .af-class-section-sales.af-class-utility-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-right: 24px;
	padding-left: 24px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	color: ${({ theme }) => theme.palette.others.l};
      }
      
      .af-view .af-class-section-sales.af-class-no-space {
	padding-top: 0px;
	padding-bottom: 0px;
      }
      
      .af-view .af-class-section-sales.af-class-hero-screenshot-above {
	margin-top: 25vh;
	padding-top: 24px;
      }
      
      .af-view .af-class-section-sales.af-class-no-top-space-copy {
	padding-top: 0px;
      }
      
      .af-view
	.af-class-section-sales.af-class-no-top-space-copy.af-class-pricing-plans-section {
	margin-top: 0px;
      }
      
      .af-view .af-class-section-sales.af-class-section-small-sales {
	margin-top: 20px;
	padding: 20px 100px 29px;
	background-color: transparent;
	color: ${({ theme }) => theme.palette.others.g};
      }
      
      .af-view .af-class-heading-2-home {
	margin-right: 100px;
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 50px;
	line-height: 50px;
	font-weight: 500;
	text-align: left;
	letter-spacing: -0.04em;
      }
      
      .af-view .af-class-title-section-conseil {
	position: relative;
	padding-top: 120px;
	padding-bottom: 120px;
      }
      
      .af-view .af-class-title-section-conseil.af-class-no-bottom-space {
	padding-bottom: 0px;
      }
      
      .af-view
	.af-class-title-section-conseil.af-class-no-bottom-space.af-class-bg-primary {
	padding-top: 99px;
	background-color: transparent;
	background-image: linear-gradient(319deg, ${({ theme }) =>
    theme.palette.secondary.main}, ${({ theme }) =>
  theme.palette.primary.main} 100%, #fff);
	background-clip: border-box;
      }
      
      .af-view .af-class-title-section-conseil.af-class-no-bottom-space-conseil {
	padding-bottom: 0px;
      }
      
      .af-view
	.af-class-title-section-conseil.af-class-no-bottom-space-conseil.af-class-bg-primary {
	padding-top: 99px;
	background-color: transparent;
	background-image: linear-gradient(319deg, ${({ theme }) =>
    theme.palette.secondary.main}, ${({ theme }) =>
  theme.palette.primary.main} 100%, #fff);
	background-clip: border-box;
      }
      
      .af-view
	.af-class-title-section-conseil.af-class-no-bottom-space-conseil.af-class-bg-primary-conseil {
	padding-top: 40px;
	padding-bottom: 40px;
	background-color: transparent;
	background-image: linear-gradient(319deg, ${({ theme }) =>
    theme.palette.secondary.main}, ${({ theme }) =>
  theme.palette.primary.main} 100%, #fff);
	background-clip: border-box;
      }
      
      .af-view .af-class-divider-conseil {
	position: relative;
	z-index: 1;
	width: 100%;
	height: 4vw;
	margin-top: 30px;
      }
      
      .af-view .af-class-divider-conseil.af-class-divider-top {
	bottom: 1px;
	margin-bottom: 96px;
      }
      
      .af-view .af-class-divider-conseil.af-class-divider-bottom {
	top: 1px;
	z-index: 1;
	margin-top: 30px;
	border-radius: 0px;
	background-color: transparent;
	color: ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-divider-conseil.af-class-divider-bottom-large {
	margin-top: 120px;
      }
      
      .af-view .af-class-divider-conseil.af-class-card-divider {
	position: absolute;
	left: 0%;
	top: auto;
	right: auto;
	bottom: 0%;
	height: 48px;
      }
      
      .af-view .af-class-divider-conseil.af-class-divider-bottom-small {
	margin-top: 72px;
      }
      
      .af-view .af-class-text-white-conseil {
	margin-bottom: 0px;
	padding-top: 0px;
	padding-right: 150px;
	padding-left: 150px;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 35px;
	line-height: 40px;
      }
      
      .af-view .af-class-heading-23 {
	margin-top: 60px;
	margin-bottom: 0px;
	font-family: Lato, sans-serif;
	font-size: 28px;
      }
      
      .af-view .af-class-bold-text-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	font-family: Rubik, sans-serif;
	font-size: 16px;
      }
      
      .af-view .af-class-heading-24 {
	margin-top: 60px;
	font-family: Lato, sans-serif;
	font-size: 28px;
      }
      
      .af-view .af-class-title-section-ml {
	position: relative;
	padding-top: 120px;
	padding-bottom: 120px;
      }
      
      .af-view .af-class-title-section-ml.af-class-no-bottom-space {
	padding-bottom: 0px;
      }
      
      .af-view
	.af-class-title-section-ml.af-class-no-bottom-space.af-class-bg-primary {
	padding-top: 99px;
	background-color: transparent;
	background-image: linear-gradient(319deg, ${({ theme }) =>
    theme.palette.secondary.main}, ${({ theme }) =>
  theme.palette.primary.main} 100%, #fff);
	background-clip: border-box;
      }
      
      .af-view .af-class-title-section-ml.af-class-no-bottom-space-ml {
	padding-bottom: 0px;
      }
      
      .af-view
	.af-class-title-section-ml.af-class-no-bottom-space-ml.af-class-bg-primary {
	padding-top: 99px;
	background-color: transparent;
	background-image: linear-gradient(319deg, ${({ theme }) =>
    theme.palette.secondary.main}, ${({ theme }) =>
  theme.palette.primary.main} 100%, #fff);
	background-clip: border-box;
      }
      
      .af-view
	.af-class-title-section-ml.af-class-no-bottom-space-ml.af-class-bg-primary-ml {
	padding-top: 40px;
	background-color: transparent;
	background-image: linear-gradient(319deg, ${({ theme }) =>
    theme.palette.secondary.main}, ${({ theme }) =>
  theme.palette.primary.main} 100%, #fff);
	background-clip: border-box;
      }
      
      .af-view .af-class-title-section-guide {
	position: relative;
	padding-top: 120px;
	padding-bottom: 120px;
      }
      
      .af-view .af-class-title-section-guide.af-class-no-bottom-space {
	padding-bottom: 0px;
      }
      
      .af-view
	.af-class-title-section-guide.af-class-no-bottom-space.af-class-bg-primary {
	padding-top: 99px;
	background-color: transparent;
	background-image: linear-gradient(319deg, ${({ theme }) =>
    theme.palette.secondary.main}, ${({ theme }) =>
  theme.palette.primary.main} 100%, #fff);
	background-clip: border-box;
      }
      
      .af-view .af-class-title-section-guide.af-class-no-bottom-space-guide {
	padding-bottom: 0px;
      }
      
      .af-view
	.af-class-title-section-guide.af-class-no-bottom-space-guide.af-class-bg-primary {
	padding-top: 99px;
	background-color: transparent;
	background-image: linear-gradient(319deg, ${({ theme }) =>
    theme.palette.secondary.main}, ${({ theme }) =>
  theme.palette.primary.main} 100%, #fff);
	background-clip: border-box;
      }
      
      .af-view
	.af-class-title-section-guide.af-class-no-bottom-space-guide.af-class-bg-primary-guide {
	padding-top: 40px;
	background-color: transparent;
	background-image: linear-gradient(319deg, ${({ theme }) =>
    theme.palette.secondary.main}, ${({ theme }) =>
  theme.palette.primary.main} 100%, #fff);
	background-clip: border-box;
      }
      
      .af-view .af-class-title-section-seo {
	position: relative;
	padding-top: 120px;
	padding-bottom: 120px;
      }
      
      .af-view .af-class-title-section-seo.af-class-no-bottom-space {
	padding-bottom: 0px;
      }
      
      .af-view
	.af-class-title-section-seo.af-class-no-bottom-space.af-class-bg-primary {
	padding-top: 99px;
	background-color: transparent;
	background-image: linear-gradient(319deg, ${({ theme }) =>
    theme.palette.secondary.main}, ${({ theme }) =>
  theme.palette.primary.main} 100%, #fff);
	background-clip: border-box;
      }
      
      .af-view .af-class-title-section-seo.af-class-no-bottom-space-seo {
	padding-bottom: 0px;
      }
      
      .af-view
	.af-class-title-section-seo.af-class-no-bottom-space-seo.af-class-bg-primary {
	padding-top: 99px;
	background-color: transparent;
	background-image: linear-gradient(319deg, ${({ theme }) =>
    theme.palette.secondary.main}, ${({ theme }) =>
  theme.palette.primary.main} 100%, #fff);
	background-clip: border-box;
      }
      
      .af-view
	.af-class-title-section-seo.af-class-no-bottom-space-seo.af-class-bg-primary-seo {
	padding-top: 40px;
	background-color: transparent;
	background-image: linear-gradient(319deg, ${({ theme }) =>
    theme.palette.secondary.main}, ${({ theme }) =>
  theme.palette.primary.main} 100%, #fff);
	background-clip: border-box;
      }
      
      .af-view .af-class-list-2 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-paragraph-4 {
	overflow: visible;
      }
      
      .af-view .af-class-dropdown-toggle {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
      }
      
      .af-view .af-class-dropdown-toggle-copy {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 40px;
	padding-left: 0px;
      }
      
      .af-view .af-class-text-block-26 {
	color: ${({ theme }) => theme.palette.others.f};
	font-weight: 500;
      }
      
      .af-view .af-class-dropdown-list-2 {
	display: none;
	background-color: #fff;
	box-shadow: 0 0 4px -1px rgba(0, 0, 0, 0.53);
      }
      
      .af-view .af-class-dropdown-link-2 {
	color: ${({ theme }) => theme.palette.others.f};
	-o-object-fit: fill;
	object-fit: fill;
      }
      
      .af-view .af-class-dropdown-link-2:hover {
	color: ${({ theme }) => theme.palette.primary.main};
      }
      
      .af-view .af-class-div-block-13 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-text-block-27 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-text-block-28 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-text-block-29 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-text-block-30 {
	font-family: Lato, sans-serif;
	color: ${({ theme }) => theme.palette.others.f};
      }
      
      .af-view .af-class-text-block-31 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-text-block-32 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-text-block-33 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-text-block-34 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-text-block-35 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-text-block-36 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-text-block-37 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-text-block-38 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-text-block-39 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-text-block-40 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-text-block-41 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-text-block-42 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-text-block-43 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-text-block-44 {
	position: static;
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-text-block-45 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-text-block-46 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-text-block-47 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-text-block-48 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-text-block-49 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-bold-text-4 {
	font-size: 16px;
      }
      
      .af-view .af-class-bold-text-5 {
	font-size: 16px;
      }
      
      .af-view .af-class-bold-text-6 {
	font-size: 16px;
      }
      
      .af-view .af-class-bold-text-7 {
	font-size: 16px;
      }
      
      .af-view .af-class-bold-text-8 {
	font-size: 16px;
      }
      
      .af-view .af-class-bold-text-9 {
	font-size: 16px;
      }
      
      .af-view .af-class-content-pair-text-about {
	display: block;
	width: 60%;
	max-width: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	text-align: justify;
      }
      
      .af-view .af-class-content-pair-image-about {
	max-width: 30%;
	border-radius: 10px;
      }
      
      .af-view .af-class-content-pair-2-about {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 15px;
	padding-left: 0px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-content: flex-start;
	-ms-flex-line-pack: start;
	align-content: flex-start;
      }
      
      .af-view .af-class-content-pair-2-about.af-class-content-pair-reverse {
	display: -ms-grid;
	display: grid;
	margin-top: 60px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-webkit-flex-direction: row-reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	-webkit-box-pack: end;
	-webkit-justify-content: end;
	-ms-flex-pack: end;
	justify-content: end;
	justify-items: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-content: end;
	-ms-flex-line-pack: end;
	align-content: end;
	grid-auto-columns: 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
      }
      
      .af-view .af-class-text-huge-about {
	margin-bottom: 24px;
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 72px;
	line-height: 72px;
	font-weight: 700;
	letter-spacing: -0.04em;
      }
      
      .af-view .af-class-text-huge-about.af-class-text-white {
	color: ${({ theme }) => theme.palette.others.g};
	font-size: 50px;
      }
      
      .af-view .af-class-heading-25 {
	font-family: Lato, sans-serif;
      }
      
      .af-view .af-class-section-about {
	position: relative;
	display: block;
	margin-top: 33px;
	margin-bottom: 11px;
	padding: 40px 135px 53px;
	text-align: left;
      }
      
      .af-view .af-class-section-about.af-class-style-guide-section {
	border-bottom: 1px solid ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-section-about.af-class-title-section {
	padding-top: 120px;
	padding-bottom: 120px;
      }
      
      .af-view .af-class-section-about.af-class-no-top-space {
	padding-top: 0px;
      }
      
      .af-view
	.af-class-section-about.af-class-no-top-space.af-class-pricing-plans-section {
	margin-top: 0px;
      }
      
      .af-view .af-class-section-about.af-class-no-bottom-space {
	padding-bottom: 0px;
	background-color: ${({ theme }) => theme.palette.others.k};
      }
      
      .af-view
	.af-class-section-about.af-class-no-bottom-space.af-class-bg-primary-3 {
	padding-right: 0px;
	padding-left: 0px;
	background-color: ${({ theme }) => theme.palette.success.light};
      }
      
      .af-view .af-class-section-about.af-class-no-bottom-space.af-class-bg-gray-4 {
	border-radius: 0px;
	background-color: transparent;
      }
      
      .af-view .af-class-section-about.af-class-overflow-hidden {
	overflow: hidden;
      }
      
      .af-view .af-class-section-about.af-class-bg-gray-4 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 80px 0px 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-section-about.af-class-section-small {
	margin-top: 30px;
	padding: 41px 100px 29px;
	background-color: transparent;
	color: ${({ theme }) => theme.palette.others.g};
      }
      
      .af-view .af-class-section-about.af-class-utility-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-right: 24px;
	padding-left: 24px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	color: ${({ theme }) => theme.palette.others.l};
      }
      
      .af-view .af-class-section-about.af-class-no-space {
	padding-top: 0px;
	padding-bottom: 0px;
      }
      
      .af-view .af-class-section-about.af-class-hero-screenshot-above {
	margin-top: 25vh;
	padding-top: 24px;
      }
      
      .af-view .af-class-section-about.af-class-no-top-space-copy {
	padding-top: 0px;
      }
      
      .af-view
	.af-class-section-about.af-class-no-top-space-copy.af-class-pricing-plans-section {
	margin-top: 0px;
      }
      
      .af-view .af-class-section-avatar {
	position: relative;
	display: block;
	margin-bottom: 96px;
	padding: 40px 135px 53px;
	text-align: left;
      }
      
      .af-view .af-class-section-avatar.af-class-style-guide-section {
	border-bottom: 1px solid ${({ theme }) => theme.palette.others.j};
      }
      
      .af-view .af-class-section-avatar.af-class-title-section {
	padding-top: 120px;
	padding-bottom: 120px;
      }
      
      .af-view .af-class-section-avatar.af-class-no-top-space {
	padding-top: 0px;
      }
      
      .af-view
	.af-class-section-avatar.af-class-no-top-space.af-class-pricing-plans-section {
	margin-top: 0px;
      }
      
      .af-view .af-class-section-avatar.af-class-no-bottom-space {
	padding-bottom: 0px;
	background-color: ${({ theme }) => theme.palette.others.k};
      }
      
      .af-view
	.af-class-section-avatar.af-class-no-bottom-space.af-class-bg-primary-3 {
	padding-right: 0px;
	padding-left: 0px;
	background-color: ${({ theme }) => theme.palette.success.light};
      }
      
      .af-view .af-class-section-avatar.af-class-no-bottom-space.af-class-bg-gray-4 {
	padding-top: 34px;
	border-radius: 0px;
	background-color: transparent;
      }
      
      .af-view .af-class-section-avatar.af-class-overflow-hidden {
	overflow: hidden;
      }
      
      .af-view .af-class-section-avatar.af-class-bg-gray-4 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 80px 0px 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
      }
      
      .af-view .af-class-section-avatar.af-class-section-small {
	margin-top: 30px;
	padding: 41px 100px 29px;
	background-color: transparent;
	color: ${({ theme }) => theme.palette.others.g};
      }
      
      .af-view .af-class-section-avatar.af-class-utility-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-right: 24px;
	padding-left: 24px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	color: ${({ theme }) => theme.palette.others.l};
      }
      
      .af-view .af-class-section-avatar.af-class-no-space {
	padding-top: 0px;
	padding-bottom: 0px;
      }
      
      .af-view .af-class-section-avatar.af-class-hero-screenshot-above {
	margin-top: 25vh;
	padding-top: 24px;
      }
      
      .af-view .af-class-section-avatar.af-class-no-top-space-copy {
	padding-top: 0px;
      }
      
      .af-view
	.af-class-section-avatar.af-class-no-top-space-copy.af-class-pricing-plans-section {
	margin-top: 0px;
      }
      
      @media screen and (min-width: 1920px) {
	.af-view .af-class-section.af-class-no-bottom-space.af-class-bg-primary-3 {
	  padding-left: 0px;
	}
      
	.af-view .af-class-section.af-class-utility-page-content {
	  color: transparent;
	}
      
	.af-view .af-class-metrics-horizontal {
	  margin-top: -30px;
	}
      
	.af-view .af-class-image {
	  padding-bottom: 0px;
	}
      
	.af-view .af-class-columns {
	  margin-right: auto;
	  margin-left: auto;
	  -webkit-box-align: center;
	  -webkit-align-items: center;
	  -ms-flex-align: center;
	  align-items: center;
	}
      
	.af-view .af-class-image-3 {
	  max-width: 80%;
	}
      
	.af-view .af-class-heading-10 {
	  background-color: transparent;
	  color: ${({ theme }) => theme.palette.others.l};
	}
      
	.af-view .af-class-button-3 {
	  padding: 15px 40px;
	  border-radius: 11px;
	  background-color: ${({ theme }) => theme.palette.others.k};
	  color: #fff;
	  font-size: 24px;
	}
      
	.af-view .af-class-heading-11 {
	  margin-top: 32px;
	  font-family: Lato, sans-serif;
	  font-size: 26px;
	  line-height: 40px;
	  text-transform: capitalize;
	}
      
	.af-view .af-class-heading-12 {
	  padding-top: 20px;
	  font-family: Lato, sans-serif;
	  font-weight: 500;
	}
      
	.af-view
	  .af-class-section-copy.af-class-no-bottom-space.af-class-bg-primary-3 {
	  padding-left: 0px;
	}
      
	.af-view .af-class-section-copy.af-class-utility-page-content {
	  color: transparent;
	}
      
	.af-view
	  .af-class-section-copy-copy.af-class-no-bottom-space.af-class-bg-primary-3 {
	  padding-left: 0px;
	}
      
	.af-view .af-class-section-copy-copy.af-class-utility-page-content {
	  color: transparent;
	}
      
	.af-view
	  .af-class-section-copy.af-class-no-bottom-space.af-class-bg-primary-3 {
	  padding-left: 0px;
	}
      
	.af-view .af-class-section-copy.af-class-utility-page-content {
	  color: transparent;
	}
      
	.af-view
	  .af-class-section-copy-2.af-class-no-bottom-space.af-class-bg-primary-3 {
	  padding-left: 0px;
	}
      
	.af-view .af-class-section-copy-2.af-class-utility-page-content {
	  color: transparent;
	}
      
	.af-view
	  .af-class-section-pricing-testimonial.af-class-no-bottom-space.af-class-bg-primary-3 {
	  padding-left: 0px;
	}
      
	.af-view .af-class-section-pricing-testimonial.af-class-utility-page-content {
	  color: transparent;
	}
      
	.af-view
	  .af-class-section-copy-2-copy.af-class-no-bottom-space.af-class-bg-primary-3 {
	  padding-left: 0px;
	}
      
	.af-view .af-class-section-copy-2-copy.af-class-utility-page-content {
	  color: transparent;
	}
      
	.af-view
	  .af-class-section-feature-step-3.af-class-no-bottom-space.af-class-bg-primary-3 {
	  padding-left: 0px;
	}
      
	.af-view .af-class-section-feature-step-3.af-class-utility-page-content {
	  color: transparent;
	}
      
	.af-view
	  .af-class-section-copy-2-copy-copy.af-class-no-bottom-space.af-class-bg-primary-3 {
	  padding-left: 0px;
	}
      
	.af-view .af-class-section-copy-2-copy-copy.af-class-utility-page-content {
	  color: transparent;
	}
      
	.af-view
	  .af-class-section-copy.af-class-no-bottom-space.af-class-bg-primary-3 {
	  padding-left: 0px;
	}
      
	.af-view .af-class-section-copy.af-class-utility-page-content {
	  color: transparent;
	}
      
	.af-view
	  .af-class-section-copy-copy.af-class-no-bottom-space.af-class-bg-primary-3 {
	  padding-left: 0px;
	}
      
	.af-view .af-class-section-copy-copy.af-class-utility-page-content {
	  color: transparent;
	}
      
	.af-view
	  .af-class-section-conseils.af-class-no-bottom-space.af-class-bg-primary-3 {
	  padding-left: 0px;
	}
      
	.af-view .af-class-section-conseils.af-class-utility-page-content {
	  color: transparent;
	}
      
	.af-view
	  .af-class-section-sales.af-class-no-bottom-space.af-class-bg-primary-3 {
	  padding-left: 0px;
	}
      
	.af-view .af-class-section-sales.af-class-utility-page-content {
	  color: transparent;
	}
      
	.af-view
	  .af-class-section-about.af-class-no-bottom-space.af-class-bg-primary-3 {
	  padding-left: 0px;
	}
      
	.af-view .af-class-section-about.af-class-utility-page-content {
	  color: transparent;
	}
      
	.af-view
	  .af-class-section-avatar.af-class-no-bottom-space.af-class-bg-primary-3 {
	  padding-left: 0px;
	}
      
	.af-view .af-class-section-avatar.af-class-utility-page-content {
	  color: transparent;
	}
      }
      
      @media screen and (max-width: 991px) {
	.af-view h1 {
	  margin-bottom: 24px;
	}
      
	.af-view h2 {
	  margin-bottom: 16px;
	  font-size: 36px;
	  line-height: 40px;
	}
      
	.af-view h4 {
	  line-height: 32px;
	}
      
	.af-view blockquote {
	  font-size: 20px;
	  line-height: 30px;
	}
      
	.af-view .af-class-section {
	  padding: 72px 20px;
	}
      
	.af-view .af-class-section.af-class-title-section {
	  padding-top: 96px;
	  padding-bottom: 96px;
	}
      
	.af-view .af-class-section.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section.af-class-no-bottom-space.af-class-bg-gray-4 {
	  padding-top: 20px;
	}
      
	.af-view .af-class-section.af-class-section-small {
	  margin-bottom: 40px;
	  padding: 48px 40px;
	}
      
	.af-view .af-class-container-testimonials {
	  padding-right: 24px;
	  padding-left: 24px;
	}
      
	.af-view
	  .af-class-container-testimonials.af-class-post-breadcrumbs-container {
	  -webkit-box-pack: center;
	  -webkit-justify-content: center;
	  -ms-flex-pack: center;
	  justify-content: center;
	}
      
	.af-view .af-class-style-guide-container {
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	}
      
	.af-view .af-class-style-guide-title {
	  width: 100%;
	  margin-bottom: 36px;
	  padding-right: 0px;
	}
      
	.af-view .af-class-button.af-class-button-large {
	  padding-top: 13px;
	  padding-bottom: 13px;
	}
      
	.af-view
	  .af-class-button.af-class-button-outline.af-class-pricing-comparison-button {
	  margin-top: 16px;
	}
      
	.af-view .af-class-button.af-class-button-round.af-class-slider-button-left {
	  left: -12px;
	}
      
	.af-view .af-class-button.af-class-button-round.af-class-slider-button-right {
	  right: -12px;
	}
      
	.af-view .af-class-button.af-class-button-round.af-class-back-to-top-button {
	  display: none;
	}
      
	.af-view
	  .af-class-button.af-class-button-round.af-class-page-navigator-button {
	  position: relative;
	}
      
	.af-view .af-class-button.af-class-submit-button {
	  margin-top: 0px;
	}
      
	.af-view .af-class-form-input.af-class-form-input-large {
	  height: 60px;
	}
      
	.af-view .af-class-navbar-container.af-class-bg-transparent {
	  margin-bottom: 72px;
	}
      
	.af-view
	  .af-class-navbar-container.af-class-bg-transparent.af-class-sticky-top {
	  margin-bottom: -96px;
	}
      
	.af-view .af-class-navbar {
	  -webkit-box-pack: start;
	  -webkit-justify-content: flex-start;
	  -ms-flex-pack: start;
	  justify-content: flex-start;
	}
      
	.af-view .af-class-nav-menu {
	  display: block;
	  padding: 16px 24px;
	  border-style: solid;
	  border-width: 1px;
	  border-color: ${({ theme }) => theme.palette.others.j};
	  border-radius: 5px;
	  background-color: ${({ theme }) => theme.palette.others.r};
	}
      
	.af-view .af-class-nav-link {
	  padding: 6px 0px;
	  font-size: 16px;
	  line-height: 24px;
	}
      
	.af-view .af-class-dropdown-list.w--open {
	  -webkit-transform: none;
	  -ms-transform: none;
	  transform: none;
	}
      
	.af-view .af-class-footer {
	  padding-top: 72px;
	  text-align: center;
	}
      
	.af-view .af-class-footer-row {
	  margin-bottom: 48px;
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	  -webkit-box-align: center;
	  -webkit-align-items: center;
	  -ms-flex-align: center;
	  align-items: center;
	}
      
	.af-view .af-class-footer-info {
	  margin-bottom: 72px;
	  -webkit-box-align: center;
	  -webkit-align-items: center;
	  -ms-flex-align: center;
	  align-items: center;
	}
      
	.af-view .af-class-footer-menu-column {
	  margin-right: 24px;
	  margin-left: 24px;
	}
      
	.af-view .af-class-avatar.af-class-avatar-xlarge {
	  width: 120px;
	  height: 120px;
	}
      
	.af-view .af-class-avatar.af-class-avatar-xlarge.af-class-team-member-avatar {
	  margin-bottom: 12px;
	}
      
	.af-view .af-class-avatar.af-class-post-horizontal-avatar {
	  margin-right: 24px;
	}
      
	.af-view .af-class-avatar-group {
	  -webkit-flex-wrap: wrap;
	  -ms-flex-wrap: wrap;
	  flex-wrap: wrap;
	}
      
	.af-view .af-class-avatar-group-item {
	  border-width: 4px;
	}
      
	.af-view .af-class-card.af-class-shadow.af-class-career-description-card {
	  margin-bottom: 72px;
	}
      
	.af-view .af-class-card.af-class-featured-post-card {
	  -webkit-box-align: center;
	  -webkit-align-items: center;
	  -ms-flex-align: center;
	  align-items: center;
	}
      
	.af-view .af-class-card-body {
	  padding: 24px;
	}
      
	.af-view .af-class-card-body.af-class-card-horizontal-body {
	  padding-right: 48px;
	  padding-left: 36px;
	}
      
	.af-view .af-class-card-body.af-class-featured-post-card-body {
	  padding-right: 24px;
	  padding-left: 24px;
	}
      
	.af-view .af-class-card-body.af-class-category-card-body {
	  padding: 36px;
	}
      
	.af-view .af-class-text-white {
	  padding-right: 0px;
	  padding-left: 0px;
	}
      
	.af-view .af-class-navbar-logo {
	  -webkit-box-flex: 1;
	  -webkit-flex: 1;
	  -ms-flex: 1;
	  flex: 1;
	}
      
	.af-view .af-class-menu-button-cross {
	  display: none;
	}
      
	.af-view .af-class-pill.af-class-pill-notice.af-class-bg-gray-3 {
	  margin-bottom: 0px;
	}
      
	.af-view .af-class-pill.af-class-mobile-screenshot-pill {
	  left: -70%;
	}
      
	.af-view
	  .af-class-pill.af-class-mobile-screenshot-pill.af-class-mobile-screenshot-pill-2 {
	  right: -70%;
	}
      
	.af-view .af-class-horizontal-tabs {
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	}
      
	.af-view .af-class-vertical-tabs-menu {
	  margin-bottom: 24px;
	  -webkit-box-orient: horizontal;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: row;
	  -ms-flex-direction: row;
	  flex-direction: row;
	  -webkit-justify-content: space-around;
	  -ms-flex-pack: distribute;
	  justify-content: space-around;
	}
      
	.af-view .af-class-horizontal-tabs-content {
	  padding-left: 0px;
	}
      
	.af-view .af-class-horizontal-tabs-menu {
	  margin-bottom: 36px;
	}
      
	.af-view .af-class-icon-circle {
	  width: 96px;
	  height: 96px;
	}
      
	.af-view .af-class-icon-list.af-class-pricing-plan-list {
	  padding-right: 0px;
	  padding-left: 0px;
	}
      
	.af-view .af-class-announcement-bar.af-class-bg-primary-3 {
	  font-size: 14px;
	}
      
	.af-view .af-class-announcement-cross {
	  right: 24px;
	}
      
	.af-view .af-class-divider {
	  height: 6vw;
	}
      
	.af-view .af-class-divider.af-class-divider-top {
	  margin-bottom: 72px;
	}
      
	.af-view .af-class-divider.af-class-divider-bottom {
	  margin-top: 72px;
	}
      
	.af-view .af-class-divider.af-class-divider-bottom-large {
	  margin-top: 96px;
	}
      
	.af-view .af-class-divider.af-class-card-divider {
	  height: 36px;
	}
      
	.af-view .af-class-divider.af-class-divider-bottom-small {
	  margin-top: 48px;
	}
      
	.af-view .af-class-image-tile-gallery {
	  -webkit-box-align: center;
	  -webkit-align-items: center;
	  -ms-flex-align: center;
	  align-items: center;
	  grid-column-gap: 12px;
	}
      
	.af-view .af-class-image-gallery-left {
	  margin-top: -6px;
	  margin-bottom: -6px;
	}
      
	.af-view .af-class-image-gallery-row {
	  margin-right: -6px;
	  margin-left: -6px;
	}
      
	.af-view .af-class-image-gallery-link {
	  margin-right: 6px;
	  margin-left: 6px;
	}
      
	.af-view .af-class-section-title.af-class-add-top-space {
	  margin-top: 0px;
	}
      
	.af-view .af-class-team-members-grid {
	  grid-column-gap: 12px;
	  grid-row-gap: 36px;
	}
      
	.af-view .af-class-map-section {
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	}
      
	.af-view .af-class-map-section-left {
	  display: block;
	  width: 100%;
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	}
      
	.af-view .af-class-vertical-divider.af-class-horizontal-section-divider {
	  display: none;
	}
      
	.af-view .af-class-map-section-right {
	  width: 100%;
	  padding: 72px 24px;
	}
      
	.af-view .af-class-map {
	  width: 100%;
	}
      
	.af-view .af-class-paragraph-large.af-class-text-large {
	  margin-right: 0px;
	  text-align: center;
	}
      
	.af-view
	  .af-class-paragraph-large.af-class-text-large.af-class-case-study-subtitle {
	  display: none;
	}
      
	.af-view .af-class-map-section-content {
	  display: -webkit-box;
	  display: -webkit-flex;
	  display: -ms-flexbox;
	  display: flex;
	  max-width: none;
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	  -webkit-box-align: center;
	  -webkit-align-items: center;
	  -ms-flex-align: center;
	  align-items: center;
	  text-align: center;
	}
      
	.af-view .af-class-brand-list {
	  margin-right: -12px;
	  margin-left: -12px;
	}
      
	.af-view .af-class-brand-list-item {
	  margin-right: 12px;
	  margin-left: 12px;
	}
      
	.af-view .af-class-brand-image.af-class-case-study-logo {
	  margin-bottom: 24px;
	}
      
	.af-view .af-class-medium-heading {
	  margin-bottom: 16px;
	}
      
	.af-view .af-class-small-heading {
	  margin-bottom: 16px;
	}
      
	.af-view .af-class-title-section {
	  padding-top: 96px;
	  padding-bottom: 96px;
	}
      
	.af-view .af-class-content-pair-2 {
	  -webkit-flex-wrap: wrap;
	  -ms-flex-wrap: wrap;
	  flex-wrap: wrap;
	}
      
	.af-view .af-class-content-pair-text {
	  margin-bottom: 48px;
	  text-align: center;
	}
      
	.af-view .af-class-content-pair-image {
	  width: 100%;
	  max-width: 300px;
	  text-align: left;
	}
      
	.af-view .af-class-text-huge {
	  margin-bottom: 16px;
	  font-size: 54px;
	  line-height: 56px;
	}
      
	.af-view .af-class-large-metric-item {
	  padding-right: 0px;
	  padding-left: 0px;
	}
      
	.af-view .af-class-large-metric-item.af-class-text-left {
	  text-align: center;
	}
      
	.af-view .af-class-large-metric-item.af-class-large-metric-horizontal {
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	}
      
	.af-view .af-class-press-grid {
	  grid-column-gap: 12px;
	}
      
	.af-view .af-class-avatar-team-wrapper {
	  margin-bottom: 36px;
	}
      
	.af-view .af-class-hero-section-action {
	  margin-top: 36px;
	  -webkit-box-pack: center;
	  -webkit-justify-content: center;
	  -ms-flex-pack: center;
	  justify-content: center;
	}
      
	.af-view .af-class-content-pair-collage {
	  display: -webkit-box;
	  display: -webkit-flex;
	  display: -ms-flexbox;
	  display: flex;
	  overflow: scroll;
	  margin-bottom: 0px;
	  -webkit-box-align: center;
	  -webkit-align-items: center;
	  -ms-flex-align: center;
	  align-items: center;
	}
      
	.af-view .af-class-collage-image.af-class-collage-image-2 {
	  position: relative;
	  top: 0%;
	  right: 0%;
	}
      
	.af-view .af-class-collage-image.af-class-collage-image-3 {
	  position: relative;
	  left: 0%;
	  bottom: 0%;
	}
      
	.af-view .af-class-card-horizontal-image {
	  border-radius: 10px;
	}
      
	.af-view .af-class-card-slide {
	  padding-right: 12px;
	  padding-left: 12px;
	}
      
	.af-view .af-class-card-horizontal-image-wrapper {
	  display: -webkit-box;
	  display: -webkit-flex;
	  display: -ms-flexbox;
	  display: flex;
	  max-width: 30%;
	  padding-left: 24px;
	  -webkit-box-align: center;
	  -webkit-align-items: center;
	  -ms-flex-align: center;
	  align-items: center;
	}
      
	.af-view
	  .af-class-card-horizontal-image-wrapper.af-class-featured-post-image-wrapper {
	  width: 100%;
	  max-width: 100%;
	  padding-right: 24px;
	}
      
	.af-view
	  .af-class-card-horizontal-image-wrapper.af-class-featured-case-study-image-wrapper {
	  width: 100%;
	  max-width: 40%;
	  margin-top: 24px;
	  margin-bottom: 24px;
	}
      
	.af-view .af-class-career-list-wrapper {
	  margin-bottom: 12px;
	}
      
	.af-view .af-class-article blockquote {
	  margin-top: 36px;
	  margin-bottom: 36px;
	  padding-left: 36px;
	}
      
	.af-view .af-class-article.af-class-help-center-article {
	  margin-bottom: 48px;
	}
      
	.af-view .af-class-form-block {
	  margin-bottom: 0px;
	}
      
	.af-view .af-class-icon-cards-grid {
	  grid-column-gap: 12px;
	  grid-row-gap: 12px;
	}
      
	.af-view .af-class-pill-wrapper {
	  margin-bottom: -120px;
	}
      
	.af-view .af-class-horizontal-rule {
	  margin-top: 72px;
	}
      
	.af-view .af-class-pricing-plans-grid {
	  grid-column-gap: 12px;
	}
      
	.af-view .af-class-three-up-grid {
	  padding-right: 40px;
	  padding-left: 40px;
	}
      
	.af-view .af-class-three-up-grid-item {
	  padding-right: 0px;
	  padding-left: 0px;
	}
      
	.af-view .af-class-faq-group {
	  margin-bottom: 12px;
	}
      
	.af-view .af-class-pricing-comparison-wrapper {
	  margin-bottom: 36px;
	}
      
	.af-view .af-class-pricing-comparison-plan {
	  width: 33.333333333%;
	}
      
	.af-view .af-class-large-heading {
	  margin-bottom: 24px;
	  font-size: 40px;
	  line-height: 48px;
	}
      
	.af-view .af-class-pricing-comparison-section-title {
	  margin-top: 48px;
	  text-align: center;
	}
      
	.af-view .af-class-pricing-comparison-row {
	  -webkit-flex-wrap: wrap;
	  -ms-flex-wrap: wrap;
	  flex-wrap: wrap;
	}
      
	.af-view .af-class-pricing-comparison-cell {
	  -webkit-flex-basis: 33.33333333%;
	  -ms-flex-preferred-size: 33.33333333%;
	  flex-basis: 33.33333333%;
	}
      
	.af-view
	  .af-class-pricing-comparison-cell.af-class-pricing-comparison-heading-cell {
	  -webkit-box-pack: center;
	  -webkit-justify-content: center;
	  -ms-flex-pack: center;
	  justify-content: center;
	  -webkit-box-flex: 0;
	  -webkit-flex: 0 100%;
	  -ms-flex: 0 100%;
	  flex: 0 100%;
	  border-bottom: 1px solid ${({ theme }) => theme.palette.others.j};
	  border-left-style: solid;
	}
      
	.af-view .af-class-pricing-comparison-button {
	  margin-top: 16px;
	}
      
	.af-view .af-class-split-layout {
	  height: auto;
	  min-height: 100vh;
	}
      
	.af-view .af-class-card-footer {
	  padding-right: 24px;
	  padding-left: 24px;
	}
      
	.af-view .af-class-blog-grid {
	  -ms-grid-columns: 1fr 1fr;
	  grid-template-columns: 1fr 1fr;
	}
      
	.af-view .af-class-post-title-wrapper {
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: reverse;
	  -webkit-flex-direction: column-reverse;
	  -ms-flex-direction: column-reverse;
	  flex-direction: column-reverse;
	  -webkit-box-align: center;
	  -webkit-align-items: center;
	  -ms-flex-align: center;
	  align-items: center;
	  text-align: center;
	}
      
	.af-view .af-class-post-title-image {
	  max-width: 100%;
	}
      
	.af-view .af-class-post-title-content {
	  margin-bottom: 36px;
	  margin-left: 0px;
	  padding-top: 0px;
	  -webkit-box-align: center;
	  -webkit-align-items: center;
	  -ms-flex-align: center;
	  align-items: center;
	}
      
	.af-view .af-class-pull-divider-up {
	  margin-bottom: -6vw;
	}
      
	.af-view .af-class-post-share-wrapper {
	  margin-top: 48px;
	}
      
	.af-view .af-class-wide-post-wrapper {
	  margin-bottom: 36px;
	}
      
	.af-view .af-class-featured-post-meta {
	  margin-top: 24px;
	}
      
	.af-view .af-class-horizontal-blog-list {
	  grid-row-gap: 24px;
	}
      
	.af-view .af-class-post-horizontal-wrapper {
	  margin-left: 0px;
	  padding: 6px;
	}
      
	.af-view .af-class-content-pair-graphic {
	  display: none;
	  width: 75%;
	}
      
	.af-view .af-class-content-pair-graphic.af-class-content-pair-graphic-narrow {
	  width: 50%;
	}
      
	.af-view .af-class-content-pair-card {
	  margin-top: 24px;
	}
      
	.af-view .af-class-icon-features-heading {
	  margin-bottom: 36px;
	}
      
	.af-view .af-class-faq-grid {
	  margin-top: 48px;
	  margin-bottom: 36px;
	}
      
	.af-view .af-class-cta-bg-graphic {
	  max-width: 30%;
	}
      
	.af-view .af-class-pill-below {
	  margin-bottom: 36px;
	}
      
	.af-view .af-class-hero-blockquote-wrapper {
	  margin-right: auto;
	  margin-left: auto;
	}
      
	.af-view .af-class-mobile-screenshot-wrapper {
	  max-width: 35vw;
	}
      
	.af-view .af-class-mobile-screenshot-text {
	  max-width: 320px;
	}
      
	.af-view .af-class-screenshot.af-class-content-pair-screenshot {
	  margin-left: 0px;
	}
      
	.af-view .af-class-badge-wrapper {
	  -webkit-box-align: center;
	  -webkit-align-items: center;
	  -ms-flex-align: center;
	  align-items: center;
	}
      
	.af-view .af-class-customer-quotes-wrapper {
	  grid-row-gap: 24px;
	}
      
	.af-view .af-class-qr-code-image {
	  margin-top: 36px;
	  margin-bottom: 36px;
	}
      
	.af-view .af-class-large-metric-horizontal-text {
	  margin-top: -12px;
	  margin-left: 0px;
	}
      
	.af-view .af-class-case-study-meta-list {
	  margin-right: 24px;
	}
      
	.af-view .af-class-page-demos-wrapper {
	  padding: 48px;
	}
      
	.af-view .af-class-nav-link-white {
	  color: ${({ theme }) => theme.palette.others.f};
	}
      
	.af-view .af-class-decoration.af-class-top-right {
	  display: none;
	}
      
	.af-view .af-class-search-form {
	  margin-top: 36px;
	}
      
	.af-view .af-class-sidebar-layout {
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	}
      
	.af-view .af-class-sidebar-aside-content {
	  width: 100%;
	  max-width: none;
	}
      
	.af-view .af-class-sidebar-main-content {
	  padding-right: 0px;
	}
      
	.af-view .af-class-category-icon {
	  margin-right: 36px;
	}
      
	.af-view .af-class-sidebar-main-content-header {
	  margin-bottom: 36px;
	  padding-bottom: 36px;
	}
      
	.af-view .af-class-heading-2 {
	  margin-right: 0px;
	  text-align: center;
	}
      
	.af-view .af-class-icon-features-heading-copy {
	  margin-bottom: 36px;
	}
      
	.af-view .af-class-content-pair-2-copy {
	  margin-left: 0px;
	  -webkit-box-orient: horizontal;
	  -webkit-box-direction: reverse;
	  -webkit-flex-direction: row-reverse;
	  -ms-flex-direction: row-reverse;
	  flex-direction: row-reverse;
	  -webkit-box-pack: justify;
	  -webkit-justify-content: space-between;
	  -ms-flex-pack: justify;
	  justify-content: space-between;
	  -webkit-flex-wrap: wrap;
	  -ms-flex-wrap: wrap;
	  flex-wrap: wrap;
	  -webkit-box-align: center;
	  -webkit-align-items: center;
	  -ms-flex-align: center;
	  align-items: center;
	}
      
	.af-view .af-class-content-pair-graphic-copy {
	  display: none;
	  width: 75%;
	}
      
	.af-view
	  .af-class-content-pair-graphic-copy.af-class-content-pair-graphic-narrow {
	  width: 50%;
	}
      
	.af-view .af-class-content-pair-text-copy {
	  margin-bottom: 48px;
	  margin-left: 0px;
	  -webkit-align-self: auto;
	  -ms-flex-item-align: auto;
	  -ms-grid-row-align: auto;
	  align-self: auto;
	  -webkit-box-flex: 0;
	  -webkit-flex: 0 auto;
	  -ms-flex: 0 auto;
	  flex: 0 auto;
	  text-align: center;
	}
      
	.af-view .af-class-icon-feature-content-copy {
	  text-align: left;
	}
      
	.af-view .af-class-div-block {
	  display: -webkit-box;
	  display: -webkit-flex;
	  display: -ms-flexbox;
	  display: flex;
	  -webkit-box-orient: horizontal;
	  -webkit-box-direction: reverse;
	  -webkit-flex-direction: row-reverse;
	  -ms-flex-direction: row-reverse;
	  flex-direction: row-reverse;
	  -webkit-box-pack: end;
	  -webkit-justify-content: flex-end;
	  -ms-flex-pack: end;
	  justify-content: flex-end;
	}
      
	.af-view .af-class-div-block-2 {
	  -webkit-box-orient: horizontal;
	  -webkit-box-direction: reverse;
	  -webkit-flex-direction: row-reverse;
	  -ms-flex-direction: row-reverse;
	  flex-direction: row-reverse;
	  -webkit-box-pack: end;
	  -webkit-justify-content: flex-end;
	  -ms-flex-pack: end;
	  justify-content: flex-end;
	  text-align: center;
	}
      
	.af-view .af-class-div-block-3 {
	  -webkit-box-orient: horizontal;
	  -webkit-box-direction: reverse;
	  -webkit-flex-direction: row-reverse;
	  -ms-flex-direction: row-reverse;
	  flex-direction: row-reverse;
	  -webkit-box-pack: end;
	  -webkit-justify-content: flex-end;
	  -ms-flex-pack: end;
	  justify-content: flex-end;
	}
      
	.af-view .af-class-heading-13 {
	  text-align: left;
	}
      
	.af-view .af-class-section-copy {
	  padding: 72px 20px;
	}
      
	.af-view .af-class-section-copy.af-class-title-section {
	  padding-top: 96px;
	  padding-bottom: 96px;
	}
      
	.af-view .af-class-section-copy.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-copy.af-class-section-small {
	  padding-top: 48px;
	  padding-bottom: 48px;
	}
      
	.af-view .af-class-section-copy-copy {
	  padding: 72px 20px;
	}
      
	.af-view .af-class-section-copy-copy.af-class-title-section {
	  padding-top: 96px;
	  padding-bottom: 96px;
	}
      
	.af-view
	  .af-class-section-copy-copy.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-copy-copy.af-class-section-small {
	  padding-top: 48px;
	  padding-bottom: 48px;
	}
      
	.af-view .af-class-section-copy-copy.af-class-section-small-copy {
	  padding-top: 48px;
	  padding-bottom: 48px;
	}
      
	.af-view .af-class-section-copy-copy.af-class-section-small-copy-copy {
	  padding-top: 48px;
	  padding-bottom: 48px;
	}
      
	.af-view .af-class-heading-2-copy {
	  margin-right: 0px;
	  padding-right: 0px;
	  text-align: center;
	}
      
	.af-view
	  .af-class-paragraph-large-copy.af-class-text-large.af-class-case-study-subtitle {
	  display: none;
	}
      
	.af-view .af-class-paragraph-large-copy.af-class-text-large-copy {
	  margin-right: 0px;
	  padding-right: 0px;
	}
      
	.af-view
	  .af-class-paragraph-large-copy.af-class-text-large-copy.af-class-case-study-subtitle {
	  display: none;
	}
      
	.af-view .af-class-heading-6-2 {
	  width: auto;
	}
      
	.af-view .af-class-columns-2 {
	  width: auto;
	  margin-top: 38px;
	}
      
	.af-view .af-class-text-block-16 {
	  font-size: 16px;
	}
      
	.af-view .af-class-heading-16 {
	  width: auto;
	}
      
	.af-view .af-class-text-block-18 {
	  width: auto;
	}
      
	.af-view .af-class-section-copy {
	  padding: 72px 20px;
	}
      
	.af-view .af-class-section-copy.af-class-title-section {
	  padding-top: 96px;
	  padding-bottom: 96px;
	}
      
	.af-view .af-class-section-copy.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-copy.af-class-section-small {
	  padding-top: 48px;
	  padding-bottom: 48px;
	}
      
	.af-view .af-class-section-copy-2 {
	  padding: 72px 20px;
	}
      
	.af-view .af-class-section-copy-2.af-class-title-section {
	  padding-top: 96px;
	  padding-bottom: 96px;
	}
      
	.af-view .af-class-section-copy-2.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-copy-2.af-class-section-small {
	  padding-top: 48px;
	  padding-bottom: 48px;
	}
      
	.af-view .af-class-div-block-8 {
	  display: block;
	}
      
	.af-view .af-class-container-testimonials-header {
	  padding-right: 24px;
	  padding-left: 24px;
	}
      
	.af-view
	  .af-class-container-testimonials-header.af-class-post-breadcrumbs-container {
	  -webkit-box-pack: center;
	  -webkit-justify-content: center;
	  -ms-flex-pack: center;
	  justify-content: center;
	}
      
	.af-view .af-class-container-testimonials-pricing {
	  padding-right: 24px;
	  padding-left: 24px;
	}
      
	.af-view
	  .af-class-container-testimonials-pricing.af-class-post-breadcrumbs-container {
	  -webkit-box-pack: center;
	  -webkit-justify-content: center;
	  -ms-flex-pack: center;
	  justify-content: center;
	}
      
	.af-view .af-class-container-testimonials-pricing-title {
	  padding-right: 24px;
	  padding-left: 24px;
	}
      
	.af-view
	  .af-class-container-testimonials-pricing-title.af-class-post-breadcrumbs-container {
	  -webkit-box-pack: center;
	  -webkit-justify-content: center;
	  -ms-flex-pack: center;
	  justify-content: center;
	}
      
	.af-view .af-class-section-pricing-testimonial {
	  padding: 72px 20px;
	}
      
	.af-view .af-class-section-pricing-testimonial.af-class-title-section {
	  padding-top: 96px;
	  padding-bottom: 96px;
	}
      
	.af-view
	  .af-class-section-pricing-testimonial.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-pricing-testimonial.af-class-section-small {
	  padding-top: 48px;
	  padding-bottom: 48px;
	}
      
	.af-view .af-class-section-copy-2-copy {
	  padding: 72px 20px;
	}
      
	.af-view .af-class-section-copy-2-copy.af-class-title-section {
	  padding-top: 96px;
	  padding-bottom: 96px;
	}
      
	.af-view
	  .af-class-section-copy-2-copy.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-copy-2-copy.af-class-section-small {
	  padding-top: 48px;
	  padding-bottom: 48px;
	}
      
	.af-view .af-class-nav-link-menu {
	  display: block;
	}
      
	.af-view .af-class-nav-link-menu.w--current {
	  display: block;
	}
      
	.af-view .af-class-section-feature-step-3 {
	  padding: 72px 20px;
	}
      
	.af-view .af-class-section-feature-step-3.af-class-title-section {
	  padding-top: 96px;
	  padding-bottom: 96px;
	}
      
	.af-view
	  .af-class-section-feature-step-3.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-feature-step-3.af-class-bg-white-step-3 {
	  padding-right: 20px;
	  padding-left: 20px;
	}
      
	.af-view .af-class-section-feature-step-3.af-class-section-small {
	  padding-top: 48px;
	  padding-bottom: 48px;
	}
      
	.af-view .af-class-large-heading-copy {
	  margin-bottom: 24px;
	  font-size: 40px;
	  line-height: 48px;
	}
      
	.af-view .af-class-image-9 {
	  display: inline-block;
	  max-width: 70%;
	}
      
	.af-view .af-class-section-copy-2-copy-copy {
	  padding: 72px 0px 0px;
	}
      
	.af-view .af-class-section-copy-2-copy-copy.af-class-title-section {
	  padding-top: 96px;
	  padding-bottom: 96px;
	}
      
	.af-view
	  .af-class-section-copy-2-copy-copy.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-copy-2-copy-copy.af-class-section-small {
	  padding-top: 48px;
	  padding-bottom: 48px;
	}
      
	.af-view .af-class-section-copy {
	  padding: 72px 20px;
	}
      
	.af-view .af-class-section-copy.af-class-title-section {
	  padding-top: 96px;
	  padding-bottom: 96px;
	}
      
	.af-view .af-class-section-copy.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-copy.af-class-section-small {
	  margin-bottom: 40px;
	  padding-top: 48px;
	  padding-bottom: 48px;
	}
      
	.af-view .af-class-section-copy-copy {
	  padding: 72px 20px;
	}
      
	.af-view .af-class-section-copy-copy.af-class-title-section {
	  padding-top: 96px;
	  padding-bottom: 96px;
	}
      
	.af-view
	  .af-class-section-copy-copy.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-copy-copy.af-class-section-small {
	  margin-bottom: 40px;
	  padding-top: 48px;
	  padding-bottom: 48px;
	}
      
	.af-view .af-class-section-copy-copy.af-class-bg-gray-4-copy2 {
	  display: block;
	  margin-right: auto;
	  margin-left: auto;
	  padding-right: 20px;
	  padding-left: 20px;
	  -webkit-box-align: center;
	  -webkit-align-items: center;
	  -ms-flex-align: center;
	  align-items: center;
	  background-color: ${({ theme }) => theme.palette.others.r};
	}
      
	.af-view .af-class-pill-wrapper-copy {
	  margin-top: 40px;
	  margin-bottom: -59px;
	}
      
	.af-view .af-class-button-copy.af-class-button-large {
	  padding-top: 13px;
	  padding-bottom: 13px;
	}
      
	.af-view
	  .af-class-button-copy.af-class-button-outline.af-class-pricing-comparison-button {
	  margin-top: 16px;
	}
      
	.af-view
	  .af-class-button-copy.af-class-button-round.af-class-slider-button-left {
	  left: -12px;
	}
      
	.af-view
	  .af-class-button-copy.af-class-button-round.af-class-slider-button-right {
	  right: -12px;
	}
      
	.af-view
	  .af-class-button-copy.af-class-button-round.af-class-back-to-top-button {
	  display: none;
	}
      
	.af-view
	  .af-class-button-copy.af-class-button-round.af-class-page-navigator-button {
	  position: relative;
	}
      
	.af-view .af-class-button-copy.af-class-submit-button {
	  margin-top: 0px;
	}
      
	.af-view .af-class-button-copy.af-class-button-large-copy {
	  padding-top: 13px;
	  padding-bottom: 13px;
	}
      
	.af-view .af-class-faq-group-conseils {
	  margin-bottom: 12px;
	}
      
	.af-view .af-class-section-conseils {
	  padding: 72px 20px;
	}
      
	.af-view .af-class-section-conseils.af-class-title-section {
	  padding-top: 96px;
	  padding-bottom: 96px;
	}
      
	.af-view
	  .af-class-section-conseils.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view
	  .af-class-section-conseils.af-class-no-bottom-space.af-class-bg-gray-4 {
	  padding-top: 20px;
	}
      
	.af-view .af-class-section-conseils.af-class-section-small {
	  margin-bottom: 40px;
	  padding: 48px 40px;
	}
      
	.af-view .af-class-icon-list-conseils.af-class-pricing-plan-list {
	  padding-right: 0px;
	  padding-left: 0px;
	}
      
	.af-view .af-class-icon-list-conseils.af-class-pricing-plan-list-conseils {
	  padding-right: 0px;
	  padding-left: 0px;
	}
      
	.af-view .af-class-paragraph-large-ml.af-class-text-large {
	  margin-right: 0px;
	  text-align: center;
	}
      
	.af-view
	  .af-class-paragraph-large-ml.af-class-text-large.af-class-case-study-subtitle {
	  display: none;
	}
      
	.af-view .af-class-paragraph-large-ml.af-class-text-large-ml {
	  margin-right: 0px;
	  text-align: center;
	}
      
	.af-view
	  .af-class-paragraph-large-ml.af-class-text-large-ml.af-class-case-study-subtitle {
	  display: none;
	}
      
	.af-view .af-class-content-pair-image-ml {
	  width: 100%;
	  max-width: 300px;
	}
      
	.af-view .af-class-paragraph-large-cc.af-class-text-large {
	  margin-right: 0px;
	  text-align: center;
	}
      
	.af-view
	  .af-class-paragraph-large-cc.af-class-text-large.af-class-case-study-subtitle {
	  display: none;
	}
      
	.af-view .af-class-paragraph-large-cc.af-class-text-large-cc {
	  margin-right: 0px;
	  text-align: center;
	}
      
	.af-view
	  .af-class-paragraph-large-cc.af-class-text-large-cc.af-class-case-study-subtitle {
	  display: none;
	}
      
	.af-view .af-class-content-pair-image-cc {
	  width: 100%;
	  max-width: 300px;
	}
      
	.af-view .af-class-three-up-grid-pricing {
	  padding-right: 40px;
	  padding-left: 40px;
	}
      
	.af-view .af-class-hero-section-action-sales {
	  margin-top: 36px;
	  -webkit-box-pack: center;
	  -webkit-justify-content: center;
	  -ms-flex-pack: center;
	  justify-content: center;
	}
      
	.af-view .af-class-section-sales {
	  padding: 72px 20px;
	}
      
	.af-view .af-class-section-sales.af-class-title-section {
	  padding-top: 96px;
	  padding-bottom: 96px;
	}
      
	.af-view .af-class-section-sales.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-sales.af-class-no-bottom-space.af-class-bg-gray-4 {
	  padding-top: 20px;
	}
      
	.af-view .af-class-section-sales.af-class-section-small {
	  margin-bottom: 40px;
	  padding: 48px 40px;
	}
      
	.af-view .af-class-section-sales.af-class-section-small-sales {
	  margin-bottom: 40px;
	  padding: 48px 40px;
	}
      
	.af-view .af-class-heading-2-home {
	  margin-right: 0px;
	  text-align: center;
	}
      
	.af-view .af-class-title-section-conseil {
	  padding-top: 96px;
	  padding-bottom: 96px;
	}
      
	.af-view .af-class-divider-conseil {
	  height: 6vw;
	}
      
	.af-view .af-class-divider-conseil.af-class-divider-top {
	  margin-bottom: 72px;
	}
      
	.af-view .af-class-divider-conseil.af-class-divider-bottom {
	  margin-top: 72px;
	}
      
	.af-view .af-class-divider-conseil.af-class-divider-bottom-large {
	  margin-top: 96px;
	}
      
	.af-view .af-class-divider-conseil.af-class-card-divider {
	  height: 36px;
	}
      
	.af-view .af-class-divider-conseil.af-class-divider-bottom-small {
	  margin-top: 48px;
	}
      
	.af-view .af-class-text-white-conseil {
	  padding-right: 0px;
	  padding-left: 0px;
	}
      
	.af-view .af-class-title-section-ml {
	  padding-top: 96px;
	  padding-bottom: 96px;
	}
      
	.af-view .af-class-title-section-guide {
	  padding-top: 96px;
	  padding-bottom: 96px;
	}
      
	.af-view .af-class-title-section-seo {
	  padding-top: 96px;
	  padding-bottom: 96px;
	}
      
	.af-view .af-class-content-pair-text-about {
	  width: 100%;
	  margin-bottom: 48px;
	  text-align: center;
	}
      
	.af-view .af-class-content-pair-image-about {
	  display: none;
	  width: 100%;
	  max-width: 300px;
	  text-align: left;
	}
      
	.af-view .af-class-content-pair-2-about {
	  display: block;
	  -webkit-flex-wrap: wrap;
	  -ms-flex-wrap: wrap;
	  flex-wrap: wrap;
	}
      
	.af-view .af-class-text-huge-about {
	  margin-bottom: 16px;
	  font-size: 54px;
	  line-height: 56px;
	}
      
	.af-view .af-class-section-about {
	  padding: 72px 20px;
	}
      
	.af-view .af-class-section-about.af-class-title-section {
	  padding-top: 96px;
	  padding-bottom: 96px;
	}
      
	.af-view .af-class-section-about.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-about.af-class-no-bottom-space.af-class-bg-gray-4 {
	  padding-top: 20px;
	}
      
	.af-view .af-class-section-about.af-class-section-small {
	  margin-bottom: 40px;
	  padding: 48px 40px;
	}
      
	.af-view .af-class-section-avatar {
	  padding: 72px 20px;
	}
      
	.af-view .af-class-section-avatar.af-class-title-section {
	  padding-top: 96px;
	  padding-bottom: 96px;
	}
      
	.af-view .af-class-section-avatar.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view
	  .af-class-section-avatar.af-class-no-bottom-space.af-class-bg-gray-4 {
	  padding-top: 20px;
	}
      
	.af-view .af-class-section-avatar.af-class-section-small {
	  margin-bottom: 40px;
	  padding: 48px 40px;
	}
      }
      
      @media screen and (max-width: 767px) {
	.af-view h1 {
	  margin-bottom: 16px;
	  font-size: 40px;
	  line-height: 48px;
	}
      
	.af-view .af-class-section {
	  padding-top: 38px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section.af-class-title-section {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 60px;
	  padding-bottom: 60px;
	}
      
	.af-view
	  .af-class-section.af-class-no-top-space.af-class-pricing-plans-section {
	  margin-top: 0px;
	  padding-top: 72px;
	}
      
	.af-view .af-class-section.af-class-section-small {
	  padding: 36px 20px;
	}
      
	.af-view .af-class-section.af-class-utility-page-content {
	  padding-right: 12px;
	  padding-left: 12px;
	  -webkit-box-pack: start;
	  -webkit-justify-content: flex-start;
	  -ms-flex-pack: start;
	  justify-content: flex-start;
	}
      
	.af-view
	  .af-class-section.af-class-no-top-space-copy.af-class-pricing-plans-section {
	  margin-top: 0px;
	  padding-top: 72px;
	}
      
	.af-view
	  .af-class-container-testimonials.af-class-announcement-bar-container {
	  padding-right: 48px;
	}
      
	.af-view
	  .af-class-container-testimonials.af-class-text-center.af-class-hero-container-nav-above {
	  margin-top: 80px;
	}
      
	.af-view .af-class-container-testimonials.af-class-case-study-container {
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	}
      
	.af-view .af-class-style-guide-row {
	  margin-bottom: 24px;
	}
      
	.af-view .af-class-style-guide-row.af-class-bg-primary-3 {
	  padding-bottom: 12px;
	}
      
	.af-view .af-class-style-guide-color-container {
	  margin-right: 12px;
	}
      
	.af-view .af-class-text-small.af-class-text-spacer {
	  line-height: 20px;
	}
      
	.af-view .af-class-style-guide-color {
	  width: 72px;
	  height: 72px;
	}
      
	.af-view .af-class-h6-small.af-class-style-guide-subheading {
	  margin-bottom: 16px;
	}
      
	.af-view .af-class-h6-small.af-class-career-heading {
	  width: 50%;
	}
      
	.af-view .af-class-text-large {
	  font-size: 16px;
	  line-height: 24px;
	}
      
	.af-view .af-class-text-small-caps {
	  font-size: 13px;
	}
      
	.af-view .af-class-style-guide-icon {
	  margin-right: 12px;
	  margin-bottom: 12px;
	}
      
	.af-view
	  .af-class-button.af-class-button-outline.af-class-pricing-comparison-button {
	  margin-top: 12px;
	}
      
	.af-view .af-class-button.af-class-navbar-button {
	  display: none;
	}
      
	.af-view
	  .af-class-form-input.af-class-form-input-large.af-class-subscribe-form-input {
	  margin-right: 8px;
	}
      
	.af-view
	  .af-class-navbar-container.af-class-bg-transparent.af-class-sticky-top {
	  margin-bottom: -80px;
	}
      
	.af-view .af-class-navbar {
	  padding-top: 16px;
	  padding-bottom: 16px;
	}
      
	.af-view .af-class-footer {
	  padding-top: 72px;
	  padding-bottom: 36px;
	}
      
	.af-view .af-class-footer-row {
	  margin-bottom: 24px;
	}
      
	.af-view .af-class-footer-info {
	  margin-bottom: 48px;
	}
      
	.af-view .af-class-footer-menus-row {
	  display: block;
	  -webkit-flex-wrap: wrap;
	  -ms-flex-wrap: wrap;
	  flex-wrap: wrap;
	  -webkit-box-align: start;
	  -webkit-align-items: flex-start;
	  -ms-flex-align: start;
	  align-items: flex-start;
	  -webkit-box-flex: 0;
	  -webkit-flex: 0 auto;
	  -ms-flex: 0 auto;
	  flex: 0 auto;
	}
      
	.af-view .af-class-footer-menu-column {
	  width: 100%;
	  margin-right: 0px;
	  margin-bottom: 24px;
	  margin-left: 0px;
	  font-family: Lato, sans-serif;
	}
      
	.af-view .af-class-style-guide-item.af-class-narrow {
	  max-width: 75%;
	}
      
	.af-view .af-class-avatar {
	  width: 60px;
	  height: 60px;
	}
      
	.af-view .af-class-avatar.af-class-avatar-xlarge {
	  width: 96px;
	  height: 96px;
	}
      
	.af-view .af-class-avatar.af-class-avatar-large {
	  width: 72px;
	  height: 72px;
	}
      
	.af-view
	  .af-class-avatar.af-class-avatar-large.af-class-customer-quote-avatar {
	  margin-right: 24px;
	}
      
	.af-view .af-class-avatar-group-item {
	  border-width: 3px;
	}
      
	.af-view .af-class-card {
	  border-radius: 5px;
	}
      
	.af-view .af-class-card.af-class-shadow.af-class-career-description-card {
	  margin-bottom: 48px;
	}
      
	.af-view .af-class-card.af-class-logo-card {
	  min-height: 96px;
	}
      
	.af-view .af-class-card.af-class-card-horizontal.af-class-shadow-large {
	  margin-bottom: 20px;
	}
      
	.af-view .af-class-card.af-class-pricing-comparison-card {
	  border-style: none;
	}
      
	.af-view .af-class-card.af-class-featured-post-card {
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	}
      
	.af-view .af-class-card-body {
	  padding: 24px;
	}
      
	.af-view .af-class-card-body.af-class-career-card-body {
	  padding: 16px;
	}
      
	.af-view .af-class-card-body.af-class-pricing-comparison-card-body {
	  padding: 0px 3px;
	}
      
	.af-view .af-class-card-body.af-class-category-card-body {
	  padding: 24px;
	}
      
	.af-view .af-class-nav-buttons-container {
	  display: -webkit-box;
	  display: -webkit-flex;
	  display: -ms-flexbox;
	  display: flex;
	}
      
	.af-view .af-class-pill {
	  padding: 6px 12px 6px 6px;
	}
      
	.af-view .af-class-pill-heading {
	  font-size: 16px;
	}
      
	.af-view .af-class-expandable-title {
	  padding: 16px;
	}
      
	.af-view .af-class-vertical-tabs-menu {
	  overflow: scroll;
	  max-width: 100%;
	  -webkit-box-pack: start;
	  -webkit-justify-content: flex-start;
	  -ms-flex-pack: start;
	  justify-content: flex-start;
	}
      
	.af-view .af-class-tab-button {
	  padding-right: 16px;
	  padding-left: 16px;
	  -webkit-box-flex: 0;
	  -webkit-flex: 0 0 auto;
	  -ms-flex: 0 0 auto;
	  flex: 0 0 auto;
	  border-radius: 5px;
	}
      
	.af-view .af-class-tab-icon {
	  max-height: 36px;
	  margin-right: 12px;
	}
      
	.af-view .af-class-horizontal-tabs-menu {
	  overflow: scroll;
	  max-width: 100%;
	  margin-top: 0px;
	  margin-bottom: 24px;
	}
      
	.af-view .af-class-tab-link {
	  -webkit-box-flex: 0;
	  -webkit-flex: 0 0 auto;
	  -ms-flex: 0 0 auto;
	  flex: 0 0 auto;
	  border-bottom-style: none;
	}
      
	.af-view .af-class-tab-link.w--current {
	  color: ${({ theme }) => theme.palette.others.h};
	}
      
	.af-view .af-class-icon-feature-content {
	  margin-left: 16px;
	}
      
	.af-view .af-class-icon-circle {
	  width: 72px;
	  height: 72px;
	}
      
	.af-view .af-class-icon-list.af-class-pricing-plan-list {
	  margin-top: 24px;
	  margin-bottom: 24px;
	}
      
	.af-view .af-class-announcement-bar.af-class-bg-primary-3 {
	  line-height: 20px;
	}
      
	.af-view .af-class-text-row {
	  -webkit-box-align: start;
	  -webkit-align-items: flex-start;
	  -ms-flex-align: start;
	  align-items: flex-start;
	}
      
	.af-view .af-class-section-subtitle.af-class-text-large {
	  margin-top: 30px;
	}
      
	.af-view .af-class-divider {
	  height: 7vw;
	}
      
	.af-view .af-class-divider.af-class-divider-top {
	  margin-bottom: 72px;
	}
      
	.af-view .af-class-divider.af-class-divider-bottom {
	  margin-top: 72px;
	}
      
	.af-view .af-class-divider.af-class-divider-bottom-large {
	  margin-top: 72px;
	}
      
	.af-view .af-class-divider.af-class-card-divider {
	  height: 24px;
	}
      
	.af-view .af-class-divider.af-class-divider-bottom-small {
	  margin-top: 24px;
	}
      
	.af-view .af-class-image-gallery-image {
	  border-radius: 5px;
	}
      
	.af-view .af-class-image-gallery-link.af-class-image-gallery-link-top {
	  margin-bottom: 16px;
	}
      
	.af-view .af-class-section-title {
	  margin-bottom: 48px;
	}
      
	.af-view .af-class-team-members-grid {
	  grid-row-gap: 24px;
	  -ms-grid-columns: 1fr 1fr;
	  grid-template-columns: 1fr 1fr;
	}
      
	.af-view .af-class-map-section-right {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-paragraph-large {
	  margin-bottom: 16px;
	}
      
	.af-view .af-class-brand-image.af-class-case-study-logo {
	  margin-bottom: 16px;
	}
      
	.af-view .af-class-small-heading.af-class-metric-heading {
	  margin-bottom: 4px;
	  font-size: 30px;
	}
      
	.af-view .af-class-title-section {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-content-pair-2.af-class-content-pair-reverse {
	  display: block;
	  -webkit-box-align: center;
	  -webkit-align-items: center;
	  -ms-flex-align: center;
	  align-items: center;
	  -webkit-align-content: end;
	  -ms-flex-line-pack: end;
	  align-content: end;
	  text-align: center;
	}
      
	.af-view .af-class-content-pair-text {
	  margin-bottom: 24px;
	}
      
	.af-view .af-class-content-pair-image {
	  max-width: 60%;
	}
      
	.af-view .af-class-large-metrics.af-class-text-center {
	  grid-column-gap: 12px;
	}
      
	.af-view .af-class-press-grid {
	  -ms-grid-columns: 1fr;
	  grid-template-columns: 1fr;
	}
      
	.af-view .af-class-logo-card {
	  border-radius: 5px;
	}
      
	.af-view .af-class-press-quote-wrapper {
	  -webkit-box-align: center;
	  -webkit-align-items: center;
	  -ms-flex-align: center;
	  align-items: center;
	}
      
	.af-view .af-class-avatar-team-wrapper {
	  margin-bottom: 24px;
	}
      
	.af-view .af-class-bg-image.af-class-account-page-image {
	  background-position: 50% 0%;
	}
      
	.af-view .af-class-bg-image.af-class-sign-up-page-image {
	  background-position: 50% 100%;
	}
      
	.af-view .af-class-hero-section-action {
	  margin-top: 24px;
	}
      
	.af-view .af-class-card-horizontal-image.af-class-featured-post-image {
	  border-radius: 5px 5px 0px 0px;
	}
      
	.af-view .af-class-card-slide {
	  padding-right: 0px;
	  padding-left: 0px;
	}
      
	.af-view .af-class-card-slide-mask {
	  overflow: hidden;
	}
      
	.af-view
	  .af-class-card-horizontal-image-wrapper.af-class-featured-post-image-wrapper {
	  padding-right: 0px;
	  padding-left: 0px;
	}
      
	.af-view .af-class-icon-pill-group-item {
	  margin: 3px;
	}
      
	.af-view .af-class-career-list-titles {
	  display: none;
	}
      
	.af-view .af-class-career-location {
	  -webkit-box-flex: 1;
	  -webkit-flex: 1;
	  -ms-flex: 1;
	  flex: 1;
	}
      
	.af-view .af-class-letterhead {
	  margin-bottom: 24px;
	  padding-bottom: 24px;
	}
      
	.af-view .af-class-article blockquote {
	  margin-top: 24px;
	  margin-bottom: 24px;
	  padding-left: 24px;
	}
      
	.af-view .af-class-article img {
	  border-radius: 5px;
	}
      
	.af-view .af-class-article figure {
	  margin-top: 36px;
	  margin-bottom: 36px;
	}
      
	.af-view .af-class-article.af-class-help-center-article {
	  margin-bottom: 36px;
	}
      
	.af-view .af-class-form-row {
	  grid-row-gap: 0px;
	  -ms-grid-columns: 1fr;
	  grid-template-columns: 1fr;
	  -ms-grid-rows: auto auto;
	  grid-template-rows: auto auto;
	}
      
	.af-view .af-class-icon-cards-grid.af-class-icon-cards-grid-wide {
	  grid-row-gap: 24px;
	  -ms-grid-columns: 1fr;
	  grid-template-columns: 1fr;
	}
      
	.af-view .af-class-horizontal-rule {
	  margin-top: 48px;
	}
      
	.af-view .af-class-contact-method-item.af-class-contact-method-group-item {
	  margin-right: 12px;
	  margin-bottom: 24px;
	  margin-left: 12px;
	}
      
	.af-view .af-class-contact-method-group {
	  margin-top: 24px;
	  margin-bottom: -24px;
	  -webkit-box-pack: center;
	  -webkit-justify-content: center;
	  -ms-flex-pack: center;
	  justify-content: center;
	}
      
	.af-view .af-class-pricing-plans-grid {
	  grid-row-gap: 24px;
	  -ms-grid-columns: 1fr;
	  grid-template-columns: 1fr;
	}
      
	.af-view .af-class-pricing-plan-heading {
	  margin-bottom: 12px;
	}
      
	.af-view .af-class-three-up-grid {
	  grid-row-gap: 24px;
	  -ms-grid-columns: 1fr;
	  grid-template-columns: 1fr;
	}
      
	.af-view .af-class-customer-blockquote {
	  margin-top: 0px;
	  margin-bottom: 12px;
	}
      
	.af-view .af-class-customer-blockquote.af-class-logo-customer-blockquote {
	  margin-top: 24px;
	}
      
	.af-view .af-class-customer-quote-item {
	  display: -webkit-box;
	  display: -webkit-flex;
	  display: -ms-flexbox;
	  display: flex;
	  margin-bottom: 12px;
	  text-align: left;
	}
      
	.af-view .af-class-customer-quote-item.af-class-logo-quote-item {
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	  -webkit-box-align: start;
	  -webkit-align-items: flex-start;
	  -ms-flex-align: start;
	  align-items: flex-start;
	}
      
	.af-view .af-class-pricing-comparison-wrapper {
	  margin-bottom: 24px;
	}
      
	.af-view .af-class-pricing-comparison-header {
	  top: 0px;
	  padding-top: 12px;
	  padding-bottom: 12px;
	  background-color: #fff;
	}
      
	.af-view .af-class-large-heading {
	  margin-bottom: 16px;
	  font-size: 40px;
	  line-height: 48px;
	}
      
	.af-view .af-class-pricing-comparison-cell {
	  padding-top: 16px;
	  padding-bottom: 16px;
	}
      
	.af-view .af-class-pricing-comparison-button {
	  margin-top: 12px;
	}
      
	.af-view .af-class-utility-page-top-bar {
	  padding-top: 24px;
	  padding-bottom: 24px;
	}
      
	.af-view .af-class-split-layout {
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	}
      
	.af-view .af-class-split-layout-image-wrapper {
	  max-width: 100%;
	}
      
	.af-view .af-class-split-layout-container {
	  -webkit-box-pack: start;
	  -webkit-justify-content: flex-start;
	  -ms-flex-pack: start;
	  justify-content: flex-start;
	}
      
	.af-view .af-class-subscribe-form-wrapper {
	  margin-top: 36px;
	}
      
	.af-view .af-class-team-member-title {
	  margin-bottom: 12px;
	}
      
	.af-view .af-class-blog-grid {
	  -ms-grid-columns: 1fr;
	  grid-template-columns: 1fr;
	}
      
	.af-view .af-class-post-title-content {
	  margin-bottom: 24px;
	}
      
	.af-view .af-class-pull-divider-up {
	  margin-bottom: -7vw;
	}
      
	.af-view .af-class-post-share-wrapper {
	  margin-top: 36px;
	  padding-top: 24px;
	}
      
	.af-view .af-class-pagination {
	  margin-top: 24px;
	}
      
	.af-view .af-class-wide-post-wrapper {
	  margin-bottom: 24px;
	}
      
	.af-view .af-class-post-horizontal-wrapper {
	  padding: 0px;
	}
      
	.af-view .af-class-content-pair-graphic {
	  width: 100%;
	}
      
	.af-view .af-class-faq-grid {
	  margin-bottom: 24px;
	  -ms-grid-columns: 1fr;
	  grid-template-columns: 1fr;
	}
      
	.af-view .af-class-cta-bg-graphic {
	  max-width: 35%;
	}
      
	.af-view .af-class-pill-below {
	  margin-bottom: 24px;
	}
      
	.af-view .af-class-screenshot-arrow {
	  display: none;
	}
      
	.af-view .af-class-mobile-screenshot-text {
	  margin-top: 0px;
	  padding-left: 24px;
	}
      
	.af-view .af-class-screenshot {
	  border-radius: 5px;
	}
      
	.af-view .af-class-customer-quotes-wrapper {
	  grid-row-gap: 12px;
	}
      
	.af-view .af-class-case-study-meta-list {
	  margin-right: 0px;
	  text-align: center;
	}
      
	.af-view .af-class-case-study-meta-list-item {
	  display: -webkit-box;
	  display: -webkit-flex;
	  display: -ms-flexbox;
	  display: flex;
	  margin-bottom: 6px;
	  -webkit-box-pack: justify;
	  -webkit-justify-content: space-between;
	  -ms-flex-pack: justify;
	  justify-content: space-between;
	}
      
	.af-view .af-class-page-demos-wrapper {
	  padding: 36px;
	  grid-row-gap: 24px;
	  border-radius: 5px;
	}
      
	.af-view .af-class-decoration-container {
	  display: none;
	}
      
	.af-view .af-class-page-navigator {
	  display: none;
	}
      
	.af-view .af-class-search-form {
	  margin-top: 24px;
	}
      
	.af-view .af-class-category-icon {
	  margin-right: 24px;
	}
      
	.af-view .af-class-sidebar-main-content-header {
	  margin-bottom: 24px;
	  padding-bottom: 24px;
	}
      
	.af-view .af-class-sidebar-main-content-heading {
	  margin-top: 12px;
	}
      
	.af-view .af-class-link {
	  display: none;
	}
      
	.af-view .af-class-heading-2 {
	  font-size: 50px;
	}
      
	.af-view .af-class-youtube {
	  margin-left: 24px;
	  text-align: center;
	}
      
	.af-view .af-class-content-pair-graphic-copy {
	  width: 100%;
	}
      
	.af-view .af-class-content-pair-text-copy {
	  margin-bottom: 24px;
	}
      
	.af-view .af-class-icon-feature-content-copy {
	  margin-left: 16px;
	}
      
	.af-view .af-class-icon-feature-content-copy {
	  margin-left: 16px;
	}
      
	.af-view .af-class-icon-feature-content-copy {
	  margin-left: 16px;
	}
      
	.af-view .af-class-section-copy {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-copy.af-class-title-section {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-copy.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 60px;
	  padding-bottom: 60px;
	}
      
	.af-view
	  .af-class-section-copy.af-class-no-top-space.af-class-pricing-plans-section {
	  margin-top: 0px;
	  padding-top: 72px;
	}
      
	.af-view .af-class-section-copy.af-class-section-small {
	  padding-top: 36px;
	  padding-bottom: 36px;
	}
      
	.af-view .af-class-section-copy.af-class-utility-page-content {
	  padding-right: 12px;
	  padding-left: 12px;
	  -webkit-box-pack: start;
	  -webkit-justify-content: flex-start;
	  -ms-flex-pack: start;
	  justify-content: flex-start;
	}
      
	.af-view .af-class-section-copy-copy {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-copy-copy.af-class-title-section {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view
	  .af-class-section-copy-copy.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 60px;
	  padding-bottom: 60px;
	}
      
	.af-view
	  .af-class-section-copy-copy.af-class-no-top-space.af-class-pricing-plans-section {
	  margin-top: 0px;
	  padding-top: 72px;
	}
      
	.af-view .af-class-section-copy-copy.af-class-section-small {
	  padding-top: 36px;
	  padding-bottom: 36px;
	}
      
	.af-view .af-class-section-copy-copy.af-class-utility-page-content {
	  padding-right: 12px;
	  padding-left: 12px;
	  -webkit-box-pack: start;
	  -webkit-justify-content: flex-start;
	  -ms-flex-pack: start;
	  justify-content: flex-start;
	}
      
	.af-view .af-class-section-copy-copy.af-class-section-small-copy {
	  padding-top: 36px;
	  padding-bottom: 36px;
	}
      
	.af-view .af-class-section-copy-copy.af-class-section-small-copy-copy {
	  padding-top: 36px;
	  padding-bottom: 36px;
	}
      
	.af-view .af-class-paragraph-large-copy {
	  margin-bottom: 16px;
	}
      
	.af-view .af-class-heading-6-2 {
	  width: auto;
	}
      
	.af-view .af-class-feature-section {
	  padding: 40px 20px;
	}
      
	.af-view .af-class-flex-container {
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: reverse;
	  -webkit-flex-direction: column-reverse;
	  -ms-flex-direction: column-reverse;
	  flex-direction: column-reverse;
	}
      
	.af-view .af-class-feature-image-mask {
	  margin-right: 0px;
	}
      
	.af-view .af-class-feature-image {
	  margin-right: auto;
	  margin-left: auto;
	}
      
	.af-view .af-class-flex-container-reverse {
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: reverse;
	  -webkit-flex-direction: column-reverse;
	  -ms-flex-direction: column-reverse;
	  flex-direction: column-reverse;
	}
      
	.af-view .af-class-feature-image-copy {
	  margin-right: auto;
	  margin-left: auto;
	}
      
	.af-view .af-class-feature-image-copy-copy {
	  margin-right: auto;
	  margin-left: auto;
	}
      
	.af-view .af-class-feature-image-copy-2 {
	  margin-right: auto;
	  margin-left: auto;
	}
      
	.af-view .af-class-contact-form {
	  padding: 40px 20px;
	}
      
	.af-view .af-class-contact-form-copy {
	  padding: 40px 20px;
	}
      
	.af-view .af-class-section-copy {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-copy.af-class-title-section {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-copy.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 60px;
	  padding-bottom: 60px;
	}
      
	.af-view
	  .af-class-section-copy.af-class-no-top-space.af-class-pricing-plans-section {
	  margin-top: 0px;
	  padding-top: 72px;
	}
      
	.af-view .af-class-section-copy.af-class-section-small {
	  padding-top: 36px;
	  padding-bottom: 36px;
	}
      
	.af-view .af-class-section-copy.af-class-utility-page-content {
	  padding-right: 12px;
	  padding-left: 12px;
	  -webkit-box-pack: start;
	  -webkit-justify-content: flex-start;
	  -ms-flex-pack: start;
	  justify-content: flex-start;
	}
      
	.af-view
	  .af-class-section-copy.af-class-no-top-space-copy.af-class-pricing-plans-section {
	  margin-top: 0px;
	  margin-bottom: 60px;
	  padding-top: 72px;
	}
      
	.af-view .af-class-section-copy-2 {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-copy-2.af-class-title-section {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-copy-2.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 60px;
	  padding-bottom: 60px;
	}
      
	.af-view
	  .af-class-section-copy-2.af-class-no-top-space.af-class-pricing-plans-section {
	  margin-top: 0px;
	  padding-top: 72px;
	}
      
	.af-view .af-class-section-copy-2.af-class-section-small {
	  padding-top: 36px;
	  padding-bottom: 36px;
	}
      
	.af-view .af-class-section-copy-2.af-class-utility-page-content {
	  padding-right: 12px;
	  padding-left: 12px;
	  -webkit-box-pack: start;
	  -webkit-justify-content: flex-start;
	  -ms-flex-pack: start;
	  justify-content: flex-start;
	}
      
	.af-view
	  .af-class-section-copy-2.af-class-no-top-space-copy.af-class-pricing-plans-section {
	  margin-top: 0px;
	  padding-top: 72px;
	}
      
	.af-view .af-class-pricing-plan-heading-copy {
	  margin-bottom: 12px;
	}
      
	.af-view
	  .af-class-container-testimonials-header.af-class-announcement-bar-container {
	  padding-right: 48px;
	}
      
	.af-view
	  .af-class-container-testimonials-header.af-class-text-center.af-class-hero-container-nav-above {
	  margin-top: 80px;
	}
      
	.af-view
	  .af-class-container-testimonials-header.af-class-case-study-container {
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	}
      
	.af-view
	  .af-class-container-testimonials-pricing.af-class-announcement-bar-container {
	  padding-right: 48px;
	}
      
	.af-view
	  .af-class-container-testimonials-pricing.af-class-text-center.af-class-hero-container-nav-above {
	  margin-top: 80px;
	}
      
	.af-view
	  .af-class-container-testimonials-pricing.af-class-case-study-container {
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	}
      
	.af-view
	  .af-class-container-testimonials-pricing-title.af-class-announcement-bar-container {
	  padding-right: 48px;
	}
      
	.af-view .af-class-container-testimonials-pricing-title.af-class-text-center {
	  padding-right: 0px;
	  padding-left: 10px;
	}
      
	.af-view
	  .af-class-container-testimonials-pricing-title.af-class-text-center.af-class-hero-container-nav-above {
	  margin-top: 80px;
	}
      
	.af-view
	  .af-class-container-testimonials-pricing-title.af-class-case-study-container {
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	}
      
	.af-view .af-class-section-pricing-testimonial {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-pricing-testimonial.af-class-title-section {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view
	  .af-class-section-pricing-testimonial.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 60px;
	  padding-bottom: 60px;
	}
      
	.af-view
	  .af-class-section-pricing-testimonial.af-class-no-top-space.af-class-pricing-plans-section {
	  margin-top: 0px;
	  padding-top: 72px;
	}
      
	.af-view .af-class-section-pricing-testimonial.af-class-section-small {
	  padding-top: 36px;
	  padding-bottom: 36px;
	}
      
	.af-view .af-class-section-pricing-testimonial.af-class-utility-page-content {
	  padding-right: 12px;
	  padding-left: 12px;
	  -webkit-box-pack: start;
	  -webkit-justify-content: flex-start;
	  -ms-flex-pack: start;
	  justify-content: flex-start;
	}
      
	.af-view
	  .af-class-section-pricing-testimonial.af-class-no-top-space-copy.af-class-pricing-plans-section {
	  margin-top: 0px;
	  padding-top: 72px;
	}
      
	.af-view .af-class-section-copy-2-copy {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-copy-2-copy.af-class-title-section {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view
	  .af-class-section-copy-2-copy.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 60px;
	  padding-bottom: 60px;
	}
      
	.af-view
	  .af-class-section-copy-2-copy.af-class-no-top-space.af-class-pricing-plans-section {
	  margin-top: 0px;
	  padding-top: 72px;
	}
      
	.af-view .af-class-section-copy-2-copy.af-class-section-small {
	  padding-top: 36px;
	  padding-bottom: 36px;
	}
      
	.af-view .af-class-section-copy-2-copy.af-class-utility-page-content {
	  padding-right: 12px;
	  padding-left: 12px;
	  -webkit-box-pack: start;
	  -webkit-justify-content: flex-start;
	  -ms-flex-pack: start;
	  justify-content: flex-start;
	}
      
	.af-view
	  .af-class-section-copy-2-copy.af-class-no-top-space-copy.af-class-pricing-plans-section {
	  margin-top: 0px;
	  padding-top: 72px;
	}
      
	.af-view .af-class-section-feature-step-3 {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-feature-step-3.af-class-title-section {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view
	  .af-class-section-feature-step-3.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 60px;
	  padding-bottom: 60px;
	}
      
	.af-view
	  .af-class-section-feature-step-3.af-class-no-top-space.af-class-pricing-plans-section {
	  margin-top: 0px;
	  padding-top: 72px;
	}
      
	.af-view .af-class-section-feature-step-3.af-class-section-small {
	  padding-top: 36px;
	  padding-bottom: 36px;
	}
      
	.af-view .af-class-section-feature-step-3.af-class-utility-page-content {
	  padding-right: 12px;
	  padding-left: 12px;
	  -webkit-box-pack: start;
	  -webkit-justify-content: flex-start;
	  -ms-flex-pack: start;
	  justify-content: flex-start;
	}
      
	.af-view
	  .af-class-section-feature-step-3.af-class-no-top-space-copy.af-class-pricing-plans-section {
	  margin-top: 0px;
	  padding-top: 72px;
	}
      
	.af-view .af-class-large-heading-copy {
	  margin-bottom: 16px;
	  font-size: 40px;
	  line-height: 48px;
	}
      
	.af-view .af-class-heading-6-copy2 {
	  display: block;
	  width: 450px;
	  margin-right: auto;
	  margin-left: auto;
	  -webkit-box-pack: center;
	  -webkit-justify-content: center;
	  -ms-flex-pack: center;
	  justify-content: center;
	  -webkit-box-align: center;
	  -webkit-align-items: center;
	  -ms-flex-align: center;
	  align-items: center;
	  -webkit-align-self: auto;
	  -ms-flex-item-align: auto;
	  -ms-grid-row-align: auto;
	  align-self: auto;
	}
      
	.af-view .af-class-text-block-23 {
	  display: none;
	}
      
	.af-view .af-class-section-copy-2-copy-copy {
	  padding-top: 72px;
	  padding-bottom: 0px;
	}
      
	.af-view .af-class-section-copy-2-copy-copy.af-class-title-section {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view
	  .af-class-section-copy-2-copy-copy.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 60px;
	  padding-bottom: 60px;
	}
      
	.af-view
	  .af-class-section-copy-2-copy-copy.af-class-no-top-space.af-class-pricing-plans-section {
	  margin-top: 0px;
	  padding-top: 72px;
	}
      
	.af-view .af-class-section-copy-2-copy-copy.af-class-section-small {
	  padding-top: 36px;
	  padding-bottom: 36px;
	}
      
	.af-view .af-class-section-copy-2-copy-copy.af-class-utility-page-content {
	  padding-right: 12px;
	  padding-left: 12px;
	  -webkit-box-pack: start;
	  -webkit-justify-content: flex-start;
	  -ms-flex-pack: start;
	  justify-content: flex-start;
	}
      
	.af-view
	  .af-class-section-copy-2-copy-copy.af-class-no-top-space-copy.af-class-pricing-plans-section {
	  margin-top: 0px;
	  padding-top: 72px;
	}
      
	.af-view .af-class-section-copy {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-copy.af-class-title-section {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-copy.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 60px;
	  padding-bottom: 60px;
	}
      
	.af-view
	  .af-class-section-copy.af-class-no-top-space.af-class-pricing-plans-section {
	  margin-top: 0px;
	  padding-top: 72px;
	}
      
	.af-view .af-class-section-copy.af-class-section-small {
	  padding-top: 36px;
	  padding-bottom: 36px;
	}
      
	.af-view .af-class-section-copy.af-class-utility-page-content {
	  padding-right: 12px;
	  padding-left: 12px;
	  -webkit-box-pack: start;
	  -webkit-justify-content: flex-start;
	  -ms-flex-pack: start;
	  justify-content: flex-start;
	}
      
	.af-view
	  .af-class-section-copy.af-class-no-top-space-copy.af-class-pricing-plans-section {
	  margin-top: 0px;
	  padding-top: 72px;
	}
      
	.af-view .af-class-section-copy-copy {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-copy-copy.af-class-title-section {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view
	  .af-class-section-copy-copy.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 60px;
	  padding-bottom: 60px;
	}
      
	.af-view
	  .af-class-section-copy-copy.af-class-no-top-space.af-class-pricing-plans-section {
	  margin-top: 0px;
	  padding-top: 72px;
	}
      
	.af-view .af-class-section-copy-copy.af-class-section-small {
	  padding-top: 36px;
	  padding-bottom: 36px;
	}
      
	.af-view .af-class-section-copy-copy.af-class-utility-page-content {
	  padding-right: 12px;
	  padding-left: 12px;
	  -webkit-box-pack: start;
	  -webkit-justify-content: flex-start;
	  -ms-flex-pack: start;
	  justify-content: flex-start;
	}
      
	.af-view
	  .af-class-section-copy-copy.af-class-no-top-space-copy.af-class-pricing-plans-section {
	  margin-top: 0px;
	  padding-top: 72px;
	}
      
	.af-view
	  .af-class-button-copy.af-class-button-outline.af-class-pricing-comparison-button {
	  margin-top: 12px;
	}
      
	.af-view .af-class-button-copy.af-class-navbar-button {
	  display: none;
	}
      
	.af-view .af-class-section-conseils {
	  padding-top: 38px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-conseils.af-class-title-section {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view
	  .af-class-section-conseils.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 60px;
	  padding-bottom: 60px;
	}
      
	.af-view
	  .af-class-section-conseils.af-class-no-top-space.af-class-pricing-plans-section {
	  margin-top: 0px;
	  padding-top: 72px;
	}
      
	.af-view .af-class-section-conseils.af-class-section-small {
	  padding: 36px 20px;
	}
      
	.af-view .af-class-section-conseils.af-class-utility-page-content {
	  padding-right: 12px;
	  padding-left: 12px;
	  -webkit-box-pack: start;
	  -webkit-justify-content: flex-start;
	  -ms-flex-pack: start;
	  justify-content: flex-start;
	}
      
	.af-view
	  .af-class-section-conseils.af-class-no-top-space-copy.af-class-pricing-plans-section {
	  margin-top: 0px;
	  padding-top: 72px;
	}
      
	.af-view .af-class-icon-list-conseils.af-class-pricing-plan-list {
	  margin-top: 24px;
	  margin-bottom: 24px;
	}
      
	.af-view .af-class-icon-list-conseils.af-class-pricing-plan-list-conseils {
	  margin-top: 24px;
	  margin-bottom: 24px;
	}
      
	.af-view .af-class-columns-3 {
	  display: block;
	}
      
	.af-view .af-class-paragraph-large-ml {
	  margin-bottom: 16px;
	}
      
	.af-view .af-class-content-pair-image-ml {
	  max-width: 60%;
	}
      
	.af-view .af-class-paragraph-large-cc {
	  margin-bottom: 16px;
	}
      
	.af-view .af-class-content-pair-image-cc {
	  max-width: 60%;
	}
      
	.af-view .af-class-column-7 {
	  text-align: center;
	}
      
	.af-view .af-class-column-8 {
	  text-align: center;
	}
      
	.af-view .af-class-column-9 {
	  text-align: center;
	}
      
	.af-view .af-class-three-up-grid-pricing {
	  grid-row-gap: 24px;
	  -ms-grid-columns: 1fr;
	  grid-template-columns: 1fr;
	}
      
	.af-view .af-class-hero-section-action-sales {
	  margin-top: 24px;
	}
      
	.af-view .af-class-section-sales {
	  padding-top: 38px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-sales.af-class-title-section {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-sales.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 60px;
	  padding-bottom: 60px;
	}
      
	.af-view
	  .af-class-section-sales.af-class-no-top-space.af-class-pricing-plans-section {
	  margin-top: 0px;
	  padding-top: 72px;
	}
      
	.af-view .af-class-section-sales.af-class-section-small {
	  padding: 36px 20px;
	}
      
	.af-view .af-class-section-sales.af-class-utility-page-content {
	  padding-right: 12px;
	  padding-left: 12px;
	  -webkit-box-pack: start;
	  -webkit-justify-content: flex-start;
	  -ms-flex-pack: start;
	  justify-content: flex-start;
	}
      
	.af-view
	  .af-class-section-sales.af-class-no-top-space-copy.af-class-pricing-plans-section {
	  margin-top: 0px;
	  padding-top: 72px;
	}
      
	.af-view .af-class-section-sales.af-class-section-small-sales {
	  padding: 36px 20px;
	}
      
	.af-view .af-class-heading-2-home {
	  font-size: 50px;
	}
      
	.af-view .af-class-title-section-conseil {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-divider-conseil {
	  height: 7vw;
	}
      
	.af-view .af-class-divider-conseil.af-class-divider-top {
	  margin-bottom: 72px;
	}
      
	.af-view .af-class-divider-conseil.af-class-divider-bottom {
	  margin-top: 72px;
	}
      
	.af-view .af-class-divider-conseil.af-class-divider-bottom-large {
	  margin-top: 72px;
	}
      
	.af-view .af-class-divider-conseil.af-class-card-divider {
	  height: 24px;
	}
      
	.af-view .af-class-divider-conseil.af-class-divider-bottom-small {
	  margin-top: 24px;
	}
      
	.af-view .af-class-title-section-ml {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-title-section-guide {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-title-section-seo {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-content-pair-text-about {
	  margin-bottom: 24px;
	}
      
	.af-view .af-class-content-pair-image-about {
	  max-width: 60%;
	}
      
	.af-view .af-class-content-pair-2-about.af-class-content-pair-reverse {
	  display: block;
	  -webkit-box-align: center;
	  -webkit-align-items: center;
	  -ms-flex-align: center;
	  align-items: center;
	  -webkit-align-content: end;
	  -ms-flex-line-pack: end;
	  align-content: end;
	  text-align: center;
	}
      
	.af-view .af-class-section-about {
	  padding-top: 38px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-about.af-class-title-section {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-about.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 60px;
	  padding-bottom: 60px;
	}
      
	.af-view
	  .af-class-section-about.af-class-no-top-space.af-class-pricing-plans-section {
	  margin-top: 0px;
	  padding-top: 72px;
	}
      
	.af-view .af-class-section-about.af-class-section-small {
	  padding: 36px 20px;
	}
      
	.af-view .af-class-section-about.af-class-utility-page-content {
	  padding-right: 12px;
	  padding-left: 12px;
	  -webkit-box-pack: start;
	  -webkit-justify-content: flex-start;
	  -ms-flex-pack: start;
	  justify-content: flex-start;
	}
      
	.af-view
	  .af-class-section-about.af-class-no-top-space-copy.af-class-pricing-plans-section {
	  margin-top: 0px;
	  padding-top: 72px;
	}
      
	.af-view .af-class-section-avatar {
	  padding-top: 38px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-avatar.af-class-title-section {
	  padding-top: 72px;
	  padding-bottom: 72px;
	}
      
	.af-view .af-class-section-avatar.af-class-title-section.af-class-bg-gray-4 {
	  padding-top: 60px;
	  padding-bottom: 60px;
	}
      
	.af-view
	  .af-class-section-avatar.af-class-no-top-space.af-class-pricing-plans-section {
	  margin-top: 0px;
	  padding-top: 72px;
	}
      
	.af-view .af-class-section-avatar.af-class-section-small {
	  padding: 36px 20px;
	}
      
	.af-view .af-class-section-avatar.af-class-utility-page-content {
	  padding-right: 12px;
	  padding-left: 12px;
	  -webkit-box-pack: start;
	  -webkit-justify-content: flex-start;
	  -ms-flex-pack: start;
	  justify-content: flex-start;
	}
      
	.af-view
	  .af-class-section-avatar.af-class-no-top-space-copy.af-class-pricing-plans-section {
	  margin-top: 0px;
	  padding-top: 72px;
	}
      }
      
      @media screen and (max-width: 479px) {
	.af-view h1 {
	  margin-bottom: 12px;
	  font-size: 32px;
	  line-height: 40px;
	}
      
	.af-view h2 {
	  margin-bottom: 12px;
	  font-size: 28px;
	  line-height: 36px;
	}
      
	.af-view h3 {
	  margin-bottom: 12px;
	  font-size: 24px;
	  line-height: 30px;
	}
      
	.af-view h4 {
	  font-size: 24px;
	  line-height: 30px;
	}
      
	.af-view h5 {
	  font-size: 20px;
	}
      
	.af-view p {
	  margin-bottom: 12px;
	}
      
	.af-view blockquote {
	  margin-bottom: 16px;
	  font-size: 16px;
	  line-height: 24px;
	  letter-spacing: 0em;
	}
      
	.af-view .af-class-section {
	  padding-top: 48px;
	  padding-bottom: 48px;
	}
      
	.af-view
	  .af-class-section.af-class-no-top-space.af-class-pricing-plans-section {
	  padding-top: 48px;
	}
      
	.af-view .af-class-section.af-class-no-bottom-space.af-class-bg-primary-3 {
	  display: block;
	}
      
	.af-view .af-class-section.af-class-section-small {
	  padding-left: 20px;
	}
      
	.af-view .af-class-section.af-class-hero-screenshot-above {
	  margin-top: 0vh;
	  padding-top: 48px;
	}
      
	.af-view
	  .af-class-section.af-class-no-top-space-copy.af-class-pricing-plans-section {
	  padding-top: 48px;
	}
      
	.af-view .af-class-container-testimonials {
	  display: block;
	  padding-right: 12px;
	  padding-left: 12px;
	  -webkit-box-pack: center;
	  -webkit-justify-content: center;
	  -ms-flex-pack: center;
	  justify-content: center;
	  -webkit-box-align: start;
	  -webkit-align-items: flex-start;
	  -ms-flex-align: start;
	  align-items: flex-start;
	  grid-auto-columns: 1fr;
	  -ms-grid-columns: 1fr 1fr;
	  grid-template-columns: 1fr 1fr;
	  -ms-grid-rows: auto auto;
	  grid-template-rows: auto auto;
	}
      
	.af-view
	  .af-class-container-testimonials.af-class-announcement-bar-container {
	  padding-right: 48px;
	}
      
	.af-view .af-class-container-testimonials.af-class-text-center {
	  display: block;
	}
      
	.af-view .af-class-container-testimonials.af-class-hero-screenshot-container {
	  margin-top: 24px;
	  margin-bottom: 0vh;
	  padding-bottom: 48px;
	}
      
	.af-view .af-class-style-guide-title {
	  margin-bottom: 24px;
	}
      
	.af-view .af-class-style-guide-color-container {
	  margin-bottom: 12px;
	}
      
	.af-view .af-class-h6-small.af-class-career-heading {
	  width: auto;
	}
      
	.af-view .af-class-text-small-caps {
	  font-size: 12px;
	}
      
	.af-view .af-class-text-small-caps.af-class-contact-method-heading {
	  margin-bottom: 4px;
	}
      
	.af-view .af-class-button.af-class-button-large {
	  padding: 10px 24px;
	  font-size: 16px;
	  line-height: 24px;
	}
      
	.af-view .af-class-button.af-class-button-large.af-class-add-space-right {
	  margin-right: 0px;
	  margin-bottom: 12px;
	}
      
	.af-view
	  .af-class-button.af-class-button-large.af-class-add-space-right.af-class-primary-button {
	  margin-right: 0px;
	}
      
	.af-view .af-class-button.af-class-button-round.af-class-slider-button-left {
	  display: none;
	}
      
	.af-view .af-class-button.af-class-button-round.af-class-slider-button-right {
	  display: none;
	}
      
	.af-view .af-class-button.af-class-submit-button {
	  width: 100%;
	}
      
	.af-view .af-class-button.af-class-navbar-button {
	  display: none;
	}
      
	.af-view .af-class-form-input.af-class-form-input-large {
	  height: 48px;
	  font-size: 16px;
	}
      
	.af-view
	  .af-class-form-input.af-class-form-input-large.af-class-search-form-input {
	  margin-bottom: 12px;
	}
      
	.af-view .af-class-navbar-container.af-class-bg-transparent {
	  margin-bottom: 48px;
	}
      
	.af-view
	  .af-class-dropdown-list.af-class-dropdown-list-top.af-class-dropdown-list-large.w--open {
	  min-width: 296px;
	}
      
	.af-view .af-class-footer {
	  padding-top: 48px;
	  padding-bottom: 24px;
	}
      
	.af-view .af-class-social-links.af-class-social-links-circles {
	  margin-right: -6px;
	  margin-left: -6px;
	  -webkit-box-pack: center;
	  -webkit-justify-content: center;
	  -ms-flex-pack: center;
	  justify-content: center;
	}
      
	.af-view .af-class-social-link.af-class-social-link-circle {
	  margin-right: 6px;
	  margin-bottom: 12px;
	  margin-left: 6px;
	}
      
	.af-view .af-class-footer-menus-row {
	  width: 100%;
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	  -webkit-box-align: center;
	  -webkit-align-items: center;
	  -ms-flex-align: center;
	  align-items: center;
	}
      
	.af-view .af-class-footer-menu-column {
	  width: 100%;
	}
      
	.af-view .af-class-style-guide-item.af-class-narrow {
	  max-width: none;
	}
      
	.af-view .af-class-avatar {
	  width: 48px;
	  height: 48px;
	}
      
	.af-view .af-class-avatar.af-class-avatar-small {
	  width: 36px;
	  height: 36px;
	}
      
	.af-view .af-class-avatar.af-class-avatar-xlarge {
	  width: 72px;
	  height: 72px;
	}
      
	.af-view .af-class-avatar.af-class-avatar-large {
	  width: 60px;
	  height: 60px;
	}
      
	.af-view
	  .af-class-avatar.af-class-avatar-large.af-class-customer-quote-avatar {
	  margin-right: 12px;
	}
      
	.af-view .af-class-avatar.af-class-post-horizontal-avatar {
	  margin-right: 0px;
	  margin-bottom: 24px;
	}
      
	.af-view .af-class-avatar-container {
	  -webkit-box-flex: 0;
	  -webkit-flex: 0 0 auto;
	  -ms-flex: 0 0 auto;
	  flex: 0 0 auto;
	}
      
	.af-view .af-class-avatar-group {
	  overflow: scroll;
	  width: 100%;
	}
      
	.af-view .af-class-card.af-class-logo-card {
	  min-height: 0px;
	}
      
	.af-view .af-class-card.af-class-card-horizontal {
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	}
      
	.af-view .af-class-card.af-class-form-card {
	  margin-bottom: 12px;
	}
      
	.af-view .af-class-card.af-class-help-center-card {
	  margin-bottom: 12px;
	}
      
	.af-view .af-class-card-body.af-class-card-horizontal-body {
	  padding-right: 24px;
	  padding-left: 24px;
	}
      
	.af-view .af-class-card-body.af-class-career-card-body {
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	}
      
	.af-view .af-class-text-white {
	  font-size: 25px;
	  line-height: 30px;
	}
      
	.af-view .af-class-pill.af-class-mobile-screenshot-pill {
	  display: none;
	}
      
	.af-view .af-class-expandable-heading.af-class-h6-small {
	  padding-right: 24px;
	}
      
	.af-view .af-class-tab-button {
	  -webkit-box-flex: 0;
	  -webkit-flex: 0 0 auto;
	  -ms-flex: 0 0 auto;
	  flex: 0 0 auto;
	}
      
	.af-view .af-class-tab-button.w--current {
	  -webkit-box-flex: 0;
	  -webkit-flex: 0 0 auto;
	  -ms-flex: 0 0 auto;
	  flex: 0 0 auto;
	}
      
	.af-view .af-class-tab-icon {
	  max-height: 24px;
	}
      
	.af-view .af-class-tab-link {
	  margin-right: 16px;
	}
      
	.af-view .af-class-icon {
	  margin-bottom: 12px;
	}
      
	.af-view .af-class-icon-feature-horizontal {
	  padding-right: 0px;
	}
      
	.af-view .af-class-icon-feature-content {
	  margin-left: 12px;
	}
      
	.af-view .af-class-icon-list.af-class-pricing-plan-list {
	  margin-top: 16px;
	  margin-bottom: 16px;
	}
      
	.af-view .af-class-text-row.af-class-blockquote-author {
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	}
      
	.af-view .af-class-announcement-cross {
	  right: 12px;
	}
      
	.af-view .af-class-breadcrumb-list {
	  overflow: scroll;
	  max-width: 100%;
	}
      
	.af-view .af-class-breadcrumb-list-item {
	  -webkit-box-flex: 0;
	  -webkit-flex: 0 0 auto;
	  -ms-flex: 0 0 auto;
	  flex: 0 0 auto;
	}
      
	.af-view .af-class-divider {
	  height: 8vw;
	}
      
	.af-view .af-class-divider.af-class-divider-top {
	  margin-bottom: 48px;
	}
      
	.af-view .af-class-divider.af-class-divider-bottom {
	  margin-top: 48px;
	}
      
	.af-view .af-class-image-tile-gallery {
	  -ms-grid-columns: 1fr;
	  grid-template-columns: 1fr;
	}
      
	.af-view .af-class-image-gallery-image.af-class-image-gallery-right {
	  display: none;
	}
      
	.af-view .af-class-image-gallery-left {
	  margin-top: 0px;
	  margin-bottom: 0px;
	  grid-row-gap: 12px;
	}
      
	.af-view .af-class-section-title {
	  margin-bottom: 36px;
	}
      
	.af-view .af-class-team-members-grid {
	  -ms-grid-columns: 1fr;
	  grid-template-columns: 1fr;
	}
      
	.af-view .af-class-map-section-right {
	  padding-top: 48px;
	  padding-bottom: 48px;
	  padding-left: 12px;
	}
      
	.af-view .af-class-metrics-horizontal {
	  display: block;
	  margin-right: -12px;
	  margin-left: -12px;
	}
      
	.af-view .af-class-metrics-horizontal-item {
	  margin-top: 39px;
	  margin-right: 12px;
	  margin-left: 12px;
	}
      
	.af-view .af-class-metric-heading {
	  margin-bottom: 0px;
	}
      
	.af-view .af-class-paragraph-large.af-class-text-large {
	  text-align: left;
	}
      
	.af-view
	  .af-class-paragraph-large.af-class-text-large.af-class-case-study-subtitle {
	  display: block;
	}
      
	.af-view .af-class-brand-list {
	  display: -webkit-box;
	  display: -webkit-flex;
	  display: -ms-flexbox;
	  display: flex;
	  margin-right: 0px;
	  margin-left: 0px;
	  -webkit-box-pack: justify;
	  -webkit-justify-content: space-between;
	  -ms-flex-pack: justify;
	  justify-content: space-between;
	  justify-items: start;
	  -webkit-flex-wrap: nowrap;
	  -ms-flex-wrap: nowrap;
	  flex-wrap: nowrap;
	  -webkit-box-align: center;
	  -webkit-align-items: center;
	  -ms-flex-align: center;
	  align-items: center;
	  -webkit-align-content: flex-start;
	  -ms-flex-line-pack: start;
	  align-content: flex-start;
	  grid-auto-columns: 1fr;
	  -ms-grid-columns: 1fr 1fr;
	  grid-template-columns: 1fr 1fr;
	  -ms-grid-rows: auto auto;
	  grid-template-rows: auto auto;
	}
      
	.af-view .af-class-brand-list-item {
	  width: 100px;
	}
      
	.af-view .af-class-brand-image {
	  width: 200px;
	  height: 100px;
	  max-height: 24px;
	}
      
	.af-view .af-class-medium-heading {
	  margin-bottom: 12px;
	  font-size: 28px;
	  line-height: 36px;
	}
      
	.af-view .af-class-small-heading {
	  margin-bottom: 12px;
	  font-size: 24px;
	  line-height: 30px;
	}
      
	.af-view .af-class-small-heading.af-class-metric-heading {
	  margin-bottom: 0px;
	}
      
	.af-view .af-class-content-pair-text {
	  width: 100%;
	  max-width: none;
	  padding-left: 10px;
	}
      
	.af-view .af-class-content-pair-image {
	  max-width: 100%;
	}
      
	.af-view .af-class-large-metrics.af-class-text-center {
	  display: block;
	  -ms-grid-columns: 1fr;
	  grid-template-columns: 1fr;
	}
      
	.af-view .af-class-text-huge {
	  margin-bottom: 8px;
	  font-size: 40px;
	  line-height: 48px;
	}
      
	.af-view .af-class-large-metric-item.af-class-large-metric-horizontal {
	  text-align: center;
	}
      
	.af-view .af-class-hero-section-action {
	  margin-top: 16px;
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	}
      
	.af-view .af-class-card-horizontal-image {
	  border-top-left-radius: 10px;
	  border-bottom-left-radius: 0px;
	  border-bottom-right-radius: 0px;
	}
      
	.af-view .af-class-card-horizontal-image-wrapper {
	  max-width: 100%;
	  padding-left: 0px;
	}
      
	.af-view
	  .af-class-card-horizontal-image-wrapper.af-class-featured-case-study-image-wrapper {
	  max-width: 100%;
	  margin-top: 0px;
	  margin-bottom: 0px;
	}
      
	.af-view .af-class-card-slide-nav {
	  display: block;
	}
      
	.af-view .af-class-careers-list-item {
	  margin-bottom: 6px;
	}
      
	.af-view .af-class-career-basis {
	  width: auto;
	}
      
	.af-view .af-class-career-location {
	  width: auto;
	}
      
	.af-view .af-class-letterhead {
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	  -webkit-box-align: stretch;
	  -webkit-align-items: stretch;
	  -ms-flex-align: stretch;
	  align-items: stretch;
	  text-align: center;
	}
      
	.af-view .af-class-career-description-meta {
	  margin-bottom: 24px;
	  -webkit-box-pack: center;
	  -webkit-justify-content: center;
	  -ms-flex-pack: center;
	  justify-content: center;
	}
      
	.af-view .af-class-article.af-class-help-center-article {
	  margin-bottom: 24px;
	}
      
	.af-view .af-class-form-group {
	  margin-bottom: 16px;
	}
      
	.af-view .af-class-icon-cards-grid {
	  grid-row-gap: 12px;
	  -ms-grid-columns: 1fr;
	  grid-template-columns: 1fr;
	}
      
	.af-view .af-class-contact-methods-row {
	  margin-bottom: -24px;
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	}
      
	.af-view .af-class-contact-method-item {
	  margin-bottom: 24px;
	}
      
	.af-view .af-class-contact-method-item.af-class-contact-method-group-item {
	  margin-right: 0px;
	}
      
	.af-view .af-class-contact-method-group {
	  margin-top: 12px;
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	  -webkit-box-align: center;
	  -webkit-align-items: center;
	  -ms-flex-align: center;
	  align-items: center;
	}
      
	.af-view .af-class-form-group-row {
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	}
      
	.af-view .af-class-form-checkbox-agree {
	  margin-top: 0px;
	  margin-bottom: 12px;
	}
      
	.af-view .af-class-pricing-plans-grid {
	  grid-row-gap: 12px;
	}
      
	.af-view .af-class-pricing-plans-grid.af-class-pricing-plans-grid-duo {
	  -ms-grid-columns: 1fr;
	  grid-template-columns: 1fr;
	}
      
	.af-view .af-class-pricing-plan-heading.af-class-pricing-comparison-heading {
	  font-size: 16px;
	  font-weight: 500;
	}
      
	.af-view .af-class-three-up-grid {
	  display: -ms-grid;
	  display: grid;
	  padding-left: 20px;
	  grid-auto-columns: 1fr;
	  grid-column-gap: 24px;
	  grid-row-gap: 24px;
	  -ms-grid-columns: 1fr;
	  grid-template-columns: 1fr;
	  -ms-grid-rows: auto;
	  grid-template-rows: auto;
	}
      
	.af-view .af-class-customer-blockquote.af-class-logo-customer-blockquote {
	  margin-top: 12px;
	}
      
	.af-view .af-class-large-heading {
	  margin-bottom: 12px;
	  font-size: 32px;
	  line-height: 40px;
	}
      
	.af-view .af-class-pricing-comparison-cell {
	  padding-top: 12px;
	  padding-bottom: 12px;
	}
      
	.af-view .af-class-subscribe-form {
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	  -webkit-box-align: stretch;
	  -webkit-align-items: stretch;
	  -ms-flex-align: stretch;
	  align-items: stretch;
	}
      
	.af-view .af-class-subscribe-form-wrapper {
	  margin-top: 24px;
	}
      
	.af-view .af-class-team-member-wrapper {
	  margin-bottom: 24px;
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	}
      
	.af-view .af-class-blog-grid {
	  grid-row-gap: 12px;
	}
      
	.af-view .af-class-pull-divider-up {
	  margin-bottom: -8vw;
	}
      
	.af-view .af-class-post-share-wrapper {
	  margin-left: 0px;
	  padding-top: 16px;
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	  -webkit-box-align: center;
	  -webkit-align-items: center;
	  -ms-flex-align: center;
	  align-items: center;
	}
      
	.af-view .af-class-share-button {
	  top: 0px;
	  margin-top: 6px;
	  margin-right: 0px;
	  margin-left: 0px;
	}
      
	.af-view .af-class-wide-post-wrapper {
	  margin-bottom: 12px;
	}
      
	.af-view .af-class-featured-post-meta {
	  margin-top: 16px;
	}
      
	.af-view .af-class-horizontal-blog-list {
	  grid-row-gap: 12px;
	}
      
	.af-view .af-class-post-horizontal-wrapper {
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	}
      
	.af-view .af-class-content-pair-graphic.af-class-content-pair-graphic-narrow {
	  width: 75%;
	}
      
	.af-view .af-class-form-title {
	  margin-bottom: 12px;
	}
      
	.af-view .af-class-icon-features-heading {
	  padding-right: 0px;
	}
      
	.af-view .af-class-icon-feature-list-item {
	  margin-bottom: 12px;
	}
      
	.af-view .af-class-faq-grid {
	  grid-row-gap: 12px;
	}
      
	.af-view .af-class-cta-bg-graphic {
	  display: none;
	}
      
	.af-view .af-class-mobile-screenshot-wrapper {
	  max-width: 60vw;
	}
      
	.af-view .af-class-mobile-screenshot-text {
	  margin-top: 24px;
	  padding-left: 0px;
	  text-align: center;
	}
      
	.af-view .af-class-content-pair-gallery {
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	}
      
	.af-view .af-class-content-pair-gallery-left {
	  width: 100%;
	}
      
	.af-view .af-class-add-space-right {
	  margin-right: 0px;
	  margin-bottom: 12px;
	}
      
	.af-view .af-class-qr-code-image {
	  margin-top: 24px;
	  margin-bottom: 24px;
	}
      
	.af-view .af-class-large-metric-horizontal-text {
	  margin-top: 0px;
	}
      
	.af-view .af-class-page-demos-wrapper {
	  padding: 24px;
	  grid-row-gap: 12px;
	  -ms-grid-columns: 1fr;
	  grid-template-columns: 1fr;
	  text-align: center;
	}
      
	.af-view .af-class-search-form {
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	}
      
	.af-view .af-class-category-icon {
	  height: 48px;
	  margin-right: 16px;
	}
      
	.af-view .af-class-heading-2 {
	  font-size: 30px;
	  text-align: left;
	}
      
	.af-view .af-class-column-2 {
	  padding-top: 40px;
	}
      
	.af-view .af-class-column-3 {
	  padding-top: 40px;
	}
      
	.af-view .af-class-button-4 {
	  font-size: 16px;
	  text-align: center;
	}
      
	.af-view
	  .af-class-content-pair-graphic-copy.af-class-content-pair-graphic-narrow {
	  width: 75%;
	}
      
	.af-view .af-class-content-pair-text-copy {
	  width: 100%;
	  max-width: none;
	  padding-left: 10px;
	}
      
	.af-view .af-class-icon-feature-content-copy {
	  margin-left: 12px;
	}
      
	.af-view .af-class-icon-feature-content-copy {
	  margin-left: 12px;
	}
      
	.af-view .af-class-icon-feature-content-copy {
	  margin-left: 12px;
	}
      
	.af-view .af-class-section-copy {
	  padding-top: 48px;
	  padding-bottom: 48px;
	}
      
	.af-view
	  .af-class-section-copy.af-class-no-top-space.af-class-pricing-plans-section {
	  padding-top: 48px;
	}
      
	.af-view
	  .af-class-section-copy.af-class-no-bottom-space.af-class-bg-primary-3 {
	  display: block;
	}
      
	.af-view .af-class-section-copy.af-class-hero-screenshot-above {
	  margin-top: 0vh;
	  padding-top: 48px;
	}
      
	.af-view .af-class-section-copy-copy {
	  padding-top: 48px;
	  padding-bottom: 48px;
	}
      
	.af-view
	  .af-class-section-copy-copy.af-class-no-top-space.af-class-pricing-plans-section {
	  padding-top: 48px;
	}
      
	.af-view
	  .af-class-section-copy-copy.af-class-no-bottom-space.af-class-bg-primary-3 {
	  display: block;
	}
      
	.af-view .af-class-section-copy-copy.af-class-hero-screenshot-above {
	  margin-top: 0vh;
	  padding-top: 48px;
	}
      
	.af-view .af-class-heading-2-copy {
	  font-size: 25px;
	  line-height: 30px;
	}
      
	.af-view
	  .af-class-paragraph-large-copy.af-class-text-large.af-class-case-study-subtitle {
	  display: block;
	}
      
	.af-view .af-class-paragraph-large-copy.af-class-text-large-copy {
	  font-size: 16px;
	}
      
	.af-view
	  .af-class-paragraph-large-copy.af-class-text-large-copy.af-class-case-study-subtitle {
	  display: block;
	}
      
	.af-view .af-class-heading-6-2 {
	  width: auto;
	  font-size: 25px;
	}
      
	.af-view .af-class-columns-2 {
	  display: block;
	}
      
	.af-view .af-class-modal-wrapper {
	  display: none;
	  padding-top: 0px;
	}
      
	.af-view .af-class-form-wrapper {
	  overflow: visible;
	  padding-top: 10px;
	  padding-bottom: 100px;
	}
      
	.af-view .af-class-contact-form-grid {
	  margin-top: 10px;
	}
      
	.af-view .af-class-contact-form-copy {
	  padding-top: 5px;
	}
      
	.af-view .af-class-heading-15 {
	  display: block;
	  font-size: 18px;
	  line-height: 20px;
	}
      
	.af-view .af-class-section-copy {
	  padding-top: 48px;
	  padding-bottom: 48px;
	}
      
	.af-view
	  .af-class-section-copy.af-class-no-top-space.af-class-pricing-plans-section {
	  padding-top: 48px;
	}
      
	.af-view
	  .af-class-section-copy.af-class-no-bottom-space.af-class-bg-primary-3 {
	  display: block;
	}
      
	.af-view .af-class-section-copy.af-class-hero-screenshot-above {
	  margin-top: 0vh;
	  padding-top: 48px;
	}
      
	.af-view
	  .af-class-section-copy.af-class-no-top-space-copy.af-class-pricing-plans-section {
	  padding-top: 48px;
	}
      
	.af-view .af-class-section-copy-2 {
	  padding-top: 48px;
	  padding-bottom: 48px;
	}
      
	.af-view
	  .af-class-section-copy-2.af-class-no-top-space.af-class-pricing-plans-section {
	  padding-top: 48px;
	}
      
	.af-view
	  .af-class-section-copy-2.af-class-no-bottom-space.af-class-bg-primary-3 {
	  display: block;
	}
      
	.af-view .af-class-section-copy-2.af-class-hero-screenshot-above {
	  margin-top: 0vh;
	  padding-top: 48px;
	}
      
	.af-view
	  .af-class-section-copy-2.af-class-no-top-space-copy.af-class-pricing-plans-section {
	  padding-top: 48px;
	}
      
	.af-view .af-class-text-block-20 {
	  padding-right: 0px;
	  font-size: 16px;
	}
      
	.af-view .af-class-text-block-21 {
	  font-size: 16px;
	}
      
	.af-view .af-class-bold-text {
	  font-size: 25px;
	}
      
	.af-view
	  .af-class-pricing-plan-heading-copy.af-class-pricing-comparison-heading {
	  font-size: 16px;
	  font-weight: 500;
	}
      
	.af-view .af-class-container-testimonials-header {
	  display: -webkit-box;
	  display: -webkit-flex;
	  display: -ms-flexbox;
	  display: flex;
	  padding-right: 12px;
	  padding-left: 12px;
	  -webkit-box-pack: center;
	  -webkit-justify-content: center;
	  -ms-flex-pack: center;
	  justify-content: center;
	  -webkit-box-align: start;
	  -webkit-align-items: flex-start;
	  -ms-flex-align: start;
	  align-items: flex-start;
	  grid-auto-columns: 1fr;
	  -ms-grid-columns: 1fr 1fr;
	  grid-template-columns: 1fr 1fr;
	  -ms-grid-rows: auto auto;
	  grid-template-rows: auto auto;
	}
      
	.af-view
	  .af-class-container-testimonials-header.af-class-announcement-bar-container {
	  padding-right: 48px;
	}
      
	.af-view
	  .af-class-container-testimonials-header.af-class-hero-screenshot-container {
	  margin-top: 24px;
	  margin-bottom: 0vh;
	  padding-bottom: 48px;
	}
      
	.af-view .af-class-container-testimonials-pricing {
	  display: block;
	  padding-right: 12px;
	  padding-left: 12px;
	  -webkit-box-pack: center;
	  -webkit-justify-content: center;
	  -ms-flex-pack: center;
	  justify-content: center;
	  -webkit-box-align: start;
	  -webkit-align-items: flex-start;
	  -ms-flex-align: start;
	  align-items: flex-start;
	  grid-auto-columns: 1fr;
	  -ms-grid-columns: 1fr 1fr;
	  grid-template-columns: 1fr 1fr;
	  -ms-grid-rows: auto auto;
	  grid-template-rows: auto auto;
	}
      
	.af-view
	  .af-class-container-testimonials-pricing.af-class-announcement-bar-container {
	  padding-right: 48px;
	}
      
	.af-view
	  .af-class-container-testimonials-pricing.af-class-hero-screenshot-container {
	  margin-top: 24px;
	  margin-bottom: 0vh;
	  padding-bottom: 48px;
	}
      
	.af-view .af-class-container-testimonials-pricing-title {
	  display: -webkit-box;
	  display: -webkit-flex;
	  display: -ms-flexbox;
	  display: flex;
	  padding-right: 12px;
	  padding-left: 12px;
	  -webkit-box-pack: center;
	  -webkit-justify-content: center;
	  -ms-flex-pack: center;
	  justify-content: center;
	  -webkit-box-align: start;
	  -webkit-align-items: flex-start;
	  -ms-flex-align: start;
	  align-items: flex-start;
	  grid-auto-columns: 1fr;
	  -ms-grid-columns: 1fr 1fr;
	  grid-template-columns: 1fr 1fr;
	  -ms-grid-rows: auto auto;
	  grid-template-rows: auto auto;
	}
      
	.af-view
	  .af-class-container-testimonials-pricing-title.af-class-announcement-bar-container {
	  padding-right: 48px;
	}
      
	.af-view
	  .af-class-container-testimonials-pricing-title.af-class-hero-screenshot-container {
	  margin-top: 24px;
	  margin-bottom: 0vh;
	  padding-bottom: 48px;
	}
      
	.af-view .af-class-heading-17 {
	  font-size: 25px;
	  line-height: 30px;
	}
      
	.af-view .af-class-section-pricing-testimonial {
	  padding-top: 48px;
	  padding-bottom: 48px;
	}
      
	.af-view
	  .af-class-section-pricing-testimonial.af-class-no-top-space.af-class-pricing-plans-section {
	  padding-top: 48px;
	}
      
	.af-view
	  .af-class-section-pricing-testimonial.af-class-no-bottom-space.af-class-bg-primary-3 {
	  display: block;
	}
      
	.af-view
	  .af-class-section-pricing-testimonial.af-class-hero-screenshot-above {
	  margin-top: 0vh;
	  padding-top: 48px;
	}
      
	.af-view
	  .af-class-section-pricing-testimonial.af-class-no-top-space-copy.af-class-pricing-plans-section {
	  padding-top: 48px;
	}
      
	.af-view .af-class-section-copy-2-copy {
	  padding-top: 48px;
	  padding-bottom: 48px;
	}
      
	.af-view
	  .af-class-section-copy-2-copy.af-class-no-top-space.af-class-pricing-plans-section {
	  padding-top: 48px;
	}
      
	.af-view
	  .af-class-section-copy-2-copy.af-class-no-bottom-space.af-class-bg-primary-3 {
	  display: block;
	}
      
	.af-view .af-class-section-copy-2-copy.af-class-hero-screenshot-above {
	  margin-top: 0vh;
	  padding-top: 48px;
	}
      
	.af-view
	  .af-class-section-copy-2-copy.af-class-no-top-space-copy.af-class-pricing-plans-section {
	  padding-top: 48px;
	}
      
	.af-view .af-class-section-feature-step-3 {
	  padding-top: 48px;
	  padding-bottom: 48px;
	}
      
	.af-view
	  .af-class-section-feature-step-3.af-class-no-top-space.af-class-pricing-plans-section {
	  padding-top: 48px;
	}
      
	.af-view
	  .af-class-section-feature-step-3.af-class-no-bottom-space.af-class-bg-primary-3 {
	  display: block;
	}
      
	.af-view .af-class-section-feature-step-3.af-class-hero-screenshot-above {
	  margin-top: 0vh;
	  padding-top: 48px;
	}
      
	.af-view
	  .af-class-section-feature-step-3.af-class-no-top-space-copy.af-class-pricing-plans-section {
	  padding-top: 48px;
	}
      
	.af-view .af-class-large-heading-copy {
	  margin-bottom: 12px;
	  font-size: 32px;
	  line-height: 40px;
	}
      
	.af-view .af-class-large-heading-copy.af-class-text-white-copy-22 {
	  margin-top: 40px;
	  font-size: 25px;
	}
      
	.af-view .af-class-heading-6-copy2 {
	  width: 300px;
	  font-size: 20px;
	  text-align: center;
	}
      
	.af-view .af-class-section-copy-2-copy-copy {
	  padding-top: 48px;
	  padding-bottom: 0px;
	}
      
	.af-view
	  .af-class-section-copy-2-copy-copy.af-class-no-top-space.af-class-pricing-plans-section {
	  padding-top: 48px;
	}
      
	.af-view
	  .af-class-section-copy-2-copy-copy.af-class-no-bottom-space.af-class-bg-primary-3 {
	  display: block;
	}
      
	.af-view .af-class-section-copy-2-copy-copy.af-class-hero-screenshot-above {
	  margin-top: 0vh;
	  padding-top: 48px;
	}
      
	.af-view
	  .af-class-section-copy-2-copy-copy.af-class-no-top-space-copy.af-class-pricing-plans-section {
	  padding-top: 48px;
	}
      
	.af-view .af-class-section-copy {
	  padding-top: 48px;
	  padding-bottom: 48px;
	}
      
	.af-view
	  .af-class-section-copy.af-class-no-top-space.af-class-pricing-plans-section {
	  padding-top: 48px;
	}
      
	.af-view
	  .af-class-section-copy.af-class-no-bottom-space.af-class-bg-primary-3 {
	  display: block;
	}
      
	.af-view .af-class-section-copy.af-class-hero-screenshot-above {
	  margin-top: 0vh;
	  padding-top: 48px;
	}
      
	.af-view
	  .af-class-section-copy.af-class-no-top-space-copy.af-class-pricing-plans-section {
	  padding-top: 48px;
	}
      
	.af-view .af-class-section-copy-copy {
	  padding-top: 48px;
	  padding-bottom: 48px;
	}
      
	.af-view
	  .af-class-section-copy-copy.af-class-no-top-space.af-class-pricing-plans-section {
	  padding-top: 48px;
	}
      
	.af-view
	  .af-class-section-copy-copy.af-class-no-bottom-space.af-class-bg-primary-3 {
	  display: block;
	}
      
	.af-view .af-class-section-copy-copy.af-class-hero-screenshot-above {
	  margin-top: 0vh;
	  padding-top: 48px;
	}
      
	.af-view
	  .af-class-section-copy-copy.af-class-no-top-space-copy.af-class-pricing-plans-section {
	  padding-top: 48px;
	}
      
	.af-view .af-class-form-block-2 {
	  margin-top: 0px;
	}
      
	.af-view .af-class-div-block-9 {
	  margin-top: -20px;
	}
      
	.af-view .af-class-div-block-10 {
	  margin-top: -20px;
	}
      
	.af-view .af-class-div-block-11 {
	  margin-top: -20px;
	}
      
	.af-view .af-class-div-block-12 {
	  margin-top: -20px;
	}
      
	.af-view .af-class-button-copy.af-class-button-large {
	  padding: 10px 24px;
	  font-size: 16px;
	  line-height: 24px;
	}
      
	.af-view
	  .af-class-button-copy.af-class-button-large.af-class-add-space-right {
	  margin-right: 0px;
	  margin-bottom: 12px;
	}
      
	.af-view
	  .af-class-button-copy.af-class-button-large.af-class-add-space-right-copy {
	  margin-right: 0px;
	  margin-bottom: 12px;
	}
      
	.af-view
	  .af-class-button-copy.af-class-button-round.af-class-slider-button-left {
	  display: none;
	}
      
	.af-view
	  .af-class-button-copy.af-class-button-round.af-class-slider-button-right {
	  display: none;
	}
      
	.af-view .af-class-button-copy.af-class-submit-button {
	  width: 100%;
	}
      
	.af-view .af-class-button-copy.af-class-navbar-button {
	  display: none;
	}
      
	.af-view .af-class-button-copy.af-class-button-large-copy {
	  padding: 10px 24px;
	  font-size: 16px;
	  line-height: 24px;
	}
      
	.af-view
	  .af-class-button-copy.af-class-button-large-copy.af-class-add-space-right {
	  margin-right: 0px;
	  margin-bottom: 12px;
	}
      
	.af-view
	  .af-class-button-copy.af-class-button-large-copy.af-class-add-space-right-copy {
	  margin-right: 0px;
	  margin-bottom: 12px;
	}
      
	.af-view .af-class-section-conseils {
	  padding-top: 48px;
	  padding-bottom: 48px;
	}
      
	.af-view
	  .af-class-section-conseils.af-class-no-top-space.af-class-pricing-plans-section {
	  padding-top: 48px;
	}
      
	.af-view
	  .af-class-section-conseils.af-class-no-bottom-space.af-class-bg-primary-3 {
	  display: block;
	}
      
	.af-view .af-class-section-conseils.af-class-section-small {
	  padding-left: 20px;
	}
      
	.af-view .af-class-section-conseils.af-class-hero-screenshot-above {
	  margin-top: 0vh;
	  padding-top: 48px;
	}
      
	.af-view
	  .af-class-section-conseils.af-class-no-top-space-copy.af-class-pricing-plans-section {
	  padding-top: 48px;
	}
      
	.af-view .af-class-icon-list-conseils.af-class-pricing-plan-list {
	  margin-top: 16px;
	  margin-bottom: 16px;
	}
      
	.af-view .af-class-icon-list-conseils.af-class-pricing-plan-list-conseils {
	  margin-top: 16px;
	  margin-bottom: 16px;
	}
      
	.af-view .af-class-icon-list-item-conseils {
	  margin-bottom: 20px;
	}
      
	.af-view .af-class-paragraph-large-ml.af-class-text-large {
	  text-align: left;
	}
      
	.af-view
	  .af-class-paragraph-large-ml.af-class-text-large.af-class-case-study-subtitle {
	  display: block;
	}
      
	.af-view .af-class-paragraph-large-ml.af-class-text-large-ml {
	  text-align: left;
	}
      
	.af-view
	  .af-class-paragraph-large-ml.af-class-text-large-ml.af-class-case-study-subtitle {
	  display: block;
	}
      
	.af-view .af-class-content-pair-image-ml {
	  max-width: 100%;
	}
      
	.af-view .af-class-paragraph-large-cc.af-class-text-large {
	  text-align: left;
	}
      
	.af-view
	  .af-class-paragraph-large-cc.af-class-text-large.af-class-case-study-subtitle {
	  display: block;
	}
      
	.af-view .af-class-paragraph-large-cc.af-class-text-large-cc {
	  text-align: left;
	}
      
	.af-view
	  .af-class-paragraph-large-cc.af-class-text-large-cc.af-class-case-study-subtitle {
	  display: block;
	}
      
	.af-view .af-class-content-pair-image-cc {
	  max-width: 100%;
	}
      
	.af-view .af-class-three-up-grid-pricing {
	  display: -ms-grid;
	  display: grid;
	  padding-left: 20px;
	  grid-auto-columns: 1fr;
	  grid-column-gap: 24px;
	  grid-row-gap: 24px;
	  -ms-grid-columns: 1fr;
	  grid-template-columns: 1fr;
	  -ms-grid-rows: auto;
	  grid-template-rows: auto;
	}
      
	.af-view .af-class-hero-section-action-sales {
	  margin-top: 16px;
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -webkit-flex-direction: column;
	  -ms-flex-direction: column;
	  flex-direction: column;
	}
      
	.af-view .af-class-section-sales {
	  padding-top: 48px;
	  padding-bottom: 48px;
	}
      
	.af-view
	  .af-class-section-sales.af-class-no-top-space.af-class-pricing-plans-section {
	  padding-top: 48px;
	}
      
	.af-view
	  .af-class-section-sales.af-class-no-bottom-space.af-class-bg-primary-3 {
	  display: block;
	}
      
	.af-view .af-class-section-sales.af-class-section-small {
	  padding-left: 20px;
	}
      
	.af-view .af-class-section-sales.af-class-hero-screenshot-above {
	  margin-top: 0vh;
	  padding-top: 48px;
	}
      
	.af-view
	  .af-class-section-sales.af-class-no-top-space-copy.af-class-pricing-plans-section {
	  padding-top: 48px;
	}
      
	.af-view .af-class-section-sales.af-class-section-small-sales {
	  padding-left: 20px;
	}
      
	.af-view .af-class-heading-2-home {
	  font-size: 30px;
	  text-align: left;
	}
      
	.af-view .af-class-divider-conseil {
	  height: 8vw;
	}
      
	.af-view .af-class-divider-conseil.af-class-divider-top {
	  margin-bottom: 48px;
	}
      
	.af-view .af-class-divider-conseil.af-class-divider-bottom {
	  margin-top: 48px;
	}
      
	.af-view .af-class-text-white-conseil {
	  font-size: 25px;
	  line-height: 30px;
	}
      
	.af-view .af-class-content-pair-text-about {
	  width: 100%;
	  max-width: none;
	  padding-left: 10px;
	}
      
	.af-view .af-class-content-pair-image-about {
	  max-width: 100%;
	}
      
	.af-view .af-class-text-huge-about {
	  margin-bottom: 8px;
	  font-size: 40px;
	  line-height: 48px;
	}
      
	.af-view .af-class-section-about {
	  padding-top: 48px;
	  padding-bottom: 48px;
	}
      
	.af-view
	  .af-class-section-about.af-class-no-top-space.af-class-pricing-plans-section {
	  padding-top: 48px;
	}
      
	.af-view
	  .af-class-section-about.af-class-no-bottom-space.af-class-bg-primary-3 {
	  display: block;
	}
      
	.af-view .af-class-section-about.af-class-section-small {
	  padding-left: 20px;
	}
      
	.af-view .af-class-section-about.af-class-hero-screenshot-above {
	  margin-top: 0vh;
	  padding-top: 48px;
	}
      
	.af-view
	  .af-class-section-about.af-class-no-top-space-copy.af-class-pricing-plans-section {
	  padding-top: 48px;
	}
      
	.af-view .af-class-section-avatar {
	  padding-top: 48px;
	  padding-bottom: 48px;
	}
      
	.af-view
	  .af-class-section-avatar.af-class-no-top-space.af-class-pricing-plans-section {
	  padding-top: 48px;
	}
      
	.af-view
	  .af-class-section-avatar.af-class-no-bottom-space.af-class-bg-primary-3 {
	  display: block;
	}
      
	.af-view .af-class-section-avatar.af-class-section-small {
	  padding-left: 20px;
	}
      
	.af-view .af-class-section-avatar.af-class-hero-screenshot-above {
	  margin-top: 0vh;
	  padding-top: 48px;
	}
      
	.af-view
	  .af-class-section-avatar.af-class-no-top-space-copy.af-class-pricing-plans-section {
	  padding-top: 48px;
	}
      }
      
      .af-view #w-node-a440c322-bf97-d4c4-2ae3-7957ac2d84c9-ac2d84ab {
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-column: span 2;
	grid-column-start: span 2;
	-ms-grid-column-span: 2;
	grid-column-end: span 2;
      }
      
      .af-view #w-node-caabcef9-af88-c7fa-b8db-1f42ff82d2c8-ff82d2a9 {
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-column: span 2;
	grid-column-start: span 2;
	-ms-grid-column-span: 2;
	grid-column-end: span 2;
      }
      
      .af-view #w-node-bbb5e9f7-da0c-7a73-f05d-8e7344969c4b-5be0546d {
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	-ms-grid-row-align: stretch;
	align-self: stretch;
      }
      
      @media screen and (max-width: 479px) {
	.af-view #w-node-a440c322-bf97-d4c4-2ae3-7957ac2d84b9-ac2d84ab {
	  -ms-grid-column: span 2;
	  grid-column-start: span 2;
	  -ms-grid-column-span: 2;
	  grid-column-end: span 2;
	  -ms-grid-row: span 1;
	  grid-row-start: span 1;
	  -ms-grid-row-span: 1;
	  grid-row-end: span 1;
	}
      
	.af-view #w-node-a440c322-bf97-d4c4-2ae3-7957ac2d84bd-ac2d84ab {
	  -ms-grid-column: span 2;
	  grid-column-start: span 2;
	  -ms-grid-column-span: 2;
	  grid-column-end: span 2;
	  -ms-grid-row: span 1;
	  grid-row-start: span 1;
	  -ms-grid-row-span: 1;
	  grid-row-end: span 1;
	}
      
	.af-view #w-node-a440c322-bf97-d4c4-2ae3-7957ac2d84c1-ac2d84ab {
	  -ms-grid-column: span 2;
	  grid-column-start: span 2;
	  -ms-grid-column-span: 2;
	  grid-column-end: span 2;
	  -ms-grid-row: span 1;
	  grid-row-start: span 1;
	  -ms-grid-row-span: 1;
	  grid-row-end: span 1;
	}
      
	.af-view #w-node-a440c322-bf97-d4c4-2ae3-7957ac2d84c5-ac2d84ab {
	  -ms-grid-column: span 2;
	  grid-column-start: span 2;
	  -ms-grid-column-span: 2;
	  grid-column-end: span 2;
	  -ms-grid-row: span 1;
	  grid-row-start: span 1;
	  -ms-grid-row-span: 1;
	  grid-row-end: span 1;
	}
      
	.af-view #w-node-caabcef9-af88-c7fa-b8db-1f42ff82d2b8-ff82d2a9 {
	  -ms-grid-column: span 2;
	  grid-column-start: span 2;
	  -ms-grid-column-span: 2;
	  grid-column-end: span 2;
	  -ms-grid-row: span 1;
	  grid-row-start: span 1;
	  -ms-grid-row-span: 1;
	  grid-row-end: span 1;
	}
      
	.af-view #w-node-caabcef9-af88-c7fa-b8db-1f42ff82d2bc-ff82d2a9 {
	  -ms-grid-column: span 2;
	  grid-column-start: span 2;
	  -ms-grid-column-span: 2;
	  grid-column-end: span 2;
	  -ms-grid-row: span 1;
	  grid-row-start: span 1;
	  -ms-grid-row-span: 1;
	  grid-row-end: span 1;
	}
      
	.af-view #w-node-caabcef9-af88-c7fa-b8db-1f42ff82d2c0-ff82d2a9 {
	  -ms-grid-column: span 2;
	  grid-column-start: span 2;
	  -ms-grid-column-span: 2;
	  grid-column-end: span 2;
	  -ms-grid-row: span 1;
	  grid-row-start: span 1;
	  -ms-grid-row-span: 1;
	  grid-row-end: span 1;
	}
      
	.af-view #w-node-caabcef9-af88-c7fa-b8db-1f42ff82d2c4-ff82d2a9 {
	  -ms-grid-column: span 2;
	  grid-column-start: span 2;
	  -ms-grid-column-span: 2;
	  grid-column-end: span 2;
	  -ms-grid-row: span 1;
	  grid-row-start: span 1;
	  -ms-grid-row-span: 1;
	  grid-row-end: span 1;
	}
      }
      
      @font-face {
	font-family: "Fa 400";
	src: url("../fonts/fa-regular-400.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
      }
      @font-face {
	font-family: "Fa solid 900";
	src: url("../fonts/fa-solid-900.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
      }
      @font-face {
	font-family: "Fa brands 400";
	src: url("../fonts/fa-brands-400.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
      }
      
`;

export default GlobalStyle;
