import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";

// Material Components
import AppBar from "@material-ui/core/AppBar";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Menu from "@material-ui/core/Menu";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import MUIButton from "@material-ui/core/Button";

// Material Icons
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import MenuIcon from "@material-ui/icons/Menu";

// helpers
import media from "../Helpers/media";

// Components
import Button from "../Common/Button";
import Logo from "../Logo";
import { useBrandConfig } from "../Context/BrandConfigContext";

// Styleds

// the $ is intentional (https://styled-components.com/releases#v5.1.0)
const Bar = styled(AppBar)`
  background-color: ${({ theme, $hasScrolled }) =>
    $hasScrolled ? theme.palette.secondary.contrastText : "transparent"};
  color: ${({ theme, $hasScrolled }) =>
    $hasScrolled ? theme.palette.primary.main : "transparent"};
  box-shadow: ${({ $hasScrolled }) =>
    $hasScrolled
      ? "0px 2px 4px -1px rgba(0,0,0,0), 0px 4px 5px 0px rgba(0,0,0,0.01), 0px 1px 10px 0px rgba(0,0,0,0.12)"
      : "none"};
  height: 100px;
  padding: 15px 40px 0 72px;
  ${media.down.lg`
    padding: 15px 0px;
    height: 90px;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: ${({ theme, $hasScrolled }) =>
      $hasScrolled ? theme.palette.primary.contrastText : "transparent"};
    color: ${({ theme, $hasScrolled }) =>
      $hasScrolled
        ? theme.palette.secondary.main
        : theme.palette.primary.contrastText};
  `};
  width: 100%;
  position: ${(props) => (props.$fixed ? "fixed" : "relative")};
  top: 0;
  transition: all 0.3s;
  z-index: 1101;
`;

const Avatar = styled(AccountCircle)`
  font-size: 48px;
`;
const User = styled(MUIButton)`
  background: none;
  box-shadow: none;
  text-transform: none;
  color: ${({ $hasScrolled, theme }) =>
    $hasScrolled
      ? theme.palette.grey[900]
      : theme.palette.primary.contrastText};
  ${media.down.lg`
    color: ${({ $hasScrolled, theme }) =>
      $hasScrolled
        ? theme.palette.secondary.main
        : theme.palette.primary.contrastText};
  `}
`;
const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  height: 100%;
`;
const StyledButton = styled(Button)`
  height: 40px;
  padding: 0 20px;
`;
const Order = styled(Grid)``;
const ArrowIcon = styled(ArrowForwardIcon)`
  border: 1px solid;
  border-radius: 50px;
  font-size: 10px;
`;
const LinkProducer = styled(Link)`
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  letter-spacing: 0.75px;
  color: ${({ $hasScrolled, theme }) =>
    $hasScrolled ? theme.palette.grey[900] : "#ffffff"};
`;
const StyledAnchor = styled.a`
  text-decoration: none;
  color: ${({ $hasScrolled, theme }) =>
    $hasScrolled ? theme.palette.grey[900] : "#ffffff"};
  :visited {
    color: inherit;
  }
`;

const StyledAnchorMobile = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.grey[900]};
  :visited {
    color: inherit;
  }
`;

const StyledMenu = styled(MenuList)`
  display: flex;
  flex-direction: row;
  color: ${({ $hasScrolled, theme }) =>
    $hasScrolled ? theme.palette.grey[900] : "#ffffff"};
`;

const Options = styled(Menu)``;

const StyledRouterLink = styled(RouterLink)`
  text-decoration: none;
`;

const CurrentUser = styled.span`
  margin-right: 10px;
  font-size: 16px;
`;

const LogoLink = styled(Link)`
  display: block;
  height: 60px;
  width: 210px;
  ${({ $isMobile }) =>
    $isMobile &&
    `
    padding: 10px;
    box-sizing: border-box;
  `}
  img {
    width: 100%;
    height: 100%;
  }
`;

const Header = ({
  user,
  logout,
  width,
  hasScrolled = true,
  fixed,
  hideButton,
}) => {
  const [isOpenMenu, setIsOpenMenu] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { featureFlags = { manageCustomerBasicInfo: true } } = useBrandConfig();
  const isMobile = useMemo(
    () => width === "xs" || width === "sm" || width === "md",
    [width]
  );

  // Handles
  const handleClick = (event) => {
    setIsOpenMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setIsOpenMenu(null);
  };
  const handleLogout = () => {
    logout();
    handleCloseMenu();
  };
  const handleClickMenuMobile = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenuMobile = () => {
    setAnchorEl(null);
  };

  const userLoggedOn = [
    <Link
      key="orders"
      color="inherit"
      underline="none"
      component={RouterLink}
      to="/profile/commandes"
    >
      <MenuItem>Mes commandes</MenuItem>
    </Link>,
    featureFlags.manageCustomerBasicInfo ? (
      <Link
        key="informations"
        color="inherit"
        underline="none"
        component={RouterLink}
        to="/profile/informations"
      >
        <MenuItem onClick={handleCloseMenu}>Mes informations</MenuItem>
      </Link>
    ) : undefined,
    <MenuItem key="logout" onClick={handleLogout}>
      Se déconnecter
    </MenuItem>,
  ].filter((it) => it);

  const noUser = [
    <Link
      key="login"
      color="inherit"
      underline="none"
      component={RouterLink}
      to="/login"
    >
      <MenuItem>Compte client</MenuItem>
    </Link>,
    <StyledAnchor
      key="account"
      $hasScrolled={true}
      href="https://admin.kuupanda.com/"
    >
      <MenuItem>Compte producteur</MenuItem>
    </StyledAnchor>,
  ];

  return (
    <Bar position="static" $hasScrolled={hasScrolled} $fixed={fixed}>
      <StyledToolbar>
        {!isMobile ? (
          <>
            <LogoLink component={RouterLink} to="/" $isMobile={isMobile}>
              {!hasScrolled ? <Logo logoType="LARGE_WHITE" /> : <Logo />}
            </LogoLink>
            <Order
              container
              direction="column"
              item
              xs={2}
              sm={4}
              alignItems="center"
            >
              {!hideButton && (
                <StyledRouterLink to="/search">
                  <StyledButton
                    component="button"
                    size="large"
                    styled={!hasScrolled ? "white" : "primary"}
                  >
                    Passer commande
                  </StyledButton>
                </StyledRouterLink>
              )}
              <LinkProducer
                underline="none"
                href="https://gestion.kuupanda.com/"
                $hasScrolled={hasScrolled}
                target="_blank"
              >
                <ArrowIcon /> Vous êtes producteur ?
              </LinkProducer>
            </Order>

            <StyledMenu $hasScrolled={hasScrolled}>
              <MenuItem>
                <StyledAnchor
                  key="how"
                  $hasScrolled={hasScrolled}
                  href="/comment-ca-marche"
                >
                  Comment ça marche ?
                </StyledAnchor>
              </MenuItem>
              <MenuItem>
                <StyledAnchor
                  key="contact"
                  $hasScrolled={hasScrolled}
                  target="_blank"
                  href="https://gestion.kuupanda.com/contact"
                >
                  Contactez-nous
                </StyledAnchor>
              </MenuItem>
            </StyledMenu>

            <User onClick={handleClick} $hasScrolled={hasScrolled}>
              {user && (
                <CurrentUser>
                  {`${user.userName} ${user.userLastname}`}
                </CurrentUser>
              )}
              <Avatar />
            </User>
            <Options
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              disableScrollLock={true}
              id="simple-menu"
              anchorEl={isOpenMenu}
              keepMounted
              open={!!isOpenMenu}
              onClose={handleCloseMenu}
            >
              {user ? userLoggedOn : noUser}
            </Options>
          </>
        ) : (
          <>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClickMenuMobile}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Options
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              disableScrollLock={true}
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={!!anchorEl}
              onClose={handleCloseMenuMobile}
            >
              <MenuItem>
                <StyledAnchorMobile key="how-mobile" href="/comment-ca-marche">
                  Comment ca marche?
                </StyledAnchorMobile>
              </MenuItem>
              <MenuItem component={RouterLink} to="/contactez-nous">
                <StyledAnchorMobile
                  key="contact-mobile"
                  href="https://gestion.kuupanda.com/contact"
                >
                  Contactez-nous
                </StyledAnchorMobile>
              </MenuItem>
            </Options>
            <LogoLink component={RouterLink} to="/" $isMobile={isMobile}>
              {!hasScrolled ? <Logo logoType="LARGE_WHITE" /> : <Logo />}
            </LogoLink>
            <User onClick={handleClick} $hasScrolled={hasScrolled}>
              <Avatar />
            </User>
            <Options
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              disableScrollLock={true}
              id="simple-menu"
              anchorEl={isOpenMenu}
              keepMounted
              open={!!isOpenMenu}
              onClose={handleCloseMenu}
            >
              {user ? userLoggedOn : noUser}
            </Options>
          </>
        )}
      </StyledToolbar>
    </Bar>
  );
};

Header.propTypes = {
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
  fixed: PropTypes.bool,
  hideButton: PropTypes.bool,
};

Header.defaultProps = {
  fixed: false,
  hideButton: true,
};

export default Header;
