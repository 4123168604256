import React, { useEffect, useState } from "react";
import { LoadingProductItem } from "./ProductItem";
import { Box, Grid } from "@material-ui/core";
import { makeMapFromArray } from "../../services/utils";
import { ProductSection, SECTION_HEADER_Y_PAD } from "./ProductSection";
import Skeleton from "@material-ui/lab/Skeleton";
import { ProductModal } from "./ProductModal";
import AgeConfirmationModal from "../../../Cart/AgeConfirmationModal";
import { useHistory } from "react-router-dom";

interface ProductListProps {
  cart: {
    [id: string]: number;
  };
  categories: any[];
  loading: boolean;
  onCartChange: (p: any) => void;
  onAddToCart: (p: any) => void;
  onRemoveFromCart: (p: any) => void;
  products: any[];
}

export interface ProductsType {
  category: string;
  name: string;
  baseProduct: string;
  variety?: null;
  baseUnit: string;
  saleChannels?: SaleChannels[] | null;
  id: string;
  preparationTime: number;
  description: string;
  tags?: null[] | null;
  maxSaleQuantity?: null;
  childProducts?: ChildProducts[] | null;
  producer: Producer;
  isProductGroup: boolean;
  vat: number;
  price: string;
  picture: Picture;
  pictures?: Pictures[] | null;
  stock?: null;
  availableDate?: null;
  preparationType: string;
  basePrice: number;
  priceReduction: number;
  discounts?: null[] | null;
  weight: number;
  storage: string;
  excludedContactPointTypes?: null[] | null;
  availableQuantity: number;
  ageVerification: boolean;
  size?: string;
}
export interface SaleChannels {
  _id: string;
  code: string;
}
export interface ChildProducts {
  id: string;
  quantity: number;
  baseUnit: string;
  name: string;
}
export interface Producer {
  storeName: string;
}
export interface Picture {
  url: string;
  default: boolean;
}
export interface Pictures {
  _id: string;
  id: string;
  type: string;
  contentType: string;
  size: string;
  url: string;
}

type ProductsWithQuantity = {
  quantity: number;
  size: number;
  product: ProductsType;
};

const ProductList = (props: ProductListProps) => {
  const { loading, products, cart, ...rest } = props;

  const history = useHistory();
  const [selectedProduct, setSelectedProduct] = useState<null | ProductsType>(
    null
  );
  const [showModal, setShowModal] = useState(false);

  const categoryMap = makeMapFromArray(props.categories);

  //TODO: handle size
  const handleAdd = ({
    product,
    quantity,
    size,
  }: {
    product: ProductsType;
    quantity: number;
    size: number;
  }) => {
    const { id: productID, maxSaleQuantity } = product;
    const currentProductQty = props.cart[productID] || 0;
    const newQty = quantity || currentProductQty + 1;
    if (maxSaleQuantity && newQty > maxSaleQuantity) return;
    props.onAddToCart({
      productID,
      quantity: newQty,
    });
    props.onCartChange({
      ...props.cart,
      [productID]: newQty,
    });
  };

  const handleChange = ({
    product,
    quantity,
  }: {
    product: ProductsType;
    quantity: number;
    size: number;
  }) => {
    const { id: productID, maxSaleQuantity } = product;
    if (maxSaleQuantity && quantity > maxSaleQuantity) return;

    const newQty = quantity || 1;

    props.onAddToCart({
      productID,
      quantity: newQty,
    });
    props.onCartChange({
      ...props.cart,
      [productID]: newQty,
    });
  };

  //TODO: handle size
  const handleRemove = ({ product, quantity, size }: ProductsWithQuantity) => {
    const { id: productID } = product;
    const currentProductQty = props.cart[productID] || 0;
    if (currentProductQty === 0) return;
    const newQty = quantity || currentProductQty - 1;
    props.onRemoveFromCart({
      productID,
      quantity: newQty,
    });
    props.onCartChange({
      ...props.cart,
      [productID]: newQty,
    });
  };

  const onModalClose = () => setSelectedProduct(null);

  const handleProductClick = (product: ProductsType) => {
    //set size to null as sizes doesnt work
    setSelectedProduct({ ...product, size: undefined });
  };

  const handleAgeConfirmation = (confirmation: boolean) => {
    if (confirmation) {
      setShowModal(false);
    } else {
      history.push("/search");
    }
  };

  useEffect(() => {
    if (products && products.length > 0) {
      const hasAgeVerificationProducts = products
        .flatMap((cat) => cat.products)
        .some((it) => it.ageVerification);

      if (hasAgeVerificationProducts) {
        setShowModal(true);
      }
    }
  }, [products]);

  return (
    <>
      {loading ? (
        <React.Fragment>
          <Box
            my={SECTION_HEADER_Y_PAD}
            display={"flex"}
            justifyContent={"center"}
          >
            <Skeleton
              animation="wave"
              height={40}
              width={200}
              style={{ display: "inline-block", transform: "none" }}
            />
          </Box>
          <Grid container spacing={2}>
            {Array(5)
              .fill(null)
              .map((e, i) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                  <LoadingProductItem />
                </Grid>
              ))}
          </Grid>
        </React.Fragment>
      ) : (
        <div>
          {products.map((section) => (
            <ProductSection
              section={section}
              key={section.category}
              onAdd={handleAdd}
              onRemove={handleRemove}
              onProductClick={handleProductClick}
              onChange={handleChange}
              categoryNameMap={categoryMap}
              cart={cart}
              {...rest}
            />
          ))}

          <AgeConfirmationModal
            isOpen={showModal}
            handleCloseModal={() => setShowModal(false)}
            handleConfirmation={handleAgeConfirmation}
          />
          <ProductModal
            product={selectedProduct}
            onClose={onModalClose}
            open={Boolean(selectedProduct)}
            onAdd={handleAdd}
            onRemove={handleRemove}
            quantity={selectedProduct ? props.cart[selectedProduct.id] || 0 : 0}
            isAvailable={
              selectedProduct ? selectedProduct.availableQuantity > 0 : 0
            }
            hideAddModal
            {...rest}
          />
        </div>
      )}
    </>
  );
};

export default ProductList;
