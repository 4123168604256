import React from "react";
import PropTypes from "prop-types";
import { Container, Grid, Box } from "@material-ui/core";
import StoreNavigation from "./storeNavigation/StoreNavigation";
import ProductList from "./products/ProductList";
import { makeMapFromArray } from "../services/utils";
import { ScrollTopBtn } from "../components/ScrollTopBtn";
import { StoreHeader } from "./storeHeader/StoreHeader";
import styled from "styled-components";

//meta
import Meta from "../../Common/Meta";

const StyledBox = styled(Box)`
  margin-top: 20px;
  font-size: 16px;
  color: ${({ theme }) => theme.palette.others.brown} !important;
  background-color: ${({ theme }) => theme.palette.info.light};
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid ${({ theme }) => theme.palette.others.yellow};
  border-radius: 0.25rem;
`;

export class Home extends React.Component {
  static propTypes = {
    categories: PropTypes.array.isRequired,
    contactPoints: PropTypes.array,
    deliveryPoints: PropTypes.array,
    products: PropTypes.array,
    store: PropTypes.object,
    isLoading: PropTypes.bool,
    onAddToCart: PropTypes.func.isRequired,
    onRemoveFromCart: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      cart: this.props.cart || {},
    };
  }

  handleCartChange = (cart) => this.setState({ cart });

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.cart !== this.props.cart) {
      this.setState({
        cart: this.props.cart,
      });
    }
  }

  render() {
    const {
      store,
      categories,
      contactPoints,
      deliveryPoints,
      products,
      isLoading,
    } = this.props;

    const categoryNameMap = makeMapFromArray(this.props.categories);

    const { cart } = this.state;
    const isStoreClosed = isLoading ? false : store.clientClosedStore;
    const messageStoreClosed = isLoading ? "" : store.closedStoreMessage;
    const activeContactPoints =
      (!isLoading &&
        contactPoints.filter(
          (cp) =>
            !cp.activityPeriod ||
            (cp.activityPeriod &&
              new Date(cp.activityPeriod.endDate) > Date.now())
        )) ||
      [];

    const { storeName, city, storeSlug, bannerUrl } = store;

    return (
      <Container maxWidth={"lg"}>
        {!isLoading && (
          <Meta
            title={`${storeName} - Commandez vos produits locaux sur kuupanda!`}
            description={`${storeName}, producteur à ${city}. Découvrez des produits locaux, autour de chez vous.`}
            keywords={`${storeName},local,produits,locaux,${city},circuit,court`}
            ogTitle={`${storeName} - Découvrez mes produits sur Kuupanda.`}
            ogDescription="Trouvez des producteurs locaux proches de chez vous et commandez sans intermédiaire pour des produits de qualité à des prix accessibles."
            ogURL={`https://kuupanda.com/boutique/${storeSlug}`}
            ogImage={bannerUrl}
          />
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <StoreNavigation
              store={store}
              products={products}
              categoryNameMap={categoryNameMap}
              loading={isLoading}
              categories={categories}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <StoreHeader
              store={store}
              contactPoints={activeContactPoints}
              deliveryPoints={deliveryPoints}
              loading={isLoading}
            />
            {!isStoreClosed && (
              <ProductList
                products={products}
                categories={categories}
                categoryNameMap={categoryNameMap}
                cart={cart}
                loading={isLoading}
                onCartChange={this.handleCartChange}
                onAddToCart={(cartInfo) => {
                  this.props.onAddToCart({ ...cartInfo, store });
                }}
                onRemoveFromCart={this.props.onRemoveFromCart}
              />
            )}
            {isStoreClosed && (
              <StyledBox>
                {messageStoreClosed && messageStoreClosed.length > 0
                  ? messageStoreClosed
                  : "Désolé mais la boutique est fermée. On se charge de revenir au plus vite avec des produits toujours plus savoureux !"}
              </StyledBox>
            )}
            <ScrollTopBtn />
          </Grid>
        </Grid>
      </Container>
    );
  }
}
