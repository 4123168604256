import styled from "styled-components";
const GlobalLoader = styled.div`
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  position: absolute;
  top: calc(50% - 59px);
  left: calc(50% - 39px);
  width: 64px;
  height: 64px;
  border: 0.4em solid rgba(238, 64, 108, 0.2);
  // Theme could be undefined when this GlobalLoader is used on #BrandConfigProvider
  border-left: 0.4em solid
    ${({ theme }) => theme?.palette?.primary?.main || "#ee406c"};
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
`;

export default GlobalLoader;
