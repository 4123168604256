import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Component
import Stepper, { stepPropTypes, useActiveStep } from "./Stepper";

const RouterStepper = ({
  steps,
  history,
  match,
  baseURL,
  beforeGoToStep,
  afterGoToStep,
  onCompleted,
  buttonController,
}) => {
  const [activeStep] = useActiveStep(steps, {
    match,
  });

  const getStepUrl = useCallback(({ step }) => `${baseURL}/${step}`, [baseURL]);

  const goToStep = useCallback(
    ({ key, redirect }) => {
      beforeGoToStep(key);
      if (!redirect) {
        history.push(getStepUrl({ step: key }));
      } else {
        history.push(redirect);
      }
      afterGoToStep(key);
    },
    [getStepUrl, history, beforeGoToStep, afterGoToStep]
  );
  const handleNext = useCallback(() => {
    const nextIndex =
      steps.findIndex((step) => step.key === activeStep.key) + 1;
    goToStep({ key: steps[nextIndex].key });
  }, [steps, activeStep, goToStep]);

  const handleBack = useCallback(
    ({ redirect }) => {
      const previousIndex =
        steps.findIndex((step) => step.key === activeStep.key) - 1;
      goToStep({ key: steps[previousIndex].key, redirect });
    },
    [steps, activeStep, goToStep]
  );

  return (
    <Stepper
      steps={steps}
      activeStep={activeStep}
      buttonController={{
        back: {
          ...buttonController.back,
          onClick: () =>
            handleBack({ redirect: buttonController.back.redirect }),
        },
        next: {
          ...buttonController.next,
          onClick: handleNext,
          disabled: !activeStep.completed || buttonController.next.disabled,
        },
        completed: onCompleted && {
          ...buttonController.completed,
          onClick: onCompleted,
        },
      }}
      goToStep={goToStep}
    >
      {activeStep.component}
    </Stepper>
  );
};

const controllerButtonPropTypes = {
  label: PropTypes.string.isRequired,
  hide: PropTypes.bool,
};

RouterStepper.propTypes = {
  history: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired,
  baseURL: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape(stepPropTypes)).isRequired,
  buttonController: PropTypes.shape({
    back: PropTypes.shape({
      ...controllerButtonPropTypes,
      redirect: PropTypes.string,
    }),
    next: PropTypes.shape(controllerButtonPropTypes),
    completed: PropTypes.shape({
      ...controllerButtonPropTypes,
      onlyLastStep: PropTypes.bool,
    }),
  }),
  beforeGoToStep: PropTypes.func,
  afterGoToStep: PropTypes.func,
  onCompleted: PropTypes.func,
};

RouterStepper.defaultProps = {
  beforeGoToStep: () => {},
  afterGoToStep: () => {},
  onCompleted: null,
  buttonController: {
    back: {
      label: "Précedent",
      hide: false,
      redirect: undefined,
    },
    next: {
      label: "next",
      hide: false,
    },
    completed: {
      label: "completed",
      onlyLastStep: false,
      hide: true,
      disabled: false,
    },
  },
};

export default withRouter(RouterStepper);
