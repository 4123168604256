import React, { useState } from "react";
import {
  AppBar,
  Box,
  Card,
  CardContent,
  CardMedia,
  Hidden,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { TheNavBar } from "../../components/TheNavBar";
import { PresentationContent } from "./PresentationContent";
import { LocationContent } from "./location/LocationContent";
import { InformationContent } from "./InformationContent";
import { PanelExpandContainer } from "./PanelExpandContainer";
import { TAB_KEYS, TabPanel, TABS_STATIC } from "./TabUtils";
import { ContactPointRow } from "./location/ContactPointRow";
import { DeliveryRow } from "./location/DeliveryRow";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import Skeleton from "@material-ui/lab/Skeleton";
import LoadScriptOnlyIfNeeded from "../../../Common/LoadScriptOnlyIfNeeded";
import styled from "styled-components";
import ResponsivePicture from "../../../Common/ResponsivePicture";

const API_KEY = "AIzaSyBvpOINUfTU3iGWSNwsjBwiBz9KlSQJPVI";

const Container = React.memo(LoadScriptOnlyIfNeeded);
const StyledResponsivePicture = styled(ResponsivePicture)``;
export const StoreHeader = React.memo(function StoreHeader({
  store,
  deliveryPoints,
  contactPoints,
  loading,
}) {
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
  const [tab, setTab] = useState(null);

  if (loading) {
    return (
      <Card>
        <Skeleton
          animation="wave"
          variant="rect"
          style={{ width: "100%", height: 200 }}
        />
        <Hidden smDown>
          <AppBar color="default" position="sticky" elevation={1}>
            <Tabs
              value={0}
              indicatorColor="primary"
              textColor="primary"
              variant={"fullWidth"}
            >
              {Array(4)
                .fill(null)
                .map((e, i) => (
                  <Tab
                    key={`loadingTab${i}`}
                    icon={
                      <Skeleton
                        animation="wave"
                        variant="circle"
                        height={20}
                        width={20}
                      />
                    }
                    label={
                      <Skeleton animation="wave" height={20} width="60%" />
                    }
                  />
                ))}
            </Tabs>
          </AppBar>

          <TabPanel index={0} value={0}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              style={{ height: 150, padding: 16 }}
            >
              {Array(4)
                .fill(null)
                .map((e, i) => (
                  <Skeleton
                    key={i}
                    animation="wave"
                    height={20}
                    width="100%"
                    style={{ marginBottom: 10 }}
                  />
                ))}
            </Box>
          </TabPanel>
          <TabPanel index={1} value={0} />
          <TabPanel index={2} value={0} />
          <TabPanel index={3} value={0} />
        </Hidden>
        <Hidden mdUp>
          {Array(4)
            .fill(null)
            .map((e, i) => (
              <Box
                key={`loadingMobileContainerTab${i}`}
                height={65}
                style={{
                  padding: "0 24px 0 24px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, .03)",
                  border: "1px solid rgba(0, 0, 0, 0.05)",
                }}
              >
                <Skeleton
                  animation="wave"
                  variant="circle"
                  height={20}
                  width={20}
                />
                <Skeleton
                  animation="wave"
                  variant="rect"
                  height={20}
                  width="80%"
                />
              </Box>
            ))}
        </Hidden>
      </Card>
    );
  }
  if (tab === null && !isSM) setTab(0);

  const handlePanelToggle = (index) => {
    const isClosing = index === tab;
    setTab(isClosing ? null : index);
    // we have one panel collapsig and the other expanding, too hard to find the right timing to scroll
    // we're always scrolling at the wrong location
    // if (!isClosing) scrollTo(`#HeaderPanelExpand_${index}`, {behavior: 'smooth' });
  };

  const presentation = (
    <PresentationContent presentation={store.presentation} />
  );
  const contactPointsContent = (
    <LocationContent
      contactPoints={contactPoints}
      RowComponent={ContactPointRow}
      mapTitle={"Points de retraits"}
      isDelivery={false}
    />
  );
  const deliveryPointsContent = (
    <LocationContent
      contactPoints={deliveryPoints}
      mapTitle={
        <Box display={"flex"} alignItems={"center"}>
          <LocalShippingIcon fontSize={"small"} />
          &nbsp;&nbsp;Livraisons
        </Box>
      }
      RowComponent={DeliveryRow}
      isDelivery={true}
    />
  );
  const information = <InformationContent store={store} />;
  const doesDelivery = !!deliveryPoints.length;
  const doesTakeAway = !!contactPoints.length;
  let tabStatics = doesDelivery
    ? TABS_STATIC
    : TABS_STATIC.filter((e, i) => i !== 2);

  tabStatics = doesTakeAway ? tabStatics : tabStatics.filter((e, i) => i !== 1);

  return (
    <Container
      googleMapsApiKey={API_KEY}
      loadingElement={<div className="SearchMap__loader" />}
    >
      <Card>
        <CardMedia
          component={() => (
            <StyledResponsivePicture
              height={isSM ? 100 : 250}
              sizeQuery="(min-width: 960px) 60vw, (max-width: 959px) 95vw"
              baseImgUrl={store.bannerUrl}
              fitType="cover"
            />
          )}
        />
        <Hidden smDown>
          <TheNavBar value={tab} onChange={setTab} tabStatic={tabStatics} />
          <TabPanel
            index={tabStatics.findIndex(
              (it) => it.key === TAB_KEYS.DESCRIPTION
            )}
            value={tab}
          >
            <CardContent>{presentation}</CardContent>
          </TabPanel>
          {doesTakeAway && (
            <TabPanel
              index={tabStatics.findIndex(
                (it) => it.key === TAB_KEYS.CONTACT_POINTS
              )}
              value={tab}
            >
              <CardContent>{contactPointsContent}</CardContent>
            </TabPanel>
          )}
          {doesDelivery && (
            <TabPanel
              index={tabStatics.findIndex((it) => it.key === TAB_KEYS.DELIVERY)}
              value={tab}
            >
              <CardContent>{deliveryPointsContent}</CardContent>
            </TabPanel>
          )}
          <TabPanel
            index={tabStatics.findIndex(
              (it) => it.key === TAB_KEYS.INFORMATION
            )}
            value={tab}
          >
            <CardContent>{information}</CardContent>
          </TabPanel>
          {/*</CardContent>*/}
        </Hidden>
        <Hidden mdUp>
          <PanelExpandContainer
            tabInfo={tabStatics[0]}
            index={0}
            value={tab}
            onChange={handlePanelToggle}
          >
            {presentation}
          </PanelExpandContainer>
          {doesTakeAway && (
            <PanelExpandContainer
              tabInfo={tabStatics[1]}
              index={1}
              value={tab}
              onChange={handlePanelToggle}
              panelContentProps={{
                style: { padding: 0 },
              }}
            >
              {contactPointsContent}
            </PanelExpandContainer>
          )}
          {doesDelivery && (
            <PanelExpandContainer
              tabInfo={tabStatics[2]}
              index={2}
              value={tab}
              onChange={handlePanelToggle}
              panelContentProps={{
                style: { padding: 0 },
              }}
            >
              {deliveryPointsContent}
            </PanelExpandContainer>
          )}
          <PanelExpandContainer
            tabInfo={tabStatics[tabStatics.length - 1]}
            index={tabStatics.length - 1}
            value={tab}
            onChange={handlePanelToggle}
          >
            {information}
          </PanelExpandContainer>
        </Hidden>
      </Card>
    </Container>
  );
});

StoreHeader.propTypes = {
  store: PropTypes.any,
  contactPoints: PropTypes.array,
  deliveryPoints: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
};
