import React from "react";
import {
  Chip,
  Divider,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import PropTypes from "prop-types";

export const CategoryRow = React.memo(function CategoryRow({
  category,
  categoryNameMap,
  onClick,
}) {
  return (
    <React.Fragment>
      <Divider />
      <ListItem dense button onClick={() => onClick(category.category)}>
        <ListItemText>{categoryNameMap[category.category]}</ListItemText>
        <ListItemSecondaryAction>
          <Chip color={"secondary"} size={"small"} label={category.count} />
        </ListItemSecondaryAction>
      </ListItem>
    </React.Fragment>
  );
});

CategoryRow.propTypes = {
  category: PropTypes.object.isRequired,
  categoryNameMap: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};
export const LoadingCategoryRow = React.memo(function LoadingCategoryRow() {
  return (
    <ListItem dense>
      <ListItemText>
        <Skeleton animation="wave" variant="text" width={"90%"} />
      </ListItemText>
      <ListItemSecondaryAction>
        <Skeleton
          animation="wave"
          variant="circle"
          width={"1.6em"}
          height={"1.6em"}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
});
