import PropTypes from "prop-types";
import React from "react";
import { AppBar, Tab, Tabs, useMediaQuery, useTheme } from "@material-ui/core";

export function TheNavBar({ value, onChange, tabStatic }) {
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });

  return (
    <AppBar
      id="store_header_navbar_tabs"
      color="default"
      position="sticky"
      elevation={1}
    >
      <Tabs
        // style={{ borderBottom: '1px solid #e8e8e8',}}
        value={value}
        onChange={(e, v) => onChange(v)}
        indicatorColor="primary"
        textColor="primary"
        variant={"fullWidth"}
      >
        {tabStatic.map((tab, i) => (
          <TheTab key={i} isSM={isSM} {...tab} />
        ))}
      </Tabs>
    </AppBar>
  );
}

TheNavBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  tabStatic: PropTypes.array.isRequired,
};

const TheTab = React.forwardRef(function TheTab(
  { isSM, label, Icon, ...props },
  ref
) {
  // was using "large" fors small device, when we're hidding the text
  const iconSize = isSM ? "default" : "default";
  return (
    <Tab
      icon={<Icon fontSize={iconSize} />}
      label={isSM ? null : label}
      ref={ref}
      {...props}
    />
  );
});

TheTab.propTypes = {
  Icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  isSM: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};
