import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import Hidden from "@material-ui/core/Hidden";
import { Box, Modal, withTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import memoizeOne from "memoize-one";
import { withRouter } from "react-router";
import styled from "styled-components";
import { PROPTYPES } from "./utils";
import { withDataFormatter } from "./withDataFormatter";
import { MapWithApi } from "./Map/MapWithApi";
import { UserList } from "./List/UserList";
import { Filters, setLocalStorageFilter } from "./Filters/Filters";
import { DEFAULT_CENTER, DEFAULT_ZOOM } from "../Common/constants";
import {
  filterMakers as filterMakersRaw,
  filterUsers as filterUsersRaw,
} from "./searchService";
import { SwitchViewModeBtn } from "./SwitchViewModeBtn";
import "./ProducerList.scss";
import LoadScriptOnlyIfNeeded from "../Common/LoadScriptOnlyIfNeeded";
import { filtersToUrlQuery } from "./searchService";
import media from "../Helpers/media";

//kuupanda
const API_KEY = "AIzaSyBvpOINUfTU3iGWSNwsjBwiBz9KlSQJPVI";

const filterMarkers = memoizeOne(filterMakersRaw);

const filterUsers = memoizeOne(filterUsersRaw);

const StyledGrid = styled(Grid)`
  margin-top: 100px;
  height: 100%;
  ${media.down.md`
    margin-top: 70px;
  `}
`;

class ProducerList extends React.Component {
  static propTypes = {
    defaultZoom: PropTypes.number,
    defaultCenter: PropTypes.shape(PROPTYPES.coordShape),
    markers: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    tags: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
    products: PropTypes.array.isRequired,
    minRadius: PropTypes.number,
    maxRadius: PropTypes.number,
    defaultRadius: PropTypes.number,
    url: PropTypes.string,
    filters: PropTypes.any.isRequired,
  };
  static defaultProps = {
    defaultCenter: DEFAULT_CENTER,
    defaultZoom: DEFAULT_ZOOM,
    defaultRadius: 30,
  };

  constructor(props) {
    super(props);

    this.getUsersMap = memoizeOne(this._getUsersMap);

    // We need `places` for the place autocomplete search input
    this.librariesToLoad = ["places", "geometry"];

    this.state = {
      openMarkerID: null,
      openMarkerUserID: null,
      openMarkPosition: null,
      hoveredUserID: null,
      filters: props.filters,
      googleLoaded: false,
      showMapInMobile: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.users, prevProps.users)) {
      this.getUsersMap = memoizeOne(this._getUsersMap);
    }
  }

  // use this.getUserMap (memoized)
  _getUsersMap = () => {
    return this.props.users.reduce((acc, user) => {
      acc[user.id] = user;
      return acc;
    }, {});
  };

  _onMarkerClick = ({ markerID, markerPosition, userID }) => {
    this.setState({
      openMarkerID: markerID,
      openMarkPosition: markerPosition,
      openMarkerUserID: userID,
    });
  };

  _onFiltersChange = (filters) => {
    this.setState({ filters });
  };

  _onFilterSubmit = (field) => {
    setLocalStorageFilter(this.state.filters);
    this.performSearch(this.state.filters, field);
  };

  performSearch = (filters, field) => {
    this.setState({ filters });
    if (
      field === "radius" ||
      field === "lat" ||
      field === "lng" ||
      field === "location" ||
      field === "product_name" ||
      field === "address"
    ) {
      this.props.updateFilters(filters);
    }

    const url = filtersToUrlQuery(filters);

    this.props.history.push(`search?${url}`);
  };

  onUserHover = (userID) => {
    this.setState({ hoveredUserID: userID });
  };

  handleSwitchMobileMode = () => {
    this.setState({ showMapInMobile: !this.state.showMapInMobile });
  };

  render() {
    const {
      defaultZoom,
      markers: allMarkers,
      users: allUsers,
      tags,
      categories,
      products,
      minRadius,
      maxRadius,
      defaultRadius,
      isPageLoading,
    } = this.props;
    const {
      openMarkerID,
      openMarkPosition,
      hoveredUserID,
      openMarkerUserID,
      filters,
      showMapInMobile,
      googleLoaded,
    } = this.state;
    const usersMap = this.getUsersMap();
    // we need google maps lib to perform the search. To know if a user is in the delivery range
    const users = googleLoaded ? filterUsers(allUsers, filters) : [];
    const markers = googleLoaded
      ? filterMarkers(users, allMarkers, filters)
      : [];
    const userList = (
      <UserList
        users={users}
        markers={markers}
        onHoverChange={this.onUserHover}
        openMarkerID={openMarkerID}
        openMarkerUserID={openMarkerUserID}
      />
    );
    const selectedLocation =
      filters.location && filters.location.address
        ? {
            lat: filters.location.lat,
            lng: filters.location.lng,
          }
        : {};

    const map = (
      <Box width={1} height={1}>
        <MapWithApi
          defaultZoom={defaultZoom}
          defaultCenter={openMarkPosition ? openMarkPosition : selectedLocation}
          apiKey={API_KEY}
          markers={markers}
          onMarkerClick={this._onMarkerClick}
          openMarkerID={openMarkerID}
          hoveredUserID={hoveredUserID}
          usersMap={usersMap}
          showBusinesses={false}
          title={
            filters.onlyDelivery
              ? "Producteurs qui livrent à domicile"
              : "Points de retraits des producteurs"
          }
        />
      </Box>
    );
    return (
      <React.Fragment>
        <LoadScriptOnlyIfNeeded
          id="script-loader"
          googleMapsApiKey={API_KEY}
          libraries={this.librariesToLoad}
          loadingElement={<div className="SearchMap__loader" />}
          onLoad={() => this.setState({ googleLoaded: true })}
        >
          <StyledGrid container className="SearchMap">
            <Grid className="SearchMap__left" item xs={12} sm={12} md={7}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                flexWrap={"nowrap"}
                className="SearchMap__left__box"
                style={{ height: "100%" }}
              >
                <Filters
                  values={filters}
                  defaultValues={filters}
                  onChange={this._onFiltersChange}
                  tags={tags}
                  categories={categories}
                  products={products}
                  onSubmit={this._onFilterSubmit}
                  turnOffLocationEditAfterSubmit={true}
                  resultCount={users.length}
                  minRadius={minRadius}
                  maxRadius={maxRadius}
                  defaultRadius={defaultRadius}
                  isMap={showMapInMobile}
                  showRadius={false}
                />
                <Hidden smDown>
                  <div
                    className="UserListGridContainer"
                    style={{ padding: "0 16px", height: "100%" }}
                  >
                    {userList}
                  </div>
                </Hidden>
                <Hidden mdUp>
                  {showMapInMobile ? (
                    <div
                      className="MapContainer"
                      style={{ height: "calc(100vh - 90px)" }}
                    >
                      {map}
                    </div>
                  ) : (
                    <div
                      className="UserListGridContainer"
                      style={{ padding: "0 16px 0 16px", height: "100%" }}
                    >
                      {userList}
                    </div>
                  )}
                </Hidden>
              </Box>
            </Grid>
            <Hidden smDown>
              <Grid
                className="SearchMap__right"
                item
                xs={12}
                sm={5}
                style={{
                  position: "fixed",
                  top: 14,
                  right: 0,
                  height: "calc(100vh - 90px)",
                  border: 0,
                  bottom: 0,
                  width: "41.6666667%",
                  marginTop: 89,
                }}
              >
                {map}
              </Grid>
            </Hidden>
          </StyledGrid>
          <SwitchViewModeBtn
            isMap={showMapInMobile}
            onClick={this.handleSwitchMobileMode}
          />
        </LoadScriptOnlyIfNeeded>
        <Modal
          open={isPageLoading}
          BackdropProps={{ style: { backgroundColor: "#ffffffa8" } }}
        >
          <div className="SearchMap__loader" />
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(withDataFormatter(withTheme(ProducerList)));
