import React from "react";
import { withStyles, Button } from "@material-ui/core";

export const SecondaryActionButton = React.forwardRef((props, ref) => {
  return (
    <StyledButton variant="text" size="small" {...props} ref={ref}>
      {props.children}
    </StyledButton>
  );
});

export const StyledButton = withStyles((theme) => ({
  root: {
    // fontSize: '0.82rem',
    fontWeight: 400,
    padding: theme.spacing(0, 1),
  },
  label: {
    textTransform: "none",
    paddingtop: theme.spacing(1),
  },
}))(Button);

export const withSecondaryIcon = (placement) => {
  return withStyles((theme) => ({
    root: {
      fontSize: "1rem",
      [placement === "right" ? "marginLeft" : "marginRight"]: theme.spacing(
        0.5
      ),
      // fontWeight: 400,
      // padding: theme.spacing(0, 1),
    },
  }));
};
