import React from "react";
import PropTypes from "prop-types";
import {
  ExpansionPanel as MuiExpansionPanel,
  ExpansionPanelDetails as MuiExpansionPanelDetails,
  ExpansionPanelSummary as MuiExpansionPanelSummary,
  Typography,
  withStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const ExpansionPanel = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    borderBottom: 0,
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 65,
    "&$expanded": {
      // minHeight: 56,
      color: theme.palette.primary.main,
    },
  },
  content: {
    "&$expanded": {
      // margin: '12px 0',
    },
  },
  expanded: {},
}))(MuiExpansionPanelSummary);
const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);

export const PanelExpandContainer = React.memo(function PanelExpandContainer({
  children,
  tabInfo,
  value,
  index,
  panelContentProps,
  onChange,
}) {
  const { label, Icon } = tabInfo;
  const expanded = value === index;
  const iconColor = expanded ? "primary" : "action";
  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={() => onChange(index)}
      id={`HeaderPanelExpand_${index}`}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon color={iconColor} />}>
        <Icon style={{ marginRight: 8 }} color={iconColor} />
        <Typography>{label}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails {...panelContentProps}>
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
});

PanelExpandContainer.propTypes = {
  children: PropTypes.any,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  panelContentProps: PropTypes.object,
  tabInfo: PropTypes.object.isRequired,
  value: PropTypes.number,
};

PanelExpandContainer.defaultProps = {};
