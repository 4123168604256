import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import Container from "@material-ui/core/Container";
import styled, { createGlobalStyle } from "styled-components";
import { Box, Card, Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Swal from "sweetalert2";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import SimplifiedHeader from "./SimplifiedHeader";
import SimplifiedProductList from "./SimplifiedProductList";
import SimplifiedCheckout from "./SimplifiedCheckout";
import SimplifiedCustomerForm from "./SimplifiedCustomerForm";
import SimplifiedOrderSummary from "./SimplifiedOrderSummary";
import { baseMargin } from "./styles";
import constants from "../VendorStore/utils/constants";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import useStoreInfo from "../Hooks/useStoreInfo";
import { useApiConfig } from "../Context/ApiContext";
import httpClient from "../httpClient";
import PresentationContext from "../Context/PresentationContext";
import * as queryString from "query-string";
import { SIZES } from "../Common/constants";
import {
  getMaxAvailableDate,
  getMaxPreparationTime,
} from "../Helpers/contactPoint";
import localStorageManager from "../Helpers/localStorageManager";
import { storeCartSimplifiedStore } from "../Helpers/localStorageManager";
import { isValidEmail } from "../Helpers/validation";
import { calculateDueTo } from "../Helpers/cart";
import Meta from "../Common/Meta";
import AgeConfirmationModal from "../Cart/AgeConfirmationModal.tsx";

const SuccessContainer = styled.div`
  min-height: ${(props) => (!props.isEmbedded ? "80vh" : "450px")};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;
const GoBackButton = styled(Button)`
  margin-top: 16px;
`;

const LOCAL_STORAGE_KEYS = {
  CUSTOMER_INFO: "kuuCustomerInfo",
};

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: auto;
  }
  @media (min-width: 960px) {
    ${(props) =>
      !props.isEmbedded &&
      ` body {
        &::before {
          background-image: url('https://s3.eu-west-3.amazonaws.com/kuu.public/img/form-background-min.png');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          content: '';
          height: 100%;
          left: 0;
          position: fixed;
          top: 0;
          width: 100%;
          will-change: transform;
          z-index: -1;
        }
      }`}  
  }
`;

const StyledContainer = styled(Container)`
  margin-bottom: 48px;
  max-width: 960px;
  background-color: white;
  box-shadow: 0 0 50px rgb(0, 0, 0, 0.1);
  margin-top: 10px;
  border-radius: 15px;
  @media (min-width: 600px) {
    padding: 0;
  }
  @media (max-width: 600px) {
    padding: 0 10px;
  }
`;

const StyledContent = styled.div`
  padding-bottom: 70px;
`;

const SimplifiedHeaderLoader = styled(Card)`
  ${baseMargin}
`;
const SimplifiedProductListLoader = styled(Card)`
  ${baseMargin}
`;
const LoadingRowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const LoadingHeaderContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledBox = styled(Box)`
  margin-top: 20px;
  font-size: 16px;
  color: ${({ theme }) => theme.palette.others.brown} !important;
  background-color: ${({ theme }) => theme.palette.info.light};
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.palette.others.yellow};
`;

const initialCustomerInfo = (saleChannel) => {
  const customerInfo =
    JSON.parse(localStorageManager.getItem(LOCAL_STORAGE_KEYS.CUSTOMER_INFO)) ||
    {};
  if (saleChannel === constants.SALE_CHANNEL.STANDARD) {
    delete customerInfo.company;
  }
  return customerInfo;
};

function validateCustomerInfo(customerInfo, saleChannel) {
  return Boolean(
    (saleChannel === constants.SALE_CHANNEL.PROFESSIONAL
      ? customerInfo.company
      : true) &&
      (saleChannel === constants.SALE_CHANNEL.STANDARD
        ? customerInfo.name
        : true) &&
      (saleChannel === constants.SALE_CHANNEL.STANDARD
        ? customerInfo.lastName
        : true) &&
      customerInfo.email &&
      isValidEmail(customerInfo.email) &&
      customerInfo.phone
  );
}
const initialCart = {};
// const initialCart = {
//   "5ef63a59523c5b002ca33fc5": {
//     quantity: 1,
//     product: {
//       category: "Lots",
//       name: "Combo Test",
//       baseProduct: "Combo",
//       variety: null,
//       baseUnit: "unit",
//       clientId: 25263,
//       saleChannels: [
//         { _id: "5f66ec8f7cf67f002baff1d6", code: "PROFESSIONAL" },
//         { _id: "5f66ec8f7cf67f002baff1d5", code: "STANDARD" },
//       ],
//       id: "5ef63a59523c5b002ca33fc5",
//       preparationTime: 0,
//       description: "Desc",
//       tags: [],
//       maxSaleQuantity: null,
//       childProducts: [
//         { quantity: 1, baseUnit: "unit", name: "Brioche" },
//         { quantity: 1, baseUnit: "unit", name: "Cerise" },
//         { quantity: 1, baseUnit: "unit", name: "Creme" },
//         { quantity: 2, baseUnit: "unit", name: "Miel" },
//         { quantity: 1, baseUnit: "unit", name: "Ail IE" },
//       ],
//       producer: {},
//       isProductGroup: false,
//       price: "35.00",
//       picture: {
//         url:
//           "https://s3.eu-west-3.amazonaws.com/kuu.imgs/original/product/default/_COMBO.png",
//         default: true,
//       },
//       stock: null,
//       availableDate: null,
//       preparationType: "DELAY",
//       basePrice: 35,
//       priceReduction: 0,
//       discounts: [],
//       availableQuantity: 50,
//       categoryCode: "_COMBO",
//     },
//   },
// };
function SimplifiedStore({
  isLoading,
  categories,
  productsByCategory,
  store,
  contactPoints,
  producerId,
  saleChannel,
  onSubmit,
  isGroup,
  validCodes,
  couponLines,
  setCodes,
  initialCodes,
  codesEvaluated,
  codes,
  sizes,
}) {
  const { isEmbedded } = useContext(PresentationContext);
  const history = useHistory();
  const location = useLocation();
  let { path, url } = useRouteMatch();
  const { storeFrontSlug } = useParams();

  const [customerInfo, setCustomerInfo] = useState(
    initialCustomerInfo(saleChannel)
  );
  const [recoveryInfo, setRecoveryInfo] = useState({});
  const [cart, setCart] = useState(initialCart);
  const [comment, setComment] = useState(undefined);

  const [isCustomerInfoValid, setIsCustomerInfoValid] = useState(
    validateCustomerInfo(customerInfo, saleChannel)
  );
  const [isRecoveryInfoValid, setIsRecoveryInfoValid] = useState(false);
  const [isCartValid, setIsCartValid] = useState(
    Object.values(cart).find((it) => it.quantity > 0)
  );

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isPaymentBeingProcessed, setIsPaymentBeingProcessed] = useState(false);
  const [disableNextButtonState, setDisableNextButton] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [ageRestrictedProduct, setAgeRestrictedProduct] = useState(false);

  const isFormValid = isCustomerInfoValid && isRecoveryInfoValid && isCartValid;
  const deliveryRate =
    (recoveryInfo && recoveryInfo.delivery && recoveryInfo.delivery.rate) || 0;

  const codeEvaluationStatus = useMemo(() => {
    if (!codesEvaluated) {
      return constants.CODE_EVALUATION_STATUS.UNDETERMINED;
    }
    if (!validCodes || validCodes.length === 0) {
      return constants.CODE_EVALUATION_STATUS.INVALID;
    }
    if (validCodes && validCodes.length > 0) {
      return constants.CODE_EVALUATION_STATUS.VALID;
    }
  }, [validCodes, codesEvaluated]);

  useEffect(() => {
    if (!productsByCategory || productsByCategory.length === 0) {
      return;
    }
    const newCart = { ...cart };
    let hasCartChanged = false;
    Object.values(newCart).forEach(({ product }) => {
      const categoryProducts = productsByCategory.find(
        (it) => it.category === product.categoryCode
      ).products;
      const newProduct = categoryProducts.find((it) => it.id === product.id);
      if (newProduct.price !== product.price) {
        hasCartChanged = true;
        newCart[product.id].product = {
          ...newProduct,
          category: product.category,
          availableQuantity: product.availableQuantity,
          categoryCode: product.categoryCode,
        };
      }
    });
    if (hasCartChanged) {
      setCart(newCart);
    }
  }, [cart, productsByCategory]);

  const cartSubTotal = useMemo(
    () =>
      Object.values(cart).reduce(
        (acc, { quantity, product }) => acc + quantity * product.price,
        0
      ),
    [cart]
  );

  const availableDate = useMemo(() => getMaxAvailableDate(cart), [cart]);

  const preparationTime = useMemo(() => getMaxPreparationTime(cart), [cart]);
  const handleRecoveryInfoChange = useCallback(
    ({ recoveryInfo, valid }) => {
      setRecoveryInfo(recoveryInfo[producerId]);
      setIsRecoveryInfoValid(valid);
    },
    [producerId]
  );

  const handleCustomerFormChange = useCallback(
    ({ field, value }) => {
      const newCustomerInfo = { ...customerInfo, [field]: value };
      setCustomerInfo(newCustomerInfo);
      const isValid = validateCustomerInfo(newCustomerInfo, saleChannel);
      setIsCustomerInfoValid(isValid);
      if (isValid) {
        localStorageManager.setItem(
          LOCAL_STORAGE_KEYS.CUSTOMER_INFO,
          JSON.stringify(newCustomerInfo)
        );
      }
    },
    [customerInfo, setIsCustomerInfoValid, saleChannel]
  );

  const handleCommentChange = useCallback(
    (event) => {
      setComment(event.target.value);
    },
    [setComment]
  );

  const handleCartChange = useCallback(
    ({ product, quantity, size }) => {
      const newCart = { ...cart };
      let key = product.id;
      // if size attribute append key
      if (size) {
        key = `${product.id}_${size}`;
      }

      let isOlderThan18 = false;

      if (product.ageVerification) {
        setAgeRestrictedProduct({ product, quantity, size });
        isOlderThan18 = localStorage.getItem("isOlderThan18") === "true";
      }

      if (product.ageVerification && !isOlderThan18) {
        setIsOpenModal(true);
      } else {
        if (quantity === 0) {
          delete newCart[key];
        } else {
          newCart[key] = { quantity, product, size };
        }
        setCart(newCart);
        storeCartSimplifiedStore(newCart);
        setIsCartValid(
          Boolean(Object.values(newCart).find((it) => it.quantity > 0))
        );

        setDisableNextButton(false);
      }
    },
    [cart, setCart]
  );

  const orderDTO = useMemo(() => {
    const dto = {
      products: Object.values(cart).map(({ quantity, product, size }) => ({
        productCustom: product.id,
        quantity,
        size,
      })),
      paymentMethod: "_cash",
      orderDueTo: calculateDueTo(recoveryInfo),
      contactPoint:
        recoveryInfo &&
        recoveryInfo.contactPointInfo &&
        recoveryInfo.contactPointInfo.id,
      status: "_new",
      origin: constants.ORDER_ORIGIN.FORM,
      saleChannel: saleChannel,
      customer: { ...customerInfo },
      clientAddress: recoveryInfo.clientAddress,
      fees:
        recoveryInfo && recoveryInfo.delivery && recoveryInfo.delivery.rate
          ? [{ ...recoveryInfo.delivery, type: "DELIVERY" }]
          : [],
      comment: comment,
      codes,
      shipmentInfo: recoveryInfo && recoveryInfo.shipmentInfo,
      storeFrontConfigSlug: storeFrontSlug,
    };
    if (isGroup) {
      dto.group = store.id;
    } else {
      dto.producer = store.id;
    }
    return dto;
  }, [
    cart,
    recoveryInfo,
    saleChannel,
    customerInfo,
    store,
    isGroup,
    comment,
    codes,
    storeFrontSlug,
  ]);

  const handleStartPaymentProcess = useCallback(async () => {
    setIsPaymentBeingProcessed(true);
    setIsFormSubmitting(true);
  }, [setIsPaymentBeingProcessed, setIsFormSubmitting]);

  const handleCancelPaymentProcess = useCallback(async () => {
    setIsPaymentBeingProcessed(false);
    setIsFormSubmitting(false);
  }, [setIsPaymentBeingProcessed, setIsFormSubmitting]);

  const handleOrderSubmit = useCallback(
    async ({
      paymentMethod = constants.PAYMENT_METHOD.CASH,
      paymentConfirmation = false,
      transactionId,
      paymentProvider,
      additionalData,
      orders,
    }) => {
      setIsFormSubmitting(true);
      let orderNumber = undefined;
      try {
        if (paymentProvider === constants.PAYMENT_PROVIDER.MANGOPAY) {
          orderNumber = orders[0].orderNumber;
        } else {
          const order = await onSubmit({
            ...orderDTO,
            paymentMethod,
            paymentConfirmation,
            transactionId,
            paymentProvider,
            additionalData,
          });
          orderNumber = order.data.orderNumber;
        }

        history.push(`${url.replace(/\/?$/, "/")}success`, {
          orderNumber,
        });
        setCart({});
      } catch (e) {
        console.error(e);
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Une erreur s'est produite lors du traitement de votre commande",
        });
      }

      setIsFormSubmitting(false);
    },
    [onSubmit, orderDTO, history, url]
  );

  const handleSuccessfulPayment = useCallback(
    async ({
      paymentMethod,
      paymentConfirmation,
      transactionId,
      paymentProvider,
      additionalData,
      orders,
    }) => {
      setIsPaymentBeingProcessed(false);
      handleOrderSubmit({
        paymentMethod,
        paymentConfirmation,
        transactionId,
        paymentProvider,
        additionalData,
        orders,
      });
    },
    [handleOrderSubmit]
  );

  const handleAgeConfirmation = (isOlder) => {
    const isOlderStr = isOlder.toString();
    localStorage.setItem("isOlderThan18", isOlderStr);
    if (isOlder) {
      handleCartChange(ageRestrictedProduct);
    }
    setIsOpenModal(false);
  };

  const handleFailedPayment = useCallback(async () => {
    setIsPaymentBeingProcessed(false);
    await Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Une erreur s'est produite lors du paiement de votre commande",
    });
    setIsFormSubmitting(false);
  }, []);

  const disableNextButton = useCallback((value) => {
    setDisableNextButton(value);
  }, []);

  const memoizedSimplifiedCheckout = useMemo(
    () => (
      <SimplifiedCheckout
        producerId={producerId}
        storeName={store && store.storeName}
        contactPoints={contactPoints}
        cart={cart}
        onRecoveryInfoChange={handleRecoveryInfoChange}
        cartSubTotal={cartSubTotal}
        availableDate={availableDate}
        preparationTime={preparationTime}
        disableNextButton={disableNextButton}
        isSimplifiedStore={true}
      />
    ),
    [
      producerId,
      store,
      contactPoints,
      cart,
      handleRecoveryInfoChange,
      cartSubTotal,
      availableDate,
      preparationTime,
      disableNextButton,
    ]
  );
  const memoizedSimplifiedProductList = useMemo(
    () => (
      <SimplifiedProductList
        productsByCategory={productsByCategory}
        cart={cart}
        categories={categories}
        onChangeCart={handleCartChange}
        sizes={sizes}
        saleChannel={saleChannel}
      />
    ),
    [productsByCategory, cart, categories, handleCartChange, sizes, saleChannel]
  );
  const memoizedSimplifiedCustomerForm = useMemo(
    () => (
      <SimplifiedCustomerForm
        productsByCategory={productsByCategory}
        onChange={handleCustomerFormChange}
        saleChannel={saleChannel}
        {...customerInfo}
      />
    ),
    [customerInfo, productsByCategory, handleCustomerFormChange, saleChannel]
  );
  const memoizedSimplifiedOrderSummary = useMemo(
    () => (
      <SimplifiedOrderSummary
        cart={cart}
        deliveryRate={deliveryRate}
        isFormValid={isFormValid}
        isCustomerInfoValid={isCustomerInfoValid}
        isRecoveryInfoValid={isRecoveryInfoValid}
        isCartValid={isCartValid}
        onSubmit={handleOrderSubmit}
        isFormSubmitting={isFormSubmitting}
        saleChannel={saleChannel}
        comment={comment}
        onCommentChange={handleCommentChange}
        orderDTO={orderDTO}
        onStartPaymentProcess={handleStartPaymentProcess}
        onCancelPaymentProcess={handleCancelPaymentProcess}
        onSuccessfulPayment={handleSuccessfulPayment}
        onFailedPayment={handleFailedPayment}
        isPaymentBeingProcessed={isPaymentBeingProcessed}
        customerInfo={customerInfo}
        paymentMethods={store.paymentMethods}
        onChangeCart={handleCartChange}
        initialCodes={initialCodes}
        setCodes={setCodes}
        couponLines={couponLines}
        codeEvaluationStatus={codeEvaluationStatus}
        disableButton={disableNextButtonState}
        producerId={producerId}
      />
    ),
    [
      cart,
      deliveryRate,
      isFormValid,
      isCustomerInfoValid,
      isRecoveryInfoValid,
      isCartValid,
      handleOrderSubmit,
      isFormSubmitting,
      saleChannel,
      comment,
      handleCommentChange,
      orderDTO,
      handleStartPaymentProcess,
      handleSuccessfulPayment,
      handleFailedPayment,
      isPaymentBeingProcessed,
      customerInfo,
      store.paymentMethods,
      handleCartChange,
      initialCodes,
      setCodes,
      couponLines,
      codeEvaluationStatus,
      producerId,
      disableNextButtonState,
      handleCancelPaymentProcess,
    ]
  );
  if (isLoading) {
    return (
      <StyledContainer maxWidth="lg">
        <GlobalStyle isEmbedded={isEmbedded} />
        <SimplifiedHeaderLoader>
          <CardMedia
            component={() => (
              <Skeleton
                animation="wave"
                variant="rect"
                style={{ width: "100%", height: 200 }}
              />
            )}
          />
          <CardContent>
            <React.Fragment>
              <Skeleton
                animation="wave"
                height={16}
                style={{ marginBottom: 6 }}
              />
              <Skeleton animation="wave" height={16} width="80%" />
            </React.Fragment>
          </CardContent>
        </SimplifiedHeaderLoader>
        <SimplifiedProductListLoader>
          <CardContent>
            <LoadingHeaderContainer>
              <Skeleton animation="wave" height={32} width="30%" />
              <Skeleton animation="wave" height={32} width="30%" />
              <Skeleton animation="wave" height={32} width="30%" />
            </LoadingHeaderContainer>
            {[...Array(5).keys()].map((it) => (
              <LoadingRowContainer key={`loading-row-it-${it}`}>
                <Skeleton
                  animation="wave"
                  variant="circle"
                  width={40}
                  height={40}
                />
                <Skeleton animation="wave" height={16} width="60%" />
                <Skeleton animation="wave" height={16} width="30%" />
              </LoadingRowContainer>
            ))}
          </CardContent>
        </SimplifiedProductListLoader>
      </StyledContainer>
    );
  }
  const isStoreClosed = store.clientClosedStore;
  const messageStoreClosed = store.closedStoreMessage;

  const { clientId, storeName, city, bannerUrl } = store;

  return (
    <>
      <Meta
        title={`${storeName} - Commandez vos produits locaux sur kuupanda!`}
        description={`${storeName}, producteur à ${city}. Découvrez des produits locaux, autour de chez vous.`}
        keywords={`${storeName},local,produits,locaux,${city},circuit,court`}
        ogTitle={`${storeName} - Découvrez mes produits sur Kuupanda.`}
        ogDescription="Trouvez des producteurs locaux proches de chez vous et commandez sans intermédiaire pour des produits de qualité à des prix accessibles."
        ogURL={`https://commande.kuupanda.com/${
          isGroup ? "group" : "particulier"
        }/${clientId}/${
          saleChannel === constants.SALE_CHANNEL.PROFESSIONAL
            ? "pro"
            : "particulier"
        }`}
        ogImage={bannerUrl}
      />

      <StyledContainer maxWidth="lg">
        <GlobalStyle isEmbedded={isEmbedded} />
        <Switch>
          <Route
            exact
            path={[
              "/:storeType(producer|producteur|group)/:producerId/:saleChannelRaw",
              "/:storeType(producer|producteur|group)/:producerId/:saleChannelRaw/boutique/:storeFrontSlug",
            ]}
          >
            <SimplifiedHeader
              bannerUrl={store.bannerUrl}
              categories={categories}
              description={store.presentation}
              email={store.email}
              name={store.storeName}
              phone={store.phone}
              producer={store.fullName}
              profilePicture={store.profileUrl}
            />
            {isStoreClosed && (
              <StyledBox>
                {messageStoreClosed && messageStoreClosed.length > 0
                  ? messageStoreClosed
                  : "Désolé mais la boutique est fermée. On se charge de revenir au plus vite avec des produits toujours plus savoureux !"}
              </StyledBox>
            )}
            <StyledContent>
              <AgeConfirmationModal
                isOpen={isOpenModal}
                handleCloseModal={() => setIsOpenModal(false)}
                handleConfirmation={handleAgeConfirmation}
              />
              <Grid container justify="center">
                {!isStoreClosed && memoizedSimplifiedProductList}
                {!isStoreClosed && memoizedSimplifiedCustomerForm}
                {!isStoreClosed && memoizedSimplifiedCheckout}
                {!isStoreClosed && memoizedSimplifiedOrderSummary}
              </Grid>
            </StyledContent>
          </Route>
          <Route
            exact
            path={[
              "/:storeType(producer|producteur|group)/:producerId/:saleChannelRaw/success",
              "/:storeType(producer|producteur|group)/:producerId/:saleChannelRaw/boutique/:storeFrontSlug/success",
            ]}
          >
            <SuccessContainer isEmbedded={isEmbedded}>
              <Typography variant="h3">
                Commande {location.state && location.state.orderNumber}{" "}
                confirmée!
              </Typography>
              <Typography variant="h5">
                Vous allez recevoir un email de confirmation dans les prochaines
                minutes!
              </Typography>
              <GoBackButton
                variant="contained"
                color="primary"
                onClick={() => history.goBack()}
              >
                Passer une nouvelle commande
              </GoBackButton>
            </SuccessContainer>
          </Route>
        </Switch>
      </StyledContainer>
    </>
  );
}

SimplifiedStore.propTypes = {
  producerId: PropTypes.string.isRequired,
  contactPoints: PropTypes.array,
  categories: PropTypes.array,
  productsByCategory: PropTypes.array,
  sizes: PropTypes.array,
  store: PropTypes.object,
  isLoading: PropTypes.bool,
  saleChannel: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  isGroup: PropTypes.bool.isRequired,
  validCodes: PropTypes.arrayOf(PropTypes.string),
  couponLines: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      discount: PropTypes.number,
      discountApplied: PropTypes.object,
    })
  ),
  setCartAmount: PropTypes.func.isRequired,
  setCodes: PropTypes.func.isRequired,
  initialCodes: PropTypes.arrayOf(PropTypes.string),
};

function calculateInitialCartAmount() {
  return 0;
}

function ConnectedSimplifiedStore(props) {
  let { saleChannelRaw, producerId, storeType, storeFrontSlug } = useParams();

  const location = useLocation();
  const queryParams = queryString.parse(location.search, {
    arrayFormat: "bracket",
  });
  // switch to embedded using get params
  const embedded = useMemo(() => queryParams.embedded, [queryParams.embedded]);
  const initialCodes = useMemo(
    () => queryParams.codes || [],
    [queryParams.codes]
  );
  const initialCartAmount = calculateInitialCartAmount();
  const isGroup = storeType === "group";
  const saleChannel =
    saleChannelRaw === "pro"
      ? constants.SALE_CHANNEL.PROFESSIONAL
      : constants.SALE_CHANNEL.STANDARD;
  const sizes = SIZES;
  const { apiRoot } = useApiConfig();
  const { bannerUrl } = props;
  const {
    store,
    isLoading,
    products,
    producer,
    categories,
    validCodes,
    couponLines,
    setCodes,
    setCartAmount,
    codesEvaluated,
    codes,
  } = useStoreInfo(
    producerId,
    apiRoot,
    saleChannel,
    isGroup,
    bannerUrl,
    constants.COMPONENT.FORM,
    initialCodes,
    initialCartAmount,
    true,
    storeFrontSlug
  );
  const submitForm = useCallback(
    async (orderDTO) => {
      const submitURL = isGroup
        ? `${apiRoot}/group-order/form/createGroupOrder`
        : `${apiRoot}/order/form/createOrder`;

      return await httpClient.post(submitURL, orderDTO);
    },
    [apiRoot, isGroup]
  );
  return (
    <PresentationContext.Provider value={{ isEmbedded: embedded }}>
      <SimplifiedStore
        producerId={producerId}
        isLoading={isLoading}
        productsByCategory={products}
        categories={categories}
        store={store}
        contactPoints={(producer && producer.contactPoints) || []}
        saleChannel={saleChannel}
        onSubmit={submitForm}
        isGroup={isGroup}
        validCodes={validCodes}
        couponLines={couponLines}
        setCartAmount={setCartAmount}
        setCodes={setCodes}
        initialCodes={initialCodes}
        codesEvaluated={codesEvaluated}
        codes={codes}
        sizes={sizes}
      />
    </PresentationContext.Provider>
  );
}

ConnectedSimplifiedStore.propTypes = {
  producerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  apiRoot: PropTypes.string,
  bannerUrl: PropTypes.string,
  onAddToCart: PropTypes.func,
  onRemoveFromCart: PropTypes.func,
  isGroup: PropTypes.bool,
  saleChannel: PropTypes.oneOf([
    constants.SALE_CHANNEL.STANDARD,
    constants.SALE_CHANNEL.PROFESSIONAL,
  ]),
  simplified: PropTypes.bool,
};
ConnectedSimplifiedStore.defaultProps = {
  producerId: 29,
  apiRoot: "https://api.kuupanda.com",
  //apiRoot: "http://localhost:3030",
  bannerUrl:
    "https://s3.eu-west-3.amazonaws.com/kuu.imgs/original/user/farm/48db9a161bdd1e9b1e03063bb1a6fd68cd2cd7fd92fa433d05017064ef32ac2f.png",
  saleChannel: constants.SALE_CHANNEL.STANDARD,
  isGroup: false,
  simplified: true,
};

export default ConnectedSimplifiedStore;
