import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Avatar,
  Grid,
  Hidden,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ResponsivePicture from "../Common/ResponsivePicture";
import { InnerPaper } from "./styles";
import PresentationContext from "../Context/PresentationContext";
import { CartShape } from "../Checkout/proptypes";
import { ProductModal } from "../VendorStore/views/products/ProductModal";
import DiscountBadge from "./DiscountBadge";
import MediaSlider from "../Common/MediaSlider";
import { Quantity, QuantityEmbedded } from "../Common/Quantity";
import SizeBox from "../Common/SizeBox";
import constants from "../VendorStore/utils/constants";
import RestoreIcon from "@material-ui/icons/Restore";
import { closeModalIframe, setModalIframePosition } from "../Helpers/modal";
import { NumberFormatCustom } from "@kuupanda/commons";
import media from "../Helpers/media";

const Container = styled.div`
  position: relative;
`;

// When category isn't embedded
const StyledPaper = styled(InnerPaper)`
  display: flex;
  flex-direction: row;
  padding: 15px 10px;
  margin: 0 20px 10px 0;
  justify-content: space-between;
  align-items: center;
  opacity: ${(props) => props.disabled && "0.5"};
  width: 100%;
  @media (max-width: 600px) {
    font-size: 0.8rem;
    margin: 0 0 20px 0;
  }
`;

const StyledResponsiveProfilePicture = styled(ResponsivePicture)`
  border-radius: 100%;
  @media (min-width: 600px) {
    width: 50px;
  }
  width: 50px;
`;

const NameStyled = styled.span`
  font-weight: 600;
  font-size: 15px;
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  padding: 0px 15px;
`;

const ProductNameContainer = styled.div`
  flex: 1 0 0;
  width: fit-content;
`;

const ProductBasePrice = styled.div`
  text-decoration: line-through;
  font-size: 13px;
  ${({ theme }) => `
    color: ${theme.palette.grey.A200};
  `}
`;

const StyledPrice = styled.div`
  @media (max-width: 600px) {
    margin-top: 15px;
  }
`;

const ProductBasePriceEmbedded = styled(ProductBasePrice)`
  margin-left: 5px;
`;

const Units = styled.span`
  ${({ theme }) => `
    color: ${theme.palette.grey["700"]};
  `}
  font-weight: 500;
  font-size: 16px;
  margin-left: 5px;
`;

const SizeBoxHolder = styled.div`
  position: absolute;
  right: 15px;
  top: 10px;
`;

function getPriceFormat({
  price,
  unit,
  priceBeforeReduction,
  showOneLine,
  basePrice,
  hasReduction,
  isProfessional,
}) {
  if (showOneLine) {
    return (
      <div>
        <StyledPrice>
          <NumberFormatCustom value={price} displayType={"text"} />{" "}
          {isProfessional && "HT"} /{" "}
          {unit === "unit" ? "unité" : unit === "l" ? "litre" : unit}
        </StyledPrice>
        {hasReduction && (
          <ProductBasePrice>
            <NumberFormatCustom
              value={priceBeforeReduction}
              displayType={"text"}
            />{" "}
            {isProfessional && "HT"} /{" "}
            {unit === "unit" ? "unité" : unit === "l" ? "litre" : unit}
          </ProductBasePrice>
        )}
      </div>
    );
  } else {
    return (
      <>
        <Typography variant="subtitle1" component="div">
          <PriceEmbeddedContainer>
            <PriceEmbedded>
              <NumberFormatCustom value={price} displayType={"text"} />
            </PriceEmbedded>
            <Units>
              {` / ${
                unit === "unit" ? "unité" : unit === "l" ? "litre" : unit
              }`}
            </Units>
            {hasReduction && (
              <ProductBasePriceEmbedded>
                <NumberFormatCustom
                  value={priceBeforeReduction}
                  displayType={"text"}
                />{" "}
              </ProductBasePriceEmbedded>
            )}
          </PriceEmbeddedContainer>
        </Typography>
      </>
    );
  }
}

// When category is embedded
const StyledGridSquare = styled(Grid)`
  min-width: 33.333333%;
`;

const StyledPaperSquare = styled(Paper)`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 6px 12px rgba(130, 126, 126, 0.17);
  border: 1px solid rgba(30, 126, 126, 0.08);
  height: 255px;
  width: 100%;
  border-radius: 8px;
  margin: 0 10px 10px 0;
  opacity: ${(props) => props.disabled && "0.5"};
`;

const MediaSliderContainer = styled.div`
  width: 100%;
  height: 180px;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  cursor: pointer;
  position: relative;
`;

const CategorySquareContainer = styled.div`
  padding: 10px;
`;

const InformationContainer = styled.div`
  display: flex;
`;

const PriceQuantityContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PriceEmbedded = styled.span`
  color: ${({ theme }) => theme.palette.warning.dark};
  font-size: 19px;
  font-weight: 500;
`;

const PriceEmbeddedContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDiscountBadge = styled(DiscountBadge)`
  position: absolute;
  background-color: ${({ theme }) => theme.palette.secondary.dark};
  top: 10px;
  left: 0;
  ${media.down.md`
    top: 0;
    right: 0;
    left: unset;
  `}
`;

const AvailableDateContainer = styled.span`
  font-style: italic;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: 12px;
  margin-top: 5px;
  border: ${(props) =>
    props.isEmbedded &&
    `1px ${({ theme }) => theme.palette.secondary.main} solid`};
  padding: ${(props) => props.isEmbedded && "1px 5px"};
  border-radius: 5px;
  display: ${(props) => (props.isEmbedded ? "inline-block" : "flex")};
  vertical-align: middle;
  align-items: center;
  span {
    margin: 0 3px;
  }
`;

const ProducerNameText = styled(Typography)`
  font-weight: 600 !important;
  line-height: 1 !important;
  font-size: 12px !important;
  color: white;
  background-color: #e6426bc7;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 210px;
  overflow: hidden;
  width: max-content;
  @media (max-width: 600px) {
    padding: 5px 10px;
    margin-bottom: 5px;
`;

const StyledRestoreIcon = styled(RestoreIcon)`
  vertical-align: middle;
`;

function Category({ product, cart, onChange, saleChannel }) {
  // better to have an array rather than a string for sizes
  product = {
    ...product,
    productSizesList: product.sizes,
  };

  const {
    availableQuantity,
    id,
    name,
    price,
    baseUnit,
    picture,
    pictures,
    basePrice,
    priceBeforeReduction,
    priceReduction,
    productSizesList,
  } = product;

  const quantity = (cart[id] && cart[id].quantity) || 0;
  const isAvailable = availableQuantity > 0;
  const { isEmbedded } = useContext(PresentationContext);
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [showAvailableDate, setShowAvailableDate] = useState(false);
  const [availableDate, setAvailableDate] = useState("");
  const theme = useTheme();

  useEffect(() => {
    if (product.preparationType === constants.PREPARATION_TYPE.SPECIFIC_DATE) {
      const availableDateTime = new Date(product.availableDate).getTime();
      if (Date.now() <= availableDateTime) {
        setShowAvailableDate(true);
        try {
          const date = new Date(product.availableDate);
          const day = ("0" + date.getDate()).slice(-2);
          const month = ("0" + (date.getMonth() + 1)).slice(-2);
          const availableDate = `${day}/${month}`;
          setAvailableDate(availableDate);
        } catch (error) {
          setShowAvailableDate(false);
        }
      }
    }
  }, [product]);

  const isProductModalFullScreen = useMediaQuery(theme.breakpoints.down("xs"), {
    noSsr: true,
  });
  const onAddProduct = useCallback(
    ({ size, quantity }) => onChange({ product, quantity, size }),
    [onChange, product]
  );
  const onRemoveProduct = useCallback(
    ({ size, quantity }) => onChange({ product, quantity, size }),
    [onChange, product]
  );

  const closeProductModal = useCallback(() => {
    setIsProductModalOpen(false);
    closeModalIframe(isEmbedded, isProductModalFullScreen);
  }, [isEmbedded, isProductModalFullScreen]);

  const onProductClick = useCallback(() => {
    if (!isProductModalOpen) {
      setIsProductModalOpen(true);
      setModalIframePosition(
        isEmbedded,
        isProductModalFullScreen,
        "kuu-product-modal"
      );
    }
  }, [
    isEmbedded,
    isProductModalFullScreen,
    isProductModalOpen,
    setIsProductModalOpen,
  ]);

  const productModalProduct = useMemo(
    () => ({ ...product, price: product.price }),
    [product]
  );
  let list;
  if (!isEmbedded) {
    list = (
      <Grid container item xs={12} style={{ height: 115 }}>
        <StyledPaper variant="outlined" elevation={0} disabled={!isAvailable}>
          <Grid
            container
            item
            alignItems="center"
            md={6}
            xs={7}
            direction="row"
            style={{ cursor: "pointer" }}
            onClick={onProductClick}
          >
            <Hidden smDown>
              <AvatarContainer>
                <Avatar
                  alt={name}
                  component={() => (
                    <StyledResponsiveProfilePicture
                      fitType="contain"
                      containerStyle={{ width: "auto" }}
                      height={50}
                      sizeQuery="50px"
                      baseImgUrl={picture.url}
                    />
                  )}
                />
              </AvatarContainer>
            </Hidden>
            <ProductNameContainer>
              {product.isProductGroup &&
                product.producer &&
                product.producer.storeName && (
                  <ProducerNameText>
                    {product.producer.storeName}
                  </ProducerNameText>
                )}
              <Typography variant="body" color={"body"}>
                <NameStyled>{name}</NameStyled>
                {!isAvailable && " (Non disponible)"}
              </Typography>

              {showAvailableDate && availableDate && (
                <AvailableDateContainer>
                  <StyledRestoreIcon />
                  <span>disponible à partir du</span>
                  {availableDate}
                </AvailableDateContainer>
              )}

              <Hidden smUp>
                {getPriceFormat({
                  basePrice: basePrice,
                  priceReduction: priceReduction,
                  priceBeforeReduction: priceBeforeReduction,
                  price: price,
                  unit: baseUnit,
                  showOneLine: true,
                  hasReduction: priceReduction > 0,
                  isProfessional:
                    saleChannel === constants.SALE_CHANNEL.PROFESSIONAL,
                })}
              </Hidden>
            </ProductNameContainer>
          </Grid>

          <Hidden smDown>
            <Grid container item justify="flex-end" md={3}>
              {getPriceFormat({
                basePrice: basePrice,
                priceReduction: priceReduction,
                priceBeforeReduction: priceBeforeReduction,
                price: price,
                unit: baseUnit,
                showOneLine: true,
                hasReduction: priceReduction > 0,
                isProfessional:
                  saleChannel === constants.SALE_CHANNEL.PROFESSIONAL,
              })}
            </Grid>
          </Hidden>

          <Grid container item justify="flex-end" xs={4} md={2}>
            <Quantity
              availableQuantity={availableQuantity}
              id={id}
              onChange={onChange}
              product={product}
              value={quantity}
            />
          </Grid>
        </StyledPaper>
      </Grid>
    );
  } else {
    list = (
      <StyledGridSquare container item xs={12}>
        <StyledPaperSquare
          variant="outlined"
          elevation={0}
          disabled={!isAvailable}
        >
          <MediaSliderContainer onClick={onProductClick}>
            {product.isProductGroup &&
              product.producer &&
              product.producer.storeName && (
                <ProducerNameText>
                  {product.producer.storeName}
                </ProducerNameText>
              )}
            <MediaSlider pictures={pictures} height={180} />
          </MediaSliderContainer>
          <CategorySquareContainer>
            <InformationContainer>
              <Typography variant="body2">
                <NameStyled>{name}</NameStyled>
                {!isAvailable && " (Non disponible)"}
              </Typography>
            </InformationContainer>
            {showAvailableDate && availableDate && (
              <AvailableDateContainer isEmbedded={true}>
                <StyledRestoreIcon />
                <span>disponible à partir du</span>
                {availableDate}
              </AvailableDateContainer>
            )}
            <PriceQuantityContainer>
              <div>
                {getPriceFormat({
                  basePrice: basePrice,
                  priceReduction: priceReduction,
                  priceBeforeReduction: priceBeforeReduction,
                  price: price,
                  unit: baseUnit,
                  showOneLine: false,
                  hasReduction: priceReduction > 0,
                  isProfessional:
                    saleChannel === constants.SALE_CHANNEL.PROFESSIONAL,
                })}
              </div>
              <QuantityEmbedded
                availableQuantity={availableQuantity}
                id={id}
                onChange={onChange}
                product={product}
                value={quantity}
              />
            </PriceQuantityContainer>
            <SizeBoxHolder>
              {productSizesList && productSizesList.length > 0 && (
                <SizeBox sizes={productSizesList} />
              )}
            </SizeBoxHolder>
          </CategorySquareContainer>
        </StyledPaperSquare>
      </StyledGridSquare>
    );
  }
  return (
    <Container>
      {isProductModalOpen && (
        <ProductModal
          cart={cart}
          product={productModalProduct}
          onClose={closeProductModal}
          open={isProductModalOpen}
          onAdd={onAddProduct}
          onRemove={onRemoveProduct}
          quantity={quantity}
          availableDate={availableDate}
          isAvailable={isAvailable}
        />
      )}
      <StyledDiscountBadge
        percentage={Math.round((priceReduction / basePrice) * 100) / 100}
      />
      {list}
    </Container>
  );
}

Category.propTypes = {
  cart: CartShape.isRequired,
  onChange: PropTypes.func.isRequired,
  product: PropTypes.shape({
    availableQuantity: PropTypes.number,
    category: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    picture: PropTypes.shape({
      url: PropTypes.string,
      default: true,
    }),
    price: PropTypes.shape({
      price: PropTypes.string,
      unit: PropTypes.string,
    }),
    unit: PropTypes.string,
  }).isRequired,
};

export default Category;
